import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function ItemName({ name, isActive }) {
  return (
    <span className={classNames(styles.name, isActive && styles.active)}>{name}</span>
  );
}

ItemName.propTypes = {
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

export default ItemName;
