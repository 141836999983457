import slugs from 'components/Navigation/menuData/slugs';

import { RELATED_APPS_SECTION } from './common';

const FEDERAL_SECTION = {
  id: 'federal',
  title: 'Federal',
  items: [
    {
      title: 'Federal legislators',
      description: 'Members of Congress',
      href: '/federal/legislators',
      slug: slugs.federal,
    },
    {
      title: 'Federal staffers',
      description: 'Congressional and committee staffers',
      href: '/federal/congressional_staffers',
      slug: slugs.federalStaffers,
    },
    {
      title: 'Agency staffers',
      description: 'Staffers of regulatory agencies',
      href: '/federal/agency_staffers',
      slug: slugs.federalStaffers,
    },
  ],
};

const GENERAL_SECTION = {
  id: 'general',
  title: 'General',
  items: [
    {
      title: 'Stakeholder management',
      description: 'View and manage stakeholders your organization has imported',
      ...(gon.isAdvocatesEnabled ? {
        href: '/advocates',
        slug: slugs.advocates,
      } : {
        href: '/stakeholder_management/directory',
        slug: slugs.stakeholderManagement,
      }),
    },
  ],
};

const STATE_SECTION = {
  id: 'state',
  title: 'State',
  items: [
    {
      title: 'State staffers',
      description: 'State legislative & committee staffers',
      href: '/state/staffers',
      slug: slugs.state,
    },
    {
      title: 'State legislators',
      description: 'State Upper/Lower/Joint legislators',
      href: '/state/legislators',
      slug: slugs.state,
    },
    {
      title: 'State executives',
      description: 'Governor, State Department Executives and Staff',
      href: '/state/executives',
      slug: slugs.state,
    },
  ],
};

const LOCAL_SECTION = {
  id: 'local',
  title: 'Local',
  items: [
    {
      title: 'Local executives',
      description: 'County/Municipal Legislators and Officials',
      href: '/local/executives',
      slug: slugs.localExecutives,
    },
  ],
};

const RELATED_SECTION = {
  ...RELATED_APPS_SECTION,
  items: [
    {
      title: 'District maps',
      description: 'Find a legislator by geographic district',
      href: '/district_map',
      slug: slugs.districtMap,
    },
    {
      title: 'Legislator spreasheet builder',
      description: 'Analyze data by dimensions and attributes',
      href: '/federal/congregator',
      slug: slugs.federal,
    },
    {
      title: 'State legislator batting average',
      description: 'Success rate for bill passage',
      href: '/state/batting_averages',
      slug: slugs.state,
    },
    {
      title: 'Custom fields',
      description: 'Create and manage custom fields for your notes and stakeholder records',
      href: '/custom_fields',
      slug: slugs.customFields,
    },
  ],
};

export const PEOPLE_COLUMNS = [
  [FEDERAL_SECTION, GENERAL_SECTION],
  [STATE_SECTION, LOCAL_SECTION],
  [RELATED_SECTION],
];

export const PEOPLE_SECTIONS = [
  FEDERAL_SECTION,
  STATE_SECTION,
  GENERAL_SECTION,
  RELATED_SECTION,
];
