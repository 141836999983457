import {
  REPUBLICAN,
  DEMOCRATIC,
  OTHER,
} from 'components/Moneyball/utils/constants';

const dataParties = [
  {
    label: 'Republican',
    value: REPUBLICAN,
  },
  {
    label: 'Democratic',
    value: DEMOCRATIC,
  },
  {
    label: 'Other',
    value: OTHER,
  },
];

export default dataParties;
