import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import Link from 'components/core/Link';
import { EMPTY_VALUE } from 'utils/constants';

import styles from './styles.module.scss';

function DisplayLink({ href, label, isExternal, className }) {
  if (isEmpty(label)) {
    return EMPTY_VALUE;
  }

  if (isEmpty(href)) {
    return label;
  }

  return (
    <Link
      theme="default"
      isExternal={isExternal}
      href={href}
      className={classNames(styles.baseLink, className)}
    >
      {label}
    </Link>
  );
}

DisplayLink.defaultProps = {
  isExternal: true,
};

DisplayLink.propTypes = {
  href: PropTypes.string,
  label: PropTypes.node,
  isExternal: PropTypes.bool,
  className: PropTypes.string,
};

export default DisplayLink;
