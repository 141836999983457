import { get } from 'lodash';

import { ApiResult } from 'components/UnifiedSearch/useSearch/types';
import { HighlightedString } from 'components/UnifiedSearch/types';

import { getHighlightedFields } from '../../base';

type Highlights = {
  fullName?: HighlightedString,
  title?: HighlightedString,
  branch?: HighlightedString,
  description?: HighlightedString,
};

export const getHighlights = (result: ApiResult): Highlights => {
  const highlightedFields = getHighlightedFields(result);

  const [fullName] = get(highlightedFields, 'full_name', []);
  const [title] = get(highlightedFields, 'executive_positions.title', []);
  const [branch] = get(highlightedFields, 'executive_positions.additional_info.branch', []);
  const [description] = get(highlightedFields, 'biography', []);

  return { fullName, title, branch, description };
};
