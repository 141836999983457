import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function UserGroup({ className }) {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.icon, className)}>
      <path d="M5.95 6.8C7.82797 6.8 9.35 5.2777 9.35 3.4C9.35 1.5223 7.82797 0 5.95 0C4.07203 0 2.55 1.5223 2.55 3.4C2.55 5.2777 4.07203 6.8 5.95 6.8ZM7.29672 8.075H4.60328C2.06045 8.075 0 10.1363 0 12.6783C0 13.1883 0.41225 13.6 0.920656 13.6H10.9783C11.4883 13.6 11.9 13.1883 11.9 12.6783C11.9 10.1363 9.83875 8.075 7.29672 8.075ZM12.7261 8.5H10.7645C11.985 9.50141 12.75 10.9995 12.75 12.6783C12.75 13.0183 12.6491 13.3317 12.4844 13.6H16.15C16.6202 13.6 17 13.2175 17 12.7261C17 10.4019 15.0981 8.5 12.7261 8.5ZM11.475 6.8C13.1192 6.8 14.45 5.46922 14.45 3.825C14.45 2.18078 13.1192 0.85 11.475 0.85C10.808 0.85 10.1989 1.07724 9.70275 1.44792C10.0087 2.03548 10.2 2.69344 10.2 3.4C10.2 4.3435 9.88311 5.20997 9.36089 5.91573C9.89984 6.46 10.6463 6.8 11.475 6.8Z" />
    </svg>
  );
}

UserGroup.propTypes = {
  className: PropTypes.string,
};

export default UserGroup;
