import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SuggesterFillerButton from './suggester-filler-button.jsx';
import Autosuggest from 'components/Autosuggest/index.jsx';

export default class AutofilledAutosuggester extends Component {
  static propTypes = {
    fillerStrategy: PropTypes.string.isRequired,
    strategy: PropTypes.string.isRequired,
    buttonGroupClassName: PropTypes.string.isRequired,
  }

  state = {
    selectedIds: [],
  }

  updateSelectedIds = (selectedIds) => {
    this.setState({ selectedIds });
  }

  render() {
    const {
      strategy,
      fillerStrategy,
      buttonGroupClassName,
      ...otherProps
  } = this.props;

    return (
      <div>
        <SuggesterFillerButton
          {...this.props}
          strategySlug={fillerStrategy}
          onClick={this.updateSelectedIds}
          groupClassName={buttonGroupClassName}
        />

        <Autosuggest
          {...otherProps}
          onChange={this.updateCollection}
          strategy={strategy}
          defaultRawValues={this.state.selectedIds}
        />
      </div>
    );
  }
}
