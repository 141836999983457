import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const ArrowDropDown = ({ className }) => (
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.icon, className)}>
    <path d="M7.76418 2.16499L4.58668 5.36499C4.40918 5.52249 4.20418 5.59999 3.99918 5.59999C3.79418 5.59999 3.58968 5.52186 3.43343 5.36561L0.255934 2.16561C0.00479379 1.93749 -0.0637162 1.59249 0.0601338 1.29499C0.183984 0.997488 0.475934 0.799988 0.799184 0.799988H7.17668C7.50018 0.799988 7.79218 0.994513 7.91618 1.29374C8.04018 1.59296 7.99418 1.93749 7.76418 2.16499Z" fill="black" />
  </svg>
);

ArrowDropDown.propTypes = {
  className: PropTypes.string,
};

export default ArrowDropDown;
