export const mapboxStyle = 'mapbox://styles/hpetru/cliu4b1wi001n01o8ef8w77lt';

export const mapboxLayers = [
  'districts-118-1-12',
  'districts_1_label', 'districts_2_label', 'districts_3_label',
  'districts_4_label', 'districts_5_label',
  'districts_boundary_line',
  'districts_1_boundary', 'districts_2_boundary', 'districts_3_boundary',
  'districts_4_boundary', 'districts_5_boundary',
  'districts_1', 'districts_2', 'districts_3',
  'districts_4', 'districts_5',
];

export const mapboxUrl = 'mapbox://hpetru.40cqttzv';

export default {
  CONGRESSIONAL_DISTRICTS: 'cd',
  STATE_LEGISLATION_UPPER: 'sldu',
  STATE_LEGISLATION_LOWER: 'sldl',
  US_CENTER: [-87.65, 41.85], // US center position [lng, lat]
};
