export const DEFAULT_STATE = {
  items: [],
  selectedItems: [],
  searchForm: {},
  pagination: {
    total_pages: 0,
    total_count: 0,
  },
  ui: {
    loading: false,
  },
};
