import { Region, Slug } from 'components/UnifiedSearch/types';

export const REGION_SLUGS: { [key in Region]?: Slug[] } = {
  federal: [
    'federal_legislators',
    'rolodex',
    'federal_executive',
    'federal_bills',
    'federal_register_regulations',
    'federal_committees',
    'doctrove_agency_press_releases',
    'cbo_gov',
    'committee_reports',
    'press_releases',
    'congressional_record',
    'fbo_gov',
    'doctrove_crs',
    'doctrove_dear_colleague_letters',
    'doctrove_executive_orders',
    'gao_gov',
    'grants_gov',
    'doctrove_live_hearing_transcripts',
    'doctrove_hearing_transcripts',
    'doctrove_sap',
    'doctrove_union_statements',
    'doctrove_third_party_reports',
    'treaties',
    'doctrove_press_briefings',
  ],
  state: [
    'state_legislation_legislators',
    'state_legislation_staffers',
    'state_executive',
    'local_executive',
    'stakeholder_mgmt_stakeholders',
    'state_legislation_bills',
    'state_legislation_regulations',
    'state_legislation_committees',
    'doctrove_governor_orders',
    'doctrove_governor_press_releases',
    'townintel',
  ],
  local: [
    'local_executive',
    'stakeholder_mgmt_stakeholders',
    'townintel',
  ],
};
