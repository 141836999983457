import React, { FC } from 'react';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

type HeaderProps = {
  title: string,
  toggleOpen: () => void,
};

const Header: FC<HeaderProps> = ({ title, toggleOpen }) => (
  <div className={styles.title}>
    {title}
    <Button onClick={toggleOpen} className={styles.closeButton} theme="light">
      <i className="fal fa-times" />
    </Button>
  </div>
);

export default Header;
