import { error, success } from 'components/core/Notification';

export default {
  defaultFetchDataFailed: error('An error occurred during the data loading. Please try again.'),
  exportEventSuccess: success('Data has been exported successfully.'),
  exportEventFailed: error('Data export failed. Please try again.'),
  deleteFeedFailed: error('Failed to delete feed. Please try again.'),
  deleteFeedSuccess: success('Feed has been deleted successfully.'),
  createFeedSuccess: success('Feed has been created successfully.'),
  createFeedFailed: error('Failed to create feed. Please try again.'),
  updateFeedFailed: error('Failed to update feed. Please try again.'),
  updateFeedSuccess: success('Feed has been updated successfully.'),
  subscriptionFailed: error('Failed to subscribe to feed. Please try again.'),
  feedNotFound: error('Failed to load feed. It has been deleted or does not exist.'),
};
