import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import moment from 'moment';
import PropTypes from 'prop-types';
import Datepicker from 'react-datepicker';

import { formatDateBy } from 'utils/date';
import coreDatePickerConstants from 'components/core/DatePicker/constants';

import { dateFormat, dateTimeFormat } from './helper';

export default class SingleDatePicker extends Component {
  state = {
    selectedDate: this.initiateSelectedDate(),
  };

  pickerProps() {
    return omit(this.props, [
      'value',
      'handleChange',
    ]);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (!isEqual(prevProps.value, value)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedDate: this.initiateSelectedDate() });
    }
  }

  initiateSelectedDate() {
    const { value, format } = this.props;

    if (!value) {
      return null;
    }

    if (typeof value === 'object') {
      return value;
    }

    return moment(value, format);
  }

  handleChange = (date) => {
    const { format, handleChange } = this.props;
    let value = date;
    if (date) {
      value = formatDateBy(date, format || dateFormat);
    }

    this.setState({ selectedDate: date }, () => handleChange && handleChange(value));
  };

  handleInputChangeRaw = (e) => {
    const { format } = this.props;

    const date = moment(e.target.value, format);

    if (date.isValid()) {
      this.handleChange(date);
    }
  };

  handleFocusOut = (...args) => {
    const { handleFocusOut } = this.props;
    if (handleFocusOut) {
      handleFocusOut(args);
    }
  };

  defaultPlaceholderText() {
    const { format } = this.props;
    return format.toLowerCase();
  }

  label() {
    const { label, labelClassName } = this.props;
    if (!label) {
      return null;
    }

    return <label className={`${labelClassName} control-label`}> {label} </label>;
  }

  render() {
    const {
      inputWrapperClassName,
      placeholderText,
      groupClassName,
      inputClassName,
      dateFNSFormat,
    } = this.props;

    const { selectedDate } = this.state;

    const pickerProps = {
      ...this.pickerProps(),
      placeholderText: placeholderText || this.defaultPlaceholderText(),
    };

    const selected = selectedDate && selectedDate.toDate ? selectedDate.toDate() : selectedDate;

    return (
      <div className={`single-datepicker ${groupClassName}`}>
        {this.label()}
        <div className={inputWrapperClassName}>
          <Datepicker
            className={inputClassName}
            {...pickerProps}
            dateFormat={dateFNSFormat}
            selected={selected}
            onChange={this.handleChange}
            onChangeRaw={this.handleInputChangeRaw}
            onBlur={this.handleFocusOut}
          />
        </div>
      </div>
    );
  }
}

SingleDatePicker.defaultProps = {
  format: dateFormat,
  inputWrapperClassName: 'col-md-8',
  labelClassName: 'col-md-4',
  groupClassName: 'form-group',
  inputClassName: 'form-control',
  dateFNSFormat: coreDatePickerConstants.DATE_FORMAT,
};

SingleDatePicker.propTypes = {
  label: PropTypes.string,
  format: PropTypes.oneOf([
    dateFormat,
    dateTimeFormat,
  ]),
  dateFNSFormat: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  handleChange: PropTypes.func,
  handleFocusOut: PropTypes.func,
  inputWrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  groupClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholderText: PropTypes.string,
};
