import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Bells({ className }) {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.icon, className)}>
      <path d="M6.62733 8.11288C6.93812 8.98242 6.96999 9.93124 6.72296 10.8226L6.69108 10.9293L0.568162 8.63515C0.266146 8.52304 0.0524507 8.24413 0.0157174 7.91601C-0.0210265 7.58788 0.125577 7.26523 0.394708 7.08202L0.61199 6.9371C1.53026 6.29179 2.22991 5.40859 2.60736 4.34765L2.99624 3.2539C3.64171 1.37921 5.66046 0.412611 7.50655 1.09593C7.63405 1.14351 7.7589 1.1982 7.87577 1.2589C6.17311 2.41171 5.39749 4.65663 6.13061 6.71288L6.62733 8.11288ZM6.92749 6.41484C6.23421 4.46523 7.20905 2.30808 9.10561 1.59769C10.9995 0.887572 13.1006 1.89245 13.7939 3.84179L14.2906 5.24179C14.6625 6.28632 15.3478 7.1832 16.2456 7.80117L16.6122 8.05273C16.8778 8.23593 17.0212 8.53398 16.9841 8.88398C16.9442 9.2121 16.7317 9.46367 16.4316 9.60038L8.52921 12.5617C8.22905 12.6738 7.89171 12.6027 7.65796 12.3785C7.42421 12.1543 7.33124 11.8125 7.4189 11.498L7.54108 11.0633C7.83858 9.99687 7.79874 8.83476 7.42686 7.81484L6.92749 6.41484ZM12.7712 13.125C12.2081 13.125 11.7087 12.8488 11.398 12.4168L14.4819 11.2629C14.4845 11.2984 14.4845 11.334 14.4845 11.3695C14.4845 12.3402 13.7169 13.125 12.7712 13.125ZM2.5059 10.2867L5.62858 11.4707C5.32046 11.941 4.79983 12.2473 4.20749 12.2473C3.26186 12.2473 2.49447 11.4625 2.49447 10.4918C2.49447 10.4207 2.49819 10.3523 2.5059 10.2867Z" />
    </svg>
  );
}

Bells.propTypes = {
  className: PropTypes.string,
};

export default Bells;
