import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import Bar from './index.jsx';

function legislatorColorByParty(party) {
  if (party === 'Republican') {
    return '#ed5565';
  }

  return '#428bca';
}

class BillSponsors extends React.Component {
  data() {
    return map(this.props.data, item =>
      ({
        id: item.id,
        name: item.name,
        color: legislatorColorByParty(item.party),
        count: item.count,
      })
    );
  }

  render() {
    if (isEmpty(this.data())) {
      return (
        <h3>No data to display</h3>
      );
    }

    return (
      <Bar
        id={this.props.id}
        data={this.data()}
        xkey="name"
        ykeys={['count']}
        labels={['Bills count']}
      />
    );
  }
}

BillSponsors.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default BillSponsors;
