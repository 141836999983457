import isFunction from 'lodash/isFunction';

import { error } from 'components/core/Notification';
import { exportBlob } from 'utils/fileExport';
import { DOCX_ID, PDF_ID, XLSX_ID } from 'utils/constants/export';
import { GOV_REPORTS_URL } from 'utils/reports/govReports';

export default function reportDownloader({
  reportBrand,
  fileType,
  visibleColumns,
  enabledColumns,
  reportSlug,
  sort,
  customApiProps,
  onNotifyFailure,
  fileName,
  documentIds,
  perPage,
}) {
  const parsedVisibleColumns = JSON.stringify(visibleColumns);
  const { sort: sortBy, direction } = sort || {};
  const apiProps = customApiProps || {};

  const method = 'POST';
  const exportFileName = `${fileName}.${fileType}`;

  const getFiletype = () => {
    if (reportSlug !== 'company_trackings') {
      return {
        'strategy_inputs[file_extension]': fileType,
        'strategy_inputs[per_page]': perPage,
      };
    }
    return {};
  };

  const data = {
    strategy_slug: reportSlug,
    'strategy_inputs[use_company_logo]': reportBrand,
    'strategy_inputs[order_column]': sortBy,
    'strategy_inputs[order_mode]': direction,
    'strategy_inputs[visible_columns]': parsedVisibleColumns,
    'strategy_inputs[enabled_columns]': enabledColumns,
    'strategy_inputs[document_ids]': documentIds,
    ...apiProps,
    ...getFiletype(),
  };

  const getError = error('Something went wrong. Please try again.');
  const getTimeoutError = error('Request timed out due to large volume of records. Please select fewer items and try again.');

  function onFailure(err) {
    if (err.status === 408) { getTimeoutError(); throw err; }
    isFunction(onNotifyFailure) ? onNotifyFailure() : getError();

    throw err;
  }

  function getAjaxProps(fileExtension) {
    if (fileExtension === XLSX_ID || fileExtension === DOCX_ID || fileExtension === PDF_ID) {
      return {
        dataType: 'native',
        xhrFields: {
          responseType: 'blob',
        },
      };
    }

    return {};
  }

  const ajax = $.ajax({
    url: GOV_REPORTS_URL,
    method,
    data,
    ...getAjaxProps(fileType),
  });

  return Promise.resolve(ajax)
    .then(responseData => exportBlob(responseData, exportFileName, fileType))
    .catch(onFailure);
}
