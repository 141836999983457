import React from 'react';

import { Result } from 'components/UnifiedSearch/types';

import { getSubtitle } from './helpers';

const Subtitle = ({ recentVisit }: { recentVisit: Result }) => (
  <div>
    {getSubtitle(recentVisit)}
  </div>
);

export default Subtitle;
