import React, { FC, useState } from 'react';

import { AdvancedOptions, Period } from 'components/UnifiedSearch/types';

import OptionsWrapper from '../../../OptionsWrapper';
import Option from '../../../Option';
import { ActionsWrapper, ApplyButton } from '../../../SlideUpModal';
import { PERIOD_OPTIONS } from '../../constants';

type OptionsProps = {
  advancedOptions: AdvancedOptions,
  toggleFilter: () => void,
  toggleSelectCustomPeriod: () => void,
  isMobile: boolean,
};

const Options: FC<OptionsProps> = ({
  advancedOptions,
  toggleFilter,
  toggleSelectCustomPeriod,
  isMobile,
}) => {
  const { period } = advancedOptions.values;
  const [mobileSelectedOption, setMobileSelectedOption] = useState<Period>(period);
  const selectedOption = isMobile ? mobileSelectedOption : period;

  const handleClick = (key: Period) => {
    if (key === 'custom') {
      toggleSelectCustomPeriod();
      return;
    }

    if (isMobile) {
      setMobileSelectedOption(key);
      return;
    }

    advancedOptions.change({ period: key, customPeriod: null });
    toggleFilter();
  };

  const renderOption = (key: Period, value: string) => {
    const isSelected = selectedOption === key;

    return (
      <Option
        onClick={() => handleClick(key)}
        isSelected={isSelected}
        id={key}
        key={key}
        isMobile={isMobile}
      >
        {value}
      </Option>
    );
  };

  const handleApply = () => {
    advancedOptions.change({ period: mobileSelectedOption, customPeriod: null });
    toggleFilter();
  };

  return (
    <>
      <OptionsWrapper>
        {Object.entries(PERIOD_OPTIONS).map(([key, value]) => (
          renderOption(key as Period, value)
        ))}
      </OptionsWrapper>
      {isMobile && (
        <ActionsWrapper>
          <ApplyButton onClick={handleApply} />
        </ActionsWrapper>
      )}
    </>
  );
};

export default Options;
