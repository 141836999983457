export default function copyToClipboard(selector, element) {
  const GlobalNotifier = window.app.GlobalNotifier;

  try {
    const input = element || $(selector);
    input.select();
    document.execCommand('copy');
    input.blur();

    GlobalNotifier.addNotification({
      message: 'Copied!',
      level: 'success',
    });
  } catch (error) {
    GlobalNotifier.addNotification({
      message: 'Failed to copy text.',
      level: 'error',
    });
    throw error;
  }
}
