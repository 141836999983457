import React from 'react';
import PropTypes from 'prop-types';

import { customOptionsShape } from 'components/GlobalSearch/utils/constants';

import Result from '../Result';

function Option({
  isFocused,
  data,
  selectProps,
  innerRef,
  innerProps,
}) {
  const { inputValue } = selectProps;

  return (
    <div {...innerProps} ref={innerRef}>
      <Result
        {...data}
        inputValue={inputValue}
        isFocused={isFocused}
      />
    </div>
  );
}

Option.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    strategy: PropTypes.string.isRequired,
    customOptions: customOptionsShape,
  }).isRequired,
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string.isRequired,
  }).isRequired,
  innerRef: PropTypes.func,
  innerProps: PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseOver: PropTypes.func,
    tabIndex: PropTypes.number,
  }).isRequired,
};

export default Option;
