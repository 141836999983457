import React, { FC } from 'react';
import classNames from 'classnames';

import { pluralize } from 'utils/string';

import styles from './styles.module.scss';

type ResultsCountProps = {
  value: number,
  isVisible: boolean,
};

const ResultsCount: FC<ResultsCountProps> = ({ value, isVisible }) => {
  if (!value) return null;

  return (
    <div className={styles.resultsCount}>
      <span className={classNames(styles.text, !isVisible && styles.hidden)}>
        {pluralize(`${value} result`, `About ${value} results`, value)}
      </span>
    </div>
  );
};

export default ResultsCount;
