import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/core/Link';
import { trackGotoAdvancedSearch } from 'utils/tracking/globalSearch';
import isEmpty from 'lodash/isEmpty';

import styles from '../styles.module.scss';

function AdvancedSearchButton({ inputValue, optionsCount }) {
  // TODO: Update URL after https://phone2action.atlassian.net/browse/GPADV-1827
  const href = isEmpty(inputValue)
    ? '/global_search_v2'
    : `/global_search_v2?searchTerm=${inputValue}`;

  const handleClick = () => {
    trackGotoAdvancedSearch(inputValue, optionsCount);
  };

  return (
    <Link
      href={href}
      className={styles.advancedSearchButton}
      theme="buttonDefault"
      onClick={handleClick}
    >
      Find in global search
    </Link>
  );
}

AdvancedSearchButton.propTypes = {
  inputValue: PropTypes.string,
  optionsCount: PropTypes.number,
};

AdvancedSearchButton.defaultProps = {
  inputValue: '',
};

export default AdvancedSearchButton;
