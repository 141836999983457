import React, { FC } from 'react';

import { NavigationContext } from 'components/Navigation/utils/navContext';
import useModal from 'components/core/Modal/useModal';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import { addFreshchatClassName } from 'utils/freshchat';
import useChatPosition from 'components/core/ActionBar/useChatPosition';

import useNavigation from './utils/useNavigation';
import Navigation from './components/Navigation';
import MobileView from './components/MobileView';

type NavigationV2Props = {
  disabledProducts?: string[],
  userImgPath?: string,
  userName?: string
};

const NavigationV2: FC<NavigationV2Props> = ({ disabledProducts = [], userImgPath, userName }) => {
  const { isMobile } = useMediaQuery();
  const {
    appMenuTab,
    isFirstPageLoad,
    toggleAppMenu,
    isAppMenuOpen,
    isMobilePage,
  } = useNavigation();

  const context = {
    disabledProducts,
    userImgPath,
    userName,
    appMenuTab,
    isFirstPageLoad,
    toggleAppMenu,
    isAppMenuOpen,
    isMobilePage,
  };

  const unifiedSearchView = useModal();

  addFreshchatClassName('navigation-v2');
  useChatPosition(false);

  return (
    <NavigationContext.Provider value={context}>
      {isMobile
        ? <MobileView unifiedSearchView={unifiedSearchView} />
        : <Navigation unifiedSearchView={unifiedSearchView} />}
    </NavigationContext.Provider>
  );
};

export default NavigationV2;
