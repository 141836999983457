import React from 'react';
import PropTypes from 'prop-types';

import TrackFrequencyContent from 'components/Dashboard/common/TrackFrequencyContent';
import { SlideUpModal } from 'components/core/Modal';
import { frequencyType } from 'components/core/FrequencyOptions/shapes';

function SlideUpTrackModal({
  title,
  isLoading,
  isOpenModal,
  toggleModal,
  onSaveMobile,
  frequency,
  withWeekAheadEmail,
  sendWeekAheadEmail,
  onChangeSendEmailMobile,
}) {
  return (
    <SlideUpModal
      title={title}
      isOpen={isOpenModal}
      toggleOpen={toggleModal}
    >
      <TrackFrequencyContent
        isLoading={isLoading}
        isMobile={false}
        frequency={frequency}
        withWeekAheadEmail={withWeekAheadEmail}
        sendWeekAheadEmail={sendWeekAheadEmail}
        onChangeSendEmail={onChangeSendEmailMobile}
        onChange={onSaveMobile}
        withFrequency
      />
    </SlideUpModal>
  );
}

SlideUpTrackModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSaveMobile: PropTypes.func.isRequired,
  frequency: frequencyType,
  title: PropTypes.string,
  withWeekAheadEmail: PropTypes.bool,
  sendWeekAheadEmail: PropTypes.bool,
  onChangeSendEmailMobile: PropTypes.func,
};

export default SlideUpTrackModal;
