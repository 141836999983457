import { useEffect, useState, useRef } from 'react';

import {
  AdvancedOptions,
  Filters,
  Result,
  Search,
  SearchStatus,
} from 'components/UnifiedSearch/types';
import useMountedRef from 'utils/useMountedRef';

import useTags from '../useTags';
import usePositions from '../usePositions';
import useTrackings from '../useTrackings';
import api from './api';
import { Response } from './types';

type UseSearchProps = {
  searchQuery: string;
  filters: Filters;
  advancedOptions: AdvancedOptions;
};
type UseSearch = (props: UseSearchProps) => Search;

const useSearch: UseSearch = ({ searchQuery, filters, advancedOptions }) => {
  const isMounted = useMountedRef();
  const [results, setResults] = useState<Result[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [status, setStatus] = useState<SearchStatus>('idle');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const latestSearch = useRef<Promise<Response>>();

  const { getTags, updateTags } = useTags(results);
  const { getPosition, updatePosition } = usePositions(results);
  const { getTrackings, updateTrackings } = useTrackings(results);

  const reset = (searchStatus: SearchStatus) => {
    setResults([]);
    setStatus(searchStatus);
    setPage(1);
    setHasMore(false);
    setIsLoadingMore(false);
    setTotal(0);
  };

  const search = async () => {
    if (searchQuery.length < 1) {
      reset('idle');
      return;
    }

    reset('loading');

    let currentSearch;

    try {
      currentSearch = api.fetchResults({ searchQuery, filters, page: 1, advancedOptions });
      latestSearch.current = currentSearch;
      const apiResults = await currentSearch;

      // If the user changes the filter/query, a new search is performed.
      // If there is a previous search running we can end up with concurrency issues.
      // This will make sure that we will display only the results for the latest search.
      const isSearchResultOutdated = currentSearch !== latestSearch.current;

      if (!isMounted || isSearchResultOutdated) return;

      if (apiResults.total === 0) {
        reset('not_found');
      } else {
        setStatus('loaded');
        setResults(apiResults.results);
        setHasMore(apiResults.hasMore);
        setTotal(apiResults.total);
      }
    } catch (error) {
      const isSearchResultOutdated = currentSearch !== latestSearch.current;

      if (!isSearchResultOutdated) {
        reset('error');
      }

      throw error;
    }
  };

  const loadMore = async () => {
    if (!hasMore) return;

    setIsLoadingMore(true);

    const currentSearch = api.fetchResults({
      searchQuery,
      filters,
      page: page + 1,
      advancedOptions,
    });
    latestSearch.current = currentSearch;
    const apiResults = await currentSearch;

    const isSearchResultOutdated = currentSearch !== latestSearch.current;

    if (!isMounted || isSearchResultOutdated) return;

    setResults([...results, ...apiResults.results]);
    setHasMore(apiResults.hasMore);
    setPage(page + 1);
    setIsLoadingMore(false);
  };

  useEffect(() => {
    search();
  }, [searchQuery, JSON.stringify(filters), JSON.stringify(advancedOptions.values)]);

  return {
    results: results.map(result => ({
      ...result,
      tags: getTags(result),
      updateTags: tags => updateTags(result, tags),
      position: getPosition(result),
      updatePosition: position => updatePosition(result, position),
      trackings: getTrackings(result),
      updateTrackings: trackings => updateTrackings(result, trackings),
    })),
    hasMore,
    isLoadingMore,
    status,
    total,
    loadMore,
  };
};

export default useSearch;
