import React from 'react';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';

import {
  NewNoteForNavigation as NewNoteButton,
  NewTaskForNavigation as NewTaskButton,
} from 'components/Grm2';
import Button from 'components/core/Button';
import {
  trackNewNoteClicked,
  trackNewTaskClicked,
} from 'components/Navigation/GlobalHeader/utils/tracking';
import NewStakeholderButton from 'components/NavigationV2/components/common/NewStakeholderButton';
import DisabledCreateMessage from 'components/NavigationV2/components/common/DisabledCreateMessage';

import styles from './styles.module.scss';

function CreateButton() {
  const isDisabled = !(gon.isGrmEnabled || gon.isStakeholderMgmtEnabled);
  const renderList = () => {
    if (isDisabled) {
      return <DisabledCreateMessage />;
    }

    return (
      <>
        <h5>Create...</h5>
        {gon.isGrmEnabled && (
          <>
            <NewNoteButton
              isAddNoteEnabled
              trackButtonClicked={trackNewNoteClicked}
              className={styles.option}
              content="Note"
            />
            <NewTaskButton trackButtonClicked={trackNewTaskClicked} className={styles.option} />
          </>
        )}
        {gon.isStakeholderMgmtEnabled && (
          <NewStakeholderButton className={styles.option} />
        )}
      </>
    );
  };

  const tooltipContent = (
    <div className={styles.content}>
      {renderList()}
    </div>
  );

  return (
    <Tooltip
      arrow
      theme="light"
      trigger="click"
      placement="bottom-end"
      content={tooltipContent}
      className={classNames(styles.tooltip, isDisabled && styles.disabled)}
    >
      <span>
        <Button className={styles.create} theme="tertiary">+ Create</Button>
      </span>
    </Tooltip>
  );
}

export default CreateButton;
