import React, { FC, useState } from 'react';

import { AdvancedOptions, Filters } from 'components/UnifiedSearch/types';

import Dropdown from '../Dropdown';
import Trigger from '../Trigger';
import SlideUpModal from '../SlideUpModal';
import Options from './components/Options';
import { VERBATIM_OPTIONS } from './constants';

type VerbatimProps = {
  advancedOptions: AdvancedOptions,
  filters: Filters,
  isMobile: boolean,
};

const Verbatim: FC<VerbatimProps> = ({ advancedOptions, isMobile }) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleFilter = () => setIsVisible(!isVisible);

  const renderButtonContent = () => {
    const { values } = advancedOptions;

    return VERBATIM_OPTIONS[values.verbatim];
  };

  const tooltipContent = (
    <Options advancedOptions={advancedOptions} toggleFilter={toggleFilter} isMobile={isMobile} />
  );

  if (isMobile) {
    return (
      <>
        <Trigger onClick={toggleFilter}>
          {renderButtonContent()}
        </Trigger>
        <SlideUpModal isOpen={isVisible} toggleOpen={toggleFilter} title="Verbatim results">
          {tooltipContent}
        </SlideUpModal>
      </>
    );
  }

  return (
    <Dropdown
      isVisible={isVisible}
      content={tooltipContent}
      onClickOutside={toggleFilter}
      onClick={toggleFilter}
    >
      {renderButtonContent()}
    </Dropdown>
  );
};

export default Verbatim;
