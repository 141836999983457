import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useModal from 'components/core/Modal/useModal';
import Button from 'components/core/Button';

import ItemName from '../ItemName';
import Panel from './components/Panel';

function Create({ className, iconClassName, onClick }) {
  const { isOpenModal, toggleModal } = useModal();

  const handleClick = () => {
    toggleModal();
    onClick && onClick();
  };

  return (
    <>
      <Button onClick={handleClick} className={className} theme="light">
        <i className={classNames('far fa-plus-circle', iconClassName)} />
        <ItemName name="Create" />
      </Button>

      <Panel isOpen={isOpenModal} toggleOpen={toggleModal} />
    </>
  );
}

Create.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
};

export default Create;
