import { get } from 'lodash';

import { getParenthesisItem, joinCompact } from 'utils/string';

import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/stakeholder';

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const primaryAddress = get(apiResult, 'options.primary_address');
  const stateCode = get(primaryAddress, 'state_code');

  const organization = get(apiResult, 'options.organization');
  const title = get(apiResult, 'options.title');
  const city = get(primaryAddress, 'city');

  const subTitle = () => (
    [
      joinCompact([
        baseResult.slugLabel,
        getParenthesisItem(joinCompact([city, stateCode])),
      ], ' '),
      joinCompact([
        title,
        getParenthesisItem(organization),
      ], ' '),
    ].join(' - ')
  );

  const getSubtitleHighlight = () => {
    const subtitleCity = highlights.city || city;
    const subtitleStateCode = highlights.stateCode || stateCode;

    return [
      joinCompact([
        baseResult.slugLabel,
        getParenthesisItem(joinCompact([subtitleCity, subtitleStateCode])),
      ], ' '),
      joinCompact([
        highlights.title || title,
        getParenthesisItem(highlights.organization || organization),
      ], ' '),
    ].join(' - ');
  };

  const phone = get(apiResult, 'options.primary_phone');
  const address = get(apiResult, 'options.address');
  const description = get(apiResult, 'options.description');

  const getDescription = () => (
    joinCompact([
      joinCompact([
        phone && `Phone: ${phone}`,
        address && `Address: ${address}`,
      ]),
      description,
    ], '. ')
  );

  const getDescriptionHighlight = () => (
    joinCompact([
      joinCompact([
        phone && `Phone: ${phone}`,
        address && `Address: ${address}`,
      ]),
      highlights.description || description,
    ], '. ')
  );

  return {
    ...baseResult,
    titleHighlight: highlights.fullName,
    subTitle: subTitle(),
    subtitleHighlight: getSubtitleHighlight(),
    description: getDescription(),
    descriptionHighlight: getDescriptionHighlight(),
    state: stateCode,
  };
};

export default resultFactory;
