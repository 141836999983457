import { useState, useEffect } from 'react';

import { ROUTE_EVENT } from 'components/NavigationV2/components/RouteListener';
import { getApps } from 'components/NavigationV2/utils/apps';

function useMenuBar(disabledProducts) {
  const [menuItems, setMenuItems] = useState(getApps(disabledProducts));

  useEffect(() => {
    const handleRouteEvent = () => {
      setMenuItems(getApps(disabledProducts));
    };

    document.addEventListener(ROUTE_EVENT, handleRouteEvent);

    window.onpopstate = handleRouteEvent;

    return () => document.removeEventListener(ROUTE_EVENT, handleRouteEvent);
  }, [disabledProducts]);

  return menuItems;
}

export default useMenuBar;
