import { useState, useEffect } from 'react';

import { DEFAULT_DEBOUNCE_TIME } from 'utils/constants/intervals';

function useDebounce(value: string, delay: number = DEFAULT_DEBOUNCE_TIME) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => { setDebouncedValue(value); }, delay);

    return () => { clearTimeout(handler); };
  }, [delay, value]);

  return debouncedValue;
}

export default useDebounce;
