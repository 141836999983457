import {
  CONTINUOUS,
  DAILY,
  NONE,
} from 'components/core/FrequencyOptions/constants';

const WEEKLY = {
  value: 'weekly',
  label: 'Weekly',
};

export const CARD_FREQUENCIES = [
  NONE,
  WEEKLY,
  DAILY,
  CONTINUOUS,
];

export const RESET_SUBSCRIPTION_FREQUENCIES = [
  NONE,
  WEEKLY,
  DAILY,
  CONTINUOUS,
];

export const BASE_URL = '/dashboard_v2';

export const WORKSPACES_DISABLED_SUBTITLE = "The page you are looking for could not be found. It either doesn't exist, or you don't have access to it.";
