import React from 'react';
import PropTypes from 'prop-types';

import { formatGlobalUtcDate } from 'utils/date';
import { resultLabels, customOptionsShape } from 'components/GlobalSearch/utils/constants';
import withGlobalSearch from 'components/GlobalSearch/utils/withGlobalSearch';

import styles from '../styles.module.scss';

function Byline({ strategy, customOptions }) {
  const getResultDate = () => {
    const { date } = customOptions;

    if (date) {
      const formattedDate = formatGlobalUtcDate(date);

      return ` - ${formattedDate}`;
    }

    return null;
  };

  const getResultState = () => {
    const { state } = customOptions;

    return state && ` - ${state}`;
  };

  const getResultLabel = () => resultLabels[strategy] || strategy;

  return (
    <span className={styles.byline}>
      {getResultLabel()}
      {getResultDate()}
      {getResultState()}
    </span>
  );
}

Byline.propTypes = {
  strategy: PropTypes.string.isRequired,
  customOptions: customOptionsShape.isRequired,
};

export default withGlobalSearch(Byline);
