import isEmpty from 'lodash/isEmpty';

const currentTime = new Date().getTime();

function getSmartSearchPacs({ lookup = [] }) {
  return [...lookup.map(({ id, name, party_type, source_type }) => (
    {
      party_type,
      source_type,
      label: name,
      value: id,
    }
  ))];
}

function getSmartSearchFilters({ cycle }) {
  if (!cycle) {
    return {};
  }

  return {
    filter: {
      id: `filtergroup-${currentTime + 1}`,
      type: 'filters_group',
      operator: {
        label: 'And',
        value: 'and',
      },
      filters: [
        {
          id: `filtergroup-${currentTime + 2}`,
          type: 'filters_group',
          operator: {
            label: 'And',
            value: 'and',
          },
          filters: [
            {
              id: `filter-${currentTime}`,
              type: 'filter',
              dimension: 'receipt.year',
              operator: {
                label: 'In',
                value: 'in',
              },
              value: cycle.map(item => item),
            },
          ],
        },
      ],
    },
  };
}

export const getSmartSearchState = ({ data, filters }) => {
  const smartSearchPacs = getSmartSearchPacs(data);
  const smartSearchFilters = getSmartSearchFilters(filters);

  return {
    moneyball: {
      smartSearchBuilder: {
        builder: {
          ui: {
            isDataLoading: false,
          },
          isTemplate: false,
          title: 'Pre populated import from your PAC ranking results',
          description: 'This Smart Search was made automagically using the PACs and selected years that appeared in your PAC ranking results. Click "Create new search" to produce an export containing a side-by-side comparison of the contributions made by those PACs.',
          transactor: 'committee_recipient',
          data: {
            transactor: 'committee_recipient',
            statement: {
              id: `statementgroup-${currentTime + 1}`,
              type: 'statements_group',
              operator: {
                label: 'And',
                value: 'and',
              },
              statements: [
                {
                  id: `statementgroup-${currentTime + 2}`,
                  type: 'statements_group',
                  operator: {
                    label: 'And',
                    value: 'and',
                  },
                  statements: [
                    {
                      id: `statement-${currentTime}`,
                      type: 'statement',
                      name: 'has_received',
                      options: {
                        toType: {
                          label: 'Committee contributor',
                          value: 'donor_super_committee_ids',
                        },
                        toValue: smartSearchPacs,
                      },
                      ...smartSearchFilters,
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    },
  };
};

export const findOptionsData = (object, item, sector) => {
  if (isEmpty(object) || isEmpty(item)) {
    return undefined;
  }

  return object.find(({ name, type }) => name === item.label && type === sector);
};

export const generateOptions = (options, withAllOption = true) => {
  if (isEmpty(options)) {
    return undefined;
  }

  return [
    ...(withAllOption ? [{ label: 'All', value: undefined }] : []),
    ...options.map(({ id, name }) => ({ label: name, value: id })),
  ];
};

export const PAC_PAGE_SIZE = 100;
