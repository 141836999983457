import React from 'react';
import PropTypes from 'prop-types';

import { optionShape } from 'components/GlobalSearch/utils/constants';

import AdvancedSearchButton from 'components/GlobalSearch/components/Menu/components/AdvancedSearchButton';
import withGlobalSearch from '../../utils/withGlobalSearch';
import Visits from './Visits';
import EmptyWarning from '../EmptyWarning';
import styles from './styles.module.scss';
import QuickLinks from './QuickLinks';

function RecentVisits({
  disabledProducts,
  isSearchable,
  visits,
  onDeleteVisit,
  onVisit,
  inputText,
  onClearAllVisits,
  isDesktopLarge,
}) {
  if (isSearchable()) {
    return <EmptyWarning />;
  }

  const handleVisit = (visit) => {
    onVisit(visit, inputText);
  };

  return (
    <>
      <div className={styles.container}>
        <Visits
          records={visits}
          disabledProducts={disabledProducts}
          onDelete={onDeleteVisit}
          onClearAll={onClearAllVisits}
          onVisit={handleVisit}
        />
        {isDesktopLarge && <QuickLinks />}
      </div>
      <AdvancedSearchButton />
    </>
  );
}

RecentVisits.defaultProps = {
  isSearchable: () => true,
  visits: [],
};

RecentVisits.propTypes = {
  isSearchable: PropTypes.func,
  visits: PropTypes.arrayOf(optionShape),
  onDeleteVisit: PropTypes.func.isRequired,
  onClearAllVisits: PropTypes.func.isRequired,
  disabledProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  onVisit: PropTypes.func.isRequired,
  inputText: PropTypes.string,
  isDesktopLarge: PropTypes.bool,
};

export { RecentVisits };
export default withGlobalSearch(RecentVisits);
