import React from 'react';
import PropTypes from 'prop-types';

import withMenuItems from 'components/Navigation/MobileView/utils/withMenuItems';
import { mobileCategoryShape } from 'components/NavigationV2/utils/directory/shapes';

import PanelHeader from '../../common/PanelHeader';
import Directory from './components/Directory';
import styles from './styles.module.scss';

function ActiveMenu({
  isLoading,
  startLoading,
  toggleNavigationMenu,
  categories,
  activeCategory,
  setActiveId,
}) {
  return (
    <div className={styles.container}>
      <PanelHeader title="Directory" onClose={toggleNavigationMenu} />

      <section className={styles.navSection}>
        <Directory
          isLoading={isLoading}
          startLoading={startLoading}
          categories={categories}
          activeCategory={activeCategory}
          setActiveId={setActiveId}
        />
      </section>
    </div>
  );
}

ActiveMenu.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  startLoading: PropTypes.func.isRequired,
  toggleNavigationMenu: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(mobileCategoryShape).isRequired,
  activeCategory: mobileCategoryShape,
  setActiveId: PropTypes.func.isRequired,
};

export default withMenuItems(ActiveMenu);
