import slugs from 'components/Navigation/menuData/slugs';

import { RELATED_APPS_SECTION, STATE_SUMMARIES } from './common';

const STATE_SECTION = {
  id: 'state',
  title: 'State',
  items: [
    {
      title: 'Governor orders',
      description: 'Executive orders by state Governors',
      href: '/state/governor_orders',
      slug: slugs.state,
    },
    {
      title: 'Governor press releases',
      description: 'Press releases from state Governors’ offices',
      href: '/state/governor_press_releases',
      slug: slugs.state,
    },
  ],
};

const LOCAL_SECTION = {
  id: 'local',
  title: 'Local',
  items: [
    {
      title: 'Local documents',
      description: 'Documents from 1,000s of locales',
      href: '/local',
      slug: slugs.local,
    },
  ],
};

const RELATED_SECTION = {
  ...RELATED_APPS_SECTION,
  items: [
    STATE_SUMMARIES,
  ],
};

export const STATE_AND_LOCAL_DOCUMENTS_COLUMNS = [
  [STATE_SECTION],
  [LOCAL_SECTION],
  [RELATED_SECTION],
];

export const STATE_AND_LOCAL_DOCUMENTS_SECTIONS = [
  STATE_SECTION,
  LOCAL_SECTION,
  RELATED_SECTION,
];
