import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { frequencyType } from 'components/core/FrequencyOptions/shapes';
import TrackFrequency from 'components/Dashboard/common/TrackFrequency';
import Checkbox from 'components/core/Checkbox';

import styles from './styles.module.scss';

function TrackFrequencyContent({
  isMobile,
  isLoading,
  frequency,
  withWeekAheadEmail,
  sendWeekAheadEmail,
  onChangeSendEmail,
  onChange,
  withFrequency,
  groupClassName,
  buttonClassName,
}) {
  return (
    <>
      {withFrequency && (
        <TrackFrequency
          isMobile={isMobile}
          isLoading={isLoading}
          className={classNames(styles.buttonGroup, groupClassName)}
          buttonClassName={classNames(styles.frequencyButton, buttonClassName)}
          selectedOption={frequency}
          onChange={onChange}
        />
      )}

      {withWeekAheadEmail && (
        <Checkbox
          isDisabled={isLoading}
          isChecked={sendWeekAheadEmail}
          theme="light"
          label="Send week ahead email"
          className={classNames(withFrequency && styles.checkbox)}
          onChange={onChangeSendEmail}
        />
      )}
    </>
  );
}

TrackFrequencyContent.defaultProps = {
  withFrequency: false,
};

TrackFrequencyContent.propTypes = {
  isMobile: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  frequency: frequencyType,
  withWeekAheadEmail: PropTypes.bool,
  sendWeekAheadEmail: PropTypes.bool,
  onChangeSendEmail: PropTypes.func,
  withFrequency: PropTypes.bool,
  groupClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
};

export default TrackFrequencyContent;
