import React from 'react';

import { InfiniteLoadPaginator } from 'components/core/Paginator';
import Button from 'components/core/Button';

import styles from './styles.module.scss';
import { Search } from '../../types';

type PaginatorProps = {
  search: Search;
};

const Paginator = ({ search }: PaginatorProps) => {
  const renderCustomLoader = () => (
    <Button
      size="large"
      theme="secondary"
      className={styles.button}
      disabled
      onClick={undefined}
      type={undefined}
      customIcon={undefined}
      iconClassName={undefined}
      isAddIcon={undefined}
      isRemoveIcon={undefined}
      isLoading={undefined}
      loaderClassName={undefined}
      innerRef={undefined}
    >
      <span>Loading...</span>
    </Button>
  );

  return (
    <InfiniteLoadPaginator
      isLoading={search.isLoadingMore}
      isPaginatorPresent={search.hasMore}
      renderCustomLoader={renderCustomLoader}
      onLoad={search.loadMore}
      capture
    />
  );
};

export default Paginator;
