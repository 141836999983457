import {
  PRIMARY,
  GENERAL,
  SPECIAL,
  OTHER,
} from 'components/Moneyball/utils/constants';

const dataElectionTypes = [
  {
    label: 'Primary',
    value: PRIMARY,
  }, {
    label: 'General',
    value: GENERAL,
  }, {
    label: 'Special',
    value: SPECIAL,
  }, {
    label: 'Other',
    value: OTHER,
  },
];

export default dataElectionTypes;
