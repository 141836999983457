import React, { FC } from 'react';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

type ApplyButtonProps = {
  onClick: () => void,
  isDisabled?: boolean,
};

const ApplyButton: FC<ApplyButtonProps> = ({ onClick, isDisabled }) => (
  <Button onClick={onClick} className={styles.apply} theme="tertiary" disabled={isDisabled}>
    Apply
  </Button>
);

export default ApplyButton;
