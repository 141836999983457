import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/modal.jsx';

import withContext from './context/hoc.jsx';
import ModalHeader from './modal/header.jsx';
import ModalContent from './modal/content.jsx';
import ModalFooter from './modal/footer.jsx';

class ModalEditor extends React.Component {
  static propTypes = {
    toggleModal: PropTypes.func,
    handleReset: PropTypes.func,
    isOpenModal: PropTypes.bool,
    imageSource: PropTypes.string,
    handleCropped: PropTypes.func,
  }

  static defaultProps = {
    isOpenModal: false,
    imageSource: '',
    initialCrop: {
      x: 5,
      y: 5,
      width: 90,
      height: 90,
      minWidth: 10,
    },
  }

  static getDerivedStateFromProps(props, state) {
    if (!Object.keys(state.crop).length) {
      return {
        ...state,
        crop: props.initialCrop,
      };
    }

    return null;
  }

  state = {
    src: null,
    croppedImageUrl: null,
    crop: {},
  }

  onCropComplete = (crop, pixelCrop) => this.makeClientCrop(crop, pixelCrop)

  onImageLoaded = (image) => {
    this.imageRef = image;

    // We need to manually do an initial crop to get a initial preview
    // since the onChange event is not triggered when the image is loaded
    // Ref: https://github.com/DominicTobias/react-image-crop/issues/148
    const { crop } = this.state;
    const { naturalWidth, naturalHeight } = image;

    const pixelCrop = {
      x: Math.round(naturalWidth * (crop.x / 100)),
      y: Math.round(naturalHeight * (crop.y / 100)),
      width: Math.round(naturalWidth * (crop.width / 100)),
      height: Math.round(naturalHeight * (crop.height / 100)),
    };

    this.makeClientCrop(crop, pixelCrop);
  }

  onCropChange = crop => this.setState({ crop })

  getCroppedImg = (image, pixelCrop) => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const context = canvas.getContext('2d');

    context.fillStyle = '#fff';
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return new Promise((resolve) => {
      this.dataURL = canvas.toDataURL('image/jpeg');
      resolve(this.dataURL);
    });
  }

  makeClientCrop = async (crop, pixelCrop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef, pixelCrop,
      );

      this.setState({ croppedImageUrl });
    }
  }

  handleClose = () => {
    this.props.handleReset();
  }

  handleSubmit = () => this.props.handleCropped(this.dataURL)

  renderPreview = (title, size = 'small') => {
    const { croppedImageUrl } = this.state;

    return (
      <div className="crop-modal__preview__item">
        <div className="crop-modal__preview__title">{title}</div>
        <img src={croppedImageUrl} className={`crop-modal__cropped-${size}`} alt="" />
      </div>
    );
  }

  render() {
    const { crop, croppedImageUrl } = this.state;
    const { isOpenModal, imageSource, toggleModal } = this.props;

    return (
      <Modal
        className="modal-dialog crop-modal__form"
        isOpen={isOpenModal}
        closeTimeoutMS={150}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={false}
      >
        <div className="modal-content">
          <ModalHeader
            handleClose={this.handleClose}
          />
          <ModalContent
            crop={crop}
            imageSource={imageSource}
            onChange={this.onCropChange}
            onComplete={this.onCropComplete}
            onImageLoaded={this.onImageLoaded}
            renderPreview={this.renderPreview}
            croppedImageUrl={croppedImageUrl}
          />
          <ModalFooter
            handleClose={this.handleClose}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Modal>
    );
  }
}

export default withContext(ModalEditor);
