import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function DropShadow({ withProductBar }) {
  const [hasMounted, setHasMounted] = useState(false);

  const dropShadowClassNames = classNames(
    styles.dropShadow,
    hasMounted && styles.visible,
    withProductBar && styles.withProductBar,
    gon.isNavigationV2Enabled && styles.navV2,
  );

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return <div className={dropShadowClassNames} />;
}

DropShadow.propTypes = {
  withProductBar: PropTypes.bool,
};

export default DropShadow;
