import slugs from 'components/Navigation/menuData/slugs';

import { RELATED_APPS_SECTION } from './common';

const DOCUMENTS_SECTION_1 = {
  id: 'documents-1',
  items: [
    {
      title: 'Agency press releases',
      description: 'Press release from Federal agencies',
      href: '/federal/agency_press_releases',
      slug: slugs.federal,
    },
    {
      title: 'CBO Cost estimates',
      description: 'Congressional Budget Office reports',
      href: '/federal/cbo_reports',
      slug: slugs.federal,
    },
    {
      title: 'Committee reports',
      description: 'Reports published by committees',
      href: '/federal/committee_reports',
      slug: slugs.federal,
    },
    {
      title: 'Congressional press releases',
      description: 'Press releases from congress & committees',
      href: '/federal/congressional_press_releases',
      slug: slugs.federal,
    },
    {
      title: 'Congressional record items',
      description: 'Official records published by congress',
      href: '/federal/congressional_record',
      slug: slugs.federal,
    },
    {
      title: 'Contracts',
      description: 'Federal contracts, solicitations, and awards',
      href: '/federal/contracts',
      slug: slugs.federal,
    },
    {
      title: 'CRS reports',
      description: 'Reports from the Congressional Research Service',
      href: '/federal/crs_reports',
      slug: slugs.federal,
    },
    {
      title: 'Executive Orders',
      description: 'Map demarcated by district',
      href: '/federal/executive_orders',
      slug: slugs.federal,
    },
    {
      title: 'GAO reports',
      description: 'Fact-based reports',
      href: '/federal/gao_reports',
      slug: slugs.federal,
    },
  ],
};

const DOCUMENTS_SECTION_2 = {
  id: 'documents-2',
  items: [
    {
      title: 'Grants',
      description: 'Federal grants and awards',
      href: '/federal/grants',
      slug: slugs.federal,
    },
    {
      title: 'Live hearing transcripts',
      description: 'Transcripts and floor speeches',
      href: '/federal/live_hearing_transcripts',
      slug: slugs.federal,
    },
    {
      title: 'Official hearing transcripts',
      description: 'Official testimonies and hearings',
      href: '/federal/official_hearing_transcripts',
      slug: slugs.federal,
    },
    {
      title: 'SAP reports',
      description: 'Statements of Administrative Policy',
      href: '/federal/sap_reports',
      slug: slugs.federal,
    },
    {
      title: 'State of the Union addresses',
      description: 'Addresses by the past and present Presidents',
      href: '/federal/state_of_the_union_addresses',
      slug: slugs.federal,
    },
    {
      title: 'Treaty documents',
      description: 'Official international treaties',
      href: '/federal/treaties',
      slug: slugs.federal,
    },
    {
      title: 'Third party reports',
      description: 'Reports from various organizations',
      href: '/federal/third_party_reports',
      slug: slugs.federal,
    },
    {
      title: 'White House press briefings',
      description: 'Briefings from the WH press office',
      href: '/federal/white_house_press_briefings',
      slug: slugs.federal,
    },
  ],
};

const RELATED_SECTION = {
  ...RELATED_APPS_SECTION,
  items: [
    {
      title: 'Appropriations tracker',
      description: 'CRS reports appropriations status table',
      href: '/federal/appropriations_tracker',
      slug: slugs.federal,
    },
  ],
};

export const FEDERAL_DOCUMENTS_COLUMNS = [
  [DOCUMENTS_SECTION_1],
  [DOCUMENTS_SECTION_2],
  [RELATED_SECTION],
];

export const FEDERAL_DOCUMENTS_SECTIONS = [
  {
    id: 'documents',
    items: [...DOCUMENTS_SECTION_1.items, ...DOCUMENTS_SECTION_2.items],
  },
  RELATED_SECTION,
];
