import React, { FC, useRef, useState } from 'react';
import { size, trim } from 'lodash';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import Autosuggest from 'components/Autosuggest';
import { trackAbandonedSearch, trackResultFound } from 'utils/tracking/globalSearch';

import Option from './components/Option';
import Input, { GLOBAL_SEARCH_INPUT_ID } from './components/Input';
import Control from './components/Control';
import Menu from './components/Menu';
import MenuList from './components/MenuList';
import LoadingWarning from './components/LoadingWarning';
import { GLOBAL_SEARCH_URL, MIN_SEARCH_INPUT_LENGTH } from './utils/constants';
import { redirectToSelectedPage, transformSuggesterRequest } from './utils';
import useRecentVisits from './components/RecentVisits/useRecentVisits';
import Visits from './components/RecentVisits';

import { getOptionsParser } from './utils/optionsParser';
import { GlobalSearchContext } from './utils/withGlobalSearch';
import { productSlugs } from './utils/constants/productSlugs';
import { resultLabels } from './utils/constants/resultLabels';

type GlobalSearchProps = {
  className?: string,
  closeGlobalSearch: () => void,
  disabledProducts: string[],
  isMenuOpen?: boolean,
  onFocus?: () => void,
  autofocus?: boolean,
  placeholder?: string,
  noGroupClassName?: boolean,
  menuClassName?: string,
};

type OptionType = {
  options: {
    party_short: string;
    party_slug: string;
    display_state: string;
  };
  slug: string;
};

type SelectedOption = {
  label: string;
  url: string;
  strategy: string;
};

const GlobalSearch: FC<GlobalSearchProps> = ({
  className,
  closeGlobalSearch,
  disabledProducts,
  isMenuOpen = true,
  onFocus,
  autofocus = true,
  placeholder,
  noGroupClassName = false,
  menuClassName,
}) => {
  const {
    visits,
    onVisit,
    onDelete,
    onClearAll,
  } = useRecentVisits();
  const [inputText, setInputText] = useState('');
  const searchResultOptions = useRef<OptionType[]>([]);

  const { isMobile, isDesktopLarge } = useMediaQuery();

  const isSearchable = () => size(trim(inputText)) >= MIN_SEARCH_INPUT_LENGTH;

  const input = document.getElementById(GLOBAL_SEARCH_INPUT_ID);
  const resetInputText = () => setInputText('');
  const handleClose = () => {
    resetInputText();
    closeGlobalSearch();
    input && input.blur();
  };

  const handleMenuClose = () => !isMobile && handleClose();

  const onCloseActions = () => {
    trackAbandonedSearch('close button', inputText, size(searchResultOptions.current));
    handleClose();
  };

  const globalSearchContext = {
    isDesktopLarge,
    disabledProducts,
    onClose: onCloseActions,
    isSearchable,
    inputText,
    visits,
    onVisit,
    onDeleteVisit: onDelete,
    onClearAllVisits: onClearAll,
    isMenuOpen,
    placeholder,
    menuClassName,
  };

  const onInputChange = (newInputValue: string, { action }: { action: string }) => {
    const isChangeEvent = ['set-value', 'input-change'].includes(action);
    const isBlurEvent = ['input-blur'].includes(action);

    if (isChangeEvent) {
      setInputText(newInputValue);

      return newInputValue;
    }

    if (isBlurEvent) {
      trackAbandonedSearch('input blur', inputText, size(searchResultOptions.current));
      resetInputText();
    }

    return inputText;
  };

  const onChange = (selectedOption: SelectedOption) => {
    onVisit(selectedOption, inputText);

    trackResultFound(inputText, selectedOption, searchResultOptions.current);

    redirectToSelectedPage(selectedOption, inputText);
  };

  const optionsParser = (options: OptionType[]) => {
    searchResultOptions.current = options;

    return getOptionsParser(disabledProducts)(options);
  };

  return (
    <GlobalSearchContext.Provider value={globalSearchContext}>
      <div className={className}>
        <Autosuggest
          clearIndicator
          shouldOverrideValue
          autofocus={autofocus}
          onFocus={onFocus}
          uri={GLOBAL_SEARCH_URL}
          reqMethod="POST"
          reqTransform={transformSuggesterRequest}
          customOption={Option}
          menuIsOpen={isMenuOpen}
          optionsParser={optionsParser}
          backspaceRemovesValue={false}
          overriddenComponents={{
            Input, Control, Menu, MenuList,
          }}
          onMenuClose={handleMenuClose}
          minimumCharactersToSearch={MIN_SEARCH_INPUT_LENGTH}
          onChange={onChange}
          tabSelectsValue={false}
          noOptionsMessage={Visits}
          onInputChange={onInputChange}
          loadingMessage={LoadingWarning}
          noGroupClassName={noGroupClassName}
        />
      </div>
    </GlobalSearchContext.Provider>
  );
};

export { productSlugs, resultLabels };
export default GlobalSearch;
