import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import Link from 'components/core/Link';
import { mobileCategoryShape } from 'components/NavigationV2/utils/directory/shapes';
import { trackDirectoryItemClicked } from 'components/NavigationV2/utils/tracking';
import { TRANSITION_DURATION } from 'components/Navigation/common/DrillDown/Transition';

import styles from './styles.module.scss';

function Links({ activeCategory, startLoading, onReturn, setIsBackDirection }) {
  useEffect(() => {
    // set isBackDirection after css transition completes
    const timer = setTimeout(() => setIsBackDirection(true), TRANSITION_DURATION);

    return () => clearTimeout(timer);
  }, []);

  const renderLink = ({ title, href }) => {
    const handleClick = () => {
      trackDirectoryItemClicked(title);
      startLoading();
    };

    return (
      <Link href={href} onClick={handleClick} className={styles.link} theme="light" key={title}>
        {title}
      </Link>
    );
  };

  const renderSection = ({ id, title, items }) => (
    <div className={styles.section} key={id}>
      {title && <h5 className={styles.sectionTitle}>{title}</h5>}
      {items.map(renderLink)}
    </div>
  );

  return (
    <section className={styles.links}>
      <Button theme="light" className={styles.title} onClick={onReturn}>
        <i className="far fa-arrow-left" />
        {activeCategory.title}
      </Button>

      {activeCategory.sections.map(renderSection)}
    </section>
  );
}

Links.propTypes = {
  activeCategory: mobileCategoryShape.isRequired,
  startLoading: PropTypes.func,
  onReturn: PropTypes.func.isRequired,
  setIsBackDirection: PropTypes.func.isRequired,
};

export default Links;
