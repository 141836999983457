import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type OptionsWrapperProps = {
  children: ReactNode,
  className?: string,
};

const OptionsWrapper: FC<OptionsWrapperProps> = ({ children, className }) => (
  <ul className={classNames(styles.options, className)}>
    {children}
  </ul>
);

export default OptionsWrapper;
