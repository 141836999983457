import React, { FC } from 'react';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';

import {
  Directory as DirectoryIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from 'components/NavigationV2/components/icons';
import Directory from '../Directory';

import styles from './styles.module.scss';

type DirectoryTogglerProps = {
  isActive: boolean;
  disabledProducts: string[];
  closeDirectory: () => void;
  toggleDirectory: () => void;
};

const DirectoryToggler: FC<DirectoryTogglerProps> = ({
  isActive,
  disabledProducts,
  closeDirectory,
  toggleDirectory,
}) => {
  const tooltipContent = (
    <Directory
      onCloseDirectory={closeDirectory}
      disabledProducts={disabledProducts}
      isVisible={isActive}
    />
  );

  return (
    <div>
      <Tooltip
        content={tooltipContent}
        visible={isActive}
        onClickOutside={closeDirectory}
        placement="bottom-start"
        theme="light"
        arrow={false}
        className={styles.directory}
        offset={[0, 5]}
        zIndex={-1}
        maxWidth="none"
        interactive
      >
        <div
          className={classNames(
            styles.directoryButton,
            isActive && styles.active,
          )}
          onClick={toggleDirectory}
          onKeyDown={toggleDirectory}
        >
          <DirectoryIcon className={styles.directoryIcon} />
          <span className={styles.directoryButtonText}>
            Directory
          </span>
          <ArrowDropDownIcon className={styles.directoryDropDownIcon} />
        </div>
      </Tooltip>
    </div>
  );
};

export default DirectoryToggler;
