import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'components/core/Select';

export default class Container extends Component {
  constructor(props) {
    super();

    this.state = {
      value: props.value,
    };
  }

  componentWillReceiveProps(props) {
    const { value } = this.props;

    if (props.value !== value) {
      this.setState({ value });
    }
  }

  handleChangeCallback = () => {
    const { onChange } = this.props;
    const { value } = this.state;

    if (onChange) {
      onChange(value);
    }
  }

  handleChange = value => this.setState({ value }, this.handleChangeCallback);

  options = () => {
    const { options, alphabetize } = this.props;

    const wrappedOptions = Object.entries(options).map(
      ([key, value]) => ({
        value: key,
        label: value,
      })
    );

    if (alphabetize) {
      return sortAutocompleteOptions(wrappedOptions);
    }

    return wrappedOptions;
  }

  name = () => {
    const { multi, name } = this.props;

    if (multi) {
      return `${name}[]`;
    }

    return name;
  }

  render() {
    const {
      clearable,
      disabled,
      groupClassName,
      labelClassName,
      inputClassName,
      label,
      ...otherProps
    } = this.props;

    const { value } = this.state;

    const labelComponent = (
      <label className={labelClassName}>
        { label }
      </label>
    );

    return (
      <div className={groupClassName}>
        { label && labelComponent }

        <div className={inputClassName}>
          <Select
            backspaceRemoves={clearable}
            clearable={clearable}
            isDisabled={disabled}
            deleteRemoves={clearable}
            {...otherProps}
            name={this.name()}
            onChange={this.handleChange}
            value={value}
            options={this.options()}
          />
        </div>
      </div>
    );
  }
}

Container.propTypes = {
  alphabetize: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  groupClassName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  multi: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

Container.defaultProps = {
  alphabetize: false,
  clearable: true,
  disabled: false,
  labelClassName: 'col-md-4 control-label',
  inputClassName: 'col-md-8',
  groupClassName: 'form-group',
  multi: false,
};
