import { pick, omit, isEmpty, sortBy } from 'lodash';

import Notifications from 'components/Advocates/Lookup/utils/Notifications';
import { arrayToSelectableItems, valueOrEmpty } from 'utils/forms';

export const emailPlaceholder = {
  default: 'The primary email address (e.g., the default email contact method)',
  custom: 'An optional email address field.',
};

export const phonePlaceholder = {
  default: 'This is the phone number for this stakeholder (e.g., +1-(555)-555-5555).',
  custom: 'An optional phone number field.',
};

export const addressPlaceholder = {
  default: 'The primary address (e.g., the address used to map this stakeholder)',
  custom: 'An optional address field.',
};

function getDefaultValue(property, category, primary = true) {
  return { [property]: '', category, primary };
}

export const defaultNewEmailValue = getDefaultValue('email', 'personal', false);
export const defaultEmailValue = getDefaultValue('email', 'personal');
export const defaultEmailArray = [defaultEmailValue];

export const defaultNewPhoneValue = getDefaultValue('phone', 'personal', false);
export const defaultPhoneValue = getDefaultValue('phone', 'personal');
export const defaultPhoneArray = [defaultPhoneValue];

export const defaultNewAddressValue = getDefaultValue('address', 'home', false);
export const defaultAddressValue = getDefaultValue('address', 'home');
export const defaultAddressArray = [defaultAddressValue];

export function getArray(array, defaultValues) {
  return !isEmpty(array) ? array : defaultValues;
}

export function mapPropsToValues({
  id,
  firstName,
  middleName,
  lastName,
  phones,
  addresses,
  emails,
  organization,
  title,
  facebookProfile,
  twitterName,
  tags,
  lists,
  partyCode,
  description,
}) {
  return {
    id,
    firstName: valueOrEmpty(firstName),
    middleName: valueOrEmpty(middleName),
    lastName: valueOrEmpty(lastName),
    phones: getArray(phones, defaultPhoneArray),
    addresses: getArray(sortBy(addresses, 'id'), defaultAddressArray),
    emails: getArray(emails, defaultEmailArray),
    facebookProfile: valueOrEmpty(facebookProfile),
    twitterName: valueOrEmpty(twitterName),
    organization: valueOrEmpty(organization),
    title: valueOrEmpty(title),
    tags: arrayToSelectableItems(tags) || [],
    lists: arrayToSelectableItems(lists) || [],
    partyCode: valueOrEmpty(partyCode),
    description,
  };
}

function removeCustomFieldsFromRoot(payload, customFields) {
  const tokens = customFields.map(({ token }) => token);

  return omit(payload, tokens);
}

function getCustomFieldsAnswers(payload, customFields) {
  const tokens = customFields.map(({ token }) => token);

  return pick(payload, tokens);
}

function normalizeAddresses(payload = {}) {
  const { addresses } = payload;

  if (addresses.length === 1 && isEmpty(addresses[0].address)) {
    return { ...payload, addresses: [] };
  }

  return { ...payload, addresses: addresses.filter(address => !isEmpty(address.address)) };
}

export function normalizePayload(payload, customFields) {
  const custom_fields = getCustomFieldsAnswers(payload, customFields);
  const profile = { custom_fields, ...normalizeAddresses(payload) };

  return removeCustomFieldsFromRoot(profile, customFields);
}

export function getNotifications({ id, createStakeholder, updateStakeholder }) {
  if (id) {
    return {
      submitAction: updateStakeholder,
      successNotification: Notifications.stakeholderEditSuccess,
      failureNotification: Notifications.stakeholderEditFailed,
    };
  }

  return {
    submitAction: createStakeholder,
    successNotification: Notifications.stakeholderCreateSuccess,
    failureNotification: Notifications.stakeholderCreateFailed,
  };
}
