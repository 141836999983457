import ReactDOM from 'react-dom';
import React from 'react';

import { loadGoogleMaps } from 'utils/external-dependency-loader';
import Navigation from 'components/Navigation';
import NavigationV2 from 'components/NavigationV2';
import ProductBar from 'components/core/ProductBar';
import Notifications from 'components/Stakeholder/utils/Notifications';
import RememberUserService from 'components/Login/utils/remember-user-service';

loadGoogleMaps(
  null,
  Notifications.showGMapsFailed,
);

const productBarElement = document.getElementById('product-bar');

if (productBarElement) {
  ReactDOM.render(
    <ProductBar />,
    productBarElement,
  );
}

const navigationElement = document.getElementById('navigation');

if (navigationElement && navigationElement.dataset) {
  const { disabledProducts, userImgPath, userName } = navigationElement.dataset;
  const NavigationComponent = gon.isNavigationV2Enabled ? NavigationV2 : Navigation;

  ReactDOM.render(
    <NavigationComponent
      disabledProducts={JSON.parse(disabledProducts)}
      userImgPath={userImgPath}
      userName={userName}
    />,
    navigationElement,
  );
}

RememberUserService.init();
