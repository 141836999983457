import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Button from 'components/core/Button';
import { mobileCategoryShape } from 'components/NavigationV2/utils/directory/shapes';
import { trackDirectoryCategoryClicked } from 'components/NavigationV2/utils/tracking';
import { TRANSITION_DURATION } from 'components/Navigation/common/DrillDown/Transition';

import styles from './styles.module.scss';

function Categories({ categories, setActiveId, setIsBackDirection }) {
  useEffect(() => {
    // unset isBackDirection after css transition completes
    const timer = setTimeout(() => setIsBackDirection(false), TRANSITION_DURATION);

    return () => clearTimeout(timer);
  }, []);

  const renderCategory = (category) => {
    if (isEmpty(category.sections)) {
      return null;
    }

    const handleClick = () => {
      setActiveId(category.id);
      trackDirectoryCategoryClicked(category.title);
    };

    return (
      <Button onClick={handleClick} theme="light" className={styles.category} key={category.id}>
        {category.title}
        <i className="far fa-arrow-right" />
      </Button>
    );
  };

  return (
    <section className={styles.categories}>
      {categories.map(renderCategory)}
    </section>
  );
}

Categories.propTypes = {
  categories: PropTypes.arrayOf(mobileCategoryShape).isRequired,
  setActiveId: PropTypes.func.isRequired,
  setIsBackDirection: PropTypes.func.isRequired,
};

export default Categories;
