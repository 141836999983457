import PropTypes from 'prop-types';
import React from 'react';
import suggesterFillerFetcher from './suggester-filler-fetcher.jsx';

export default class SuggesterFillerButton extends React.Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    requiredInputs: PropTypes.object.isRequired,
    strategySlug: PropTypes.string.isRequired,
    groupClassName: PropTypes.string.isRequired,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
  }

  static defaultProps = {
    requiredInputs: {},
  }

  handleClick = () => {
    suggesterFillerFetcher({
      strategySlug: this.props.strategySlug,
      requiredInputs: this.props.requiredInputs,
      onSuccess: (response) => {
        const data = response.data;
        const ids = data.map(t => t.id);
        this.props.onClick(ids);
      },
    });
  };

  render() {
    const {
      buttonText,
      groupClassName,
      labelClassName,
      inputClassName,
    } = this.props;

    return (
      <div className={groupClassName}>
        <label className={labelClassName} />
        <div className={inputClassName}>
          <button
            type="button"
            className="btn btn-primary col-md-12"
            onClick={this.handleClick}
          >
            {buttonText}
          </button>
        </div>
      </div>
    );
  }
}
