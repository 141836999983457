import { useEffect, useState } from 'react';
import { isEmpty, find, includes } from 'lodash';

import {
  COMMITTEE_TYPE,
  STAFFER_OFFICE_TYPE,
  STATE_COMMITTEE_TYPE,
  STATE_STAFFER_OFFICE_TYPE,
} from 'redux/dashboardV2/constants';
import { NONE_ID } from 'components/core/FrequencyOptions/constants';

import useButtonState from './useButtonState';

function getCommitteeData(listSubscriptions) {
  const hearing = find(
    listSubscriptions, ({ type, isRemoved }) => !isRemoved && includes([
      COMMITTEE_TYPE,
      STATE_COMMITTEE_TYPE,
    ], type)
  );
  const staffer = find(
    listSubscriptions, ({ type, isRemoved }) => !isRemoved && includes([
      STAFFER_OFFICE_TYPE,
      STATE_STAFFER_OFFICE_TYPE,
    ], type)
  );
  const isPresentHearings = !isEmpty(hearing);
  const isPresentStaffers = !isEmpty(staffer);

  return {
    hearing,
    staffer,
    isPresentHearings,
    isPresentStaffers,
  };
}

function useCommitteeItem({ listSubscriptions, frequency }) {
  const { hearing, staffer, isPresentHearings, isPresentStaffers } = getCommitteeData(listSubscriptions);

  const [stafferSubscription, setStafferSubscription] = useState({ frequency: NONE_ID });
  const [hearingSubscription, setHearingSubscription] = useState({
    frequency: NONE_ID,
    sendWeekAheadEmail: false,
  });

  const { resetButtonState, ...buttonState } = useButtonState({
    isPresentHearings,
    isPresentStaffers,
  });

  function setStafferFrequency(value) {
    return setStafferSubscription(prevProps => ({ ...prevProps, frequency: value }));
  }

  function setHearingsFrequency(value) {
    return setHearingSubscription(prevProps => ({ ...prevProps, frequency: value }));
  }

  function setWeekAhead(value) {
    return setHearingSubscription(prevProps => ({ ...prevProps, sendWeekAheadEmail: value }));
  }

  function resetData() {
    isPresentHearings && setHearingSubscription(hearing);
    isPresentStaffers && setStafferSubscription(staffer);
  }

  useEffect(() => {
    if (frequency) {
      setStafferFrequency(frequency);
      setHearingsFrequency(frequency);
    }
  }, [frequency]);

  useEffect(() => {
    resetData();
  }, [JSON.stringify(listSubscriptions)]);

  return {
    isPresentHearings,
    isPresentStaffers,
    stafferSubscription,
    hearingSubscription,
    buttonState,
    setStafferFrequency,
    setHearingsFrequency,
    setWeekAhead,
    resetButtonState,
    resetData,
  };
}

export default useCommitteeItem;
