import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  REPUBLICAN_PARTY_SHORT,
  DEMOCRAT_PARTY_SHORT,
  INDEPENDENT_SHORT,
  NONPARTISAN_SHORT,
  OTHER_SHORT,
} from 'utils/constants/parties';
import Badge from 'components/core/Badge';

import styles from './styles.module.scss';

function PartyBadge({ children, party, className }) {
  if (!party) {
    return null;
  }

  const formattedParty = party.toLowerCase();
  const { [formattedParty]: partyClassName } = {
    [REPUBLICAN_PARTY_SHORT]: styles.redBadge,
    [DEMOCRAT_PARTY_SHORT]: styles.blueBadge,
    [INDEPENDENT_SHORT]: styles.gray,
    [NONPARTISAN_SHORT]: styles.gray,
    [OTHER_SHORT]: styles.gray,
  };

  return (
    <Badge className={classNames(styles.commonBadge, partyClassName, className)}>
      {children}
    </Badge>
  );
}

PartyBadge.propTypes = {
  children: PropTypes.node.isRequired,
  party: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PartyBadge;
