import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function FooterBar({ children, className }) {
  return (
    <nav id="sidebar-mobile" className={classNames(styles.footerBar, className)}>
      {children}
    </nav>
  );
}

FooterBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default FooterBar;
