import { get } from 'lodash';

import { ApiResult } from 'components/UnifiedSearch/useSearch/types';
import { HighlightedString } from 'components/UnifiedSearch/types';

import { getHighlightedFields } from '../../base';

type Highlights = {
  displayId?: HighlightedString,
  officialTitle?: HighlightedString,
  text?: HighlightedString,
};

export const getHighlights = (result: ApiResult): Highlights => {
  const highlightedFields = getHighlightedFields(result);

  const [displayId] = get(highlightedFields, 'display_id', []);
  const [officialTitle] = get(highlightedFields, 'official_title', []);
  const [text] = get(highlightedFields, 'text_versions.text', []);

  return { displayId, officialTitle, text };
};
