import { DASHBOARD_BASE_URL } from 'components/Dashboard/common/FeedPage/utils/constants';

export const MY_FEED_LINKS = {
  myFeed: DASHBOARD_BASE_URL,
  myFeedSettings: '/dashboard_v2/my_subscriptions',
  keywordSearch: '/dashboard_v2/keyword_search',
};

export const LINKS = {
  ...MY_FEED_LINKS,
  workspaces: '/dashboard_v2/workspaces',
  activity: '/grm',
  advocates: '/advocates',
  stakeholders: '/stakeholder_management/directory',
  moneyball: '/moneyball_v2',
  hillDay: '/hill_day/hill_days',
  reports: '/reports',
  federalStaffers: '/federal/congressional_staffers',
};

export const NAMES = {
  myFeed: 'My feed',
  workspaces: 'Workspaces',
  activity: 'Activity',
  advocates: 'Stakeholders',
  stakeholders: 'Stakeholders',
  moneyball: 'Donor research',
  hillDay: 'Hill day',
  reports: 'Reports',
};
