import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function LightbulbDollar({ className }) {
  return (
    <svg width="12" height="17" viewBox="0 0 12 17" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.icon, className)}>
      <path d="M3.27477 15.0855C3.27511 15.2944 3.33852 15.4989 3.4575 15.6729L4.04011 16.5259C4.13964 16.6717 4.27463 16.7913 4.43308 16.874C4.59153 16.9567 4.76852 17 4.9483 17H7.05205C7.23182 17 7.40881 16.9567 7.56726 16.874C7.72571 16.7913 7.8607 16.6717 7.96023 16.5259L8.54284 15.6729C8.66177 15.4989 8.72533 15.2946 8.72557 15.0855L8.72693 13.8122H3.27307L3.27477 15.0855ZM5.98125 2.83077e-05C2.50364 0.0103211 0 2.75483 0 5.84365C0 7.31685 0.560795 8.66088 1.485 9.68783C2.04818 10.3137 2.92909 11.6212 3.26489 12.7242C3.26625 12.7328 3.26727 12.7415 3.26864 12.7501H8.73136C8.73273 12.7415 8.73375 12.7332 8.73511 12.7242C9.07091 11.6212 9.95182 10.3137 10.515 9.68783C11.4392 8.66088 12 7.31685 12 5.84365C12 2.61007 9.30375 -0.00993242 5.98125 2.83077e-05ZM6.54545 8.49586V9.03109C6.54545 9.17784 6.42341 9.29671 6.27273 9.29671H5.72727C5.57659 9.29671 5.45455 9.17784 5.45455 9.03109V8.49022C5.06966 8.47096 4.69534 8.34014 4.38511 8.11337C4.25216 8.01609 4.24534 7.82219 4.36568 7.71029L4.76625 7.3381C4.86068 7.25044 5.00114 7.24646 5.11159 7.31386C5.24352 7.39421 5.39318 7.43737 5.54864 7.43737H6.50693C6.72852 7.43737 6.9092 7.24081 6.9092 6.99943C6.9092 6.80188 6.78614 6.6279 6.61023 6.57676L5.07614 6.12853C4.44239 5.94326 3.99955 5.35093 3.99955 4.68788C3.99955 3.87375 4.64898 3.21236 5.45421 3.19145V2.65622C5.45421 2.50947 5.57625 2.3906 5.72693 2.3906H6.27239C6.42307 2.3906 6.54511 2.50947 6.54511 2.65622V3.19709C6.93 3.21635 7.30432 3.34683 7.61455 3.57394C7.7475 3.67122 7.75432 3.86512 7.63398 3.97701L7.23341 4.34921C7.13898 4.43687 6.99852 4.44085 6.88807 4.37345C6.75614 4.29277 6.60648 4.24994 6.45102 4.24994H5.49273C5.27114 4.24994 5.09046 4.4465 5.09046 4.68788C5.09046 4.88543 5.21352 5.05941 5.38943 5.11055L6.92352 5.55878C7.55727 5.74405 8.00011 6.33638 8.00011 6.99943C8.00011 7.81389 7.35068 8.47495 6.54545 8.49586Z" />
    </svg>
  );
}

LightbulbDollar.propTypes = {
  className: PropTypes.string,
};

export default LightbulbDollar;
