import mapConstants from 'components/map/constants';
import { HOUSE, SENATE, FEDERAL, STATE } from 'components/grassroots-campaign-builder/constants';

export const MAPBOX_TYPE_BY_MATCHER_SLUG = {
  [FEDERAL]: {
    [HOUSE]: mapConstants.CONGRESSIONAL_DISTRICTS,
    [SENATE]: mapConstants.CONGRESSIONAL_DISTRICTS,
  },
  [STATE]: {
    [HOUSE]: mapConstants.STATE_LEGISLATION_LOWER,
    [SENATE]: mapConstants.STATE_LEGISLATION_UPPER,
  },
};

export const ACTION_TYPE = {
  FACEBOOK_POST: 'facebook_post',
  MAIL: 'mail',
  REGULATION_COMMENT: 'regulation_comment',
  TWITTER_POST: 'twitter_post',
};

export const RECIPIENT_TYPE = {
  FEDERAL_LEGISLATOR: 'federal_legislator',
  STATE_LEGISLATOR: 'state_legislator',
  FEDERAL_REGISTER_REGULATION: 'federal_register_regulation',
  CUSTOM_RECIPIENT: 'custom_recipient',
};
