import get from 'lodash/get';

import { BASE_URL } from 'components/Dashboard/utils/constants';

export const getMyFeedToken = () => get(gon, 'currentUser.myFeedToken');
export const getMyFeedName = () => get(gon, 'currentUser.myFeedName');
export const getMyFeed = () => ({ token: getMyFeedToken(), name: getMyFeedName() });

export const isMyFeed = feedToken => feedToken === getMyFeedToken();

export const getFeedUrl = token => (
  isMyFeed(token) ? `${BASE_URL}/feed` : `${BASE_URL}/workspaces/${token}`
);

export const getFeedSettingsUrl = token => (
  isMyFeed(token) ? `${BASE_URL}/my_subscriptions` : `${BASE_URL}/workspaces/${token}/settings`
);
