import { useState, useMemo, ChangeEvent } from 'react';

import { AdvancedOptions } from 'components/UnifiedSearch/types';

import { CUSTOM_STATE_DEFAULT, STATE_OPTIONS } from '../../constants';
import { State } from './types';

const useStates = (advancedOptions: AdvancedOptions) => {
  const { region, customRegions } = advancedOptions.values;
  const isSelectedRegion = region === 'state';
  const initialSelectedStates = isSelectedRegion ? customRegions : [CUSTOM_STATE_DEFAULT];
  const [selectedStates, setSelectedStates] = useState<State[]>(initialSelectedStates);

  const [search, setSearch] = useState('');

  const regex = new RegExp(search, 'i');
  const filteredOptions = useMemo(() => STATE_OPTIONS.filter(
    option => option.label.match(regex) || option.value.match(regex),
  ), [regex]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return {
    search,
    handleSearchChange,
    options: filteredOptions,
    selectedStates,
    setSelectedStates,
  };
};

export default useStates;
