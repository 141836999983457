import React, { FC } from 'react';

import { AdvancedOptions, Filters } from 'components/UnifiedSearch/types';

import Trigger from '../Trigger';
import SlideUpModal from '../SlideUpModal';
import Dropdown from '../Dropdown';
import Options from './components/Options';
import { COLLECTION_OPTIONS } from './constants';
import { CollectionOptions } from './types';
import styles from './styles.module.scss';
import useCollections from './useCollections';

type CollectionsProps = {
  advancedOptions: AdvancedOptions,
  filters: Filters,
  isMobile: boolean,
};

const Collections: FC<CollectionsProps> = ({ advancedOptions, filters, isMobile }) => {
  const collections = useCollections({ advancedOptions, filters, isMobile });

  const renderButtonContent = () => {
    const { values } = advancedOptions;
    const hasMultipleOptionsSelected = values.collections.length > 1;
    const filteredOptions = COLLECTION_OPTIONS[filters.value] as CollectionOptions;
    const collectionKey = values.collections[0];
    const displayedCollection = filteredOptions[collectionKey];

    return displayedCollection + (hasMultipleOptionsSelected ? '...' : '');
  };

  const tooltipContent = (
    <Options
      advancedOptions={advancedOptions}
      isMobile={isMobile}
      collections={collections}
    />
  );

  if (filters.value === 'all') return null;

  if (isMobile) {
    return (
      <>
        <span className={styles.divider} />
        <Trigger onClick={collections.toggleFilter}>
          {renderButtonContent()}
        </Trigger>
        <SlideUpModal
          isOpen={collections.isVisible}
          toggleOpen={collections.toggleFilter}
          title="Collections"
        >
          {tooltipContent}
        </SlideUpModal>
      </>
    );
  }

  return (
    <>
      <span className={styles.divider} />
      <Dropdown
        isVisible={collections.isVisible}
        content={tooltipContent}
        onClickOutside={collections.toggleFilter}
        onClick={collections.toggleFilter}
      >
        {renderButtonContent()}
      </Dropdown>
    </>
  );
};

export default Collections;
