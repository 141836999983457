import React, { ChangeEvent, FC } from 'react';

import Input from 'components/core/Input';

import styles from './styles.module.scss';

type SearchWrapperProps = {
  value: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  placeholder: string,
};

const SearchWrapper: FC<SearchWrapperProps> = ({ value, onChange, placeholder }) => (
  <div className={styles.searchWrapper}>
    <Input
      value={value}
      onChange={onChange}
      className={styles.search}
      placeholder={placeholder}
    />
  </div>
);

export default SearchWrapper;
