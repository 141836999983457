import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { customOptionsShape } from 'components/GlobalSearch/utils/constants';

function MenuList({ options, innerRef, ...restProps }) {
  const optionsRef = useRef(options);
  const menuListRef = useRef(null);

  useEffect(() => {
    const isHTMLElement = menuListRef.current instanceof HTMLElement;
    const haveOptionsChanged = optionsRef.current !== options;

    if (isHTMLElement && haveOptionsChanged) {
      menuListRef.current.scrollTop = 0;
      optionsRef.current = options;
    }
  }, [options]);

  return (
    <components.MenuList
      {...restProps}
      options={options}
      innerRef={(node) => {
        innerRef(node);
        menuListRef.current = node;
      }}
    />
  );
}

MenuList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      strategy: PropTypes.string.isRequired,
      customOptions: customOptionsShape,
    }),
  ),
  innerRef: PropTypes.func,
};

export default MenuList;
