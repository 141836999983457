import { get } from 'lodash';

import { ApiResult } from 'components/UnifiedSearch/useSearch/types';
import { HighlightedString } from 'components/UnifiedSearch/types';

import { getHighlightedFields } from '../../base';

type Highlights = {
  sessionName?: HighlightedString,
  stateAlpha?: HighlightedString,
  stateName?: HighlightedString,
  title?: HighlightedString,
  billNumber?: HighlightedString,
  description?: HighlightedString,
};

export const getHighlights = (result: ApiResult): Highlights => {
  const highlightedFields = getHighlightedFields(result);

  const [sessionName] = get(highlightedFields, 'session.name', []);
  const [stateAlpha] = get(highlightedFields, 'state.alpha2', []);
  const [stateName] = get(highlightedFields, 'state.name', []);
  const [title] = get(highlightedFields, 'title', []);
  const [billNumber] = get(highlightedFields, 'bill_number', []);
  const [description] = get(highlightedFields, 'description', []);
  const [text] = get(highlightedFields, 'text_versions.text', []);

  return {
    sessionName,
    stateAlpha,
    stateName,
    title,
    billNumber,
    description: description || text,
  };
};
