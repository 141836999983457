import { isEmpty } from 'lodash';

import { Collection } from 'components/UnifiedSearch/types';

import { Collections } from '../../types';

type SelectHandlerProps = {
  key: Collection,
  collections: Collections,
  setCollections: (newCollections: Collection[]) => void,
};

const removeCollection = ({ key, collections, setCollections }: SelectHandlerProps) => {
  const newCollections = collections.value.filter(collection => collection !== key);

  if (isEmpty(newCollections)) {
    return setCollections(['all']);
  }

  return setCollections(newCollections);
};

const addCollection = ({ key, collections, setCollections }: SelectHandlerProps) => {
  const newCollections = collections.value.filter(collection => collection !== 'all');

  return setCollections([...newCollections, key]);
};

export const handleOptionClick = ({ key, collections, setCollections }: SelectHandlerProps) => {
  if (key === 'all') {
    return setCollections([key]);
  }

  if (collections.value.includes(key)) {
    return removeCollection({ key, collections, setCollections });
  }

  return addCollection({ key, collections, setCollections });
};
