import React, { FC } from 'react';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import {
  Result as ResultType,
  Search,
  AdvancedOptions,
} from '../../types';
import ResultsCount from '../ResultsCount';
import Paginator from '../Paginator';
import Result from '../Result';
import styles from './styles.module.scss';

type FeedProps = {
  search: Search;
  onAddRecentVisit: (visited: ResultType) => Promise<void>;
  advancedOptions: AdvancedOptions;
};

const Feed: FC<FeedProps> = ({ search, onAddRecentVisit, advancedOptions }) => {
  const { isMobile } = useMediaQuery();

  return (
    <div className={styles.feed}>
      <ResultsCount value={search.total} isVisible={!advancedOptions.isOpen} />

      {search.results.map(result => (
        <Result
          key={result.key}
          result={result}
          isMobile={isMobile}
          onAddRecentVisit={onAddRecentVisit}
        />
      ))}

      <Paginator search={search} />
    </div>
  );
};

export default Feed;
