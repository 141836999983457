/* eslint-disable import/no-webpack-loader-syntax */
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

import '@babel/polyfill';
import 'react_ujs';
// TODO: fix inline webpack syntax import
import '!style-loader!css-loader!tippy.js/dist/tippy.css';
import '!style-loader!css-loader!tippy.js/dist/border.css';

import Modal from 'react-modal';
import Store from 'redux/store';
import Autocomplete from 'components/autocomplete/autocomplete';
import Autosuggest from 'components/Autosuggest/index';
import LinkedAutosuggest from 'components/LinkedAutosuggest/index';
import GlobalNotifier from 'components/GlobalNotifier/index';
import PageNotifier from 'components/PageNotifier/index';
import MaskedInput from 'components/masked-input/masked-input';
import TagInput from 'components/tag-input/tag-input';
import RoyalCheckbox from 'components/royal-checkbox/royal-checkbox';
import RoyalFileUploader from 'components/royal-file-uploader/royal-file-uploader';
import StaffersMailer from 'components/StaffersMailer/index';
import LinkShareIcons from 'components/link-share-icons/link-share-icons';
import VisualizationChartBarBase from 'components/Visualization/Chart/Bar/index';
import VisualizationChartBarBillSponsors from 'components/Visualization/Chart/Bar/BillSponsors';
import VisualizationChartLine from 'components/Visualization/Chart/Line/index';
import CopyToClipboardInput from 'components/shared/copy-to-clipboard-input';
import SingleDatePicker from 'components/shared/datepicker/single-datepicker';
import AutofilledAutosuggester from 'components/SharedFolder/autofilled-autosugester';
import ImageCropper from 'components/image-cropper/image-cropper';
import PageNotFound from 'components/PageNotFound';
import PublicToolsNavBar from 'components/PublicToolsNavBar';
import CosponsorshipNavBar from 'components/CosponsorshipNavBar';

import 'utils/sentry';
import './navigation-pack';
import './utils/vh-fix';

// TODO: investigate react_ujs, the docs are saying:
// no need to expose all these components to window.app
// https://govpredict.atlassian.net/browse/GOV-3412
window.app = {
  PageNotFound,
  Autocomplete,
  Autosuggest,
  GlobalNotifier,
  LinkedAutosuggest,
  MaskedInput,
  TagInput,
  CopyToClipboardInput,
  AutofilledAutosuggester,
  // Modal should not be required here. The initialization should be done within
  // a custom components
  Modal,
  SingleDatePicker,
  PageNotifier,
  RoyalCheckbox,
  RoyalFileUploader,
  StaffersMailer,
  LinkShareIcons,
  Store,
  Visualization: {
    Chart: {
      Line: VisualizationChartLine,
      Bar: {
        Base: VisualizationChartBarBase,
        BillSponsors: VisualizationChartBarBillSponsors,
      },
    },
  },
  ImageCropper,
  PublicToolsNavBar,
  CosponsorshipNavBar,
};

const pageHeaderElement = document.getElementById('react-page-header');
const PageHeader = lazy(() => import('components/core/PageHeader' /* webpackChunkName: "page-header" */));

if (pageHeaderElement) {
  const {
    title,
    noOffset,
    product,
    hideSeparator,
    productName,
    description,
    noMobileOffset,
    customBreadcrumb,
  } = pageHeaderElement.dataset;

  const props = {
    title,
    description,
    page: product,
    pageName: productName,
    isRightOffset: !Boolean(noOffset),
    applyExtraMargin: !Boolean(hideSeparator),
    isMobileOffsetVisible: !Boolean(noMobileOffset),
    customBreadcrumb,
  };

  ReactDOM.render(
    <Suspense fallback={null}>
      <PageHeader {...props} />
    </Suspense>,
    pageHeaderElement,
  );
}

const pageActionsButtonElement = document.getElementById('react-page-actions-button');
const PageActionsButton = lazy(() => import('components/core/PageActionsButton/index.jsx' /* webpackChunkName: "page-actions-button" */));

if (pageActionsButtonElement) {
  const {
    tooltipTitle,
    pageLinks,
  } = pageActionsButtonElement.dataset;

  const props = {
    tooltipTitle,
    pageLinks: JSON.parse(pageLinks),
  };

  ReactDOM.render(
    <Suspense fallback={null}>
      <PageActionsButton {...props} />
    </Suspense>,
    pageActionsButtonElement,
  );
}
