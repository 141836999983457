import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import { optionShape } from 'components/GlobalSearch/utils/constants';

import { trackRecentResultFound } from 'utils/tracking/globalSearch';
import Visit from '../Visit';
import Placeholder from '../Placeholder';
import styles from './styles.module.scss';

export default function Visits({
  records,
  onDelete,
  onVisit,
  onClearAll,
  disabledProducts,
}) {
  const anyResult = Boolean(records.length);
  const handleVisit = (visit, index) => (isLegacyStrategy) => {
    onVisit({ ...visit, isLegacyStrategy });
    trackRecentResultFound(visit, index, records.length);
  };

  return (
    <div className={styles.visits}>
      <div className={styles.header}>
        <h2>RECENT VISITS</h2>
        {anyResult && (
          <Button
            theme="tableLink"
            className={styles.clearAll}
            onClick={onClearAll}
          >
            Clear all
          </Button>
        )}
      </div>
      <div>
        {records.map((result, index) => (
          <Visit
            {...result}
            onSelect={handleVisit(result, index)}
            disabledProducts={disabledProducts}
            onDelete={onDelete}
            key={result.url}
          />
        ))}
      </div>
      <Placeholder anyResult={anyResult} />
    </div>
  );
}

Visits.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  records: PropTypes.arrayOf(optionShape).isRequired,
  disabledProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  onVisit: PropTypes.func.isRequired,
};
