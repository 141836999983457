import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmationModal } from 'components/core/Modal';
import Input from 'components/core/Input';
import Label from 'components/core/Label';
import Loader from 'components/core/Loader';
import FrequencyOptions from 'components/core/FrequencyOptions';
import { ALL_FREQUENCIES } from 'components/core/FrequencyOptions/constants';
import { TextInputPlaceholder } from 'components/core/Placeholder';
import Asterisk from 'components/core/Asterisk';
import FrequencyCard from 'components/Dashboard/common/FrequencyCard';

import { workspaceShape } from '../../utils/shapes';
import useNewWorkspaceModal from './useNewWorkspaceModal';
import styles from './styles.module.scss';

function NewWorkspaceModal({ isOpen, toggleModal, onSuccess, feed, initialName }) {
  const {
    name,
    frequency,
    subscribe,
    handleNameChange,
    handleFrequencyChange,
    handleSubscribeChange,
    handleClose,
    errorMessage,
    handleSubmit,
    isLoading,
    isMobile,
    isValid,
  } = useNewWorkspaceModal({ toggleModal, onSuccess, feed, initialName });

  const NAME_ID = 'workspace-name';

  const loaderNode = <Loader onlyIcon theme="light" size="small" />;
  const messageContinue = isLoading ? loaderNode : 'Save';

  return (
    <ConfirmationModal
      id="create-workspace-modal"
      messageContinue={messageContinue}
      title="Create new workspace"
      isOpen={isOpen}
      toggle={handleClose}
      action={handleSubmit}
      disableContinue={isLoading || !isValid()}
      keepItOpened
    >
      <Label htmlFor={NAME_ID} className={styles.nameLabel}>
        Name <Asterisk show />
      </Label>

      <TextInputPlaceholder className={styles.namePlaceholder}>
        The name that will appear in your email alert subject line.
      </TextInputPlaceholder>

      <Input
        id={NAME_ID}
        value={name}
        onChange={handleNameChange}
        errorMessage={errorMessage}
        className={styles.nameInput}
      />

      <FrequencyCard
        label="Subscribe me to updates"
        withTitle={false}
        isChecked={subscribe}
        onCheck={handleSubscribeChange}
        withHeaderNode
      >
        <>
          <p className={styles.frequencyLabel}>Email frequency</p>

          <FrequencyOptions
            selectedOption={frequency}
            onChange={handleFrequencyChange}
            options={ALL_FREQUENCIES}
            isMobile={isMobile}
            buttonClassName={styles.frequencyButton}
            labelCase="capitalize"
          />
        </>
      </FrequencyCard>
    </ConfirmationModal>
  );
}

NewWorkspaceModal.defaultProps = {
  initialName: '',
  feed: [],
};

NewWorkspaceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  feed: PropTypes.arrayOf(workspaceShape),
  initialName: PropTypes.string,
};

export default NewWorkspaceModal;
