import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TagInput from 'components/core/TagInput';
import Label from 'components/core/Label';
import { TextInputPlaceholder } from 'components/core/Placeholder';

import styles from './styles.module.scss';

function TagInputField({ placeholder, title, className, ...restProps }) {
  return (
    <div className={classNames(styles.formField, className)}>
      <Label>{title}</Label>
      <TextInputPlaceholder className={styles.placeholder}>
        {placeholder}
      </TextInputPlaceholder>

      <TagInput
        {...restProps}
        shouldOverrideValue
        placeholder=""
        allowCreate
      />
    </div>
  );
}


TagInputField.propTypes = {
  placeholder: PropTypes.string,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default TagInputField;
