import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Button from 'components/core/Button';
import { activeIdType, categoryShape } from 'components/NavigationV2/utils/directory/shapes';
import { trackDirectoryCategoryClicked } from 'components/NavigationV2/utils/tracking';

import styles from './styles.module.scss';

function Categories({ activeId, onSetCategory, categories }) {
  const renderCategory = ({ id, title, columns }) => {
    if (isEmpty(columns)) {
      return null;
    }

    const handleCategoryClick = () => {
      onSetCategory(id);
      trackDirectoryCategoryClicked(title);
    };
    const isActive = activeId === id;

    return (
      <li key={id} className={classNames(styles.category, isActive && styles.isActive)}>
        <Button
          onClick={handleCategoryClick}
          className={styles.button}
          theme="tableLink"
        >
          {title}
        </Button>
      </li>
    );
  };

  return (
    <ul className={styles.categories}>
      {categories.map(renderCategory)}
    </ul>
  );
}

Categories.propTypes = {
  activeId: activeIdType.isRequired,
  onSetCategory: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(categoryShape).isRequired,
};

export default Categories;
