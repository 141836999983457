import { get } from 'lodash';

import { getParenthesisItem, joinCompact } from 'utils/string';

import { parentheses } from '../../../helpers';
import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/executive';

type Address = {
  city: string;
  room: string;
  state?: {
    code: string;
    name: string;
  };
  street: string;
  building: string;
  street_2: string;
  zip_code: string;
};

type Position = {
  address: Address;
  branch: string;
};

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const [mainPosition]: [pos?: Position] = get(apiResult, 'options.executive_positions', []);
  const address = mainPosition?.address;

  const getAddress = () => parentheses([address?.city, address?.state?.code]);
  const branch = get(apiResult, 'options.branch');
  const title = get(apiResult, 'options.title');

  const getSubTitle = () => (
    joinCompact([
      baseResult.slugLabel,
      getAddress(),
      '-',
      title,
      getParenthesisItem(branch),
    ], ' ')
  );

  const getSubtitleHighlight = () => (
    joinCompact([
      baseResult.slugLabel,
      getAddress(),
      '-',
      highlights.title || title,
      getParenthesisItem(highlights.branch || branch),
    ], ' ')
  );

  return {
    ...baseResult,
    titleHighlight: highlights.fullName,
    subTitle: getSubTitle(),
    subtitleHighlight: getSubtitleHighlight(),
    description: get(apiResult, 'options.biography'),
    descriptionHighlight: highlights.description,
    options: {
      ...apiResult.options,
      state: address?.state,
    },
    party: get(apiResult, 'options.party.code'),
  };
};

export default resultFactory;
