import React from 'react';
import PropTypes from 'prop-types';

import { CONTINUOUS_ID, DAILY_ID, NONE_ID, WEEKLY_ID } from 'components/core/FrequencyOptions/constants';
import { frequencyType } from 'components/core/FrequencyOptions/shapes';

function FrequencyPlaceholder({ notificationFrequency, className }) {
  const frequencyPlaceholder = {
    [CONTINUOUS_ID]: <>Updates will be sent via email <b>every few minutes.</b></>,
    [DAILY_ID]: <>Updates will be combined into one email, sent <b>daily</b> at <b>7:00 am ET.</b></>,
    [WEEKLY_ID]: <>Updates will be combined into one email, sent <b>weekly</b> at <b>6:00 am ET</b> every <b>Friday.</b></>,
    [NONE_ID]: 'Updates will not be included in any email.',
  };

  return (
    <p className={className}>
      {frequencyPlaceholder[notificationFrequency] || 'Select an email frequency for your subscription(s).'}
    </p>
  );
}

FrequencyPlaceholder.propTypes = {
  notificationFrequency: frequencyType,
  className: PropTypes.string,
};

export default FrequencyPlaceholder;
