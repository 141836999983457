import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { historyShape } from 'utils/shapes/history';

export const ROUTE_EVENT = 'routeevent';

function RouteListener({ history }) {
  useEffect(() => {
    const dispatchEvent = () => document.dispatchEvent(new CustomEvent(ROUTE_EVENT));
    const removeListener = history.listen(() => {
      dispatchEvent();
    });

    if (history.action === 'REPLACE') dispatchEvent();

    return removeListener;
  }, [history.action]);

  return null;
}

RouteListener.propTypes = {
  history: historyShape.isRequired,
};

export default withRouter(RouteListener);
