import { useEffect, useState } from 'react';

import { APP_MENU } from '.';

function useNavigation() {
  const [appMenuTab, setAppMenuTab] = useState(null);
  const isMobilePage = /^\/mobile/.test(window.location.pathname);

  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);

  useEffect(() => {
    const menuPlaceholder = document.getElementById('navigation-sidebar-placeholder');
    menuPlaceholder && menuPlaceholder.parentNode.removeChild(menuPlaceholder);
  }, []);

  function toggleAppMenu(currentSelectedTab) {
    currentSelectedTab === appMenuTab
      ? setAppMenuTab(null)
      : setAppMenuTab(currentSelectedTab);

    setIsFirstPageLoad(false);
  }

  return {
    appMenuTab,
    isMobilePage,
    isAppMenuOpen: isMobilePage || (appMenuTab === APP_MENU),
    isFirstPageLoad,
    toggleAppMenu,
  };
}

export default useNavigation;
