import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import Loader from 'components/core/Loader';

import styles from './styles.module.scss';

function IconButton({
  children,
  iconClass,
  className,
  iconClassName,
  isLoading,
  disabled,
  ...restProps
}) {
  const buttonClassNames = classNames(
    styles.button,
    className,
    (isLoading || disabled) && styles.disabled,
  );

  return (
    <Button
      customIcon={!isLoading && <i className={iconClass} />}
      iconClassName={classNames(styles.icon, iconClassName)}
      theme="light"
      disabled={disabled || isLoading}
      className={buttonClassNames}
      {...restProps}
    >
      {isLoading && <Loader onlyIcon size="xSmall" className={styles.loader} />}
      {children}
    </Button>
  );
}

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  iconClass: PropTypes.string.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  iconClassName: PropTypes.string,
};

export default IconButton;
