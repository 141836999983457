import React from 'react';
import PropTypes from 'prop-types';

import {
  profileShape,
  formStatusShape,
  errorsShape,
} from 'components/Advocates/common/utils/shapes';

import withForm from '../../../utils/withForm';
import styles from '../styles.module.scss';
import BaseField from './Fields/BaseField';
import AddressField from './Fields/AddressField';
import EmailField from './Fields/EmailField';
import PhoneField from './Fields/PhoneField';

function BasicInfo({
  values,
  formState,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
}) {
  const commonProps = {
    onChange: handleChange,
    onBlur: handleBlur,
    className: styles.formControl,
    formState,
    values,
    setFieldValue,
  };

  return (
    <>
      <section className={styles.name}>
        <BaseField
          {...commonProps}
          id="firstName"
          title="First name"
          value={values.firstName}
          isRequired
        />
        <BaseField
          {...commonProps}
          id="middleName"
          title="Middle name"
          value={values.middleName}
          className={styles.middle}
        />
        <BaseField
          {...commonProps}
          id="lastName"
          title="Last name"
          value={values.lastName}
          isRequired
        />
      </section>
      <EmailField {...commonProps} isRequired />
      <AddressField {...commonProps} setFieldTouched={setFieldTouched} />
      <PhoneField {...commonProps} setFieldTouched={setFieldTouched} />
    </>
  );
}

BasicInfo.propTypes = {
  formState: PropTypes.shape({
    errors: errorsShape,
    touched: formStatusShape,
  }).isRequired,
  values: profileShape.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

export default withForm(BasicInfo);
