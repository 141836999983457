import PropTypes from 'prop-types';
import React from 'react';

export default class Container extends React.Component {
  static propTypes = {
    link: PropTypes.string.isRequired,
  }

  link = () => encodeURIComponent(this.props.link);

  mailLink = () => `mailto:?body=${this.link()}`;

  tweetLink = () => `https://twitter.com/intent/tweet?url=${this.link()}`;

  render() {
    return (
      <span>
        <a className="share-icon" href={this.mailLink()}>
          <i className="far fa-envelope" />
        </a>
        <a className="share-icon" href={this.tweetLink()} target="blank">
          <i className="fab fa-twitter" />
        </a>
      </span>
    );
  }
}
