import React from 'react';
import sortBy from 'lodash/sortBy';

import slugs from 'components/Navigation/menuData/slugs';
import { LINKS, NAMES } from './constants';
import { getIsActive, getIsMyFeedActive } from '.';

import {
  Bells,
  Briefcase,
  Activity,
  UserGroup,
  LightbulbDollar,
  Dome,
  Reports,
} from '../components/icons';

const getAdvocatesItem = (disabledProducts) => {
  const icon = <UserGroup />;

  if (gon.isAdvocatesEnabled) {
    return {
      name: NAMES.advocates,
      icon,
      href: LINKS.advocates,
      isDisabled: disabledProducts.includes(slugs.advocates),
      isActive: getIsActive(LINKS.advocates),
    };
  }

  return {
    name: NAMES.stakeholders,
    icon,
    href: LINKS.stakeholders,
    isDisabled: disabledProducts.includes(slugs.stakeholderManagement),
    isActive: getIsActive(LINKS.stakeholders),
  };
};

export const getApps = disabledProducts => (sortBy([
  {
    name: NAMES.myFeed,
    icon: <Bells />,
    href: LINKS.myFeed,
    isActive: getIsMyFeedActive(),
    isDisabled: disabledProducts.includes(slugs.dashboard),
  },
  {
    name: NAMES.workspaces,
    icon: <Briefcase />,
    href: LINKS.workspaces,
    isDisabled: !gon.isWorkspacesEnabled || disabledProducts.includes(slugs.dashboard),
    isActive: getIsActive(LINKS.workspaces),
  },
  {
    name: NAMES.activity,
    icon: <Activity />,
    href: LINKS.activity,
    isDisabled: disabledProducts.includes(slugs.grm),
    isActive: getIsActive(LINKS.activity),
  },
  getAdvocatesItem(disabledProducts),
  {
    name: NAMES.moneyball,
    icon: <LightbulbDollar />,
    href: LINKS.moneyball,
    isDisabled: disabledProducts.includes(slugs.moneyball),
    isActive: getIsActive(LINKS.moneyball),
  },
  {
    name: NAMES.hillDay,
    icon: <Dome />,
    href: LINKS.hillDay,
    isDisabled: disabledProducts.includes(slugs.hillDay),
    isActive: getIsActive(LINKS.hillDay),
  },
  {
    name: NAMES.reports,
    icon: <Reports />,
    href: LINKS.reports,
    isDisabled: false,
    isActive: getIsActive(LINKS.reports),
  },
], 'isDisabled'));
