import { useEffect, useState } from 'react';
import { map } from 'lodash';

import useMountedRef from 'utils/useMountedRef';

import { Result, Tracking } from '../types';
import { TrackingsByRecord } from './types';
import api from './api';

type UseTrackings = (results: Result[]) => {
  getTrackings: (result: Result) => Tracking[];
  updateTrackings: (result: Result, trackings: Tracking[]) => void;
};

const useTrackings: UseTrackings = (results) => {
  const isMounted = useMountedRef();
  const [trackings, setTrackings] = useState<TrackingsByRecord[]>([]);

  const getTrackings = (result: Result) => {
    const resultTrackings = trackings.find(
      tracking => tracking.resultId === result.id && tracking.resultSlug === result.slug,
    );

    if (resultTrackings) {
      return resultTrackings.trackings;
    }

    return [];
  };

  const fetchTrackings = async () => {
    const newTrackings = await api.fetchTrackings(results);

    if (!isMounted) return;

    setTrackings(newTrackings);
  };

  const updateTrackings = (result: Result, newTrackings: Tracking[]) => {
    const updatedTrackings = trackings.map((tracking) => {
      if (tracking.resultId === result.id && tracking.resultSlug === result.slug) {
        return {
          ...tracking,
          trackings: newTrackings,
        };
      }

      return tracking;
    });

    setTrackings(updatedTrackings);
  };

  useEffect(() => {
    fetchTrackings();
  }, [JSON.stringify(map(results, 'key'))]);

  return {
    getTrackings,
    updateTrackings,
  };
};

export default useTrackings;
