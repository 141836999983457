import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/core/Link';
import { trackHomeButtonClicked } from 'components/Navigation/GlobalHeader/utils/tracking';

import ItemName from '../ItemName';
import Icon from './components/Icon';
import useHomeLink from './utils/useHomeLink';
import styles from './styles.module.scss';

function HomeLink({ isInactive }) {
  const {
    withActiveStyle,
    name,
  } = useHomeLink({ isInactive });

  return (
    <Link theme="light" href={gon.homepage.path} className={styles.link} onClick={trackHomeButtonClicked}>
      <Icon isActive={withActiveStyle} />
      <ItemName name={name} isActive={withActiveStyle} />
    </Link>
  );
}

HomeLink.propTypes = {
  isInactive: PropTypes.bool.isRequired,
};

export default HomeLink;
