import React, { FC } from 'react';

import BackToTopButton from 'components/core/ButtonBackToTop';

import Feed from './components/Feed';
import Filters from './components/Filters';
import Lobby from './components/Lobby';
import Loading from './components/Loading';
import NotFound from './components/Error/NotFound';
import Error from './components/Error/ServerError';
import useIsBackToTopVisible from './helpers/useIsBackToTopVisible';
import useUnifiedSearch from './useUnifiedSearch';
import styles from './styles.module.scss';

type UnifiedSearchProps = {
  searchQuery: string,
};

const UnifiedSearch: FC<UnifiedSearchProps> = ({ searchQuery }) => {
  const {
    filters,
    recentVisits,
    search,
    debouncedSearchQuery,
    advancedOptions,
  } = useUnifiedSearch({ searchQuery });

  const { isBackToTopVisible, containerRef } = useIsBackToTopVisible();

  return (
    <div className={styles.searchWrapper}>
      <Filters filters={filters} advancedOptions={advancedOptions} />

      <div className={styles.content} ref={containerRef}>
        {search.status === 'idle' && <Lobby recentVisits={recentVisits} />}

        {search.status === 'loading' && <Loading />}

        {search.status === 'not_found' && (
          <NotFound searchQuery={debouncedSearchQuery} category={filters.value} />
        )}

        {search.status === 'loaded' && (
          <Feed
            search={search}
            onAddRecentVisit={recentVisits.handleAdd}
            advancedOptions={advancedOptions}
          />
        )}

        {search.status === 'error' && (
          <Error />
        )}

        {isBackToTopVisible && (
          <BackToTopButton className={styles.backToTop} containerRef={containerRef} />
        )}
      </div>
    </div>
  );
};

export default UnifiedSearch;
