import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';

import { getError } from 'utils/forms';

import BaseField from './BaseField';

function MultipleField({
  options,
  typeName,
  typeOptions,
  isLoading,
  name,
  fieldName,
  optionalFieldName,
  formState,
  id,
  title,
  defaultValue,
  placeholder,
  setFieldValue,
  viewLimit,
  setFieldTouched,
  ...restProps
}) {
  const hasMultipleValues = options.length > 1;
  const isAddMoreDisabled = viewLimit && options.length > viewLimit;

  function getFieldName(field, index = 0, property = '') {
    return `${field}[${index}][${property}]`;
  }

  function getTitle(index) {
    return `${title} ${index > 0 ? index : ''}`;
  }

  function getPlaceholder(isPrimary) {
    return isPrimary ? placeholder.default : placeholder.custom;
  }

  function getErrorMessage(index) {
    return getError(getFieldName(name, index, fieldName), formState);
  }

  function resetPrimaryButtonValue() {
    options.forEach((_, index) => {
      setFieldValue(getFieldName(name, index, 'primary'), false);
    });
  }

  function renderOptions({ remove, push }) {
    return options.map((option, index) => {
      const selectedTypeValue = typeOptions && typeOptions.find(typeOption => typeOption.value === option[typeName]);

      function handleChangePrimary(event) {
        resetPrimaryButtonValue();
        setFieldValue(getFieldName(name, index, 'primary'), event.target.checked);
        setFieldTouched && setFieldTouched(getFieldName(name, index, fieldName), true);
      }

      function handleRemoveField(removedIndex) {
        remove(removedIndex);

        if (options[removedIndex].primary) {
          setFieldValue(getFieldName(name, 0, 'primary'), true);
        }
      }

      function handleAddField() {
        push(defaultValue);
      }

      return (
        <BaseField
          {...restProps}
          key={`${getFieldName(name, index, fieldName)}-${index}`}
          index={index}
          id={`${id}-${index}`}
          fieldName={fieldName}
          optionalFieldName={optionalFieldName}
          typeValue={selectedTypeValue}
          typeOptions={typeOptions}
          typeName={getFieldName(name, index, typeName)}
          name={getFieldName(name, index, fieldName)}
          primaryName={getFieldName(name, index, 'primary')}
          value={option[fieldName]}
          title={getTitle(index)}
          placeholder={getPlaceholder(option.primary)}
          errorMessage={getErrorMessage(index)}
          formState={formState}
          setFieldValue={setFieldValue}
          onRemoveField={handleRemoveField}
          onAddField={handleAddField}
          isLoading={isLoading}
          isAddMoreDisabled={isAddMoreDisabled}
          hasMultipleValues={hasMultipleValues}
          primaryValue={option.primary}
          onChangePrimary={handleChangePrimary}
          multiple
        />
      );
    });
  }

  return (
    <FieldArray
      rerenderOnEveryChange={false}
      name={name}
      render={renderOptions}
    />
  );
}

MultipleField.propTypes = {
  options: PropTypes.array.isRequired,
  typeName: PropTypes.string,
  typeOptions: PropTypes.array,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  fieldName: PropTypes.string,
  optionalFieldName: PropTypes.string,
  formState: PropTypes.object,
  id: PropTypes.string,
  viewLimit: PropTypes.number,
  title: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func,
};


export default MultipleField;
