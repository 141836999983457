import { filter, isEmpty } from 'lodash';

import { Slug, TagRecordType, TrackableRecordType } from '../types';

type SlugLabel = {
  [key in Slug | string]: string;
};

const getSlugLabel = (slug: Slug) => {
  const slugLabels: SlugLabel = {
    stakeholder_mgmt_stakeholders: 'Stakeholder',
    rolodex: 'Congressional staffer',
    federal_agency_contacts: 'Federal agency staffer',
    state_legislation_staffers: 'State staffer',
    federal_legislators: 'Federal legislator',
    state_legislation_legislators: 'State legislator',
    federal_executive: 'Federal executive',
    state_executive: 'State executive',
    state_staffer_executive: 'State executive staffer',
    local_executive: 'Local executive',
    municipal_executive: 'Municipal executive',
    county_executive: 'County executive',
    federal_committees: 'Federal committee',
    state_legislation_committees: 'State committee',
    federal_bills: 'Federal bill',
    state_legislation_bills: 'State bill',
    federal_register_regulations: 'Federal regulation',
    state_legislation_regulations: 'State regulation',
    doctrove_agency_press_releases: 'Agency press release',
    cbo_gov: 'CBO cost estimate',
    committee_reports: 'Committee report',
    press_releases: 'Congressional press release',
    congressional_record: 'Congressional record item',
    fbo_gov: 'Contract',
    doctrove_crs: 'CRS report',
    doctrove_dear_colleague_letters: 'Dear Colleague letter',
    doctrove_executive_orders: 'Executive order',
    gao_gov: 'GAO report',
    grants_gov: 'Grant',
    lobby_filing_issues: 'LD-2 reports',
    doctrove_live_hearing_transcripts: 'Live hearing transcript',
    doctrove_hearing_transcripts: 'Official hearing transcript',
    doctrove_sap: 'SAP report',
    doctrove_union_statements: 'State of the Union address',
    treaties: 'Treaty document',
    doctrove_third_party_reports: 'Third party reports',
    doctrove_press_briefings: 'White House press briefing',
    doctrove_governor_orders: 'Governor order',
    doctrove_governor_press_releases: 'Governor press releases',
    social_media: 'Twitter',
  };
  return slugLabels[slug] || slug;
};

type Chamber = 'house' | 'senate';

const getChamberLabel = (chamber: Chamber) => {
  if (chamber === 'house') return 'House';
  if (chamber === 'senate') return 'Senate';

  return chamber;
};

const parentheses = (items: (string | undefined)[], separator = ', ') => {
  const content = filter(items).join(separator);

  if (isEmpty(content)) return '';

  return `(${content})`;
};

type Mapper = {
  [key in Slug]: TagRecordType | null;
};

const getRecordType = (slug: Slug): TagRecordType | null => {
  const mapper: Mapper = {
    cbo_gov: 'doctrove_cbo',
    congressional_record: 'congressional_record',
    doctrove_crs: 'doctrove_crs',
    doctrove_agency_press_releases: 'doctrove_agency_press_release',
    doctrove_governor_press_releases: 'doctrove_governor_press_release',
    doctrove_governor_orders: 'doctrove_governor_order',
    doctrove_executive_orders: 'doctrove_executive_order',
    doctrove_hearing_transcripts: 'doctrove_hearing_transcript',
    doctrove_dear_colleague_letters: 'doctrove_dear_colleague_letter',
    fbo_gov: 'doctrove_fbo',
    gao_gov: 'doctrove_gao',
    grants_gov: 'doctrove_grant',
    doctrove_sap: 'doctrove_sap',
    doctrove_live_hearing_transcripts: 'doctrove_live_hearing_transcript',
    doctrove_press_briefings: 'doctrove_press_briefing',
    committee_reports: 'doctrove_committee_report',
    press_releases: 'doctrove_press_release',
    doctrove_third_party_reports: 'doctrove_third_party_report',
    treaties: 'doctrove_treaty',
    doctrove_union_statements: 'doctrove_union_statement',
    federal_bills: 'federal_bill',
    federal_committees: 'federal_committee',
    federal_legislators: 'federal_legislator',
    federal_register_regulations: 'federal_register_regulation',
    stakeholder_mgmt_stakeholders: 'stakeholder_mgmt_stakeholder',
    state_legislation_bills: 'state_bill',
    state_legislation_committees: 'state_committee',
    state_legislation_legislators: 'state_legislator',
    state_legislation_regulations: 'state_regulation',
    state_legislation_staffers: 'state_staffer',
    social_media: 'social_media_post',
    townintel: 'town_intel_document',
    federal_executive: 'federal_executive',
    state_executive: 'state_executive',
    local_executive: 'local_executive',
    state_staffer_executive: 'state_agency_staffer',

    // not taggable
    rolodex: null,
    lobby_filing_issues: null,
  };

  return mapper[slug];
};

type RecordTypeToSlugMapper = {
  [key in TagRecordType | TrackableRecordType]: Slug;
};

const getSlug = (recordType: TagRecordType | TrackableRecordType): Slug => {
  const mapper: RecordTypeToSlugMapper = {
    doctrove_cbo: 'cbo_gov',
    congressional_record: 'congressional_record',
    doctrove_crs: 'doctrove_crs',
    doctrove_agency_press_release: 'doctrove_agency_press_releases',
    doctrove_governor_press_release: 'doctrove_governor_press_releases',
    doctrove_governor_order: 'doctrove_governor_orders',
    doctrove_executive_order: 'doctrove_executive_orders',
    doctrove_hearing_transcript: 'doctrove_hearing_transcripts',
    doctrove_dear_colleague_letter: 'doctrove_dear_colleague_letters',
    doctrove_fbo: 'fbo_gov',
    doctrove_gao: 'gao_gov',
    doctrove_grant: 'grants_gov',
    doctrove_sap: 'doctrove_sap',
    doctrove_live_hearing_transcript: 'doctrove_live_hearing_transcripts',
    doctrove_press_briefing: 'doctrove_press_briefings',
    doctrove_committee_report: 'committee_reports',
    doctrove_press_release: 'press_releases',
    doctrove_third_party_report: 'doctrove_third_party_reports',
    doctrove_treaty: 'treaties',
    doctrove_union_statement: 'doctrove_union_statements',
    federal_bill: 'federal_bills',
    federal_committee: 'federal_committees',
    federal_committee_staffer: 'federal_committees',
    federal_committee_hearing: 'federal_committees',
    federal_legislator: 'federal_legislators',
    federal_legislator_staffer: 'federal_legislators',
    federal_register_regulation: 'federal_register_regulations',
    stakeholder_mgmt_stakeholder: 'stakeholder_mgmt_stakeholders',
    state_bill: 'state_legislation_bills',
    state_committee: 'state_legislation_committees',
    state_committee_staffer: 'state_legislation_committees',
    state_committee_hearing: 'state_legislation_committees',
    state_legislator: 'state_legislation_legislators',
    state_legislator_staffer: 'state_legislation_legislators',
    state_regulation: 'state_legislation_regulations',
    state_staffer: 'state_legislation_staffers',
    social_media_post: 'social_media',
    town_intel_document: 'townintel',
    federal_executive: 'federal_executive',
    state_executive: 'state_executive',
    local_executive: 'local_executive',
    state_agency_staffer: 'state_staffer_executive',
    news_search: 'news_search' as Slug,
  };

  return mapper[recordType];
};

export {
  getSlugLabel, getChamberLabel, parentheses, getRecordType, getSlug,
};
