import React, { ReactNode } from 'react';

import styles from './styles.module.scss';

const SomethingWentWrong = ({ iconNode }: { iconNode?: ReactNode }) => (
  <div className={styles.somethingWentWrongContainer}>
    {iconNode || <img src="/images/something-went-wrong.svg" alt="Not found" />}
    <div className={styles.title}>Something went wrong...</div>
    <p className={styles.subTitle}>
      Sorry an error occurred during the data loading.
    </p>
  </div>
);

export default SomethingWentWrong;
