import { APP_MENU } from 'components/Navigation/utils';

import useMobileDirectory from './useMobileDirectory';

function useMobileNavigation({
  disabledProducts,
  toggleAppMenu,
  isAppMenuOpen,
  isMobilePage,
}) {
  const directoryProps = useMobileDirectory({
    disabledProducts,
    isVisible: isAppMenuOpen,
  });

  function toggleNavigationMenu() {
    if (isMobilePage) {
      window.location.replace(gon.homepage.path);
      return;
    }

    toggleAppMenu(APP_MENU);
  }

  return {
    ...directoryProps,
    toggleNavigationMenu,
  };
}

export default useMobileNavigation;
