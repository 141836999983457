import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PartyIcon from 'components/shared/party-icon';
import { isExternal } from 'utils/url';
import { Icon } from 'components/core/Icons';
import Button from 'components/core/Button';

import LinkIcon from './LinkIcon';
import styles from '../styles.module.scss';
import HighlightedTerm from './HighlightedTerm';

function Label({
  label,
  url,
  inputValue,
  customOptions,
  isLocked,
  className,
  onDelete,
}) {
  const getPartyIcon = () => {
    const { partySlug, partyShort } = customOptions;

    return partySlug && partyShort && (
      <PartyIcon
        partySlug={partySlug}
        partyShort={partyShort}
        className={styles.partyIcon}
      />
    );
  };

  const getLockedItemIcon = () => {
    const padlockIconClassNames = classNames(
      'fas fa-lock',
      styles.padlockIcon,
    );
    const padlockIcon = <i className={padlockIconClassNames} />;

    return (isLocked && padlockIcon);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete(event);
  };

  const isUrlExternal = isExternal(url);
  const wrapperClassNames = classNames(
    !onDelete && styles.readonly,
    styles.label,
    className
  );

  return (
    <span className={wrapperClassNames}>
      <HighlightedTerm label={label} inputValue={inputValue} />
      {getPartyIcon()}
      {getLockedItemIcon()}
      {onDelete && (
        <Button
          className={styles.deleteWrapper}
          theme="link"
          onClick={handleDelete}
        >
          <Icon iconClass={classNames(styles.deleteIcon, 'fal fa-times')} />
        </Button>
      )}
      {<LinkIcon isExternal={isUrlExternal} />}
    </span>
  );
}

Label.defaultProps = {
  customOptions: {},
  inputValue: '',
};

Label.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  className: PropTypes.string,
  customOptions: PropTypes.shape({
    partyShort: PropTypes.string,
    partySlug: PropTypes.string,
  }),
  isLocked: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
};

export default Label;
