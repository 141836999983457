import React from 'react';

import styles from './styles.module.scss';

function Icon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
      <rect x="1" y="1" width="4" height="4" />
      <rect x="8" y="1" width="4" height="4" />
      <rect x="15" y="1" width="4" height="4" />
      <rect x="1" y="8" width="4" height="4" />
      <rect x="8" y="8" width="4" height="4" />
      <rect x="15" y="8" width="4" height="4" />
      <rect x="1" y="15" width="4" height="4" />
      <rect x="8" y="15" width="4" height="4" />
      <rect x="15" y="15" width="4" height="4" />
    </svg>
  );
}

export default Icon;
