import React from 'react';
import { Provider } from 'react-redux';
import store from 'redux/store';
import Container from './components/container.jsx';

export default function RoyalCheckbox(props) {
  return (
    <Provider store={store}>
      <Container {...props} />
    </Provider>
  );
}

export function isRoyalCheckboxIndeterminate(items, selectedItems) {
  return selectedItems.length > 0 && selectedItems.length < items.length;
}

export function isRoyalCheckboxChecked(items, selectedItems) {
  return selectedItems.length === items.length && items.length !== 0;
}
