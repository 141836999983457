import PropTypes from 'prop-types';

import { CATEGORIES } from './constants';

export const activeIdType = PropTypes.oneOf(CATEGORIES.map(category => category.id));

const linkShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
});

const sectionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  items: PropTypes.arrayOf(linkShape).isRequired,
});

const columnShape = PropTypes.arrayOf(sectionShape);

const requiredCategoryProps = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export const categoryShape = PropTypes.shape({
  ...requiredCategoryProps,
  columns: PropTypes.arrayOf(columnShape),
});

export const mobileCategoryShape = PropTypes.shape({
  ...requiredCategoryProps,
  sections: PropTypes.arrayOf(sectionShape),
});
