import get from 'lodash/get';

import { trackNavigationBarEvent } from 'utils/tracking/navigation';

const getUserId = () => get(gon, 'currentUser.user_id', null);

export function trackTasksClicked() {
  trackNavigationBarEvent('clicked on Tasks button', { userId: getUserId() });
}

export function trackCreateStakeholderClicked() {
  trackNavigationBarEvent('clicked on Create stakeholder button', { userId: getUserId() });
}

export function trackNotificationsClicked() {
  trackNavigationBarEvent('clicked on Notifications button', { userId: getUserId() });
}

export function trackAppClicked(app) {
  trackNavigationBarEvent('clicked on Apps item', { userId: getUserId(), app });
}

export function trackDirectoryCategoryClicked(category) {
  trackNavigationBarEvent('clicked on Category', { userId: getUserId(), category });
}

export function trackDirectoryItemClicked(item) {
  trackNavigationBarEvent('clicked on Directory item', { userId: getUserId(), item });
}
