import React from 'react';

import { TextPlaceholder } from 'components/core/Placeholder';

import styles from './styles.module.scss';

const VisitPlaceholder = () => (
  <div className={styles.recentVisits}>
    <TextPlaceholder className={styles.document} />
    <div className={styles.recentVisitsTextWrapper}>
      <TextPlaceholder />
      <TextPlaceholder className={styles.subText} />
    </div>
  </div>
);

const VisitsPlaceholder = () => (
  <>
    <VisitPlaceholder />
    <VisitPlaceholder />
    <VisitPlaceholder />
  </>
);

export default VisitsPlaceholder;
