import { get } from 'lodash';
import { getParenthesisItem, joinCompact } from 'utils/string';

import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/staffer';

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const party = get(apiResult, 'options.party_short');
  const state = get(apiResult, 'options.state');

  const title = get(apiResult, 'options.title');
  const officeServed = get(apiResult, 'options.boss_name');
  const role = get(apiResult, 'options.role');

  const subTitle = () => (
    joinCompact([
      baseResult.slugLabel,
      joinCompact([joinCompact([title, role], '/'), getParenthesisItem(officeServed)], ' '),
    ])
  );

  const getSubtitleHighlight = () => (
    joinCompact([
      baseResult.slugLabel,
      joinCompact([
        joinCompact([title, role], '/'),
        getParenthesisItem(highlights.officeServed || officeServed),
      ], ' '),
    ])
  );

  const description = joinCompact([officeServed, title], '. ');

  const getDescriptionHighlight = () => joinCompact([
    highlights.officeServed || officeServed,
    highlights.title || title,
  ], '. ');

  return {
    ...baseResult,
    titleHighlight: highlights.fullName,
    subTitle: subTitle(),
    subtitleHighlight: getSubtitleHighlight(),
    description,
    descriptionHighlight: getDescriptionHighlight(),
    party,
    state,
  };
};

export default resultFactory;
