import { useState } from 'react';

import { AdvancedOptions, Category, Filters } from 'components/UnifiedSearch/types';

import { COLLECTIONS_DEFAULT } from './utils/constants';

type UseFilters = Filters;

function useFilters(advancedOptions: AdvancedOptions): UseFilters {
  const [filter, setFilter] = useState<Category>('all');

  const change = (value: Category) => {
    setFilter(value);
    advancedOptions.change({ collections: COLLECTIONS_DEFAULT });
  };

  return {
    value: filter,
    change,
  };
}

export default useFilters;
