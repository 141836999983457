import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { canRenderTopBar } from 'components/core/ProductBar/utils';
import { optionShape } from 'components/GlobalSearch/utils/constants';
import withGlobalSearch from 'components/GlobalSearch/utils/withGlobalSearch';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import DropShadow from '../DropShadow';
import AdvancedSearchButton from './components/AdvancedSearchButton';
import styles from './styles.module.scss';

function Menu({ innerRef, children, innerProps, options, selectProps, menuClassName }) {
  const { isMobile } = useMediaQuery();
  const withProductBar = canRenderTopBar();
  const { inputValue } = selectProps;
  const hasOptions = !isEmpty(options);

  const menuClassNames = classNames(
    styles.menu,
    hasOptions && styles.menuWithOptions,
    menuClassName,
    gon.isNavigationV2Enabled && styles.v2,
  );

  return (
    <div className={styles.menuWrapper}>
      <div {...innerProps} className={menuClassNames} ref={innerRef}>
        {children}
        {hasOptions && <AdvancedSearchButton inputValue={inputValue} optionsCount={options.length} />}
      </div>

      {!isMobile && <DropShadow withProductBar={withProductBar} />}
    </div>
  );
}

Menu.propTypes = {
  innerRef: PropTypes.func,
  children: PropTypes.node.isRequired,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func,
    onMouseMove: PropTypes.func,
  }).isRequired,
  options: PropTypes.arrayOf(optionShape),
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
  }).isRequired,
  menuClassName: PropTypes.string,
};

export default withGlobalSearch(Menu);
