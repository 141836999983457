import React, { FC, useState } from 'react';

import { AdvancedOptions, Region } from 'components/UnifiedSearch/types';

import OptionsWrapper from '../../../OptionsWrapper';
import Option from '../../../Option';
import { ActionsWrapper, ApplyButton } from '../../../SlideUpModal';
import { CUSTOM_LOCALE_DEFAULT, CUSTOM_STATE_DEFAULT, REGION_OPTIONS } from '../../constants';
import styles from './styles.module.scss';

type OptionsProps = {
  advancedOptions: AdvancedOptions,
  toggleFilter: () => void,
  onSelectCustomRegion: (key: 'state' | 'local') => void,
  isMobile: boolean,
};

const Options: FC<OptionsProps> = ({
  advancedOptions,
  toggleFilter,
  onSelectCustomRegion,
  isMobile,
}) => {
  const { region } = advancedOptions.values;
  const [mobileSelectedOption, setMobileSelectedOption] = useState<Region>(region);
  const selectedOption = isMobile ? mobileSelectedOption : region;

  const handleClick = (key: Region) => {
    if (key === 'state' || key === 'local') {
      onSelectCustomRegion(key);

      if (isMobile) return;

      if (region !== key) {
        const customRegion = key === 'state' ? CUSTOM_STATE_DEFAULT : CUSTOM_LOCALE_DEFAULT;

        advancedOptions.change({ region: key, customRegions: [customRegion] });
      }

      return;
    }

    if (isMobile) {
      setMobileSelectedOption(key);
      return;
    }

    advancedOptions.change({ region: key, customRegions: [] });
    toggleFilter();
  };

  const renderOption = (key: Region, value: string) => {
    const isSelected = selectedOption === key;
    const isCustomRegion = key === 'state' || key === 'local';

    const getContent = () => {
      const { customRegions } = advancedOptions.values;
      const displayCount = isCustomRegion
        && isSelected
        && customRegions.length;

      if (displayCount) {
        return (
          <span className={styles.customRegion}>
            {value} <span className={styles.count}>{customRegions.length}</span>
          </span>
        );
      }

      return value;
    };

    return (
      <Option
        className={styles.option}
        onClick={() => handleClick(key)}
        isSelected={isSelected}
        id={key}
        key={key}
        isMobile={isMobile}
        withCheckMark={!(isMobile && isCustomRegion)}
      >
        {getContent()}
      </Option>
    );
  };

  const handleApply = () => {
    const isAlreadyApplied = selectedOption === region;

    if (!isAlreadyApplied) {
      advancedOptions.change({ region: selectedOption, customRegions: [] });
    }

    toggleFilter();
  };

  return (
    <>
      <OptionsWrapper>
        {Object.entries(REGION_OPTIONS).map(([key, value]) => (
          renderOption(key as Region, value)
        ))}
      </OptionsWrapper>
      {isMobile && (
        <ActionsWrapper>
          <ApplyButton onClick={handleApply} />
        </ActionsWrapper>
      )}
    </>
  );
};

export default Options;
