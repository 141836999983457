import { filter, get } from 'lodash';
import moment from 'moment';

import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/documents';

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const getSubTitle = () => {
    const date = moment(
      get(apiResult, 'options.date'),
    ).format('dddd, MMMM Do, YYYY');

    return filter([
      'Document',
      ` - ${baseResult.slugLabel}`,
      ` - ${date}`,
    ]).join(' ');
  };

  return {
    ...baseResult,
    titleHighlight: highlights.title,
    subTitle: getSubTitle(),
    descriptionHighlight: highlights.description,
  };
};

export default resultFactory;
