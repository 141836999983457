import React from 'react';

function Note() {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.125 8.71875V9.70312C10.125 9.93516 9.93516 10.125 9.70312 10.125H3.79688C3.56484 10.125 3.375 9.93516 3.375 9.70312V8.71875C3.375 8.48672 3.56484 8.29688 3.79688 8.29688H9.70312C9.93516 8.29688 10.125 8.48672 10.125 8.71875ZM9.70312 11.25H3.79688C3.56484 11.25 3.375 11.4398 3.375 11.6719V12.6562C3.375 12.8883 3.56484 13.0781 3.79688 13.0781H9.70312C9.93516 13.0781 10.125 12.8883 10.125 12.6562V11.6719C10.125 11.4398 9.93516 11.25 9.70312 11.25ZM13.5 4.63711V16.3125C13.5 17.2441 12.7441 18 11.8125 18H1.6875C0.755859 18 0 17.2441 0 16.3125V1.6875C0 0.755859 0.755859 0 1.6875 0H8.86289C9.30937 0 9.73828 0.179297 10.0547 0.495703L13.0043 3.44531C13.3207 3.7582 13.5 4.19062 13.5 4.63711ZM9 1.82461V4.5H11.6754L9 1.82461ZM11.8125 16.3125V6.1875H8.15625C7.68867 6.1875 7.3125 5.81133 7.3125 5.34375V1.6875H1.6875V16.3125H11.8125Z" fill="#0E4EFF" />
    </svg>
  );
}

export default Note;
