import React from 'react';
import PropTypes from 'prop-types';

import {
  allCustomFieldsShape,
  customFieldsAnswersShape,
} from 'components/Advocates/common/utils/shapes';

import Container from './components/Container';
import withForm, { StakeholderFormContext } from './utils/withForm';

function StakeholderForm({ ui, ...restProps }) {
  const { isSubmitting: isLoading, isLoadingCustomFields } = ui;
  const stakeholderContext = {
    ...restProps,
    isLoading,
    isLoadingCustomFields,
  };

  return (
    <StakeholderFormContext.Provider value={stakeholderContext}>
      <Container />
    </StakeholderFormContext.Provider>
  );
}

StakeholderForm.defaultProps = {
  customField: {},
  allCustomFields: [],
};

StakeholderForm.propTypes = {
  id: PropTypes.number,
  ui: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
    isLoadingCustomFields: PropTypes.bool.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  createStakeholder: PropTypes.func,
  updateStakeholder: PropTypes.func,
  fetchCustomFields: PropTypes.func.isRequired,
  allCustomFields: allCustomFieldsShape,
  customField: customFieldsAnswersShape,
  handleChangeCurrentStep: PropTypes.func,
};

export default withForm(StakeholderForm);
