import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';


const Modal = props => (
  <ReactModal
    ariaHideApp={false}
    className="modal-dialog"
    {...props}
  >
    {props.children}
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.node,
};

export default Modal;
