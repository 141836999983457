import * as yup from 'yup';
import { head, toLower, map } from 'lodash';

export const MAX_TITLE_LENGTH = 50;

export const errorMessages = {
  required: 'Please type a name.',
  duplicate: 'This name is in use by another workspace. Please type a new name.',
  maxLength: `Workspace title must have a maximum length of ${MAX_TITLE_LENGTH} characters.`,
};

const validateUniqueness = names => value => !map(names, toLower).includes(toLower(value));

const getNameSchema = workspacesNames => yup.string()
  .required(errorMessages.required)
  .max(MAX_TITLE_LENGTH, errorMessages.maxLength)
  .test('is-unique', errorMessages.duplicate, validateUniqueness([...workspacesNames, 'My feed']));

const validateName = (value, workspacesNames = []) => getNameSchema(workspacesNames).validate(value)
  .then(() => [true, null])
  .catch((error) => {
    const errors = error.errors || [];
    const errorMessage = head(errors);

    return [false, errorMessage];
  });

export default validateName;
