import PropTypes from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';

class Bar extends React.Component {
  constructor(props) {
    super(props);
    this.data = this.props.data;
    this.xkey = this.props.xkey;
  }

  componentDidMount() {
    if (!isEmpty(this.props.data)) {
      this.initializeMorris();
    }
  }

  initializeMorris = () =>
    Morris.Bar({
      element: this.props.id,
      data: this.data,
      xkey: this.xkey,
      ykeys: this.props.ykeys,
      resize: this.props.resize,
      hideHover: this.props.hideHover,
      labels: this.props.labels,
      barColors: (row) => {
        const element = this.data.find(
          cell => cell[this.xkey] === row.label
        );
        if (element.color !== undefined) {
          return element.color;
        }

        return '#0b62a4';
      },
    })

  render() {
    const { id, data } = this.props;

    if (isEmpty(data)) {
      return (
        <h3>No data to display</h3>
      );
    }

    return (
      <div id={id} className="chart-overflow" />
    );
  }
}

Bar.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  xkey: PropTypes.string.isRequired,
  ykeys: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  resize: PropTypes.bool,
  hideHover: PropTypes.bool,
};

Bar.defaultProps = {
  resize: true,
  hideHover: true,
};

export default Bar;
