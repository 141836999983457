import { isEmpty } from 'lodash';

import { Locale, State } from './types';

type Option = State | Locale;
type SelectHandlerProps = {
  option: Option,
  selectedOptions: Option[],
  setOptions: (options: Option[]) => void,
  defaultOption: Option,
};

const removeRegion = ({
  option,
  selectedOptions,
  setOptions,
  defaultOption,
}: SelectHandlerProps) => {
  const newCustomRegions = selectedOptions.filter(region => region.value !== option.value);

  setOptions(isEmpty(newCustomRegions) ? [defaultOption] : newCustomRegions);
};

const addRegion = ({
  option,
  selectedOptions,
  setOptions,
}: Omit<SelectHandlerProps, 'defaultOption'>) => {
  const newCustomRegions = selectedOptions.filter(region => region.value !== 'all');

  setOptions([...newCustomRegions, option]);
};

export const handleOptionClick = ({
  option,
  selectedOptions,
  setOptions,
  defaultOption,
}: SelectHandlerProps) => {
  if (option.value === 'all') {
    setOptions([option]);
    return;
  }

  const isOptionSelected = selectedOptions.some(region => region.value === option.value);

  if (isOptionSelected) {
    removeRegion({ option, selectedOptions, setOptions, defaultOption });
    return;
  }

  addRegion({ option, selectedOptions, setOptions });
};
