import PropTypes from 'prop-types';
import React from 'react';
import Autosuggest from 'components/Autosuggest/index.jsx';

function autosuggestChange(autosuggestChoice) {
  window.location.href = autosuggestChoice.link;
}

export default function LinkedAutosuggest({ strategySlug, placeholder,
                                            className, strategyInputs, clearIndicator }) {
  return (
    <Autosuggest
      strategy={strategySlug}
      onChange={autosuggestChange}
      placeholder={placeholder}
      inputClassName={className}
      strategyInputs={strategyInputs}
      clearIndicator={clearIndicator}
    />
  );
}

LinkedAutosuggest.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  strategySlug: PropTypes.string.isRequired,
  strategyInputs: PropTypes.object,
  clearIndicator: PropTypes.bool,
};

LinkedAutosuggest.defaultProps = {
  clearIndicator: false,
};
