import { get } from 'lodash';
import { joinCompact } from 'utils/string';

import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/federalLegislator';

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const party = get(apiResult, 'options.party_short');

  const subTitle = () => {
    const role = get(apiResult, 'options.full_title');

    return (
      joinCompact([
        baseResult.slugLabel,
        role && `United States ${role}`,
      ], ' - ')
    );
  };

  const getHighlightedTitle = () => {
    if (highlights.districtName) {
      const legislator = (highlights.title || apiResult.label).split('(')[0];

      return `${legislator} (${highlights.districtName})`;
    }

    return highlights.title || apiResult.label;
  };

  return {
    ...baseResult,
    titleHighlight: getHighlightedTitle(),
    subTitle: subTitle(),
    description: get(apiResult, 'options.full_biography'),
    descriptionHighlight: highlights.description,
    party,
    photoUrl: get(apiResult, 'options.photo_url'),
  };
};

export default resultFactory;
