import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import useModal from 'components/core/Modal/useModal';

import ItemName from '../ItemName';
import Panel from './components/Panel';
import Icon from './components/Icon';

function Apps({ disabledProducts, className, onClick }) {
  const { isOpenModal, toggleModal } = useModal();

  const handleClick = () => {
    toggleModal();
    onClick && onClick();
  };

  return (
    <>
      <Button
        className={className}
        onClick={handleClick}
        theme="light"
      >
        <Icon />
        <ItemName name="Apps" />
      </Button>

      <Panel
        disabledProducts={disabledProducts}
        isOpen={isOpenModal}
        toggleOpen={toggleModal}
      />
    </>
  );
}

Apps.propTypes = {
  disabledProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Apps;
