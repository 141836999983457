import { Slug, Category, Collection } from 'components/UnifiedSearch/types';

export const FILTER_OPTIONS: { label: string, value: Category }[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'People',
    value: 'people',
  },
  {
    label: 'Legislation & Regulation',
    value: 'legislation & regulation',
  },
  {
    label: 'Committees',
    value: 'committees',
  },
  {
    label: 'Documents',
    value: 'documents',
  },
];

export const RESULT_TEXT_LIMITS = {
  title: 88,
  titleMobile: 33,
  description: 313,
};

export const executiveSlugs: Slug[] = [
  'local_executive',
  'federal_executive',
  'state_executive',
];

export const peopleSlugs: Slug [] = [
  'stakeholder_mgmt_stakeholders',
  'rolodex',
  'federal_agency_contacts' as Slug,
  'state_staffer_executives' as Slug,
  'state_legislation_staffers',
  'federal_legislators',
  'state_legislation_legislators',
  'federal_executive',
  'state_executive',
  'state_staffer_executive',
  'local_executive',
  'municipal_executive' as Slug,
  'county_executive' as Slug,
];

export const legislationSlugs: Slug[] = [
  'federal_bills',
  'state_legislation_bills',
];

export const regulationSlugs: Slug[] = [
  'federal_register_regulations',
  'state_legislation_regulations',
];

export const legislationAndRegulationSlugs: Slug[] = [
  ...legislationSlugs,
  ...regulationSlugs,
];

export const committeesSlugs: Slug[] = [
  'federal_committees',
  'state_legislation_committees',
];

export const documentsSlugs: Slug[] = [
  'doctrove_agency_press_releases',
  'cbo_gov',
  'committee_reports',
  'press_releases',
  'congressional_record',
  'fbo_gov',
  'doctrove_crs',
  'doctrove_dear_colleague_letters',
  'doctrove_executive_orders',
  'gao_gov',
  'grants_gov',
  'lobby_filing_issues',
  'doctrove_live_hearing_transcripts',
  'doctrove_hearing_transcripts',
  'doctrove_sap',
  'doctrove_union_statements',
  'treaties',
  'doctrove_third_party_reports',
  'doctrove_press_briefings',
  'doctrove_governor_orders',
  'doctrove_governor_press_releases',
];

export const TRACKABLE_SLUGS: Slug[] = [
  'federal_committees',
  'state_legislation_committees',
  'federal_bills',
  'federal_register_regulations',
  'federal_legislators',
  'state_legislation_bills',
  'state_legislation_regulations',
  'state_legislation_legislators',
];

export const COLLECTIONS_DEFAULT: Collection[] = ['all'];
