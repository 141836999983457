import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';

import Categories from './components/Categories';
import Links from './components/Links';
import useDirectory from './useDirectory';
import styles from './styles.module.scss';

function Directory({ onCloseDirectory, disabledProducts, isVisible }) {
  const {
    activeId,
    setActiveId,
    categories,
    activeCategory,
  } = useDirectory({ disabledProducts, isVisible });

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>Directory</h3>
        <Button theme="light" className={styles.closeButton} onClick={onCloseDirectory}>
          <img src="/images/close-grey-icon.svg" alt="Close button" />
        </Button>
      </div>
      <div className={styles.main}>
        <Categories activeId={activeId} onSetCategory={setActiveId} categories={categories} />
        <Links
          activeId={activeId}
          disabledProducts={disabledProducts}
          activeCategory={activeCategory}
        />
      </div>
    </>
  );
}

Directory.propTypes = {
  onCloseDirectory: PropTypes.func.isRequired,
  disabledProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default Directory;
