import { useState } from 'react';
import { find, filter, map } from 'lodash';

import useModal from 'components/core/Modal/useModal';
import { transformTag } from 'components/core/ButtonTagManager/utils';

function useWorkspacesSelect(workspaces, onToggleNewWorkspaceModal) {
  const { isOpenModal, toggleModal } = useModal();
  const [newWorkspaceName, setNewWorkspaceName] = useState('');

  const handleToggleModal = () => {
    toggleModal();
    onToggleNewWorkspaceModal && onToggleNewWorkspaceModal();
  };

  const handleChange = (values) => {
    const newWorkspace = find(values, 'isNew');

    if (newWorkspace) {
      setNewWorkspaceName(newWorkspace.value);
      handleToggleModal();
    }

    const persistedWorkspaces = filter(values, workspace => !workspace.isNew);

    workspaces.onChangeValues(map(persistedWorkspaces, transformTag));
  };

  const handleSuccess = ({ token, name }) => {
    const newWorkspace = { label: name, value: token };

    workspaces.onChangeValues(prevWorkspaces => [...prevWorkspaces, newWorkspace]);
  };

  return {
    isOpenModal,
    onToggleModal: handleToggleModal,
    newWorkspaceName,
    handleChange,
    handleSuccess,
  };
}

export default useWorkspacesSelect;
