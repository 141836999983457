import React from 'react';
import PropTypes from 'prop-types';

import { TRACK_SUBSCRIPTION_FREQUENCIES } from 'redux/dashboardV2/constants';
import { SUBSCRIBABLE_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import SubscribeOption from '../SubscribeOption';

function SubscriptionCard({
  isStaffer,
  isHearing,
  isLoading,
  withTitle,
  staffer,
  hearings,
  sendWeekAheadEmail,
  setStafferFrequency,
  setHearingsFrequency,
  setWeekAhead,
  setStaffers,
  setHearings,
  withFrequency,
  showHearings,
  withWeekAheadEmail,
}) {
  const {
    [SUBSCRIBABLE_TYPES.FEDERAL_STAFFER_OFFICE]: stafferLabel,
    [SUBSCRIBABLE_TYPES.COMMITTEE]: committeeLabel,
  } = TRACK_SUBSCRIPTION_FREQUENCIES;

  return (
    <>
      <SubscribeOption
        withTitle={withTitle}
        label={stafferLabel}
        isChecked={isStaffer}
        onCheckCard={setStaffers}
        isLoading={isLoading}
        value={staffer}
        onChange={setStafferFrequency}
        withFrequency={withFrequency}
      />

      {showHearings && (
        <SubscribeOption
          withWeekAheadEmail={withWeekAheadEmail}
          label={committeeLabel}
          isChecked={isHearing}
          onCheckCard={setHearings}
          withTitle={false}
          isLoading={isLoading}
          value={hearings}
          sendWeekAheadEmail={sendWeekAheadEmail}
          onChange={setHearingsFrequency}
          onCheck={setWeekAhead}
          withFrequency={withFrequency}
        />
      )}
    </>
  );
}

SubscriptionCard.defaultProps = {
  withFrequency: false,
  showHearings: true,
  withWeekAheadEmail: false,
};

SubscriptionCard.propTypes = {
  isStaffer: PropTypes.bool,
  isHearing: PropTypes.bool,
  isLoading: PropTypes.bool,
  withTitle: PropTypes.bool,
  staffer: PropTypes.string,
  hearings: PropTypes.string,
  sendWeekAheadEmail: PropTypes.bool,
  setStafferFrequency: PropTypes.func.isRequired,
  setHearingsFrequency: PropTypes.func.isRequired,
  setWeekAhead: PropTypes.func.isRequired,
  setStaffers: PropTypes.func,
  setHearings: PropTypes.func,
  withFrequency: PropTypes.bool,
  showHearings: PropTypes.bool,
  withWeekAheadEmail: PropTypes.bool,
};

export default SubscriptionCard;
