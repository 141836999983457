import React from 'react';
import PropTypes from 'prop-types';

import { updateTrackShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { multipleSubscriptionShape } from 'components/Dashboard/utils/shapes';
import Button from 'components/core/Button';
import Modal from 'components/core/Modal';
import { trackingShape } from 'redux/subscriptions/utils/shapes';
import { workspacesShape } from 'components/core/TrackButton/shapes';
import WorkspacesSelect from 'components/Dashboard/common/TrackModal/components/WorkspacesSelect';
import MyFeedFrequency from 'components/Dashboard/common/TrackModal/components/MyFeedFrequency';
import { FREQUENCY_TITLE } from 'components/Dashboard/common/FrequencyCard';
import { isMyFeed } from 'components/Dashboard/utils';

import useCommitteeTrack from '../../utils/useCommitteeTrack';
import SubscriptionCard from '../SubscriptionCard';
import styles from './styles.module.scss';

function CommitteeTrack({
  feedToken,
  documentType,
  itemType,
  listSubscriptions,
  defaultSubscription,
  isLoading,
  isOpenModal,
  toggleModal,
  updateParams,
  onUpdateTrackCommittee,
  trackings,
  workspaces,
  withWorkspacesSelect,
  withSelectedWorkspace,
}) {
  const {
    isStaffer,
    isHearing,
    onSave,
    onClose,
    workspacesCommitteeItem,
    isDisabled,
    showHearings,
    ...subscriptionProps
  } = useCommitteeTrack({
    feedToken,
    documentType,
    listSubscriptions,
    defaultSubscription,
    toggleModal,
    updateParams,
    onUpdateTrackCommittee,
    trackings,
    workspaces,
    itemType,
  });

  const modalActions = (
    <>
      <Button theme="light" size="small" onClick={onClose}>
        Cancel
      </Button>
      <Button
        isLoading={isLoading}
        size="small"
        disabled={isDisabled}
        onClick={onSave}
      >
        Save
      </Button>
    </>
  );

  const getContent = () => {
    const commonSubscriptionCardProps = {
      ...subscriptionProps,
      isStaffer,
      isHearing,
      showHearings,
    };

    if (withSelectedWorkspace || !withWorkspacesSelect) {
      return (
        <SubscriptionCard
          {...commonSubscriptionCardProps}
          withFrequency
          withWeekAheadEmail={isMyFeed(feedToken)}
        />
      );
    }

    return (
      <>
        <p>{FREQUENCY_TITLE}</p>
        <MyFeedFrequency
          isChecked={workspaces.isMyFeedChecked}
          onCheck={workspaces.onCheckMyFeed}
          withTitle={false}
        >
          <SubscriptionCard
            {...commonSubscriptionCardProps}
            withTitle={false}
            withFrequency
            withWeekAheadEmail
          />
        </MyFeedFrequency>
        {withWorkspacesSelect && (
          <WorkspacesSelect workspaces={workspaces}>
            <SubscriptionCard
              {...workspacesCommitteeItem}
              withTitle={false}
              showHearings={showHearings}
            />
          </WorkspacesSelect>
        )}
      </>
    );
  };

  return (
    <Modal
      title="Subscription settings"
      isOpen={isOpenModal}
      toggleOpen={toggleModal}
      buttons={modalActions}
      className={styles.container}
      onRequestClose={onClose}
      theme="scrollable"
    >
      {getContent()}
    </Modal>
  );
}

CommitteeTrack.defaultProps = {
  withSelectedWorkspace: false,
};

CommitteeTrack.propTypes = {
  ...multipleSubscriptionShape,
  feedToken: PropTypes.string,
  documentType: PropTypes.string,
  itemType: PropTypes.string,
  defaultSubscription: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpenModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  updateParams: updateTrackShape,
  onUpdateTrackCommittee: PropTypes.func.isRequired,
  trackings: PropTypes.arrayOf(trackingShape),
  workspaces: workspacesShape,
  withWorkspacesSelect: PropTypes.bool.isRequired,
  withSelectedWorkspace: PropTypes.bool,
};

export default CommitteeTrack;
