import { Filters, AdvancedOptions } from 'components/UnifiedSearch/types';

import { RequestParams } from '../types';
import { getSearchSlugs, subtractTime } from './helpers';

type PeriodRange = { from_date?: Date, to_date?: Date };

const getVerbatimParams = (advancedOptions: AdvancedOptions) => (
  advancedOptions.values.verbatim === 'verbatim' ? { is_verbatim: true } : {}
);

const getUTCDate = (date?: Date, hour = 0, minute = 0, second = 0) => (
  date && new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hour,
    minute,
    second,
  ))
);

const getPeriodParams = (advancedOptions: AdvancedOptions, filters: Filters): PeriodRange => {
  const isDisabled = filters.value === 'committees' || filters.value === 'people';

  if (isDisabled) return {};

  if (advancedOptions.values.period === 'custom') {
    return {
      from_date: getUTCDate(advancedOptions.values.customPeriod?.from),
      to_date: getUTCDate(advancedOptions.values.customPeriod?.to, 23, 59, 59),
    };
  }

  const toDate = new Date();
  const { [advancedOptions.values.period]: periodRange } = {
    any: {},
    hour: { from_date: subtractTime('hour', 1), to_date: toDate },
    day: { from_date: subtractTime('day', 1), to_date: toDate },
    week: { from_date: subtractTime('day', 7), to_date: toDate },
    month: { from_date: subtractTime('month', 1), to_date: toDate },
    year: { from_date: subtractTime('year', 1), to_date: toDate },
  };

  return periodRange;
};

const getRegionParams = (advancedOptions: AdvancedOptions) => {
  const { region, customRegions } = advancedOptions.values;

  const customRegionsParam = customRegions
    .filter(customRegion => customRegion.value !== 'all')
    .map(customRegion => customRegion.value);

  if (region === 'state') {
    return { state_codes: customRegionsParam };
  }

  if (region === 'local') {
    return { locale_names: customRegionsParam };
  }

  return {};
};

const getAdvancedOptions = (advancedOptions: AdvancedOptions, filters: Filters) => ({
  ...getPeriodParams(advancedOptions, filters),
  ...getVerbatimParams(advancedOptions),
  ...getRegionParams(advancedOptions),
});

export const buildARequestData = (params: RequestParams) => (
  JSON.stringify({
    search: {
      ...getAdvancedOptions(params.advancedOptions, params.filters),
      query: params.searchQuery,
      search_slugs: getSearchSlugs(params.advancedOptions, params.filters),
      page: params.page,
    },
  })
);
