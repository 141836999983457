import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactHintFactory from 'react-hint';

const defaultCheckerClassName = 'royal-checkbox__checker';
const checkedCheckerClassName = 'royal-checkbox__checker--checked';
const indeterminateCheckerClassName = 'royal-checkbox__checker--indeterminate';
const defaultTooltipAttr = 'data-custom';
const ReactHint = ReactHintFactory(React);


export default class Container extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    tooltipKey: PropTypes.string,
    wrapperClassName: PropTypes.string,
    checked: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    onChange: PropTypes.func,
    showTooltip: PropTypes.bool,
    tooltipMessage: PropTypes.string,
  }

  static defaultProps = {
    checked: false,
    indeterminate: false,
    showTooltip: false,
  }

  state = {
    checked: this.props.checked,
  }

  componentDidUpdate = (prevProps) => {
    let { checked } = this.props;
    checked = this.getBoolValue(checked);

    if (checked !== this.getBoolValue(prevProps.checked) &&
        checked !== this.getBoolValue(this.state.checked)) {
      this.setState({ checked });
    }
  }

  getBoolValue(value) {
    return value === true || value === 'true';
  }

  handleClick = () => {
    const { disabled, onChange } = this.props;

    if (disabled) return;

    this.setState(
      prevState => ({ checked: !prevState.checked }),
      () => onChange && onChange(this.state.checked)
    );
  }

  // create a custom tooltipAttr for each checkbox on page
  tooltipAttr = () => [
    defaultTooltipAttr,
    this.props.tooltipKey,
  ].join('-');

  render() {
    const {
      name,
      value,
      label,
      disabled,
      indeterminate,
      showTooltip,
      tooltipMessage,
      wrapperClassName,
    } = this.props;
    const { checked } = this.state;

    const checkerClassName = classnames({
      [defaultCheckerClassName]: !checked && !indeterminate,
      [checkedCheckerClassName]: checked && !indeterminate,
      [indeterminateCheckerClassName]: indeterminate,
    });

    const elementClassName = classnames({
      'royal-checkbox': true,
      'royal-disabled': disabled,
    });

    const labelBlock = (
      <div className="royal-checkbox__label">
        { label }
      </div>
    );

    // Fixed changing of uncontrolled input
    const inputProps = {
      name,
      value: '',
    };

    if (checked && value) {
      inputProps.value = value;
    }

    const hiddenInput = (
      <input type="hidden" {...inputProps} />
    );

    const isTooltipEnabled = showTooltip && disabled;
    const tooltipContent = () => (
      <div className="react-hint__content">
        {tooltipMessage}
      </div>
    );
    const tooltip = (
      <ReactHint
        attribute={this.tooltipAttr()}
        position="top"
        events={{ hover: true }}
        onRenderContent={tooltipContent}
      />
    );

    return (
      <div className={wrapperClassName}>
        { isTooltipEnabled && tooltip }
        <div
          className={elementClassName}
          onClick={this.handleClick}
          {...(isTooltipEnabled && { [this.tooltipAttr()]: true })}
        >
          <div className="royal-checkbox__square">
            <div className={checkerClassName} />
          </div>
          { label && labelBlock }
          { name && hiddenInput }
        </div>
      </div>
    );
  }
}
