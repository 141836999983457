import { MY_FEED_LINKS } from 'components/NavigationV2/utils/constants';

export const addNavigationV2Classes = () => {
  const mainEl = document.getElementById('main');
  mainEl && mainEl.classList.add('navigation-v2');

  const navigationEl = document.getElementById('navigation');
  navigationEl && navigationEl.classList.add('v2');
};

export const getIsActiveExact = link => !!window.location.pathname.match(new RegExp(`^${link}$`));
export const getIsActive = link => !!window.location.pathname.match(new RegExp(link));

export const APP_MENU = 'menu';

export const getIsMyFeedActive = () => (
  Object.values(MY_FEED_LINKS).some(link => getIsActive(link))
);
