import React from 'react';

import { typeOptions } from 'utils/constants';
import { defaultNewPhoneValue, phonePlaceholder } from 'components/Advocates/common/Modals/StakeholderForm/utils';
import { profileShape } from 'components/Advocates/common/utils/shapes';

import MultipleField from './MultipleField';

const { personalTypeOptions } = typeOptions;

function PhoneField({ values, ...restProps }) {
  const options = values.phones;

  return (
    <MultipleField
      {...restProps}
      id="phones"
      name="phones"
      title="Phone number"
      optionalFieldName="number"
      fieldName="phone"
      typeName="category"
      options={options}
      isLoading={false}
      typeOptions={personalTypeOptions}
      placeholder={phonePlaceholder}
      defaultValue={defaultNewPhoneValue}
      maskSlug="us_phone_number_with_country_code"
    />
  );
}

PhoneField.propTypes = {
  values: profileShape.isRequired,
};

export default PhoneField;
