import PropTypes from 'prop-types';

import { stringNumberType } from 'utils/shapes';
import { partyShape, subscribableTypePropType } from 'components/Dashboard/utils/shapes';
import { dateRangeShape } from 'utils/shapes/dateRange';
import { frequencyType } from 'components/core/FrequencyOptions/shapes';
import { RECENT, TRACKED } from 'components/Dashboard/common/FeedPage/utils/constants';

export const tabTypePropType = PropTypes.oneOf([RECENT, TRACKED]);

export const uiShape = PropTypes.shape({
  isInfiniteDisabled: PropTypes.bool,
  isInitialSeenUpdated: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingDefaultSubscription: PropTypes.bool,
  isLoadingKeywords: PropTypes.bool,
  isUpdatingDefaultSubscription: PropTypes.bool,
  isLoadingNextPage: PropTypes.bool,
});

export const myFeedShape = PropTypes.shape({
  ui: uiShape,
  paginator: PropTypes.shape({
    totalCount: PropTypes.number,
  }),
});

export const sponsorShape = {
  id: stringNumberType,
  addressableMoniker: PropTypes.string,
  partyShort: PropTypes.oneOf(['D', 'R', 'N', 'I']),
  partySlug: PropTypes.oneOf(['democrat', 'democratic', 'republican', 'nonpartisan', 'independent']),
  comeFrom: PropTypes.string,
};

export const lastActionShape = {
  displayingProps: PropTypes.arrayOf(PropTypes.string),
  action: PropTypes.string,
  date: PropTypes.string,
};

export const cosponsorsShape = {
  democrat: partyShape,
  republican: partyShape,
  other: partyShape,
};

export const stafferTypes = {
  id: stringNumberType,
  position: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  lastActionDate: PropTypes.string,
  url: PropTypes.string,
};

export const positionTrackShape = {
  id: stringNumberType,
  ids: PropTypes.arrayOf(stringNumberType),
  displayingProps: PropTypes.arrayOf(PropTypes.string),
  eventType: PropTypes.string,
  withPosition: PropTypes.bool,
  position: PropTypes.string,
  withTrack: PropTypes.bool,
  isTracking: PropTypes.bool,
  priority: PropTypes.string,
  feedToken: PropTypes.string.isRequired,
};

export const trackItemShape = {
  isOpenTrackItem: PropTypes.bool.isRequired,
  openTrackItem: PropTypes.func.isRequired,
  closeTrackItem: PropTypes.func.isRequired,
};

export const trackedShape = {
  title: PropTypes.string,
  category: PropTypes.string.isRequired,
  titleUrl: PropTypes.string,
  displayTitle: PropTypes.shape({
    cardTitle: PropTypes.string,
    stateShort: PropTypes.string,
    frequency: frequencyType,
  }),
};

const commonDescriptionPropTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  isExternal: PropTypes.bool,
};

const linkNodeOnlyPropTypes = {
  afterLabel: PropTypes.string,
};

export const linkNodePropTypes = {
  ...commonDescriptionPropTypes,
  ...linkNodeOnlyPropTypes,
};

const sponsorOnlyPropTypes = {
  partyShort: PropTypes.string,
  comeFrom: PropTypes.string,
  infoText: PropTypes.string,
};

export const sponsorPropTypes = {
  ...commonDescriptionPropTypes,
  ...sponsorOnlyPropTypes,
};

export const hearingPropTypes = {
  subject: PropTypes.string,
  location: PropTypes.string,
  committee: PropTypes.string,
};

const descriptionNodeShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  data: PropTypes.shape({
    ...commonDescriptionPropTypes,
    ...hearingPropTypes,
    ...sponsorOnlyPropTypes,
    ...linkNodeOnlyPropTypes,
  }),
});

export const eventShape = {
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  priority: PropTypes.string,
  isSaved: PropTypes.bool.isRequired,
  description: PropTypes.string,
  descriptionNode: descriptionNodeShape,
  actionType: PropTypes.string,
  itemType: PropTypes.string.isRequired,
  titleUrl: PropTypes.string,
  frequency: frequencyType,
};

const stateShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

export const tagShape = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
});

export const filterValuesShape = {
  searchValue: PropTypes.string,
  options: PropTypes.shape({
    date: dateRangeShape,
    dateAdded: dateRangeShape,
    emailFrequency: frequencyType,
    itemType: PropTypes.arrayOf(PropTypes.string),
    keywords: PropTypes.arrayOf(PropTypes.string),
    lastUpdate: dateRangeShape,
    position: PropTypes.arrayOf(PropTypes.string),
    priority: PropTypes.arrayOf(PropTypes.string),
    readItems: PropTypes.string,
    removedItems: PropTypes.arrayOf(PropTypes.string),
    savedItems: PropTypes.arrayOf(PropTypes.string),
    state: PropTypes.arrayOf(stateShape),
    status: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(tagShape),
    trackedItems: PropTypes.string,
    sharedItems: PropTypes.string,
  }),
};

export const appliedFiltersShape = PropTypes.shape({
  ...filterValuesShape,
  perPage: PropTypes.number,
});

export const updateTrackShape = PropTypes.shape({
  id: stringNumberType.isRequired,
  modelId: stringNumberType.isRequired,
  type: PropTypes.string,
  priority: PropTypes.string,
  isSaved: PropTypes.bool,
});

export const subscriptionShape = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  token: PropTypes.string,
  frequency: frequencyType,
  isSaved: PropTypes.bool,
  keyword: PropTypes.string,
  trackingsSlug: PropTypes.arrayOf(PropTypes.string),
  priority: PropTypes.string,
  title: PropTypes.string,
  subscribableType: PropTypes.string,
  subscribableData: PropTypes.shape({
    model_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    model_type: PropTypes.string,
  }),
  isDisabled: PropTypes.bool,
  sendWeekAheadEmail: PropTypes.bool,
  isTracking: PropTypes.bool,
});

export const eventSubscriptionShape = PropTypes.shape({
  token: PropTypes.string,
  frequency: frequencyType,
  keyword: PropTypes.string,
  subscribableType: PropTypes.string,
  isDisabled: PropTypes.bool,
  title: PropTypes.string,
  trackingsSlug: PropTypes.arrayOf(PropTypes.string),
});

export const listFieldItemShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
});

const commonCardProps = {
  id: stringNumberType.isRequired,
  recordId: stringNumberType.isRequired,
  documentType: PropTypes.string.isRequired,
  isRead: PropTypes.bool,
  positionRecordType: PropTypes.string,
  withPosition: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleUrl: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoadingPosition: PropTypes.bool,
  isLoadingTags: PropTypes.bool,
};

export const recentCardShape = PropTypes.shape({
  ...commonCardProps,
  ...eventShape,
  displayTitle: PropTypes.shape({
    cardTitle: PropTypes.string,
    stateShort: PropTypes.string,
    frequency: frequencyType,
  }),
  isLoadingTracking: PropTypes.bool,
  isNewData: PropTypes.bool,
  isRemoved: PropTypes.bool,
  isTracking: PropTypes.bool,
  position: PropTypes.string,
  recordType: PropTypes.string,
  sendWeekAheadEmail: PropTypes.bool,
  subscription: subscriptionShape,
  trackRecordType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  withTrack: PropTypes.bool,
});

export const listSubscriptionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    content: PropTypes.string,
    frequency: frequencyType,
    isRemoved: PropTypes.bool,
    label: PropTypes.string,
    modelId: stringNumberType,
    sendWeekAheadEmail: PropTypes.bool,
    type: subscribableTypePropType,
  })
);

export const officeHolderShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  url: PropTypes.string,
  addressableMoniker: PropTypes.string,
  partyShort: PropTypes.string,
  partySlug: PropTypes.string,
  comeFrom: PropTypes.string,
});

export const trackedCardShape = PropTypes.shape({
  ...commonCardProps,
  ...trackedShape,
  ids: PropTypes.arrayOf(stringNumberType).isRequired,
  category: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  priority: PropTypes.string.isRequired,
  frequency: frequencyType.isRequired,
  isSaved: PropTypes.bool,
  subscriptionId: stringNumberType,
  description: PropTypes.string,
  isRemoved: PropTypes.bool.isRequired,
  lastActionDate: PropTypes.string,
  listSubscriptions: listSubscriptionsShape,
  officeHolder: officeHolderShape,
  staffer: PropTypes.shape(stafferTypes),
});

export const cardShape = PropTypes.oneOfType([
  recentCardShape,
  trackedCardShape,
]);

export const commonUiShape = PropTypes.shape({
  isInfiniteDisabled: PropTypes.bool,
  isInitialLoad: PropTypes.bool,
  isInitialSeenUpdated: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingDefaultSubscription: PropTypes.bool,
  isLoadingKeywords: PropTypes.bool,
  isUpdatingDefaultSubscription: PropTypes.bool,
});

export const lastVisitShape = PropTypes.shape({
  last_visit_at: PropTypes.string,
  latest_event_token: PropTypes.string,
});

export const sourceContentType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
    partyShort: PropTypes.string,
    comeFrom: PropTypes.string,
  }),
]);

export const searchParamsShape = PropTypes.shape({
  position: PropTypes.string,
  track: PropTypes.string,
  keywordId: PropTypes.string,
  save: PropTypes.string,
  share: PropTypes.string,
  tag: PropTypes.string,
  priority: PropTypes.string,
  frequency: frequencyType,
});

export const sortShape = PropTypes.shape({
  sort: PropTypes.string,
  direction: PropTypes.string,
  toReset: PropTypes.bool,
});

export const officialSourceShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
]);

export const keywordShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});
