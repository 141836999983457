import { useEffect, useState, useRef } from 'react';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

const useIsBackToTopVisible = () => {
  const MIN_DISTANCE_TO_SHOW_BACK_TO_TOP_BUTTON = 400;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isBackToTopVisible, setIsBackToTopVisible] = useState(false);
  const { isMobile } = useMediaQuery();

  const handleIsVisible = () => {
    if (containerRef.current) {
      const distanceToTop = containerRef.current.scrollTop || 0;
      setIsBackToTopVisible(distanceToTop > MIN_DISTANCE_TO_SHOW_BACK_TO_TOP_BUTTON);
    }
  };

  useEffect(() => {
    if (isMobile) return undefined;

    document.addEventListener('scroll', handleIsVisible, true);

    return () => document.removeEventListener('scroll', handleIsVisible, true);
  }, []);

  return {
    isBackToTopVisible,
    containerRef,
  };
};

export default useIsBackToTopVisible;
