import PropTypes from 'prop-types';

import { stringNumberType } from 'utils/shapes';
import { suggestionShape } from 'utils/shapes/suggestion';
import { dateRangeShape } from 'utils/shapes/dateRange';
import { frequencyType } from 'components/core/FrequencyOptions/shapes';

export const userShape = PropTypes.shape({
  id: stringNumberType.isRequired,
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string,
  profileImg: PropTypes.string,
});

export const workspaceShape = PropTypes.shape({
  token: PropTypes.string,
  name: PropTypes.string,
  isDeletable: PropTypes.bool,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  subscription: PropTypes.shape({
    users: PropTypes.arrayOf(userShape),
    frequency: frequencyType,
  }),
  disabled: PropTypes.bool,
});

export const uiShape = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  isInitialLoad: PropTypes.bool.isRequired,
  isLoadingWorkspace: PropTypes.bool.isRequired,
  isDeletingWorkspace: PropTypes.bool.isRequired,
  isNotFound: PropTypes.bool.isRequired,
});

export const workspacesShape = PropTypes.shape({
  feed: PropTypes.arrayOf(workspaceShape).isRequired,
  workspace: workspaceShape.isRequired,
  ui: uiShape.isRequired,
});

export const filterValuesShape = PropTypes.shape({
  options: PropTypes.shape({
    name: PropTypes.arrayOf(suggestionShape),
    createdAt: dateRangeShape,
    updatedAt: dateRangeShape,
    subscribers: PropTypes.arrayOf(suggestionShape),
    subscriptionStatus: PropTypes.string.isRequired,
  }),
});

export const appliedFiltersShape = filterValuesShape;
