import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const DATE_FORMAT = 'L';

export default class FileDetails extends React.Component {
  static propTypes = {
    files: PropTypes.array.isRequired,
    fileNames: PropTypes.array.isRequired,
    className: PropTypes.string.isRequired,
    handleDelete: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
  }

  static defaultProps = {
    multiple: false,
  }

  lastFile = () => {
    const { files } = this.props;

    if (files.length === 0) {
      return null;
    }

    return files[files.length - 1];
  }

  deleteButton = () => (
    <i
      className="far fa-times-circle fa-lg royal-file-uploader__delete-button"
      onClick={this.props.handleDelete}
    />
  );

  fileDetails = () => {
    const { fileNames, className, multiple } = this.props;

    if (multiple) return (<span>Delete All</span>);
    const displayFileNames = fileNames.join(', ');
    const uploadedAt = moment(this.lastFile().created_at).format(DATE_FORMAT);

    return (
      <span className={className}>
        { displayFileNames } Uploaded { uploadedAt }
      </span>
    );
  }

  details = () => {
    if (!this.lastFile()) {
      return null;
    }

    return (
      <span>
        { this.fileDetails() }&nbsp;
        { this.deleteButton() }
      </span>
    );
  }

  render() {
    return this.details();
  }
}
