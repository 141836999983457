import React from 'react';

import styles from './styles.module.scss';

export default function EmptyWarning() {
  return (
    <p className={styles.warning}>
      Search doesn’t match any documents
    </p>
  );
}
