import slugs from 'components/Navigation/menuData/slugs';

import { RELATED_APPS_SECTION, STATE_SUMMARIES } from './common';

const FEDERAL_SECTION = {
  id: 'federal',
  title: 'Federal',
  items: [
    {
      title: 'Federal bills',
      description: 'Current and past federal legislation',
      href: '/federal/bills',
      slug: slugs.federal,
    },
    {
      title: 'Federal regulations',
      description: 'Federal rules and administrative codes',
      href: '/federal/regulations',
      slug: slugs.federal,
    },
    {
      title: 'Lobbying (LD-2)',
      description: 'Domestic lobbying disclosures',
      href: '/lobbying_research/ld2',
      slug: slugs.federal,
    },
    {
      title: 'Lobbying (FARA)',
      description: 'Foreign lobbying disclosures',
      href: '/lobbying_research/fara',
      slug: slugs.federal,
    },
  ],
};

const STATE_SECTION = {
  id: 'state',
  title: 'State',
  items: [
    {
      title: 'State bills',
      description: 'Current and past state legislation',
      href: '/state/bills',
      slug: slugs.state,
    },
    {
      title: 'State regulations',
      description: 'State rules and administrative codes',
      href: '/state/regulations',
      slug: slugs.stateRegulation,
    },
  ],
};

const RELATED_SECTION = {
  ...RELATED_APPS_SECTION,
  items: [
    {
      title: 'Cosponsor oracle',
      description: 'Federal legislators most likely to cosponsor a bill',
      href: '/federal/cosponsors_oracle',
      slug: slugs.federal,
    },
    {
      title: 'Cosponsorship similarities',
      description: 'Most common cosponsors for state & fed leg.',
      href: '/federal/cosponsorship_similarity',
      slug: slugs.cosponsorshipSimilarities,
    },
    STATE_SUMMARIES,
  ],
};

export const LEGISLATION_AND_REGULATION_COLUMNS = [
  [FEDERAL_SECTION],
  [STATE_SECTION],
  [RELATED_SECTION],
];

export const LEGISLATION_AND_REGULATION_SECTIONS = [
  FEDERAL_SECTION,
  STATE_SECTION,
  RELATED_SECTION,
];
