// @ts-nocheck
import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'components/core/Button';
import { UseModalType } from 'components/core/Modal/types';

import Navigation from '../../../Navigation';
import FooterBar from '../common/FooterBar';
import Create from './components/Create';
import Apps from './components/Apps';
import ItemName from './components/ItemName';
import HomeLink from './components/HomeLink';
import styles from './styles.module.scss';

type NavigationBarProps = {
  isAppMenuOpen: boolean,
  disabledProducts: string[],
  toggleNavigationMenu: () => void,
  unifiedSearchView: UseModalType
};

const NavigationBar: FC<NavigationBarProps> = ({
  isAppMenuOpen,
  disabledProducts,
  toggleNavigationMenu,
  unifiedSearchView,
}) => {
  const {
    isOpenModal: isGlobalSearchOpen,
    toggleModal: toggleGlobalSearch,
    closeModal: closeGlobalSearch,
  } = unifiedSearchView;

  if (isAppMenuOpen) {
    return null;
  }

  const searchIconClassNames = classNames(
    'far fa-search',
    styles.icon,
    isGlobalSearchOpen && styles.activeIcon,
  );

  const handleCloseGlobalSearch = () => isGlobalSearchOpen && closeGlobalSearch();
  const handleDirectoryClick = () => {
    handleCloseGlobalSearch();
    toggleNavigationMenu();
  };

  return (
    <FooterBar>
      <div className={styles.closedMenuContainer}>
        <Apps
          disabledProducts={disabledProducts}
          className={styles.navButton}
          onClick={handleCloseGlobalSearch}
        />

        <Button onClick={handleDirectoryClick} className={styles.navButton} theme="light">
          <i className={classNames('far fa-folder-open', styles.icon)} />
          <ItemName name="Directory" />
        </Button>

        <Create
          className={styles.navButton}
          iconClassName={styles.icon}
          onClick={handleCloseGlobalSearch}
        />

        <Button onClick={toggleGlobalSearch} className={styles.navButton} theme="light">
          <i className={searchIconClassNames} />
          <ItemName name="Search" isActive={isGlobalSearchOpen} />
        </Button>

        <HomeLink isInactive={isGlobalSearchOpen} />

        {isGlobalSearchOpen && (
          <Navigation
            isMobile
            className={styles.globalQuickSearch}
            unifiedSearchView={unifiedSearchView}
          />
        )}
      </div>
    </FooterBar>
  );
};

export default NavigationBar;
