import PropTypes from 'prop-types';
import React from 'react';
import Select from 'components/core/Select';


class SelectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    this.setState({ value }, () => {
      this.props.updateSelected(this.state.value);
    });
  }

  render() {
    return (
      <div className="form-group">
        <label className="control-label">Select one or more staffers</label>
        <Select
          name="gov_mail[recipient_ids]"
          placeholder="Select staffers from list"
          clearable
          searchable
          value={this.state.value}
          options={this.props.options}
          onChange={this.onChange}
          multi
        />
      </div>
    );
  }
}

SelectList.propTypes = {
  updateSelected: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default SelectList;
