import PropTypes from 'prop-types';
import React from 'react';

function ModalHeader({ handleClose }) {
  return (
    <header className="modal-header" key="modal-header">
      <button
        type="button"
        className="close"
        data-dismiss="modal" aria-label="Close"
        onClick={handleClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 className="modal-title">Crop your photo using the dotted box below</h4>
    </header>
  );
}

ModalHeader.propTypes = {
  handleClose: PropTypes.func,
};

export default ModalHeader;
