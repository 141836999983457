import PropTypes from 'prop-types';
import React from 'react';
import ReactCrop from 'react-image-crop';

function ModalContent({
  crop, imageSource, onChange, onImageLoaded, onComplete, renderPreview, croppedImageUrl,
}) {
  return (
    <div className="crop-modal__body" key="modal-body">
      <div className="crop-modal__large">
        {imageSource && (
          <ReactCrop
            src={imageSource}
            crop={crop}
            className="crop-modal__image"
            onImageLoaded={onImageLoaded}
            onComplete={onComplete}
            onChange={onChange}
            keepSelection
          />
        )}
      </div>

      <div className="crop-modal__preview">
        {croppedImageUrl && renderPreview('Small Preview')}
        {croppedImageUrl && renderPreview('Large Preview', 'large')}
      </div>
    </div>
  );
}

ModalContent.propTypes = {
  crop: PropTypes.object,
  imageSource: PropTypes.string,
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  renderPreview: PropTypes.func,
  onImageLoaded: PropTypes.func,
  croppedImageUrl: PropTypes.string,
};

export default ModalContent;
