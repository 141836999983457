import React, { FC } from 'react';

import Placeholder from 'components/GlobalSearch/components/RecentVisits/Placeholder';
import { RecentVisits as RecentVisitsType } from 'components/UnifiedSearch/types';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import RecentCard from './components/RecentCard';
import VisitsPlaceholder from './components/VisitsPlaceholder';
import styles from './styles.module.scss';

type RecentVisitsProps = {
  recentVisits: RecentVisitsType
};

const RecentVisits: FC<RecentVisitsProps> = ({ recentVisits }) => {
  const { isMobile } = useMediaQuery();
  const isLoading = recentVisits.status === 'loading';
  const isError = recentVisits.status === 'error';
  const isIdle = recentVisits.status === 'idle';
  const isEmpty = isIdle && !recentVisits.recentVisits?.length;
  const showClearAll = isIdle && !isEmpty;

  const renderVisits = () => {
    if (isLoading) {
      return <VisitsPlaceholder />;
    }

    if (isError || isEmpty) {
      return (
        <Placeholder
          className={styles.noRecentVisits}
          isRecentVisitsError={isError}
        />
      );
    }
    return (
      <>
        {[...recentVisits?.recentVisits].map(visit => (
          <RecentCard
            key={visit.payload.key}
            recentVisit={visit}
            onRemoveRecentVisit={recentVisits.clear}
            isMobile={isMobile}
          />
        ))}
      </>
    );
  };

  return (
    <div className={styles.recentVisits}>
      <div className={styles.header}>
        <div className={styles.title}>RECENT VISITS</div>
        {showClearAll && (
          <button className={styles.clearBtn} type="button" onClick={recentVisits.clearAll}>
            Clear all
          </button>
        )}
      </div>
      {renderVisits()}
    </div>
  );
};

export default RecentVisits;
