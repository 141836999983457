import { Slug, TrackableRecordType } from '../types';

type TrackableMapper = {
  [key in Slug]?: TrackableRecordType
};

export const getTrackableRecordType = (slug: Slug): TrackableRecordType | undefined => {
  const trackableMapper: TrackableMapper = {
    federal_bills: 'federal_bill',
    federal_register_regulations: 'federal_register_regulation',
    federal_legislators: 'federal_legislator_staffer',
    state_legislation_bills: 'state_bill',
    state_legislation_regulations: 'state_regulation',
    state_legislation_legislators: 'state_legislator_staffer',
  };

  return trackableMapper[slug];
};
