import debounce from 'lodash/debounce';

import { MD_SCREEN } from 'utils/breakpoints/constants';

function setDocHeight() {
  document
    .documentElement
    .style
    .setProperty('--vh', `${window.innerHeight * 0.01}px`);
}

(window.innerWidth < MD_SCREEN) && setDocHeight();

window.addEventListener('resize', debounce(setDocHeight, 100));
