import { isEmpty } from 'lodash';
import { Result } from '../types';
import { ApiResponse, TagsByRecord } from './types';
import factories from './factories';

const fetchTags = async (results: Result[]): Promise<TagsByRecord[]> => {
  if (isEmpty(results)) return [];

  const apiResponse: ApiResponse = await $.ajax({
    method: 'POST',
    url: '/api_web/tags/lookup',
    data: JSON.stringify(factories.buildRequestData(results)),
    contentType: 'application/json',
  });

  return factories.buildResponse(apiResponse);
};

export default {
  fetchTags,
};
