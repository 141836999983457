import { useEffect } from 'react';

import Notifications from 'components/Advocates/Lookup/utils/Notifications';
import useModals from 'components/Advocates/common/Modals/useModals';
import { transformStakeholderToBE } from 'redux/stakeholder/Profile/utils';
import { MAX_CUSTOM_FIELDS_PAGE_SIZE } from 'components/Advocates/utils/constants';
import { PRODUCT_SLUGS } from 'components/core/CustomFields/utils/constants';

import validationSchema from './validationSchema';
import { mapPropsToValues, normalizePayload, getNotifications } from '.';

export default function useForm({
  isOpen,
  stakeholder,
  toggleOpen,
  customField,
  allCustomFields,
  createStakeholder,
  updateStakeholder,
  fetchCustomFields,
  currentStep,
  handleChangeCurrentStep,
  isProfilePageUpdate,
  avoidDispatchFetch,
  onSuccess,
}) {
  const { stakeholderModalStep, handleChangeStakeholderModalStep } = useModals(currentStep);
  const { id } = stakeholder || {};

  function handleStepRedirect(step) {
    handleChangeCurrentStep && handleChangeCurrentStep(step);
    !handleChangeCurrentStep && handleChangeStakeholderModalStep(step);
  }

  const onOpen = () => (
    fetchCustomFields({
      search_form: {
        product_slugs: [PRODUCT_SLUGS.STAKEHOLDER_MGMT],
        enabled: true,
      },
      per_page: MAX_CUSTOM_FIELDS_PAGE_SIZE,
    }).catch((error) => {
      Notifications.defaultFetchDataFailed();
      throw error;
    })
  );

  useEffect(() => {
    isOpen && onOpen();
  }, [isOpen]);

  const values = mapPropsToValues({ ...stakeholder, id });

  const initialValues = { ...values, ...customField, id };
  const schema = validationSchema(allCustomFields);

  function onSubmit(payload, formActions) {
    const { setSubmitting, setFieldError, resetForm } = formActions;

    const {
      submitAction,
      successNotification,
      failureNotification,
    } = getNotifications({ id, createStakeholder, updateStakeholder });

    const profile = normalizePayload(payload, allCustomFields.filter(field => field.enabled));

    function handleFailure(error) {
      error && Object.keys(error).forEach((key) => {
        setFieldError(key, error[key]);
      });
      setSubmitting(false);
      failureNotification();
    }

    function handleSuccess() {
      setSubmitting(false);
      toggleOpen();
      resetForm();
      handleStepRedirect(0);
      successNotification();
      onSuccess && onSuccess();
    }

    const stakeholderData = transformStakeholderToBE(profile);

    return submitAction({
      stakeholderData,
      handleFailure,
      handleSuccess,
      isProfilePageUpdate,
      avoidDispatchFetch,
    });
  }

  return {
    currentStep: stakeholderModalStep,
    onRedirect: handleStepRedirect,
    onChangeCurrentStep: handleChangeCurrentStep,
    onSubmit,
    initialValues,
    schema,
  };
}
