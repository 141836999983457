import React, { FC } from 'react';

import ErrorTemplate from 'components/Errors/ErrorTemplate';

import { Category } from '../../../types';
import { FILTER_OPTIONS } from '../../../utils/constants';
import styles from './styles.module.scss';

type NotFoundProps = {
  searchQuery: string; category: Category;
};

const getCategoryLabel = (category: Category) => {
  if (category === 'all') return '';

  const categoryOption = FILTER_OPTIONS.find(({ value }) => value === category);

  if (!categoryOption) return '';

  return categoryOption.label;
};

const NotFound: FC<NotFoundProps> = ({ searchQuery, category }) => {
  const categoryLabel = getCategoryLabel(category);

  const text = (
    <>
      There are no matches for
      <span className={styles.searchText}>
        {' '}
        “
        {searchQuery}
        ”
      </span>
      {!!categoryLabel && (
      <span>
        {' '}
        in
        {' '}
        {categoryLabel}
      </span>
      )}
    </>
  );

  return (
    <ErrorTemplate
      src="/images/no-results.svg"
      alt="No Results"
      text={text}
      subText="Try typing something else or changing your filter criteria"
    />
  );
};

export default NotFound;
