import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';

import {
  PHONE_REGEXP,
  TWITTER_USERNAME_REGEXP,
  FACEBOOK_PROFILE_URL_REGEXP,
} from 'utils/constants';
import getCustomFieldsSchema from 'utils/forms/getCustomFieldsSchema';
import validateUniqueness from 'utils/yup/validateUniqueness';

import errorMessages from './errorMessages';

Yup.addMethod(Yup.array, 'unique', validateUniqueness);

function customRequired(message, propertyName, rules = {}) {
  return this.test('customRequired', message, function requiredItem(item) {
    const { path, parent } = this;
    const { minArrayLength = 0, is } = rules;
    const isInvalid = is(item);

    if (parent.length > minArrayLength && isInvalid) {
      throw this.createError({
        path: `${path}.${propertyName}`,
        message,
      });
    }

    return true;
  });
}

Yup.addMethod(Yup.object, 'customRequired', customRequired);

const validationSchema = (customFields) => {
  const defaultShape = {
    firstName: Yup.string().required(errorMessages.required),
    middleName: Yup.string(),
    lastName: Yup.string().required(errorMessages.required),
    addresses: Yup.array()
      .of(
        Yup.object().shape({
          place_id: Yup.string(),
          address: Yup.string(),
          category: Yup.string(),
          primary: Yup.bool(),
        })
          .customRequired(errorMessages.required, 'address', {
            is: item => isEmpty(item.address),
            minArrayLength: 1,
          })
      )
      .unique(errorMessages.unique, 'address'),
    organization: Yup.string(),
    phones: Yup.array()
      .of(
        Yup.object().shape({
          phone: Yup.string().trim().matches(PHONE_REGEXP, { message: errorMessages.phone, excludeEmptyString: true }),
          category: Yup.string().nullable(),
          primary: Yup.bool(),
        })
          .customRequired(errorMessages.required, 'phone', {
            is: item => isEmpty(item.phone),
            minArrayLength: 1,
          })
      )
      .unique(errorMessages.unique, 'phone'),
    emails: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .email(errorMessages.email)
          .required(errorMessages.required),
        category: Yup.string(),
        primary: Yup.bool(),
      })
    )
      .unique(errorMessages.unique, 'email'),
    facebookProfile: Yup.string().matches(FACEBOOK_PROFILE_URL_REGEXP, errorMessages.facebookProfileUrl),
    twitterName: Yup.string().matches(TWITTER_USERNAME_REGEXP, errorMessages.username),
  };
  const customFieldsShape = getCustomFieldsSchema(customFields, errorMessages.required);

  return Yup.object().shape({ ...defaultShape, ...customFieldsShape });
};

export default validationSchema;
