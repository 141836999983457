import React, { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

const ActionsWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <footer className={styles.actions}>
    {children}
  </footer>
);

export default ActionsWrapper;
