import React from 'react';
import PropTypes from 'prop-types';

import FrequencyCard from 'components/Dashboard/common/FrequencyCard';

function MyFeedFrequency({
  children,
  isChecked,
  onCheck,
  withTitle,
  hideChildren,
  isCheckboxDisabled,
}) {
  return (
    <FrequencyCard
      label="Save to My feed to receive updates"
      isChecked={isChecked}
      onCheck={onCheck}
      withTitle={withTitle}
      hideChildren={hideChildren}
      isDisabled={isCheckboxDisabled}
    >
      {children}
    </FrequencyCard>
  );
}

MyFeedFrequency.defaultProps = {
  hideChildren: false,
  isCheckboxDisabled: false,
};

MyFeedFrequency.propTypes = {
  children: PropTypes.node,
  isChecked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  withTitle: PropTypes.bool,
  hideChildren: PropTypes.bool,
  isCheckboxDisabled: PropTypes.bool,
};

export default MyFeedFrequency;
