import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { isNil, isEmpty } from 'lodash';
import classNames from 'classnames';

import Link from 'components/core/Link';
import PartyIcon from 'components/shared/party-icon';
import { EMPTY_VALUE } from 'utils/constants';
import { stringNumberType } from 'utils/shapes';

import styles from './styles.module.scss';

function Name({
  id,
  name,
  partyShort,
  partySlug,
  url,
  routerLink,
  className,
  contentClassName,
  partyIconClassName,
  onClick,
  rightContent,
  isPresentAvatar,
  avatarUrl,
} = {}) {
  const commonProps = {
    onClick,
    className: classNames(styles.nameWeight, className),
  };

  const nameNode = <span className={styles.name}>{name}</span>;

  function getIcon() {
    if (isEmpty(partySlug)) {
      return null;
    }

    return (
      <PartyIcon
        partySlug={partySlug}
        partyShort={partyShort}
        className={partyIconClassName}
      />
    );
  }

  if (isEmpty(name)) {
    return EMPTY_VALUE;
  }

  if (isNil(id)) {
    return <div {...commonProps}>{nameNode} {getIcon()}</div>;
  }

  const content = (
    <>
      {isPresentAvatar && (
        <img
          src={avatarUrl}
          className={styles.avatarImg}
          alt="Legislator avatar"
        />
      )}
      {nameNode}&nbsp;
      {getIcon()}
      {rightContent}
    </>
  );

  const contentNode = contentClassName
    ? <div className={classNames(styles.content, contentClassName)}>{content}</div>
    : content;

  if (!isEmpty(routerLink)) {
    return <RouterLink to={routerLink} {...commonProps}>{contentNode}</RouterLink>;
  }

  return (
    <Link
      {...commonProps}
      href={url}
      theme="default"
      rel="noopener noreferrer"
    >
      {contentNode}
    </Link>
  );
}

Name.propTypes = {
  id: stringNumberType,
  isPresentAvatar: PropTypes.bool,
  avatarUrl: PropTypes.string,
  rightContent: PropTypes.node,
  name: PropTypes.string,
  partySlug: PropTypes.string,
  url: PropTypes.string,
  partyShort: PropTypes.string,
  routerLink: PropTypes.string,
  isPlainText: PropTypes.bool,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  onClick: PropTypes.func,
  partyIconClassName: PropTypes.string,
};

export default Name;
