import { useState } from 'react';

import { AdvancedOptions, AdvancedOptionsValues } from 'components/UnifiedSearch/types';

import { INITIAL_STATE } from './initialState';

const useAdvancedOptions = (): AdvancedOptions => {
  const [options, setOptions] = useState<AdvancedOptionsValues>(INITIAL_STATE);
  const [isOpen, setIsOpen] = useState(false);

  const change = (updatedOptions: Partial<AdvancedOptionsValues>) => {
    setOptions(prevOptions => ({ ...prevOptions, ...updatedOptions }));
  };

  const toggle = () => setIsOpen(!isOpen);

  const reset = () => setOptions(INITIAL_STATE);

  return {
    values: options,
    change,
    isOpen,
    toggle,
    reset,
  };
};

export default useAdvancedOptions;
