import React from 'react';
import classNames from 'classnames';

import { Lock as LockIcon } from 'components/NavigationV2/components/icons';

import { DropdownContentComponent } from '../HiddenItemsDropdown.types';
import styles from './styles.module.scss';

export const DropdownContent: DropdownContentComponent = ({ items }) => (
  <div className={styles.dropdownList}>
    {items.map(item => (
      <a
        key={item.name}
        href={item.href}
        className={classNames(
          styles.dropdownItem,
          { [styles.disabled]: item.isDisabled },
        )}
      >
        <span className={classNames(item.isDisabled && styles.disabledText)}>{item.name}</span>
        {item.isDisabled && <LockIcon className={styles.disabledIcon} />}
      </a>
    ))}
  </div>
);
