import { Slug } from '../../../types';
import { ResultFactory } from '../types';
import federalRegulations from './legislationAndRegulation/federalRegulations';
import stateRegulations from './legislationAndRegulation/stateRegulations';
import federalBill from './legislationAndRegulation/federalBill';
import stateBill from './legislationAndRegulation/stateBill';
import federalLegislator from './people/federalLegislator';
import stateLegislator from './people/stateLegislator';
import stakeholder from './people/stakeholder';
import executive from './people/executive';
import federalCommittees from './committees/federalCommittees';
import stateCommittees from './committees/stateCommittees';
import staffer from './people/staffer';
import documents from './documents/documents';
import base from './base';

type Factories = {
  [key in Slug]?: ResultFactory;
};

const factories: Factories & { base: ResultFactory } = {
  stakeholder_mgmt_stakeholders: stakeholder,
  federal_executive: executive,
  state_executive: executive,
  state_staffer_executive: executive,
  local_executive: executive,
  federal_committees: federalCommittees,
  state_legislation_committees: stateCommittees,
  federal_register_regulations: federalRegulations,
  state_legislation_regulations: stateRegulations,
  federal_legislators: federalLegislator,
  state_legislation_legislators: stateLegislator,
  rolodex: staffer,
  state_legislation_staffers: staffer,
  federal_bills: federalBill,
  state_legislation_bills: stateBill,
  doctrove_agency_press_releases: documents,
  cbo_gov: documents,
  committee_reports: documents,
  press_releases: documents,
  congressional_record: documents,
  fbo_gov: documents,
  doctrove_crs: documents,
  doctrove_dear_colleague_letters: documents,
  doctrove_executive_orders: documents,
  gao_gov: documents,
  doctrove_governor_orders: documents,
  doctrove_governor_press_releases: documents,
  grants_gov: documents,
  lobby_filing_issues: documents,
  doctrove_live_hearing_transcripts: documents,
  doctrove_hearing_transcripts: documents,
  doctrove_sap: documents,
  doctrove_union_statements: documents,
  doctrove_third_party_reports: documents,
  treaties: documents,
  doctrove_press_briefings: documents,
  base,
};

export default factories;
