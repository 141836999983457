import PropTypes from 'prop-types';
import React from 'react';

export default class HiddenInputs extends React.Component {
  static propTypes = {
    files: PropTypes.array.isRequired,
    name: PropTypes.string,
    multiple: PropTypes.bool.isRequired,
  }

  buildInput = ({ fileId, name }) => (
    <input type="hidden" key={fileId} value={fileId} name={name} />
  );

  hiddenInputs = () => {
    const { name, files, multiple } = this.props;
    let inputName = name;

    if (multiple) {
      inputName = `${name}[]`;
    }

    const inputs = files.map(file =>
      this.buildInput({ fileId: file.id, name: inputName })
    );

    if (inputs.length > 0) {
      return inputs;
    }

    // build an empty Input if none is present
    // required in tests to run a script like that:
    // $("input[name='#{name}']" ).val(#{value})
    return this.buildInput({ name: inputName });
  }

  render() {
    const { name } = this.props;

    return (
      <span>
        { name && this.hiddenInputs() }
      </span>
    );
  }
}
