import { flatMap, groupBy, map, isEmpty } from 'lodash';

import { TRACKABLE_SLUGS } from 'components/UnifiedSearch/utils/constants';

import { getSlug } from '../useSearch/helpers';
import { getTrackableRecordType } from './helpers';
import { Result, Slug, Tracking } from '../types';
import {
  ApiResponse,
  ApiSubscription,
  SearchForm,
  TrackingsByRecord,
  SearchFormElement,
} from './types';

const buildRequestData = (results: Result[]): SearchForm | null => {
  const groupedResults: { [key in Slug]?: Result[] } = groupBy(results, 'slug');

  const getSearchFormElement = (
    groups: SearchFormElement[],
    [slug, trackingResults]: [string, Result[]],
  ): SearchFormElement[] => {
    if (!TRACKABLE_SLUGS.includes(slug as Slug)) {
      return groups;
    }

    const recordIds = map(trackingResults, 'id');

    // Federal and State committees tracking is split into staffer and hearing
    if (slug === 'federal_committees') {
      return [
        ...groups,
        { record_type: 'federal_committee_staffer', record_ids: recordIds },
        { record_type: 'federal_committee_hearing', record_ids: recordIds },
      ];
    }

    if (slug === 'state_legislation_committees') {
      return [
        ...groups,
        { record_type: 'state_committee_staffer', record_ids: recordIds },
        { record_type: 'state_committee_hearing', record_ids: recordIds },
      ];
    }

    const recordType = getTrackableRecordType(slug as Slug);

    if (recordType) {
      return [...groups, { record_type: recordType, record_ids: recordIds }];
    }

    return groups;
  };

  const searchForm = Object.entries(groupedResults)
    .reduce(getSearchFormElement, []);

  if (isEmpty(searchForm)) return null;

  return {
    search_form: searchForm,
  };
};

const buildTracking = (apiSubscription: ApiSubscription): Tracking => ({
  feed: {
    name: apiSubscription.feed.name,
    token: apiSubscription.feed.token,
  },
  subscription: {
    id: apiSubscription.subscription.id,
    token: apiSubscription.subscription.token,
    frequency: apiSubscription.subscription.frequency,
    isSaved: apiSubscription.subscription.is_saved,
    priority: apiSubscription.subscription.priority,
    title: apiSubscription.subscription.title,
    subscribableData: apiSubscription.subscription.subscribable_data,
    subscribableType: apiSubscription.subscription.subscribable_type,
    isDisabled: apiSubscription.subscription.is_disabled,
    isTracking: true,
  },
});

const buildResponse = (apiResponse: ApiResponse): TrackingsByRecord[] => flatMap(
  apiResponse.data, ({ subscriptions, record_type }) => {
    const groupedSubscriptions = groupBy(subscriptions, 'record_id');

    return Object.entries(groupedSubscriptions)
      .map(([record_id, trackingSubscriptions]) => ({
        resultId: record_id,
        resultSlug: getSlug(record_type),
        trackings: map(trackingSubscriptions, buildTracking),
      }));
  },
);

export default {
  buildRequestData,
  buildResponse,
};
