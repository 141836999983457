import { map } from 'lodash';

import { Result } from '../../types';
import { ApiResponse, Response } from '../types';
import resultFactories from './result';

const getResults = (apiResponse: ApiResponse): Omit<
Result & { slugLabel?: string }, 'tags' | 'trackings'
>[] => map(apiResponse.data, (item) => {
  const factory = resultFactories[item.slug] || resultFactories.base;

  return factory(item);
});

const hasMore = (apiResponse: ApiResponse): boolean => (
  apiResponse.pagination.current_page < apiResponse.pagination.total_pages
);

const total = (apiResponse: ApiResponse): number => (
  apiResponse.pagination.total_count
);

export const buildResponse = (apiResponse: ApiResponse) => <Response>({
  results: getResults(apiResponse),
  hasMore: hasMore(apiResponse),
  total: total(apiResponse),
});
