import GlobalNotifier from 'components/GlobalNotifier';

const { addNotification } = GlobalNotifier;

const success = message => addNotification({ message, level: 'success' });
const error = message => addNotification({ message, level: 'error' });

export default class Notifications {
  static stakeholderCreateSuccess = () => success('Stakeholder has been created successfully.');

  static stakeholderCreateFailed = () => error('An error occurred while creating the stakeholder. Please try again.');

  static stakeholderEditSuccess = () => success('Stakeholder has been edited successfully.');

  static stakeholderEditFailed = () => error('An error occurred while updating the stakeholder. Please try again.');

  static defaultFetchDataFailed = () => error('An error occurred during the data loading. Please try again.');

  static exportEventSuccess = () => success('Data has been exported successfully.');

  static exportEventFailed = () => error('Data export failed. Please try again.');

  static tagEventSuccess = () => success('Tags have been applied successfully.');

  static tagEventFailed = () => error('Failed to apply tags. Please try again.');

  static listEventSuccess = () => success('Stakeholders have been added to list successfully.');

  static listEventFailed = () => error('Failed to add Stakeholder to list. Please try again.');
}
