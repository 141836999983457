import { get, upperFirst } from 'lodash';

import { joinCompact } from 'utils/string';

import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/stateLegislator';

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const party = get(apiResult, 'options.party_short');
  const roleCode = get(apiResult, 'options.role_code');
  const state = get(apiResult, 'options.state.name');

  const role = roleCode && `State ${upperFirst(roleCode)}`;

  const subTitle = () => (
    joinCompact([
      baseResult.slugLabel,
      joinCompact([state, role], ' '),
    ], ' - ')
  );

  const getSubtitleHighlight = () => (
    joinCompact([
      baseResult.slugLabel,
      joinCompact([highlights.stateName || state, role], ' '),
    ], ' - ')
  );

  const getHighlightedTitle = () => {
    if (highlights.districtName) {
      const legislator = (highlights.title || apiResult.label).split('(')[0];

      return `${legislator} (${highlights.districtName})`;
    }

    return highlights.title || apiResult.label;
  };

  return {
    ...baseResult,
    titleHighlight: getHighlightedTitle(),
    subTitle: subTitle(),
    subtitleHighlight: getSubtitleHighlight(),
    description: get(apiResult, 'options.full_biography'),
    descriptionHighlight: highlights.description,
    party,
    photoUrl: get(apiResult, 'options.photo_url'),
  };
};

export default resultFactory;
