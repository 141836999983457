import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'components/core/Button';

import { AdvancedOptions } from '../../types';
import styles from './advanced-options-button.module.scss';

type AdvancedOptionsButtonProps = {
  advancedOptions: AdvancedOptions,
};

const AdvancedOptionsButton: FC<AdvancedOptionsButtonProps> = ({ advancedOptions }) => (
  <Button
    theme="light"
    className={classNames(
      styles.advancedOptions,
      advancedOptions.isOpen && styles.active,
    )}
    onClick={advancedOptions.toggle}
  >
    <i className="fas fa-filter" />
    Advanced options
  </Button>
);

export default AdvancedOptionsButton;
