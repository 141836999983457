import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { NAVIGATION_USER_PROFILE_IMG_ID } from 'components/Navigation/utils/constants';
import { customError } from 'utils/customError';

import withContext from './context/hoc.jsx';
import { handleUpload } from './api';

class UploadButton extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    };
  }

  inputReferer = React.createRef()

  resetInput = () => {
    this.inputReferer.current.value = null;
  }

  handleChange = (e) => {
    const { files } = e.target;

    const reader = new FileReader();

    const selectedFile = (files && files[0]) || null;

    reader.onload = ev => this.props.uploadImage(ev.target.result);

    reader.readAsDataURL(selectedFile);

    this.resetInput();

    this.props.handleChange(selectedFile);
  }

  handleSuccess = () => {
    const {
      croppedImage,
      reloadAfterSubmit,
      options: { replaceTargets = [] },
    } = this.props;

    replaceTargets.forEach((node) => {
      const item = document.querySelector(node);

      if (!item) {
        customError(`Could not find an element for '${node}' selector`);
        return;
      }

      if (node === '#'.concat(NAVIGATION_USER_PROFILE_IMG_ID)) {
        window.gon.currentUser.profileImgUrl = croppedImage;

        item.setAttribute(
          'style',
          `background-image: url(${croppedImage}); background-size: cover;`
        );
        item.innerHTML = '';
      } else {
        const { offsetWidth, offsetHeight } = item;

        item.setAttribute('src', croppedImage);
        item.setAttribute('width', offsetWidth);
        item.setAttribute('height', offsetHeight);
      }
    });

    this.setState({ isLoading: false });

    if (reloadAfterSubmit) {
      window.location.reload();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true }, () => {
      handleUpload(this.props).then(this.handleSuccess);
    });
  }

  renderDeleteButton = () => (
    <button
      type="button"
      className="delete-button input-centered crop__file-delete-button"
      onClick={this.props.handleDelete}
    >
      <i className="far fa-times-circle fa-lg" />
    </button>
  )

  renderFileName = (file, id) => (
    <React.Fragment key={`file_name_${id}`}>
      {file && file.name && <span className="crop__file-name">{file.name}</span>}
      {file && file.name && this.renderDeleteButton()}
    </React.Fragment>
  )

  renderFileList = files => files.map(this.renderFileName)

  renderLabel = () => {
    const { options: { accept, buttonText, inputId, inputName } } = this.props;

    return (
      <label className="crop__upload-trigger">
        <i className="fas fa-upload crop__upload-trigger__icon" />
        <span className="crop__upload-trigger__title">
          {buttonText}
        </span>
        <input
          type="file"
          id={inputId}
          accept={accept}
          name={inputName}
          ref={this.inputReferer}
          className="crop__file-input"
          onChange={this.handleChange}
        />
      </label>
    );
  }

  renderSubmit() {
    const { isLoading } = this.state;
    const { options: { submitButton }, croppedImage } = this.props;

    if (!submitButton) {
      return null;
    }

    const disabled = isLoading || !croppedImage;

    return (
      <>
        <button type="submit" className="btn btn-primary" onClick={this.handleSubmit} disabled={disabled}>
          {submitButton}
        </button>
        {isLoading && <i className="far fa-circle-notch fa-spin crop__upload-isLoading" />}
      </>
    );
  }

  render() {
    const { files, selectedFile, croppedImage } = this.props;

    return (
      <>
        <section className="crop__wrapper">
          <div className="crop__upload-button">
            {this.renderLabel()}
            {croppedImage && this.renderFileName(selectedFile)}
            {files.length && this.renderFileList(files)}
          </div>
        </section>
        {this.renderSubmit()}
      </>
    );
  }
}

UploadButton.defaultProps = {
  files: [],
  croppedImage: '',
  reloadAfterSubmit: false,
};

UploadButton.propTypes = {
  files: PropTypes.array,
  options: PropTypes.object,
  uploadImage: PropTypes.func,
  handleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  croppedImage: PropTypes.string,
  selectedFile: PropTypes.object,
  reloadAfterSubmit: PropTypes.bool,
};

export default withContext(UploadButton);
