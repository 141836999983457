import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import AdvocateForm from 'components/Advocates/common/Modals/StakeholderForm';
import StakeholderForm from 'components/Stakeholder/common/Modals/StakeholderForm';
import useModal from 'components/core/Modal/useModal';
import {
  allCustomFieldsShape,
  customFieldsAnswersShape,
} from 'components/Advocates/common/utils/shapes';
import { trackCreateStakeholderClicked } from 'components/NavigationV2/utils/tracking';

function Container({
  className,
  createStakeholder,
  fetchCustomFields,
  stakeholder,
  customFields,
  content,
  onSuccess,
}) {
  const Form = gon.isAdvocatesEnabled ? AdvocateForm : StakeholderForm;
  const { isOpenModal, toggleModal } = useModal();

  const ui = { ...stakeholder.ui, ...customFields.ui };

  const handleClick = () => {
    toggleModal();
    trackCreateStakeholderClicked();
  };

  return (
    <>
      <Button onClick={handleClick} className={className} theme="tertiary">
        {content}
      </Button>
      <Form
        isOpen={isOpenModal}
        toggleOpen={toggleModal}
        createStakeholder={createStakeholder}
        fetchCustomFields={fetchCustomFields}
        ui={ui}
        allCustomFields={customFields.allCustomFields}
        onSuccess={onSuccess}
        avoidDispatchFetch
      />
    </>
  );
}

Container.defaultProps = {
  content: 'Stakeholder',
};

Container.propTypes = {
  className: PropTypes.string,
  createStakeholder: PropTypes.func.isRequired,
  fetchCustomFields: PropTypes.func.isRequired,
  stakeholder: PropTypes.shape({
    ui: PropTypes.shape({
      isSubmitting: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  customFields: PropTypes.shape({
    ui: PropTypes.shape({
      isLoadingCustomFields: PropTypes.bool.isRequired,
    }).isRequired,
    allCustomFields: allCustomFieldsShape,
    customField: customFieldsAnswersShape,
  }).isRequired,
  content: PropTypes.node,
  onSuccess: PropTypes.func,
};

export default Container;
