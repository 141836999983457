import React, { FC, useState } from 'react';

import { AdvancedOptions, Filters } from 'components/UnifiedSearch/types';
import { formatDate } from 'utils/intl';
import { DEFAULT_SLIDE_DURATION } from 'components/core/Modal/components/SlideUpModal';
import useMountedRef from 'utils/useMountedRef';

import Dropdown from '../Dropdown';
import Trigger from '../Trigger';
import SlideUpModal from '../SlideUpModal';
import CustomPeriod from './components/CustomPeriod';
import Options from './components/Options';
import { PERIOD_OPTIONS } from './constants';

type PeriodProps = {
  advancedOptions: AdvancedOptions,
  filters: Filters,
  isMobile: boolean,
};

const Period: FC<PeriodProps> = ({ advancedOptions, filters, isMobile }) => {
  const isMounted = useMountedRef();
  const [isVisible, setIsVisible] = useState(false);
  const toggleIsVisible = () => setIsVisible(isVisiblePrev => !isVisiblePrev);
  const [isCustomPeriodSelected, setIsCustomPeriodSelected] = useState(false);
  const toggleFilter = () => {
    toggleIsVisible();

    setTimeout(() => isMounted && setIsCustomPeriodSelected(false), DEFAULT_SLIDE_DURATION);
  };
  const toggleSelectCustomPeriod = () => setIsCustomPeriodSelected(!isCustomPeriodSelected);

  const renderButtonContent = () => {
    const { values } = advancedOptions;

    if (values.period !== 'custom') {
      return PERIOD_OPTIONS[values.period];
    }

    return [
      formatDate(values.customPeriod?.from),
      formatDate(values.customPeriod?.to),
    ].join(' - ');
  };

  const isDisabled = filters.value === 'committees' || filters.value === 'people';

  const renderFilterContent = () => {
    const commonProps = {
      advancedOptions,
      toggleFilter,
      toggleSelectCustomPeriod,
      isMobile,
    };

    return isCustomPeriodSelected
      ? <CustomPeriod {...commonProps} />
      : <Options {...commonProps} />;
  };

  if (isMobile) {
    return (
      <>
        <Trigger onClick={toggleFilter} isDisabled={isDisabled}>
          {renderButtonContent()}
        </Trigger>
        <SlideUpModal isOpen={isVisible} toggleOpen={toggleFilter} title="When">
          {renderFilterContent()}
        </SlideUpModal>
      </>
    );
  }

  return (
    <Dropdown
      isVisible={isVisible}
      content={renderFilterContent()}
      onClickOutside={toggleFilter}
      onClick={toggleIsVisible}
      isDisabled={isDisabled}
    >
      {renderButtonContent()}
    </Dropdown>
  );
};

export default Period;
