import GlobalNotifier from 'components/GlobalNotifier';
import { RecentVisits, Result } from 'components/UnifiedSearch/types';

const { addNotification } = GlobalNotifier;

const error = (message: string) => addNotification({ message, level: 'error' });

type StatusHandler = (value: RecentVisits['status']) => void;
const getRecentVisits = async (setStatus: StatusHandler) => {
  setStatus('loading');
  try {
    const response = await $.ajax({
      method: 'GET',
      url: '/api_web/recent_visits',
    });
    setStatus('idle');

    return response.recent_visits;
  } catch (e) {
    setStatus('error');
    throw e;
  }
};

const addRecentVisit = async (visited: Result) => {
  await $.ajax({
    method: 'POST',
    url: '/api_web/recent_visits',
    data: JSON.stringify({ url: visited.url, payload: visited }),
    contentType: 'application/json',
  });
};

const deleteRecentVisit = async (id: number) => {
  try {
    await $.ajax({
      method: 'DELETE', url: `/api_web/recent_visits/${id}`,
    });
  } catch (e) {
    error('Something went wrong, please try again');
  }
};

const deleteAllRecentVisits = async () => {
  await $.ajax({
    method: 'DELETE', url: '/api_web/recent_visits/destroy_all',
  });
};

export default {
  getRecentVisits,
  addRecentVisit,
  deleteRecentVisit,
  deleteAllRecentVisits,
};
