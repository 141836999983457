import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'lodash/isEqual';

import { DEFAULT_STATE } from './constants';
import FileDetails from './file-details.jsx';
import UploadButton from './upload-button.jsx';
import HiddenInputs from './hidden-inputs.jsx';
import LoadingMessage from './loading-message.jsx';
import ErrorMessage from './error-message.jsx';
import FilesList from './file-list.jsx';

export default class Container extends React.Component {
  static propTypes = {
    // reduxProps
    files: PropTypes.array,
    fileErrors: PropTypes.array,
    ui: PropTypes.object,
    fetchFiles: PropTypes.func,
    uploadFiles: PropTypes.func,
    deleteFiles: PropTypes.func,
    deleteFile: PropTypes.func,

    // componentProps
    accept: PropTypes.string,
    buttonClassName: PropTypes.string,
    buttonText: PropTypes.string,
    inputName: PropTypes.string,
    inputErrors: PropTypes.array,
    fileDetailsClassName: PropTypes.string,
    fileUploadIds: PropTypes.array,
    fileUploadsWithNames: PropTypes.array,
    requiredInputs: PropTypes.object,
    multiple: PropTypes.bool,
    displayFileDetails: PropTypes.bool,
    onDelete: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    strategySlug: PropTypes.string.isRequired,
    fileNamesEditable: PropTypes.bool,
  }

  static defaultProps = {
    ...DEFAULT_STATE,
    fileDetailsClassName: 'royal-file-uploader__file-details',
    buttonClassName: 'btn btn-primary',
    buttonText: 'Upload File',
    requiredInputs: {},
    fileUploadIds: [],
    fileUploadsWithNames: [],
    inputErrors: [],
    multiple: false,
    displayFileDetails: true,
    fileNamesEditable: false,
  }

  state = {
    fileNames: [],
    normalizedFileUploadsWithNames: {},
  }

  componentWillMount() {
    const { fileUploadsWithNames } = this.props;
    const normalizedFileUploadsWithNames = fileUploadsWithNames.reduce((acc, file) =>
      ({ ...acc, [file.file_upload_id]: file })
    , {});

    this.setState({ normalizedFileUploadsWithNames });
  }

  componentDidMount() {
    const { fileUploadIds, fetchFiles } = this.props;

    fetchFiles({ fileUploadIds });
  }

  componentDidUpdate(prevProps) {
    const { fileUploadIds, fetchFiles } = this.props;

    if (!isEqual(prevProps.fileUploadIds, fileUploadIds)) {
      fetchFiles({ fileUploadIds });
    }
  }

  componentWillUnmount = () => {
    const { deleteFiles } = this.props;

    deleteFiles();
  }

  handleChange = (files) => {
    let fileNames = files.map(file => file.name);
    fileNames = this.state.fileNames.concat(fileNames);

    this.setState({ fileNames }, () => this.uploadFiles(files));
  }

  uploadFiles = (files) => {
    const { strategySlug, requiredInputs } = this.props;

    this.props.uploadFiles({ files, strategySlug, requiredInputs })
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  handleSuccess = () => {
    const { onSuccess, files } = this.props;
    let { normalizedFileUploadsWithNames } = this.state;

    const wrappedFiles = files.map(file => ({
      id: file.id,
      name: file.name,
      ...file,
    }));

    const initialAccumulator = normalizedFileUploadsWithNames;
    normalizedFileUploadsWithNames = wrappedFiles.reduce((acc, file) =>
      ({ ...acc, [file.id]: file })
    , initialAccumulator);

    this.setState({ normalizedFileUploadsWithNames });

    if (onSuccess) {
      onSuccess(Object.values(normalizedFileUploadsWithNames));
    }
  }

  handleFileNameChange = (event, file) => {
    const { normalizedFileUploadsWithNames } = this.state;
    const { onSuccess } = this.props;

    normalizedFileUploadsWithNames[file.id].name = event.target.value;

    this.setState({ normalizedFileUploadsWithNames });
    if (onSuccess) onSuccess(Object.values(normalizedFileUploadsWithNames));
  }

  handleError = () => {
    const { onError } = this.props;

    if (onError) {
      onError();
    }
  }

  handleDelete = () => {
    const { onDelete, deleteFiles } = this.props;

    deleteFiles();
    this.setState({ normalizedFileUploadsWithNames: {} });

    if (onDelete) {
      onDelete();
    }
  }

  handleDeleteSingleFile = (id) => {
    const { deleteFile, onSuccess } = this.props;
    deleteFile(id);
    const normalizedFileUploadsWithNames = { ...this.state.normalizedFileUploadsWithNames };
    delete normalizedFileUploadsWithNames[id];
    this.setState({ normalizedFileUploadsWithNames });
    if (onSuccess) onSuccess(Object.values(normalizedFileUploadsWithNames));
  }

  filesPresent = () => this.props.files.length > 0;

  render() {
    const {
      accept,
      buttonClassName,
      buttonText,
      files,
      fileErrors,
      fileDetailsClassName,
      inputName,
      inputErrors,
      multiple,
      displayFileDetails,
      fileNamesEditable,
      ui,
    } = this.props;

    return (
      <div>
        <div className="row form-group royal-file-uploader">
          <div className="col-lg-12">
            <UploadButton
              accept={accept}
              className={buttonClassName}
              text={buttonText}
              multiple={multiple}
              handleChange={this.handleChange}
            />
            <HiddenInputs
              files={files}
              name={inputName}
              multiple={multiple}
            />
            &nbsp;
            <LoadingMessage
              loading={ui.loadingFiles}
              uploading={ui.uploadingFiles}
            />
            <ErrorMessage
              loading={ui.loadingFiles || ui.uploadingFiles}
              filesPresent={this.filesPresent()}
              fileErrors={fileErrors}
              inputErrors={inputErrors}
            />
            {displayFileDetails && <FileDetails
              files={files}
              multiple={multiple}
              fileNames={this.state.fileNames}
              className={fileDetailsClassName}
              handleDelete={this.handleDelete}
            />}
          </div>
        </div>
        {multiple && <FilesList
          files={this.state.normalizedFileUploadsWithNames}
          handleDelete={this.handleDeleteSingleFile}
          handleFileNameChange={this.handleFileNameChange}
          fileNamesEditable={fileNamesEditable}
        />}
      </div>
    );
  }
}
