const tableColumns = [
  {
    label: 'First Name',
    keyName: 'first_name',
    sortingKey: 'first_name',
    allowToggle: false,
  },
  {
    label: 'Last Name',
    keyName: 'last_name',
    sortingKey: 'last_name',
    allowToggle: false,
  },
  {
    label: 'Middle Name',
    keyName: 'middle_name',
    sortingKey: 'middle_name',
    allowToggle: false,
  },
  {
    label: 'Primary phone #',
    keyName: 'phone',
    sortingKey: 'phone',
    allowToggle: true,
  },
  {
    label: 'Primary email',
    keyName: 'email',
    sortingKey: 'email',
    allowToggle: true,
  },
  {
    label: 'Party',
    keyName: 'party_code',
    sortingKey: 'party_code',
    allowToggle: true,
  },
  {
    label: 'Primary address',
    keyName: 'address',
    sortingKey: 'address',
    allowToggle: true,
  },
  {
    label: 'Organization',
    keyName: 'organization',
    sortingKey: 'organization',
    allowToggle: true,
  },
  {
    label: 'Lists',
    keyName: 'lists',
    sortingKey: 'lists',
    allowToggle: true,
  },
  {
    label: 'Tags',
    keyName: 'tags',
    sortingKey: 'tags',
    allowToggle: true,
  },
];

export default tableColumns;
