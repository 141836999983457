import * as tracking from 'utils/tracking';

export function trackUsingFunctionName({
  trackingFunctionName,
  eventContext,
  eventName,
  eventData,
}) {
  if (!trackingFunctionName) {
    return;
  }

  const track = tracking[trackingFunctionName];
  if (typeof track !== 'function') {
    return;
  }

  if (eventName && eventContext) {
    track(eventContext, eventName, eventData);
  } else if (eventContext) {
    track(eventContext, eventData);
  } else {
    track(eventData);
  }
}
