import { useState, useEffect } from 'react';
import { get, trim, isEmpty, map, identity } from 'lodash';

import * as workspacesApi from 'redux/dashboardV2/common/workspaces/api';
import { NONE_ID } from 'components/core/FrequencyOptions/constants';
import isUnprocessableEntityError from 'utils/http/isUnprocessableEntityError';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import useLoader from 'utils/useLoader';

import validateName from './utils/nameValidation';

function useNewWorkspaceModal({ toggleModal, feed, onSuccess = identity, initialName = '' }) {
  const [name, setName] = useState(initialName);
  const [frequency, setFrequency] = useState(NONE_ID);
  const [subscribe, setSubscribe] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const { isLoading, startLoading, stopLoading } = useLoader();
  const { isMobile } = useMediaQuery();
  const trimmedName = trim(name);

  const isValid = () => !isEmpty(trimmedName);

  const handleNameChange = (event) => {
    setName(get(event, 'target.value'));
  };

  const handleFrequencyChange = (value) => {
    setFrequency(value);
  };

  const handleSubscribeChange = (event) => {
    setSubscribe(get(event, 'target.checked'));
  };

  const reset = () => {
    setName('');
    setFrequency(NONE_ID);
    setErrorMessage('');
    setSubscribe(true);
  };

  const handleClose = () => {
    reset();
    toggleModal();
  };

  const handleSubmit = async () => {
    const workspacesNames = map(feed, 'name');
    const [isNameValid, validationError] = await validateName(trimmedName, workspacesNames);

    if (!isNameValid) {
      return setErrorMessage(validationError);
    }

    startLoading();
    setErrorMessage('');

    const onFailure = (error = {}) => {
      if (isUnprocessableEntityError(error)) {
        return setErrorMessage(error.text);
      }

      throw error;
    };

    return workspacesApi.createWorkspace({ ...(subscribe && { frequency }), name: trimmedName })
      .then(onSuccess)
      .then(handleClose)
      .catch(onFailure)
      .finally(stopLoading);
  };

  useEffect(() => setName(initialName), [initialName]);

  return {
    name,
    frequency,
    subscribe,
    handleNameChange,
    handleFrequencyChange,
    handleSubscribeChange,
    handleClose,
    errorMessage,
    handleSubmit,
    isLoading,
    isMobile,
    isValid,
  };
}

export default useNewWorkspaceModal;
