import { Period } from 'components/UnifiedSearch/types';

export const PERIOD_OPTIONS: { [key in Period]: string } = {
  any: 'Any time',
  hour: 'Past hour',
  day: 'Past 24 hours',
  week: 'Past week',
  month: 'Past month',
  year: 'Past year',
  custom: 'Custom range...',
};
