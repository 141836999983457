import React, { FC, useState } from 'react';
import classNames from 'classnames';

import UnifiedSearch from 'components/UnifiedSearch';
import { UseModalType } from 'components/core/Modal/types';

import { addNavigationV2Classes } from '../../utils';
import SearchBar from './components/SearchBar';
import MenuBar from './components/MenuBar';
import styles from './styles.module.scss';

type NavigationProps = {
  isMobile?: boolean,
  className?: string,
  unifiedSearchView: UseModalType
};

const Navigation: FC<NavigationProps> = ({ isMobile = false, className = '', unifiedSearchView }) => {
  // TODO: remove when v2 is released
  addNavigationV2Classes();

  const [searchQuery, setSearchQuery] = useState('');

  const {
    isOpenModal: isUnifiedSearchOpen,
    openModal: openGlobalSearch,
    closeModal: closeGlobalSearch,
  } = unifiedSearchView;

  const handleCloseUnifiedSearch = () => {
    setSearchQuery('');
    closeGlobalSearch();
  };

  return (
    <div className={classNames(styles.navigationWrapper, className && className)}>
      <section
        className={classNames(styles.navigationContainer, isUnifiedSearchOpen && styles.open)}
      >
        <SearchBar
          onSearchQueryChange={setSearchQuery}
          onCloseUnifiedSearch={handleCloseUnifiedSearch}
          onFocus={openGlobalSearch}
          isUnifiedSearchOpen={isUnifiedSearchOpen}
          isMobile={isMobile}
        />
        {!isMobile && <MenuBar searchQuery={searchQuery} />}
      </section>
      <div className={classNames(styles.unifiedSearch, isUnifiedSearchOpen && styles.open)}>
        {isUnifiedSearchOpen && <UnifiedSearch searchQuery={searchQuery} />}
      </div>
    </div>
  );
};

export default Navigation;
