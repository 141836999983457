import React from 'react';

function Stakeholder() {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.875 9C10.3605 9 12.375 6.98555 12.375 4.5C12.375 2.01445 10.3605 0 7.875 0C5.38945 0 3.375 2.01445 3.375 4.5C3.375 6.98555 5.38945 9 7.875 9ZM11.243 10.1461L9.5625 16.875L8.4375 12.0938L9.5625 10.125H6.1875L7.3125 12.0938L6.1875 16.875L4.50703 10.1461C2.00039 10.2656 0 12.3152 0 14.85V16.3125C0 17.2441 0.755859 18 1.6875 18H14.0625C14.9941 18 15.75 17.2441 15.75 16.3125V14.85C15.75 12.3152 13.7496 10.2656 11.243 10.1461Z" fill="#0E4EFF" />
    </svg>
  );
}

export default Stakeholder;
