import slugs from 'components/Navigation/menuData/slugs';

export const STATE_SUMMARIES = {
  title: 'State summaries',
  description: 'Session dates, commitees, and legislators',
  href: '/state/states',
  slug: slugs.state,
};

export const RELATED_APPS_SECTION = {
  id: 'relatedApps',
  title: 'Related apps',
};
