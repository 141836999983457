import React, { ReactNode, FC } from 'react';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

type TriggerProps = {
  onClick: () => void,
  children: ReactNode,
  isDisabled?: boolean,
};

const Trigger: FC<TriggerProps> = ({ onClick, isDisabled, children }) => (
  <Button
    theme="light"
    onClick={onClick}
    className={styles.trigger}
    disabled={isDisabled}
  >
    {children}
    <i className="fas fa-angle-down" />
  </Button>
);

export default Trigger;
