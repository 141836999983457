import React from 'react';

import ErrorTemplate from 'components/Errors/ErrorTemplate';

const ServerError = () => (
  <ErrorTemplate
    src="/images/error.svg"
    alt="Server error"
    text="Something went wrong..."
    subText="The data you requested encountered an issue. Please try again."
  />
);

export default ServerError;
