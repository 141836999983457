import React, { FC } from 'react';
import isEqual from 'lodash/isEqual';

import Button from 'components/core/Button';
import { INITIAL_STATE } from 'components/UnifiedSearch/useAdvancedOptions/initialState';
import { AdvancedOptions } from 'components/UnifiedSearch/types';

import styles from './clear-filters-button.module.scss';

type ClearFiltersButtonProps = {
  advancedOptions: AdvancedOptions,
};

const ClearFiltersButton: FC<ClearFiltersButtonProps> = ({ advancedOptions }) => {
  const hasFiltersApplied = !isEqual(INITIAL_STATE, advancedOptions.values);

  if (!hasFiltersApplied) return null;

  return (
    <Button
      theme="light"
      className={styles.clearFilters}
      onClick={advancedOptions.reset}
    >
      <i className="fas fa-times-circle" /> Clear filters
    </Button>
  );
};

export default ClearFiltersButton;
