import PropTypes from 'prop-types';
import { omit, keys } from 'lodash';

import {
  TWITTER_SLUG,
  CONGRESSIONAL_RECORD_ITEMS_SLUG,
  CONGRESSIONAL_PRESS_RELEASES_SLUG,
} from 'utils/constants/featureSlugs';

import { resultLabels } from './resultLabels';

export { productSlugs } from './productSlugs';
export { resultLabels };

export const GLOBAL_SEARCH_URL = '/api_web/global_search/search/lookup';

export const MIN_SEARCH_INPUT_LENGTH = 3;

export const QUICK_SEARCH_SLUGS = keys(
  omit(
    resultLabels,
    [
      TWITTER_SLUG,
      CONGRESSIONAL_RECORD_ITEMS_SLUG,
      CONGRESSIONAL_PRESS_RELEASES_SLUG,
    ]
  ),
);

export const customOptionsShape = PropTypes.shape({
  partyShort: PropTypes.string,
  partySlug: PropTypes.string,
  date: PropTypes.string,
  state: PropTypes.string,
});

export const optionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  strategy: PropTypes.string.isRequired,
  customOptions: customOptionsShape,
});

export const EXECUTIVE_PERSON_SLUG = 'executive_person';
