import PropTypes from 'prop-types';
import { useEffect } from 'react';

import GlobalNotifier from 'components/GlobalNotifier';

const addNotification = GlobalNotifier.addNotification;

const notifierLevel = level => (['error', 'success'].includes(level) && level) || 'info';

function PageNotifier({ notices }) {
  useEffect(() => {
    notices.forEach(notice => notice && notice[1] && addNotification({
      level: notifierLevel(notice[0]),
      message: notice[1],
    }));
  });

  return null;
}

PageNotifier.propTypes = {
  notices: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

PageNotifier.defaultProps = {
  notices: [],
};

export default PageNotifier;
