import { flatMap, groupBy, isEmpty } from 'lodash';

import { getRecordType, getSlug } from '../useSearch/helpers';
import { Result, Slug, TagRecordType } from '../types';
import { ApiResponse, PositionByRecord, SearchForm } from './types';

const resultSlugsWithPosition: Slug[] = [
  'federal_bills',
  'state_legislation_regulations',
  'federal_register_regulations',
  'state_legislation_regulations',
];

const buildRequestData = (results: Result[]): SearchForm | null => {
  const filteredResults = results.filter(({ slug }) => resultSlugsWithPosition.includes(slug));

  if (isEmpty(filteredResults)) return null;

  const groupedResults = groupBy(filteredResults, 'slug');

  return {
    search_form: Object.entries(groupedResults)
      .map(([slug, positionResults]) => ({
        record_type: getRecordType(slug as Slug) as TagRecordType,
        record_ids: positionResults.map(result => result.id),
      }))
      .filter(({ record_type }) => record_type !== null),
  };
};

const buildResponse = (
  apiResponse: ApiResponse,
): PositionByRecord[] => flatMap(apiResponse.data, (({ record_type, user_positions }) => (
  user_positions.map(({ record_id, position_type }) => {
    const positionByRecord: PositionByRecord = {
      position: position_type,
      resultId: record_id,
      resultSlug: getSlug(record_type),
    };

    return positionByRecord;
  })
)));

export default {
  buildRequestData,
  buildResponse,
};
