import React, { Fragment } from 'react';

import {
  defaultNewEmailValue,
  emailPlaceholder,
} from 'components/Advocates/common/Modals/StakeholderForm/utils';
import { typeOptions } from 'utils/constants';
import { profileShape } from 'components/Advocates/common/utils/shapes';

import MultipleField from './MultipleField';

const { personalTypeOptions } = typeOptions;

function EmailField({ values, ...restProps }) {
  const options = values.emails;

  return (
    <>
      <MultipleField
        {...restProps}
        id="emails"
        name="emails"
        fieldName="email"
        typeName="category"
        options={options}
        isLoading={false}
        typeOptions={personalTypeOptions}
        title="Email"
        placeholder={emailPlaceholder}
        defaultValue={defaultNewEmailValue}
        viewLimit={5}
      />
      {options.length > 1 && <hr />}
    </>
  );
}

EmailField.propTypes = {
  values: profileShape.isRequired,
};

export default EmailField;
