import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonGroup from 'components/core/ButtonGroup';
import Label from 'components/core/Label';
import Button from 'components/core/Button';
import { TextInputPlaceholder } from 'components/core/Placeholder';
import { suggestionShape } from 'utils/shapes/suggestion';

import styles from './styles.module.scss';

function ButtonGroupField({ id, title, placeholder, value, setFieldValue, data }) {
  function handleButtonClick(dataValue) {
    return () => {
      setFieldValue(id, dataValue);
    };
  }

  return (
    <div className={styles.formField}>
      <Label>{title}</Label>
      <TextInputPlaceholder className={styles.placeholder}>
        {placeholder}
      </TextInputPlaceholder>
      <ButtonGroup className={styles.buttonGroup}>
        {
          data.map(({ label, value: dataValue }) => (
            <Button
              theme="default"
              key={dataValue}
              value={dataValue}
              onClick={handleButtonClick(dataValue)}
              className={classNames(value === dataValue && styles.isActive)}
            >
              {label}
            </Button>
          ))
        }
      </ButtonGroup>
    </div>
  );
}

ButtonGroupField.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(suggestionShape).isRequired,
  value: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

export default ButtonGroupField;
