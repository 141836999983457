import { get } from 'lodash';

import { Result, Slug } from 'components/UnifiedSearch/types';
import { getSlugLabel } from 'components/UnifiedSearch/useSearch/helpers';
import { getParenthesisItem, joinCompact } from 'utils/string';

type Helpers = { [s in Slug]?: (visit?: Result) => string };

const getExecutiveSubtitle = (visit: Result) => {
  const title = get(visit, 'options.title');
  const state = get(visit, 'options.state');
  const formattedState = getParenthesisItem(joinCompact([state?.name, state?.code]));

  return joinCompact([getSlugLabel(visit.slug), joinCompact([title, formattedState], ' ')], ' | ');
};

const getLegislatorSubtitle = (visit: Result) => (visit.subTitle);

const getLabelWithStateCode = (visit: Result) => {
  if (!visit.stateCode) {
    return visit.slugLabel;
  }

  return `${visit.slugLabel} - ${visit.stateCode}`;
};

export const getSubtitle = (visit: Result) => {
  const { [visit.slug]: getResultTitle = () => (
    joinCompact([getSlugLabel(visit.slug), visit.state], ' - ')
  ) } = <Helpers>{
    state_executive: getExecutiveSubtitle,
    federal_executive: getExecutiveSubtitle,
    local_executive: getExecutiveSubtitle,
    state_legislation_legislators: getLegislatorSubtitle,
    federal_legislators: getLegislatorSubtitle,
    state_legislation_bills: getLabelWithStateCode,
    state_legislation_regulations: getLabelWithStateCode,
  };

  return getResultTitle(visit);
};
