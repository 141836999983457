import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Icon({ isActive }) {
  return (
    <svg className={classNames(styles.icon, isActive && styles.active)} width="26" height="23" viewBox="0 0 26 23" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 2.90625V20.0938C0.5 21.3882 1.54932 22.4375 2.84375 22.4375H23.1562C24.4507 22.4375 25.5 21.3882 25.5 20.0938V2.90625C25.5 1.61182 24.4507 0.5625 23.1562 0.5625H2.84375C1.54932 0.5625 0.5 1.61182 0.5 2.90625ZM23.5469 13.8438H10.1354V9.15625H23.5469V13.8438ZM23.5469 15.7969V19.8984C23.5469 20.222 23.2845 20.4844 22.9609 20.4844H10.1354V15.7969H23.5469ZM2.45312 9.15625H8.18227V13.8438H2.45312V9.15625ZM10.1354 7.20312V2.51562H22.9609C23.2845 2.51562 23.5469 2.77798 23.5469 3.10156V7.20312H10.1354ZM8.18227 2.51562V7.20312H2.45312V3.10156C2.45312 2.77798 2.71548 2.51562 3.03906 2.51562H8.18227ZM2.45312 15.7969H8.18227V20.4844H3.03906C2.71548 20.4844 2.45312 20.222 2.45312 19.8984V15.7969Z" />
    </svg>
  );
}

Icon.propTypes = {
  isActive: PropTypes.bool,
};

export default Icon;
