import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import Logo from 'components/core/Logo';

import styles from './styles.module.scss';

function PanelHeader({ title, onClose }) {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <Logo />
        {title}
      </div>
      <Button className={styles.closeButton} theme="light" onClick={onClose}>
        <i className={classNames('far fa-times', styles.icon)} />
      </Button>
    </div>
  );
}

PanelHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PanelHeader;
