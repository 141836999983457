import PropTypes from 'prop-types';
import React from 'react';

function ModalFooter({ handleClose, handleSubmit }) {
  return (
    <footer className="modal-footer" key="modal-footer">
      <button type="button" className="btn btn-default" data-dismiss="modal" onClick={handleClose}>Close</button>
      <button type="button" className="btn btn-primary" onClick={handleSubmit}>Crop Image</button>
    </footer>
  );
}

ModalFooter.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default ModalFooter;
