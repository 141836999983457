import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/modal.jsx';
import SelectList from './SelectList.jsx';

function displayName(staff) {
  const staffResp = staff.responsibilities.join(', ');
  const displayResp = staffResp === '' ? '' : ` -- ${staffResp}`;

  return `${staff.first_name} ${staff.last_name} (${staff.title})${displayResp}`;
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      href: '#',
    };
    this.closeModal = this.closeModal.bind(this);
    this.staffers = this.staffers.bind(this);
  }

  closeModal() {
    this.props.onToggleVisibility(false);
  }

  staffers() {
    return this.props.staffers.map(staff =>
      ({ value: staff.id, label: displayName(staff), email: staff.email })
    );
  }

  updateSelected(values) {
    if (values) {
      const emails = values.map(value => value.email).join(';');
      const href = `mailto:?bcc=${emails}`;
      this.setState({ href });
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.visible}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={this.closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h3 className="modal-title">Send an email through your email client</h3>
          </div>
          <form action="/gov_mail/new">
            <div className="modal-body staffers-modal-body">
              <SelectList
                options={this.staffers()}
                updateSelected={this.updateSelected.bind(this)}
              />
            </div>
            <div className="modal-footer">
              <a
                href={this.state.href}
                className="btn btn-primary"
              >
                <i className="far fa-send" />
                &nbsp;
                Email All
              </a>
              <button
                type="button"
                className="btn btn-default"
                onClick={this.closeModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

Form.propTypes = {
  onToggleVisibility: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  staffers: PropTypes.array.isRequired,
};

Form.defaultProps = {
  visible: false,
};

export default Form;
