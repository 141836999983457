const PREFIX = 'LOGGED_IN_AS_v1';

// Save user info on the current device to be used in the next login
const RememberUserService = {
  init() {
    const { currentUser } = gon || {};
    const { email = '', first_name = '', profileImgUrl = '' } = currentUser || {};

    if (!email || !first_name) return;

    localStorage.setItem(`${PREFIX}__FIRST_NAME__${email}`, first_name);
    localStorage.setItem(`${PREFIX}__PROFILE_IMG__${email}`, profileImgUrl);
  },

  getFirstName(email) {
    const firstName = localStorage.getItem(`${PREFIX}__FIRST_NAME__${email}`);

    if (typeof firstName !== 'string') return '';

    return firstName;
  },

  getProfileImgUrl(email) {
    const profileImgUrl = localStorage.getItem(`${PREFIX}__PROFILE_IMG__${email}`);

    if (typeof profileImgUrl !== 'string' || profileImgUrl === 'false') return '';

    return profileImgUrl;
  },
};

export default RememberUserService;
