import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from 'components/core/Checkbox';
import Card from 'components/core/Card';

import styles from './styles.module.scss';

export const FREQUENCY_TITLE = 'Select the types of updates you’d like to receive and the frequencies at which you’d like to be notified of them via email.';

function FrequencyCard({
  children,
  label,
  withTitle,
  withHeaderNode,
  title,
  isChecked,
  isDisabled,
  onCheck,
  hideChildren,
  checkboxClassName,
}) {
  const headerNodes = (
    <Checkbox
      isChecked={isChecked}
      isDisabled={isDisabled}
      label={label}
      onChange={onCheck}
      theme="light"
      className={checkboxClassName}
    />
  );

  const showChildren = isChecked && !hideChildren;

  const cardProps = {
    className: styles.card,
    headerClassName: classNames(styles.cardHeader, !showChildren && styles.noBorder),
    ...(withHeaderNode && { headerNodes }),
  };

  return (
    <>
      {withTitle && (
        <p className={styles.topMessage}>
          {title}
        </p>
      )}
      <Card {...cardProps}>
        {showChildren && (
          <div className={styles.container}>
            {children}
          </div>
        )}
      </Card>
    </>
  );
}

FrequencyCard.defaultProps = {
  withTitle: true,
  withHeaderNode: true,
  isChecked: true,
  isDisabled: false,
  label: 'Subscription updates',
  title: FREQUENCY_TITLE,
  hideChildren: false,
};

FrequencyCard.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
  withTitle: PropTypes.bool,
  withHeaderNode: PropTypes.bool,
  title: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onCheck: PropTypes.func,
  hideChildren: PropTypes.bool,
  checkboxClassName: PropTypes.string,
};

export default FrequencyCard;
