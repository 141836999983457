import React from 'react';
import { isEmpty, size, upperFirst, lowerCase } from 'lodash';

import { BASE_URL } from 'components/Dashboard/utils/constants';
import { alertErrorAction, alertSuccessAction } from 'components/core/Notification';
import { pluralize } from 'utils/string';
import { getFeedUrl, getFeedSettingsUrl } from 'components/Dashboard/utils';
import Link from 'utils/Link';

import TrackedItemLink from './components/TrackedItemLink';
import WorkspaceLink from './components/WorkspaceLink';
import styles from './styles.module.scss';

function getItemType(itemType, href = '') {
  const formattedItemType = upperFirst(itemType);

  if (!isEmpty(href)) {
    return (
      <Link
        className={styles.itemType}
        theme="default"
        href={href}
      >
        {formattedItemType}
      </Link>
    );
  }

  return <span>{formattedItemType}</span>;
}

export function commonSuccessAlert(content) {
  alertSuccessAction({
    content: (
      <div className={styles.content}>
        {content}
      </div>
    ),
  });
}

export function commonErrorAlert(content) {
  alertErrorAction({
    content: (
      <div className={styles.content}>
        {content}
      </div>
    ),
  });
}

export const MY_FEED_SETTINGS_URL = `${BASE_URL}/my_subscriptions`;

// @TODO link itemType to single card view
export function notifyTrackItem({ itemType, url = MY_FEED_SETTINGS_URL, trackingsCount = 1 }) {
  const label = `${pluralize('Subscription', 'Subscriptions', trackingsCount)} added`;

  commonSuccessAlert((
    <>
      <WorkspaceLink label={label} url={url} /> for {getItemType(itemType)}.
    </>
  ));
}

export function notifyUpdateTrackItem({ itemTitle, url = MY_FEED_SETTINGS_URL }) {
  commonSuccessAlert((
    <>
      Keyword search subscription updated in <WorkspaceLink url={url} label={itemTitle} />.
    </>
  ));
}

// @TODO link itemType to single card view
export function notifyUntrackItem({
  itemType,
  itemTitle,
  url = MY_FEED_SETTINGS_URL,
  trackingsCount = 1,
}) {
  const subscriptions = pluralize('subscription', 'subscriptions', trackingsCount);
  const fromWorkspace = trackingsCount === 1 && (
    <> from <WorkspaceLink label={itemTitle} url={url} /></>
  );

  commonSuccessAlert((
    <>
      {getItemType(itemType)} {subscriptions} removed{fromWorkspace}.
    </>
  ));
}

export function notifyUntrackItemFailure({ itemType }) {
  commonErrorAlert((
    <>
      Failed to delete {lowerCase(itemType)} subscription. Please try again.
    </>
  ));
}

// @TODO link itemType to single card view
export function notifySetPosition({ itemType, positionLabel }) {
  commonSuccessAlert((
    <>
      Position on {getItemType(itemType)} updated to ‘{positionLabel}’.
    </>
  ));
}

// @TODO link itemType to single card view
export function notifySetPriority({ itemType, feedName, url, priorityLabel }) {
  commonSuccessAlert((
    <>
      <WorkspaceLink label={feedName} url={url} /> priority on
      &nbsp;{getItemType(itemType)} updated to ‘{priorityLabel}’.
    </>
  ));
}

export function notifyMarkItemAsRead({ itemType, isRead }) {
  commonSuccessAlert((
    <>
      {getItemType(itemType)} update marked as {isRead && 'un'}read.
    </>
  ));
}

export function notifyBulkMarkAsRead({ itemsLength, isRead }) {
  commonSuccessAlert((
    <>
      {itemsLength} {pluralize('update', 'updates', itemsLength)} marked as {!isRead && 'un'}read.
    </>
  ));
}

export function notifyRemoveItem({ feedName, itemType, url }) {
  commonSuccessAlert((
    <>
      {getItemType(itemType)} removed from <WorkspaceLink label={feedName} url={url} />.
    </>
  ));
}

// @TODO link itemType to single card view
export function notifySaveItem({ feedName, itemType, isSaved, url }) {
  commonSuccessAlert((
    <>
      {getItemType(itemType)} {isSaved ? 'saved to' : 'unsaved from'}
      &nbsp;<WorkspaceLink label={feedName} url={url} />.
    </>
  ));
}

export function notifyAddTagsMultiple(itemsLength) {
  commonSuccessAlert((
    <>
      Tags added to {itemsLength} {pluralize('item', 'items', itemsLength)}.
    </>
  ));
}

// @TODO link itemType to single card view
export function notifyAddTags({ itemType }) {
  commonSuccessAlert((
    <>
      Tags updated on {getItemType(itemType)}.
    </>
  ));
}

export function removedMultipleItems({ selectedItemsSize, feedName, url }) {
  commonSuccessAlert((
    <>
      {selectedItemsSize} {pluralize('item', 'items', selectedItemsSize)} removed from
      &nbsp;<WorkspaceLink label={feedName} url={url} />.
    </>
  ));
}

export function savedMultipleItems({ selectedItemsSize, feedName, url }) {
  commonSuccessAlert((
    <>
      {selectedItemsSize} {pluralize('item', 'items', selectedItemsSize)} saved to
      &nbsp;<WorkspaceLink label={feedName} url={url} />.
    </>
  ));
}

export function prioritizedMultipleItems({ url, feedName, numberOfItems, priority }) {
  commonSuccessAlert((
    <>
      <WorkspaceLink label={feedName} url={url} /> priority on {numberOfItems}
      &nbsp;{pluralize('item', 'items', numberOfItems)} updated to ‘{priority}’.
    </>
  ));
}

// @TODO link itemType to single card view
export function notifySubscriptionChange({ itemType }) {
  commonSuccessAlert((
    <>
      {getItemType(itemType)} subscription updated.
    </>
  ));
}

export function notifySubscriptionChangeFailure({ itemType }) {
  commonErrorAlert((
    <>
      Failed to update {lowerCase(itemType)} subscription. Please try again.
    </>
  ));
}

// @TODO link itemType to single card view
export function notifySubscriptionSendEmail({ itemType, sendWeekAheadEmail }) {
  commonSuccessAlert((
    <>
      <WorkspaceLink label="Week ahead email" url={MY_FEED_SETTINGS_URL} /> for
      your {getItemType(itemType)} subscription is turned {sendWeekAheadEmail ? 'on' : 'off'}.
    </>
  ));
}

export function notifySubscriptionSendEmailMultiple({ numberOfItems, sendWeekAheadEmail }) {
  commonSuccessAlert((
    <>
      <WorkspaceLink label="Week ahead email" url={MY_FEED_SETTINGS_URL} /> for
      your {pluralize('subscription', 'subscriptions', numberOfItems)} is
      turned {sendWeekAheadEmail ? 'on' : 'off'}.
    </>
  ));
}

export function notifySubscriptionChangeMultiple({ numberOfItems, frequency }) {
  commonSuccessAlert((
    <>
      <WorkspaceLink label="Email frequency" url={MY_FEED_SETTINGS_URL} /> for
      your {numberOfItems} {pluralize('subscription', 'subscriptions', numberOfItems)} updated to ‘{frequency}’.
    </>
  ));
}

export function notifyRemoveSubscription({ itemType }) {
  commonSuccessAlert((
    <>
      {getItemType(itemType)} subscription removed from <WorkspaceLink url={MY_FEED_SETTINGS_URL} />.
    </>
  ));
}

export function notifyRemoveSubscriptionMultiple(numberOfItems) {
  commonSuccessAlert((
    <>
      {numberOfItems} {pluralize('subscription', 'subscriptions', numberOfItems)}
      &nbsp;removed from <WorkspaceLink url={MY_FEED_SETTINGS_URL} />.
    </>
  ));
}

export function saveTrackItemsSuccess(subscriptions, feedToken, label) {
  const itemsLength = size(subscriptions);

  if (itemsLength === 0) {
    return;
  }

  const [subscription] = subscriptions;
  const message = pluralize('subscription', 'subscriptions', itemsLength);
  const workspaceLink = <WorkspaceLink url={getFeedSettingsUrl(feedToken)} label={label} />;
  const customNode = pluralize(
    <TrackedItemLink feedToken={feedToken} subscription={subscription} />, itemsLength, itemsLength
  );

  commonSuccessAlert((
    <>{customNode} {message} added to {workspaceLink}.</>
  ));
}

export function resetSubscriptionFrequency({ frequency }) {
  commonSuccessAlert((
    <>
      <WorkspaceLink label="Default email frequency" url={MY_FEED_SETTINGS_URL} /> updated to ‘{frequency}’.
    </>
  ));
}

export function restoreAllSubscriptions({ frequency }) {
  commonSuccessAlert((
    <>
      <WorkspaceLink label="All subscriptions" url={MY_FEED_SETTINGS_URL} /> restored to default ‘{frequency}’.
    </>
  ));
}

export function notifyAddedToMyFeed({ itemType }) {
  commonSuccessAlert((
    <>
      {getItemType(itemType)} saved to <WorkspaceLink />.
    </>
  ));
}

export function notifySharedToMyFeed({ itemType, href = '' }) {
  commonSuccessAlert((
    <>
      {getItemType(itemType, href)} shared to <WorkspaceLink />.
    </>
  ));
}

export function notifySharedToMyFeedMultiple(itemsSize) {
  alertSuccessAction({
    content: (
      <div className={styles.content}>
        {`${itemsSize} ${pluralize('item', 'items', itemsSize)}`} shared to <WorkspaceLink />.
      </div>
    ),
  });
}

export function notifyLoadPageFailure({ itemTitle }) {
  commonErrorAlert((
    <>Failed to load {itemTitle} page. Please try again.</>
  ));
}

// workspace
export function notifyWorkspaceCreateSuccess({ token, itemTitle }) {
  commonSuccessAlert((
    <>
      Created new workspace <WorkspaceLink label={itemTitle} url={getFeedUrl(token)} />.
    </>
  ));
}

export function notifyWorkspaceCreateFailure() {
  commonErrorAlert((
    <>Failed to create workspace. Please try again.</>
  ));
}

export function notifyWorkspaceUpdateSuccess({ token, itemTitle }) {
  commonSuccessAlert((
    <>
      <WorkspaceLink label={itemTitle} url={getFeedSettingsUrl(token)} /> updated.
    </>
  ));
}

export function notifyWorkspaceUpdateFailure({ itemTitle }) {
  commonErrorAlert((
    <>Failed to update {itemTitle}. Please try again.</>
  ));
}

export function notifyWorkspaceDeleteSuccess({ itemTitle }) {
  commonSuccessAlert((
    <>{itemTitle} deleted.</>
  ));
}

export function notifyWorkspaceDeleteFailure({ itemTitle }) {
  commonErrorAlert((
    <>Failed to delete {itemTitle}. Please try again.</>
  ));
}

// workspace subscription
export function notifyWorkspaceSubscriptionSubscribeSuccess({ itemToken, itemTitle }) {
  commonSuccessAlert((
    <>
      Subscription added for <WorkspaceLink label={itemTitle} url={getFeedSettingsUrl(itemToken)} />.
    </>
  ));
}

export function notifyWorkspaceSubscriptionSubscribeFailure({ itemTitle }) {
  commonErrorAlert((
    <>Failed to subscribe to {itemTitle}. Please try again.</>
  ));
}

export function notifyWorkspaceSubscriptionUpdateSuccess({ itemTitle }) {
  commonSuccessAlert((
    <>Subscription updated for {itemTitle}.</>
  ));
}

export function notifyWorkspaceSubscriptionUpdateFailure({ itemTitle }) {
  commonErrorAlert((
    <>Failed to update subscription for {itemTitle}. Please try again.</>
  ));
}

export function notifyWorkspaceSubscriptionUnsubscribeSuccess({ itemTitle }) {
  commonSuccessAlert((
    <>
      Workspace subscription removed from <WorkspaceLink label={itemTitle} url={MY_FEED_SETTINGS_URL} />.
    </>
  ));
}

export function notifyWorkspaceSubscriptionUnsubscribeFailure({ itemTitle }) {
  commonErrorAlert((
    <>Failed to unsubscribe from {itemTitle}. Please try again.</>
  ));
}

// workspace items
export function notifyWorkspaceKeywordUpdateSuccess({ itemType, itemToken, itemTitle }) {
  commonSuccessAlert((
    <>
      {itemType} updated in <WorkspaceLink label={itemTitle} url={getFeedSettingsUrl(itemToken)} />.
    </>
  ));
}

export function notifyWorkspaceKeywordUpdateFailure({ itemTitle }) {
  commonErrorAlert((
    <>Failed to update subscription in {itemTitle}. Please try again.</>
  ));
}

export function notifyWorkspaceItemUnsubscribeSuccess({ itemType, itemToken, itemTitle }) {
  commonSuccessAlert((
    <>
      {itemType} subscription removed from <WorkspaceLink label={itemTitle} url={getFeedSettingsUrl(itemToken)} />.
    </>
  ));
}

export function notifyWorkspaceItemUnsubscribeFailure({ itemTitle }) {
  commonErrorAlert((
    <>Failed to remove subscription from {itemTitle}. Please try again.</>
  ));
}
