import React from 'react';

import Provider from './components/context/provider.jsx';
import Container from './components/container.jsx';
import Modal from './components/modal.jsx';

export default function ImageCropper(props) {
  return (
    <Provider options={props}>
      <Modal />
      <Container />
    </Provider>
  );
}
