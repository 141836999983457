import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import CustomField from 'components/core/CustomField';
import EmptyPlaceholder from 'components/core/EmptyPlaceholder';
import Button from 'components/core/Button';
import Loader from 'components/core/Loader';
import {
  allCustomFieldsShape,
  profileShape,
  formStatusShape,
  errorsShape,
} from 'components/Advocates/common/utils/shapes';

import withForm from '../../../../utils/withForm';
import styles from '../../styles.module.scss';

const controlStyles = {
  wrapperClass: styles.formField,
  controlClass: styles.formControl,
};

function CustomFields({
  allCustomFields: customFields,
  values,
  formState,
  handleBlur,
  handleChange,
  submitForm,
  isSubmitting,
  isCustomFieldsRedirect,
  onCustomFieldsRedirect,
  isLoadingCustomFields,
}) {
  const { errors, touched } = formState;

  useEffect(() => {
    if (isEmpty(errors) && !isSubmitting && isCustomFieldsRedirect) {
      window.location.assign('/custom_fields');
    }
  }, [errors, isCustomFieldsRedirect, isSubmitting]);

  const handlers = { handleBlur, handleChange };

  function handleFormSubmit() {
    submitForm()
      .finally(() => onCustomFieldsRedirect(true));
  }

  const emptyDescription = (
    <>
      <p>You haven’t created/enabled any custom fields yet. When you do, they will be shown here.</p>
      <Button
        theme="link"
        onClick={handleFormSubmit}
        className={styles.linkButton}
        type="submit"
      >
        Save this record and navigate to custom fields
      </Button>
    </>
  );

  if (isLoadingCustomFields) {
    return (
      <Loader
        message="Loading"
        onlyIcon={false}
        size="medium"
        theme="dark"
      />
    );
  }

  const enabledCustomFields = customFields.filter(({ enabled }) => enabled);

  if (isEmpty(enabledCustomFields)) {
    return (
      <EmptyPlaceholder
        description={emptyDescription}
      />
    );
  }

  return (
    enabledCustomFields.map((field) => {
      const { token } = field;
      const error = touched[token] && errors[token];
      const value = values[token] || [];
      const controlState = { value, error };

      return (
        <CustomField
          key={token}
          field={field}
          controlState={controlState}
          handlers={handlers}
          styles={controlStyles}
        />
      );
    })
  );
}

CustomFields.propTypes = {
  allCustomFields: allCustomFieldsShape.isRequired,
  values: profileShape.isRequired,
  formState: PropTypes.shape({
    errors: errorsShape,
    touched: formStatusShape,
  }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isCustomFieldsRedirect: PropTypes.bool,
  onCustomFieldsRedirect: PropTypes.func.isRequired,
  isLoadingCustomFields: PropTypes.bool.isRequired,
};

export default withForm(CustomFields);
