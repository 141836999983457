import React from 'react';

function Task() {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.1562 11.8398H0.84375C0.376172 11.8398 0 12.216 0 12.6836V15.4961C0 15.9637 0.376172 16.3398 0.84375 16.3398H17.1562C17.6238 16.3398 18 15.9637 18 15.4961V12.6836C18 12.216 17.6238 11.8398 17.1562 11.8398ZM16.3125 14.6523H10.1602V13.5273H16.3125V14.6523ZM17.1562 6.21484H0.84375C0.376172 6.21484 0 6.59102 0 7.05859V9.87109C0 10.3387 0.376172 10.7148 0.84375 10.7148H17.1562C17.6238 10.7148 18 10.3387 18 9.87109V7.05859C18 6.59102 17.6238 6.21484 17.1562 6.21484ZM16.3125 9.02734H5.66016V7.90234H16.3125V9.02734ZM17.1562 0.589844H0.84375C0.376172 0.589844 0 0.966016 0 1.43359V4.24609C0 4.71367 0.376172 5.08984 0.84375 5.08984H17.1562C17.6238 5.08984 18 4.71367 18 4.24609V1.43359C18 0.966016 17.6238 0.589844 17.1562 0.589844ZM16.3125 3.40234H12.4102V2.27734H16.3125V3.40234Z" fill="#0E4EFF" />
    </svg>
  );
}

export default Task;
