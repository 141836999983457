import PropTypes from 'prop-types';

import { frequencyType } from 'components/core/FrequencyOptions/shapes';
import { stringNumberType } from 'utils/shapes';
import {
  responseTrackingTypes,
  SUBSCRIBABLE_TYPES,
} from 'components/Dashboard/common/FeedPage/utils/constants';

export const subscribableTypePropType = PropTypes.oneOf(
  Object.values(SUBSCRIBABLE_TYPES).concat(
    [
      responseTrackingTypes.FEDERAL_LEGISLATOR_STAFFER,
      responseTrackingTypes.STATE_LEGISLATOR_STAFFER,
    ]
  )
);

export const subscriptionShape = PropTypes.shape({
  frequency: frequencyType,
  isSaved: PropTypes.bool,
  keyword: PropTypes.string,
  modelId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  modelSlugs: PropTypes.arrayOf(PropTypes.string),
  modelType: PropTypes.string,
  priority: PropTypes.string,
  sectionType: PropTypes.string.isRequired,
  sendWeekAheadEmail: PropTypes.bool,
  title: PropTypes.string,
  token: PropTypes.string.isRequired,
  type: subscribableTypePropType.isRequired,
  url: PropTypes.string,
});

export const defaultSubscriptionShape = PropTypes.shape({
  defaultSubscription: PropTypes.string,
  ui: PropTypes.shape({
    isLoadingDefaultSubscription: PropTypes.bool.isRequired,
    isUpdatingDefaultSubscription: PropTypes.bool.isRequired,
  }),
});

export const listSubscriptionShape = PropTypes.shape({
  modelId: stringNumberType,
  type: subscribableTypePropType.isRequired,
  frequency: frequencyType.isRequired,
  sendWeekAheadEmail: PropTypes.bool,
  label: PropTypes.string,
  content: PropTypes.string,
});

export const multipleSubscriptionShape = {
  isCommittee: PropTypes.bool,
  listSubscriptions: PropTypes.arrayOf(listSubscriptionShape),
};

export const partyShape = PropTypes.shape({
  percentage: PropTypes.number,
  count: PropTypes.number,
});
