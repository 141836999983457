const errorMessages = {
  required: 'Required field',
  phone: 'Invalid phone number',
  email: 'Invalid email address',
  facebookProfileUrl: 'Invalid Facebook profile url',
  username: 'Invalid username',
  unique: 'This is a duplicate. Please enter different value',
};

export default errorMessages;
