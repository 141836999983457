import { Slug } from 'components/UnifiedSearch/types';
import {
  peopleSlugs,
  committeesSlugs,
  documentsSlugs,
  executiveSlugs,
  regulationSlugs,
  legislationSlugs,
} from 'components/UnifiedSearch/utils/constants';

export const getIconClass = (slug: Slug) => {
  if (peopleSlugs.includes(slug) || executiveSlugs.includes(slug)) {
    return 'fas fa-user';
  }

  if (documentsSlugs.includes(slug) || legislationSlugs.includes(slug)) {
    return 'fas fa-file-alt';
  }

  if (regulationSlugs.includes(slug)) {
    return 'fas fa-stamp';
  }

  if (committeesSlugs.includes(slug)) {
    return 'fas fa-adjust';
  }

  return '';
};
