import { BASE_URL, SUBSCRIBABLE_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getItemType } from 'redux/dashboardV2/common/subscriptions/utils';
import { isMyFeed } from 'components/Dashboard/utils';

export function getTrackedItemProps({ id, subscribableType, subscribableData }, feedToken) {
  const { model_type: modelType } = subscribableData || {};
  const itemType = getItemType(subscribableType, modelType);
  const feedUrl = isMyFeed(feedToken)
    ? `${BASE_URL}/feed/tracked/${id}`
    : `${BASE_URL}/workspaces/${feedToken}/tracked/${id}`;
  const url = subscribableType === SUBSCRIBABLE_TYPES.NEWSFEED
    ? '/news'
    : feedUrl;

  return { itemType, url };
}
