import { useEffect, useState } from 'react';

import { RecentVisit, RecentVisits, Result as ResultType } from '../types';
import api from './api';

type UseRecentVisits = RecentVisits;

const useRecentVisits = (): UseRecentVisits => {
  const [recentVisits, setRecentVisits] = useState<RecentVisit[] | []>([]);
  const [status, setStatus] = useState<RecentVisits['status']>('idle');

  const loadRecentVisits = async () => {
    setRecentVisits(await api.getRecentVisits(setStatus));
  };

  useEffect(() => {
    loadRecentVisits();
  }, []);

  const clear = async (id: number) => {
    await api.deleteRecentVisit(id);
    const newVisits = recentVisits.filter(newVisit => +newVisit.id !== id);
    setRecentVisits(newVisits);
  };

  const clearAll = () => {
    api.deleteAllRecentVisits();
    setRecentVisits([]);
  };

  const handleAdd = async (visited: ResultType) => {
    await api.addRecentVisit(visited);
  };

  return {
    recentVisits,
    clear,
    clearAll,
    handleAdd,
    status,
  };
};

export default useRecentVisits;
