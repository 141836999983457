import React, { FC } from 'react';

import { AdvancedOptions } from 'components/UnifiedSearch/types';

import { ActionsWrapper, ApplyButton, BackButton } from '../../../SlideUpModal';
import SearchWrapper from '../SearchWrapper';
import Options from './components/Options';
import useLocales from './useLocales';
import styles from './styles.module.scss';

type LocalesProps = {
  advancedOptions: AdvancedOptions,
  isMobile: boolean,
  onResetCustomRegion: () => void,
  toggleFilter: () => void,
};

const Locales: FC<LocalesProps> = ({
  advancedOptions,
  isMobile,
  onResetCustomRegion,
  toggleFilter,
}) => {
  const locales = useLocales(advancedOptions);
  const placeholder = isMobile ? 'Type to search' : 'City or school district';

  const handleApply = () => {
    advancedOptions.change({ region: 'local', customRegions: locales.selectedLocales });
    toggleFilter();
    onResetCustomRegion();
  };

  return (
    <div className={styles.wrapper}>
      <SearchWrapper
        value={locales.search}
        onChange={locales.handleSearchChange}
        placeholder={placeholder}
      />

      {locales.status === 'loading' && <div className={styles.loading}>Loading...</div>}

      {locales.status === 'idle' && (
        <Options
          advancedOptions={advancedOptions}
          locales={locales}
          isMobile={isMobile}
        />
      )}

      {isMobile && (
        <ActionsWrapper>
          <BackButton onClick={onResetCustomRegion} />

          <ApplyButton onClick={handleApply} />
        </ActionsWrapper>
      )}
    </div>
  );
};

export default Locales;
