import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Reports({ className }) {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.icon, className)}>
      <path d="M8 0V4H12L8 0ZM7 4V0H1.5C0.671562 0 0 0.671562 0 1.5V14.5C0 15.3281 0.671562 16 1.5 16H10.5C11.3284 16 12 15.3284 12 14.5V5H8.02812C7.44687 5 7 4.55313 7 4ZM4 13.6031C4 13.8 3.8 14 3.60313 14H2.4C2.19938 14 2 13.8 2 13.6031V11.4C2 11.2 2.19938 11 2.39844 11H3.60156C3.8 11 4 11.2 4 11.4V13.6031ZM7 13.6031C7 13.8 6.8 14 6.60313 14H5.4C5.2 14 5 13.8 5 13.6031V7.4C5 7.2 5.2 7 5.4 7H6.60313C6.8 7 7 7.2 7 7.4V13.6031ZM8.4 9H9.60312C9.8 9 10 9.2 10 9.4V13.6031C10 13.8 9.8 14 9.60312 14H8.4C8.2 14 8 13.8 8 13.6031V9.4C8 9.2 8.2 9 8.4 9Z" />
    </svg>
  );
}

Reports.propTypes = {
  className: PropTypes.string,
};

export default Reports;
