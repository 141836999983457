import { STATE, LOCAL, FEC, IRS } from 'components/Moneyball/utils/constants';

const dataSourceTypes = [
  {
    label: 'FEC',
    value: FEC,
  },
  {
    label: 'IRS',
    value: IRS,
  },
  {
    label: 'State',
    value: STATE,
    embed: true,
  },
  {
    label: 'Local',
    value: LOCAL,
    disabled: true,
  },
];

export default dataSourceTypes;
