import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import copyToClipboard from 'components/shared/copy-to-clipboard';
import { trackUsingFunctionName } from 'components/tracking/utils';

import InfoBar from './components/InfoBar';
import styles from './styles.module.scss';

export const constants = {
  TYPE_INPUT: 'input',
  TYPE_TEXTAREA: 'textarea',
};

export default class CopyToClipboardInput extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf([
      constants.TYPE_INPUT,
      constants.TYPE_TEXTAREA,
    ]),
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    autoSize: PropTypes.bool,
    inputClass: PropTypes.string,
    buttonClass: PropTypes.string,
    wrapperClass: PropTypes.string,
    buttonText: PropTypes.string,
    rows: PropTypes.number,
    onChange: PropTypes.func,
    trackingFunctionName: PropTypes.string,
    infoBar: PropTypes.bool,
    infoBarMax: PropTypes.number,
    infoBarError: PropTypes.string,
    InfoBarComponent: PropTypes.func,
    name: PropTypes.string,
    showCopyButton: PropTypes.bool,
  }

  static defaultProps = {
    type: constants.TYPE_TEXTAREA,
    showCopyButton: true,
    readOnly: false,
    autoFocus: false,
    autoSize: false,
    infoBar: false,
    buttonClass: 'btn btn-xs',
    wrapperClass: '',
    inputClass: 'form-control',
    buttonText: 'Copy to clipboard',
    rows: 3,
  }

  getRows() {
    const { autoSize, rows, value } = this.props;

    if (!autoSize) {
      return rows;
    }

    const lines = value.split('\n').length;
    return Math.max(rows, lines);
  }

  autoSize() {
    const { autoSize, type, rows } = this.props;

    if (autoSize && type === constants.TYPE_TEXTAREA) {
      const lines = this.input.value.split('\n').length;
      this.input.rows = Math.max(rows, lines);
    }
  }

  handleOnChange = (event) => {
    this.setState({ value: this.input.value });
    this.autoSize();
    this.props.onChange(event);
  }

  handleClickCopyButton = () => {
    const { trackingFunctionName } = this.props;

    copyToClipboard(this.input);
    trackUsingFunctionName({ trackingFunctionName });
  };

  content() {
    const { type, readOnly, autoFocus, inputClass, value, name } = this.props;

    const componentProps = {
      ref: c => (this.input = c),
      className: classNames(styles.input, inputClass),
      onChange: this.handleOnChange,
      defaultValue: value,
      name,
      readOnly,
      autoFocus,
    };

    if (type === constants.TYPE_INPUT) {
      return <input {...componentProps} type="text" />;
    }
    if (type === constants.TYPE_TEXTAREA) {
      return <textarea {...componentProps} rows={this.getRows()} />;
    }

    return null;
  }

  render() {
    const { wrapperClass, buttonClass, buttonText, infoBar, infoBarMax, infoBarError, showCopyButton, InfoBarComponent } = this.props;

    const value = this.input && this.input.value || this.props.value || '';
    const chars = value.length;

    const Wrapper = infoBar
      ? (InfoBarComponent || InfoBar)
      : Fragment;
    const wrapperProps = infoBar ? {
      maxAllowed: infoBarMax,
      error: infoBarError,
      chars,
    } : {};

    return (
      <div className={wrapperClass}>
        <Wrapper {...wrapperProps}>
          {this.content()}
        </Wrapper>
        {showCopyButton && (
          <button
            type="button"
            onClick={this.handleClickCopyButton}
            className={classNames('btn btn-xs', styles.button, buttonClass)}
          >
            <i className="far fa-copy" />{' '}
            {buttonText}
          </button>
        )}
      </div>
    );
  }
}
