import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import CropContext from './context.jsx';
import { purified } from '../helpers';
import { handleUpload, fetchFiles } from '../api';

const DATE_FORMAT = 'L';

class Provider extends React.Component {
  static propTypes = {
    options: PropTypes.object,
    children: PropTypes.array,
  }

  state = {
    files: [],
    imageSource: '',
    initialCrop: {},
    isOpenModal: false,
    croppedImage: null,
    selectedFile: null,
  }

  componentDidMount() {
    this.setInitialCrop();
  }

  componentDidUpdate(prevProps) {
    const { options } = prevProps;
    const { fileUploadIds = [] } = this.props.options;

    if (purified(fileUploadIds).length && !purified(options.fileUploadIds).length) {
      fetchFiles(fileUploadIds)
        .then(this.handleLoadFiles);
    }
  }

  setInitialCrop = () => {
    const { options: { square = false } } = this.props;

    const defaultCrop = {
      x: 5,
      y: 5,
      width: 90,
      height: 90,
      minWidth: 10,
    };

    this.setState({
      initialCrop: {
        ...defaultCrop,
        ...square && {
          aspect: 1,
          x: 15,
          y: null,
          width: 70,
          height: 70,
        },
      },
    });
  }

  uploadImage = imageSource => this.setState({ imageSource, isOpenModal: true })

  toggleModal = () =>
    this.setState(state => ({ isOpenModal: !state.isOpenModal }))

  handleLoadFiles = (response) => {
    const files = [];

    response.forEach((file) => {
      const uploadedAt = moment(file.created_at).format(DATE_FORMAT);

      files.push({
        ...file,
        name: `Uploaded ${uploadedAt}`,
      });
    });

    this.setState(state => ({
      files: [...state.files, ...files],
    }));
  }

  handleCropped = croppedImage =>
    this.setState({ croppedImage, isOpenModal: false }, () => {
      if (this.props.options.handleSuccess) {
        handleUpload({ croppedImage: this.state.croppedImage, ...this.props })
          .then(this.props.options.handleSuccess);
      }
    })

  handleReset = () =>
    this.setState({
      files: [],
      selectedFile: null,
      croppedImage: null,
      isOpenModal: false,
    })

  handleChange = selectedFile =>
    this.setState({
      selectedFile,
      isOpenModal: true,
    })

  handleDelete = () => this.setState({ files: [], croppedImage: null }, () => {
    if (this.props.options.handleDelete) {
      this.props.options.handleDelete();
    }
  })

  render() {
    const { options, children } = this.props;

    return (
      <CropContext.Provider
        value={{
          ...this.state,
          options,
          handleCrop: this.handleCrop,
          toggleModal: this.toggleModal,
          uploadImage: this.uploadImage,
          handleReset: this.handleReset,
          handleDelete: this.handleDelete,
          handleChange: this.handleChange,
          handleCropped: this.handleCropped,
        }}
      >
        {children}
      </CropContext.Provider>
    );
  }
}

export default Provider;
