import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Kbd } from 'components/core/TipMessage';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import withGlobalSearch from 'components/GlobalSearch/utils/withGlobalSearch';

import DropShadow from '../DropShadow';
import styles from './styles.module.scss';

function Control({
  children,
  innerRef,
  innerProps,
  onClose,
  isMenuOpen,
}) {
  const { isMobile, isDesktop } = useMediaQuery();

  const searchIconClassNames = classNames('fas fa-search', styles.searchIcon);
  const closeIconClassNames = classNames('fas fa-times-circle', styles.closeIcon);
  const [hasCloseInstructions, setHasCloseInstructions] = useState(isMenuOpen);

  /* This effect prevents clicking on the close instructions
  * immediately as it's opened if the user opens the search
  * by clicking on the spot the close icon will be positioned
  */
  let timeout;
  useEffect(() => {
    if (isMenuOpen) {
      timeout = setTimeout(() => setHasCloseInstructions(true), 10);
    } else {
      clearTimeout(timeout);
      setHasCloseInstructions(false);
    }

    return () => clearTimeout(timeout);
  }, [isMenuOpen]);

  return (
    <>
      <div className={styles.control} ref={innerRef} {...innerProps}>
        <i className={searchIconClassNames} />
        {children}
        {hasCloseInstructions && (
          <span className={styles.closeInstructions}>
            {isDesktop && (
              <>
                To cancel hit <Kbd className={styles.key}>ESC</Kbd> or press
              </>
            )}
            <i
              className={closeIconClassNames}
              onClick={onClose}
              onKeyDown={onClose}
              onTouchEnd={onClose}
            />
          </span>
        )}
      </div>

      {isMobile && <DropShadow />}
    </>
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};

export default withGlobalSearch(Control);
