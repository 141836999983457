import { useState, useEffect } from 'react';

import { getIsMyFeedActive, getIsActive } from 'components/NavigationV2/utils';
import { ROUTE_EVENT } from 'components/NavigationV2/components/RouteListener';
import slugs from 'components/Navigation/menuData/slugs';

const DEFAULT_LINK_PROPS = {
  name: 'Home',
  getIsActive() { return getIsActive(gon.homepage.path); },
};

const LINK_PROPS = {
  [slugs.dashboard]: {
    name: 'Feed',
    getIsActive: getIsMyFeedActive,
  },
};

const getLinkProps = () => (LINK_PROPS[gon.homepage.slug] || DEFAULT_LINK_PROPS);

function useHomeLink({ isInactive }) {
  const linkProps = getLinkProps();
  const [isActive, setIsActive] = useState(linkProps.getIsActive());
  const withActiveStyle = !isInactive && isActive;

  useEffect(() => {
    const handleRouteEvent = () => {
      setIsActive(linkProps.getIsActive());
    };

    document.addEventListener(ROUTE_EVENT, handleRouteEvent);

    window.onpopstate = handleRouteEvent;

    return () => document.removeEventListener(ROUTE_EVENT, handleRouteEvent);
  }, []);

  return {
    name: linkProps.name,
    withActiveStyle,
  };
}

export default useHomeLink;
