export function purified(arr = []) {
  return arr.filter(v => v !== undefined);
}

export function dataURItoBlob(dataURI) {
  const splitted = dataURI.split(',');

  // convert base64/URLEncoded data component to raw binary data held in a string
  const length = splitted[0].indexOf('base64') >= 0;

  const byteString = length
    ? atob(splitted[1])
    : unescape(splitted[1]);

  // separate out the mime component
  const mimeString = splitted[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}
