import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../styles.module.scss';

const LinkIcon = ({ isExternal }) => {
  const externalIconClassNames = classNames(
    'far fa-external-link-alt',
    styles.linkIcon,
  );

  const internalIconClassNames = classNames(
    'far fa-level-down-alt',
    styles.internalLinkIcon,
  );

  const iconClassNames = isExternal ? externalIconClassNames : internalIconClassNames;

  return <i className={iconClassNames} />;
};

LinkIcon.propTypes = {
  isExternal: PropTypes.bool.isRequired,
};

export default LinkIcon;
