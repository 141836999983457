import pickBy from 'lodash/pickBy';
import cloneDeep from 'lodash/cloneDeep';

import { isItemLocked } from '.';

const parseOption = (option) => {
  const { options, slug } = option;
  const {
    party_short,
    party_slug,
    display_state,
    ...restOptions
  } = options || {};

  option.customOptions = pickBy({
    ...restOptions,
    state: display_state,
    partySlug: party_slug,
    partyShort: party_short,
  });

  option.strategy = slug;

  delete option.options;
  delete option.slug;

  return option;
};

const getOptionsSorter = disabledProducts => (
  function optionsSorter(firstOption, secondOption) {
    const isFirstItemLocked = isItemLocked(firstOption, disabledProducts);
    const isSecondItemLocked = isItemLocked(secondOption, disabledProducts);

    if (!isFirstItemLocked && isSecondItemLocked) return -1;
    if (isFirstItemLocked && !isSecondItemLocked) return 1;

    return 0;
  }
);

export const getOptionsParser = disabledProducts => (
  function optionsParser(options) {
    const optionsCopy = cloneDeep(options || []);
    const parsedOptions = optionsCopy.map(parseOption);

    const optionsSorter = getOptionsSorter(disabledProducts);
    return parsedOptions.sort(optionsSorter);
  }
);
