import React from 'react';
import PropTypes from 'prop-types';

import { customOptionsShape } from 'components/GlobalSearch/utils/constants';

import Result from '../../Result';
import { redirectToSelectedPage } from '../../../utils';
import styles from './styles.module.scss';

export default function Visit({
  url,
  label,
  strategy,
  onDelete,
  inputText,
  onClick,
  onSelect,
  customOptions,
  disabledProducts,
}) {
  const handleDelete = () => onDelete({ url });
  const handleClick = (isLegacyStrategy) => {
    onSelect(isLegacyStrategy);
    onClick({ url, strategy }, inputText);
  };

  return (
    <Result
      label={label}
      url={url}
      strategy={strategy}
      customOptions={customOptions}
      disabledProducts={disabledProducts}
      labelClassName={styles.label}
      className={styles.result}
      onDelete={handleDelete}
      onClick={handleClick}
    />
  );
}

Visit.defaultProps = {
  onClick: redirectToSelectedPage,
};

Visit.propTypes = {
  inputText: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  strategy: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  customOptions: customOptionsShape,
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};
