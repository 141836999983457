import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

type OptionProps = {
  onClick: () => void,
  id: string,
  isSelected: boolean,
  children: ReactNode,
  className?: string,
  isDisabled?: boolean,
  isMobile: boolean,
  withCheckMark?: boolean,
};

const Option: FC<OptionProps> = ({
  onClick,
  id,
  isSelected,
  children,
  className,
  isDisabled,
  isMobile,
  withCheckMark = true,
}) => {
  const hasCheckMark = isSelected && withCheckMark;

  return (
    <li className={styles.wrapper}>
      <Button
        theme="light"
        onClick={onClick}
        className={classNames(styles.option, isSelected && styles.selected, className)}
        data-testid={`options-${id}`}
        disabled={isDisabled}
      >
        {!isMobile && hasCheckMark && <i className="fas fa-check" />}

        {children}

        {isMobile && hasCheckMark && <i className="fas fa-check" />}
      </Button>
    </li>
  );
};

export default Option;
