import moment from 'moment';
import { find, flatten, sortBy, flattenDeep, isEqual } from 'lodash';
import { DEFAULT_CALENDAR_TIME_ZONE } from 'components/hill-day-calendar/constants';
import { dateFormat } from 'components/shared/datepicker/helper';

export const FORM_NAME = 'fly-in-calendar-filters-form';
export const timeFormat = 'h:mma';
export const eventDateFormat = 'ddd, MMM D';
export const dateTimeFormat = 'MM/DD/YYYY hh:mm a';
export const titleDateFormat = 'dddd, MMMM D, YYYY';
export const requestDateFormat = 'YYYY-MM-DD';
export const pending = 300;
export const dateKeys = ['starts_at', 'ends_at'];
export const uniqueKeys = ['text', ...dateKeys];
export const sortingByKeys = [...dateKeys, 'text', 'attendee_tokens', 'legislator_tokens'];

const useFilterKeys = ['attendee_tokens', 'legislator_tokens'];

const keyName = {
  attendee_tokens: 'attendees',
  legislator_tokens: 'legislators',
};

export const isBeforeResult = (a, b) => (moment(a).isBefore(b) ? 1 : -1);

export const formatDate = (dateTime, format) =>
  moment(dateTime).format(format);

export const convertDateTimeZone = (dateTime, format) =>
  moment(moment.tz(dateTime, DEFAULT_CALENDAR_TIME_ZONE), requestDateFormat).format(format);

export const attendeeNames = (arr, argument) => arr.map(props => props[argument]).join(', ');

export const isEqualEvents = (prevEvents = [], nextEvents = []) =>
  isEqual(
    sortBy(flatten(prevEvents.map(e => e.events.map(ev => ev))), ['token']),
    sortBy(flatten(nextEvents.map(e => e.events.map(ev => ev))), ['token']),
  );

export const deleteEventItem = (events, eventToken) => events
  // To delete an event by token
  .map(g => ({
    ...g,
    events: g.events.filter(event => event.token !== eventToken),
  }))
  // To delete an empty group
  .filter(g => g.events.length)
;

export const sortedSelected = (selected = []) => selected
  .filter(({ value }) => value)
  .sort((a, b) => {
    if (sortingByKeys.indexOf(a.name) > sortingByKeys.indexOf(b.name)) {
      return 1;
    }

    return -1;
  });

export const formatedValue = (name, value) => {
  const isDateFilled = dateKeys.includes(name) && value;

  if (!isDateFilled) {
    return value;
  }

  const prefixes = {
    starts_at: 'On or after',
    ends_at: 'On or before',
  };

  const date = moment(value).format(dateFormat);

  return `${prefixes[name]} ${date}`;
};

export const prepareEventItem = event => ({
  ...event,
  starts_at: convertDateTimeZone(event.starts_at, timeFormat),
  ends_at: convertDateTimeZone(event.ends_at, timeFormat),
});

export const prepareEvents = (events, hill_day_token) => events
  .map(event => ({
    ...prepareEventItem(event),
    hill_day_token,
  }))
;

export const routing = (eventToken = '', method) => {
  const isNew = method === 'new';
  const isEdit = method === 'edit';
  const isView = !!eventToken && !isNew && !isEdit;

  return { eventToken, isView, isNew, isEdit };
};

export const filteredValue = (initialState) => {
  const filtered = {};

  Object.keys(initialState).forEach((name) => {
    const value = initialState[name];

    if (useFilterKeys.includes(name)) {
      filtered[name] = [...value.split(',')];
    } else {
      filtered[name] = value;
    }
  });

  return filtered;
};

export const getName = (name, token, eventList) => {
  const key = keyName[name];
  const list = eventList.map(({ events }) => events.map(event => event[key]));
  const item = find(flattenDeep(list), ['token', token]);

  return item && item.full_name;
};

const splittedValue = (splitted, name, events) => {
  const selected = [];

  splitted.forEach((value) => {
    const label = getName(name, value, events);

    if (label) {
      selected.push({
        key: value,
        name,
        value,
        label,
      });
    }
  });

  return selected;
};

export const initialState = (state, events) => {
  const selected = [];

  Object.keys(state).forEach((name) => {
    const value = state[name];

    if (useFilterKeys.includes(name)) {
      selected.push(...splittedValue(value.split(','), name, events));
    } else {
      selected.push({
        key: name,
        name,
        value,
      });
    }
  });

  return selected;
};

export const getValues = (selected = []) => {
  const values = {};

  selected.forEach(({ key, name, value }) => {
    if (useFilterKeys.includes(name) && key) {
      values[name] = values[name] || [];
      values[name].push(key);
    } else if (value) {
      values[name] = value.toLowerCase();
    }
  });

  return values;
};

export const queryValues = (selected = []) => {
  const search = [];
  const values = getValues(selected);

  Object.keys(values).forEach(name => search.push(`${name}=${values[name]}`));

  return search;
};
