import React from 'react';
import PropTypes from 'prop-types';

import { SlideUpModal } from 'components/core/Modal';
import Button from 'components/core/Button';
import {
  NewNoteForNavigation as NewNoteButton,
  NewTaskForNavigation as NewTaskButton,
} from 'components/Grm2';
import {
  trackNewNoteClicked,
  trackNewTaskClicked,
} from 'components/Navigation/GlobalHeader/utils/tracking';
import NewStakeholderButton from 'components/NavigationV2/components/common/NewStakeholderButton';
import DisabledCreateMessage from 'components/NavigationV2/components/common/DisabledCreateMessage';

import { Note, Stakeholder, Task } from './components/Icons';
import styles from './styles.module.scss';

function Panel({ isOpen, toggleOpen }) {
  const renderContent = (icon, name) => (<>{icon} <span className={styles.name}>{name}</span></>);

  const renderList = () => {
    if (!(gon.isStakeholderMgmtEnabled || gon.isGrmEnabled)) {
      return <DisabledCreateMessage isMobile />;
    }

    return (
      <>
        {gon.isGrmEnabled && (
          <>
            <NewNoteButton
              isAddNoteEnabled
              trackButtonClicked={trackNewNoteClicked}
              className={styles.option}
              content={renderContent(<Note />, 'Note')}
              withMobileStyle={false}
              onSuccess={toggleOpen}
            />

            <NewTaskButton
              trackButtonClicked={trackNewTaskClicked}
              className={styles.option}
              content={renderContent(<Task />, 'Task')}
              onSuccess={toggleOpen}
            />
          </>
        )}

        {gon.isStakeholderMgmtEnabled && (
          <NewStakeholderButton
            className={styles.option}
            content={renderContent(<Stakeholder />, 'Stakeholder contact')}
            onSuccess={toggleOpen}
          />
        )}
      </>
    );
  };

  return (
    <SlideUpModal
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      className={styles.modal}
      bodyClassName={styles.body}
    >
      <div className={styles.header}>
        <span className={styles.title}>Create</span>

        <Button className={styles.closeButton} theme="light" onClick={toggleOpen}>
          <i className="far fa-times" />
        </Button>
      </div>

      <div className={styles.list}>
        {renderList()}
      </div>
    </SlideUpModal>
  );
}

Panel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default Panel;
