import { get } from 'lodash';

import { ApiResult } from 'components/UnifiedSearch/useSearch/types';
import { HighlightedString } from 'components/UnifiedSearch/types';

import { getHighlightedFields } from '../../base';

type Highlights = {
  chamberName?: HighlightedString,
  stateAlpha?: HighlightedString,
  stateName?: HighlightedString,
  chair?: HighlightedString,
  title?: HighlightedString,
};

export const getHighlights = (result: ApiResult): Highlights => {
  const highlightedFields = getHighlightedFields(result);

  const [chamberName] = get(highlightedFields, 'chamber_name', []);
  const [stateAlpha] = get(highlightedFields, 'state.alpha2', []);
  const [stateName] = get(highlightedFields, 'state.name', []);
  const [chair] = get(highlightedFields, 'chairman.universal_moniker', []);
  const [title] = get(highlightedFields, 'name', []);

  return {
    chamberName,
    stateAlpha,
    stateName,
    chair,
    title,
  };
};
