import { get } from 'lodash';

import { ApiResult } from 'components/UnifiedSearch/useSearch/types';
import { HighlightedString } from 'components/UnifiedSearch/types';

import { getHighlightedFields } from '../../base';

type Highlights = {
  description?: HighlightedString,
  title?: HighlightedString,
  nickname?: HighlightedString,
  districtName?: HighlightedString,
};

export const getHighlights = (result: ApiResult): Highlights => {
  const highlightedFields = getHighlightedFields(result);

  const [description] = get(highlightedFields, 'full_biography', []);
  const [universalMoniker] = get(highlightedFields, 'universal_moniker', []);
  const [nickname] = get(highlightedFields, 'nickname', []);
  const [districtName] = get(highlightedFields, 'display_district_name', []);

  return { description, title: universalMoniker || nickname, districtName };
};
