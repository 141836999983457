import PropTypes from 'prop-types';

export const customFieldsAnswersShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.arrayOf(PropTypes.string),
});

export const emailsShape = PropTypes.shape({
  category: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.number,
  primary: PropTypes.bool,
});

export const phonesShape = PropTypes.shape({
  id: PropTypes.number,
  phone: PropTypes.string,
  category: PropTypes.string,
  primary: PropTypes.bool,
});

export const listTagShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.name,
  })
);

export const addressShape = PropTypes.shape({
  category: PropTypes.string,
  city: PropTypes.string,
  company_id: PropTypes.number,
  complement: PropTypes.string,
  congressional_district_id: PropTypes.number,
  country: PropTypes.string,
  created_at: PropTypes.string,
  id: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  number: PropTypes.string,
  place_id: PropTypes.string,
  primary: PropTypes.bool,
  state: PropTypes.string,
  state_code: PropTypes.string,
  state_lower_district_id: PropTypes.number,
  state_upper_district_id: PropTypes.number,
  street: PropTypes.string,
  updated_at: PropTypes.string,
  zip: PropTypes.string,
});

export const allCustomFieldsShape = PropTypes.arrayOf(PropTypes.shape({
  action: PropTypes.shape({
    editable: PropTypes.bool,
    enabled: PropTypes.bool,
    token: PropTypes.string,
  }),
  createdAt: PropTypes.string,
  description: PropTypes.string,
  enabled: PropTypes.bool,
  fieldType: PropTypes.string,
  isEditable: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  productSlugs: PropTypes.shape({
    token: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
  }),
  required: PropTypes.bool,
  token: PropTypes.string,
}));

export const profileShape = PropTypes.shape({
  address: PropTypes.oneOfType([
    PropTypes.string,
    addressShape,
  ]),
  addresses: PropTypes.arrayOf(addressShape),
  customFields: customFieldsAnswersShape,
  email: PropTypes.string,
  emails: PropTypes.arrayOf(emailsShape),
  facebookProfile: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.number,
  lastName: PropTypes.string,
  lists: listTagShape,
  middleName: PropTypes.string,
  organization: PropTypes.string,
  partyCode: PropTypes.string,
  phone: PropTypes.string,
  phones: PropTypes.arrayOf(phonesShape),
  smartLists: listTagShape,
  tags: listTagShape,
  title: PropTypes.string,
  twitterName: PropTypes.string,
});

const statusType = PropTypes.oneOfType([PropTypes.string, PropTypes.bool]);

const statusShape = PropTypes.shape({
  [PropTypes.string]: statusType,
});

export const formStatusShape = PropTypes.shape({
  addresses: PropTypes.arrayOf(statusShape),
  description: statusType,
  emails: PropTypes.arrayOf(statusShape),
  facebookProfile: statusType,
  firstName: statusType,
  id: statusType,
  lastName: statusType,
  lists: PropTypes.arrayOf(statusType),
  middleName: statusType,
  organization: statusType,
  partyCode: statusType,
  phones: PropTypes.arrayOf(statusShape),
  tags: PropTypes.arrayOf(statusType),
  title: statusType,
  twitterName: statusType,
});

export const errorsShape = PropTypes.shape({
  addresses: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string,
  })),
  emails: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string,
  })),
  phones: PropTypes.arrayOf(PropTypes.shape({
    phone: PropTypes.string,
  })),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  [PropTypes.string]: PropTypes.string,
});
