import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { NORMAL, WARNING, DANGER, WARNING_RATIO } from './constants';
import styles from './styles.module.scss';

function InfoBar({ children, maxAllowed, chars, error }) {
  const remaining = maxAllowed - chars;

  const getStatus = () => {
    if (remaining < 0) {
      return DANGER;
    }

    if (remaining <= maxAllowed * WARNING_RATIO) {
      return WARNING;
    }

    return NORMAL;
  };

  const renderError = () => (
    <span className={styles.danger}>{error}</span>
  );

  const renderStatus = () => {
    const status = getStatus();

    const className = {
      [NORMAL]: classNames(styles.status),
      [WARNING]: classNames(styles.status, styles.warning),
      [DANGER]: classNames(styles.status, styles.danger),
    }[status];

    const text = {
      [NORMAL]: `${remaining} characters remaining`,
      [WARNING]: `${remaining} characters remaining`,
      [DANGER]: `${Math.abs(remaining)} characters beyond the limit`,
    }[status];

    return (
      <span className={className}>{text}</span>
    );
  };

  const [hasFocus, setHasFocus] = useState(false);
  const handleFocus = () => {
    setHasFocus(true);
    children.onFocus && children.onFocus();
  };

  const handleBlur = () => {
    setHasFocus(false);
    children.onBlur && children.onBlur();
  };

  const hasError = !!error || getStatus() === DANGER;

  const wrapperClassNames = classNames({
    [styles.wrapper]: true,
    [styles.hasFocus]: hasFocus,
    [styles.hasError]: hasError,
  });

  return (
    <div className={wrapperClassNames}>
      {cloneElement(children, {
        ...children.props,
        onBlur: handleBlur,
        onFocus: handleFocus,
      })}
      <div className={styles.infoBar}>
        <span>Maximum allowed characters: {maxAllowed}</span>
        {!!error
          ? renderError()
          : renderStatus()
        }
      </div>
    </div>
  );
}

InfoBar.propTypes = {
  maxAllowed: PropTypes.number,
  children: PropTypes.node,
  chars: PropTypes.number,
  error: PropTypes.string,
};

export default InfoBar;
