import isEmpty from 'lodash/isEmpty';

import { getIsActive } from 'components/NavigationV2/utils';

import { CATEGORIES, MOBILE_CATEGORIES } from './constants';

// removes sections with only disabled links
const reduceSections = disabledProducts => (sections, section) => {
  const isEnabled = ({ slug }) => !disabledProducts.includes(slug);
  const enabledItems = section.items.filter(isEnabled);

  if (isEmpty(enabledItems)) {
    return sections;
  }

  return [...sections, { ...section, items: enabledItems }];
};

export function getReducedCategories(disabledProducts) {
  const reduceColumns = category => (category.columns.reduce((acc, column) => {
    const reducedColumn = column.reduce(reduceSections(disabledProducts), []);

    return isEmpty(reducedColumn) ? acc : [...acc, reducedColumn];
  }, []));

  return CATEGORIES.map(category => ({
    ...category,
    columns: reduceColumns(category),
  }));
}

export function getReducedMobileCategories(disabledProducts) {
  return MOBILE_CATEGORIES.map(category => ({
    ...category,
    sections: category.sections.reduce(reduceSections(disabledProducts), []),
  }));
}

const getIsActiveSection = section => (
  section.items.some(item => getIsActive(item.href))
);

export const getIsActivePageCategory = columns => (
  columns.some(column => column.some(getIsActiveSection))
);

export const getIsActiveMobilePageCategory = sections => (
  sections.some(getIsActiveSection)
);
