import { useState } from 'react';
import { merge, slice } from 'lodash';

import { trackClearedAll } from 'utils/tracking/globalSearch';

const { user_id: userId } = gon.currentUser || {};
const HISTORY_KEY = `govpredict.searchHistory.${userId}`;
const MAX_HISTORY_LENGTH = 8;

const loadHistory = () => {
  const history = localStorage.getItem(HISTORY_KEY) || '[]';

  return JSON.parse(history);
};

const updateHistory = newHistory => localStorage
  .setItem(HISTORY_KEY, JSON.stringify(newHistory));

export default function useRecentVisits(
  loadVisits = loadHistory,
  persistVisits = updateHistory,
  maxHistoryLength = MAX_HISTORY_LENGTH,
) {
  const [visits, setVisits] = useState(loadVisits());

  const updateVisits = (newVisists) => {
    const slicedVisits = slice(newVisists, 0, maxHistoryLength);
    persistVisits(slicedVisits);
    setVisits(loadVisits());
  };

  const handleResultVisit = (result, inputText) => {
    const { url: resultUrl, isLegacyStrategy } = result;
    const newVisits = visits.filter(({ url }) => url !== resultUrl);
    const visitedItem = merge(result, { inputText });

    !isLegacyStrategy && newVisits.unshift(visitedItem);
    updateVisits(newVisits);
  };

  const handleDelete = ({ url: deletedUrl }) => {
    const newVisits = visits.filter(({ url }) => url !== deletedUrl);
    updateVisits(newVisits);
  };

  const handleClearAll = () => {
    const total = visits.length;
    trackClearedAll({ total });
    updateVisits([]);
  };

  return {
    onVisit: handleResultVisit,
    onDelete: handleDelete,
    onClearAll: handleClearAll,
    visits,
  };
}
