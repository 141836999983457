import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default function wrapMenuList(MenuList, timeout) {
  class WrappedMenuList extends Component {
    componentDidMount() {
      this.setCheckAndHandleTimeout();
    }

    componentWillUnmount() {
      if (this.checkTimeout) clearTimeout(this.checkTimeout);
    }

    innerRef = (ref) => {
      if (ref === this.menuListRef) return;

      const { innerRef } = this.props;
      this.menuListRef = ref;
      innerRef(ref);
    };

    setCheckAndHandleTimeout = () => {
      this.checkAndHandle();
      this.checkTimeout = setTimeout(this.setCheckAndHandleTimeout, timeout);
    };

    checkAndHandle() {
      if (this.shouldHandle()) {
        const { selectProps: { inputValue, onInputChange, handleScrolledToBottom } } = this.props;

        handleScrolledToBottom && handleScrolledToBottom(onInputChange, inputValue);
      }
    }

    shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
      const bottomBorder = scrollHeight - clientHeight - 10;

      return bottomBorder < scrollTop;
    };

    shouldHandle() {
      const el = this.menuListRef;

      if (!el) return false;

      const { scrollTop, scrollHeight, clientHeight } = el;
      const { isLoading, options } = this.props;
      const hasFilteredOptions = options.some(({ __isNew__ }) => __isNew__ !== true);

      return !isLoading && hasFilteredOptions > 0 && this.shouldLoadMore(
        scrollHeight,
        clientHeight,
        scrollTop
      );
    }

    render() {
      return <MenuList {...this.props} innerRef={this.innerRef} />;
    }
  }

  WrappedMenuList.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    innerRef: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    selectProps: PropTypes.shape({
      handleScrolledToBottom: PropTypes.func.isRequired,
    }).isRequired,
  };

  return WrappedMenuList;
}
