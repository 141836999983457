import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Input, { InputSelect } from 'components/core/Input';
import MaskedInput from 'components/core/MaskedInput';
import Label from 'components/core/Label';
import ErrorMessage from 'components/core/ErrorMessage';
import RadioBox from 'components/core/RadioBox';
import Asterisk from 'components/core/Asterisk';
import { TextInputPlaceholder } from 'components/core/Placeholder';
import { getError } from 'utils/forms';

import AddRemoveButtons from './components/AddRemoveButtons';
import styles from './styles.module.scss';

function BaseField({
  id,
  name,
  placeholder,
  title,
  className,
  formState,
  isRequired,
  typeValue,
  typeOptions,
  typeName,
  renderCustomInputNode,
  prefix,
  prefixClassName,
  maskSlug,
  setFieldValue,
  multiple,
  index,
  fieldName,
  optionalFieldName,
  errorMessage,
  onRemoveField,
  onAddField,
  isLoading,
  hasMultipleValues,
  primaryValue,
  primaryName,
  onChangePrimary,
  isAddMoreDisabled,
  complementaryFieldName,
  clearComplementaryField,
  ...restProps
}) {
  const [complementaryFieldState, setComplementaryFieldState] = useState({
    isVisible: false,
    initialLoad: true,
  });
  const { isVisible } = complementaryFieldState;

  const withTypes = !isEmpty(typeOptions);
  const errorText = !isEmpty(errorMessage)
    ? errorMessage
    : getError(name || id, formState);

  const isError = !isEmpty(errorText);

  const prefixClassNames = classNames(
    styles.prefix,
    errorMessage && styles.invalid,
    prefixClassName,
  );

  const inputProps = {
    ...restProps,
    index,
    id,
    name,
    placeholder: ' ',
    isError,
  };

  function toggleComplementaryFieldVisibilty() {
    setComplementaryFieldState(prev => ({
      isVisible: !prev.isVisible,
      initialLoad: false,
    }));
    clearComplementaryField(index);
  }

  function showComplementaryField() {
    setComplementaryFieldState(prev => ({
      ...prev,
      isVisible: true,
    }));
  }

  const addRemoveButtonsProps = {
    name: optionalFieldName || fieldName,
    onAdd: onAddField,
    onRemove: () => onRemoveField(index),
    isLoading,
    isAddMoreDisabled,
    showRemove: hasMultipleValues,
    complementaryFieldProps: {
      isVisible,
      name: complementaryFieldName,
      onToggle: toggleComplementaryFieldVisibilty,
    },
  };

  const primaryButtonProps = {
    ...restProps,
    label: index === 0 ? 'Primary' : '',
    name: primaryName,
    theme: 'button',
    checked: primaryValue,
    onChange: onChangePrimary,
    className: styles.primaryButton,
  };

  const primaryButton = <RadioBox {...primaryButtonProps} />;

  function getInput() {
    if (renderCustomInputNode) {
      return renderCustomInputNode({
        ...inputProps,
        showComplementaryField,
        complementaryFieldState,
      });
    }

    if (maskSlug) {
      return (
        <MaskedInput
          {...inputProps}
          maskSlug={maskSlug}
          className={classNames(prefix && styles.withPrefix, className)}
        />
      );
    }

    return <Input {...inputProps} />;
  }

  return (
    <div className={classNames(styles.formField, className)}>
      {title && (
        <Label htmlFor={id}>
          {title}
          <Asterisk show={isRequired} />
        </Label>
      )}
      <TextInputPlaceholder className={styles.placeholder}>
        {placeholder}
      </TextInputPlaceholder>

      <div className={styles.inputFieldGroup}>
        <div className={styles.inputWithTypes}>
          {prefix && (
            <span className={prefixClassNames}>
              {prefix}
            </span>
          )}

          {getInput()}

          {withTypes && (
            <InputSelect
              {...restProps}
              name={typeName}
              value={typeValue.value}
              options={typeOptions}
              isError={isError}
              onChange={setFieldValue}
            />
          )}
        </div>

        {multiple && hasMultipleValues && primaryButton}
      </div>

      {multiple && <AddRemoveButtons {...addRemoveButtonsProps} />}

      <ErrorMessage message={errorText} />
    </div>
  );
}

BaseField.defaultProps = {
  isRequired: false,
  value: '',
  title: '',
  optionalFieldName: '',
  typeValue: {},
};

BaseField.propTypes = {
  formState: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  isRequired: PropTypes.bool,
  typeValue: PropTypes.object,
  typeOptions: PropTypes.array,
  onChangeType: PropTypes.func,
  setFieldValue: PropTypes.func,
  typeName: PropTypes.string,
  renderCustomInputNode: PropTypes.func,
  prefix: PropTypes.string,
  prefixClassName: PropTypes.string,
  maskSlug: PropTypes.string,
  value: PropTypes.string,
  multiple: PropTypes.bool,
  index: PropTypes.number,
  fieldName: PropTypes.string,
  optionalFieldName: PropTypes.string,
  errorMessage: PropTypes.string,
  onRemoveField: PropTypes.func,
  onAddField: PropTypes.func,
  isLoading: PropTypes.bool,
  isAddMoreDisabled: PropTypes.bool,
  hasMultipleValues: PropTypes.bool,
  primaryValue: PropTypes.bool,
  primaryName: PropTypes.string,
  onChangePrimary: PropTypes.func,
  complementaryFieldName: PropTypes.string,
  clearComplementaryField: PropTypes.func,
};

export default BaseField;
