import { get, capitalize } from 'lodash';

import { ApiResult } from 'components/UnifiedSearch/useSearch/types';
import { HighlightedString } from 'components/UnifiedSearch/types';

import { getHighlightedFields } from '../../base';

type Highlights = {
  chamberName?: HighlightedString,
  chair?: HighlightedString,
  title?: HighlightedString,
};

export const getCapitalizedChamberNameHighlight = (highlight?: HighlightedString) => {
  if (!highlight) return;

  const emphasis = '<em>';
  const [, highlightRest] = highlight.split(emphasis);

  // eslint-disable-next-line consistent-return
  return [emphasis, capitalize(highlightRest)].join('');
};

export const getHighlights = (result: ApiResult): Highlights => {
  const highlightedFields = getHighlightedFields(result);

  const [chamberName] = get(highlightedFields, 'type', []);
  const [chair] = get(highlightedFields, 'current_chairman.universal_moniker', []);
  const [title] = get(highlightedFields, 'name', []);

  return {
    chamberName: getCapitalizedChamberNameHighlight(chamberName),
    chair,
    title,
  };
};
