const options = [
  {
    label: 'Federal',
    value: '/federal/cosponsorship_similarity',
  },
  {
    label: 'State',
    value: '/state/cosponsorship_similarity',
  },
];

export default options;
