import React from 'react';
import PropTypes from 'prop-types';

import Badge from 'components/core/Badge';
import { sponsorPropTypes } from 'components/Dashboard/common/FeedPage/utils/shapes';

import DisplayLink from '../DisplayLink';
import PartyBadge from '../PartyBadge';
import styles from './styles.module.scss';

function Sponsor({ data, withLink }) {
  const { url, label, partyShort, comeFrom, infoText, isExternal } = data || {};

  const displayLabel = withLink
    ? (
      <DisplayLink
        href={url}
        label={label}
        className={styles.link}
        isExternal={isExternal}
      />
    )
    : <span className={styles.link}>{label}</span>;

  return (
    <>
      {displayLabel}
      {partyShort && <PartyBadge party={partyShort}>{partyShort}</PartyBadge>}
      {comeFrom && <Badge className={styles.commonBadge}>{comeFrom}</Badge>}&nbsp;
      {infoText}
    </>
  );
}

Sponsor.defaultProps = {
  withLink: true,
};

Sponsor.propTypes = {
  data: PropTypes.shape(sponsorPropTypes),
  withLink: PropTypes.bool,
};

export default Sponsor;
