import React, { FC } from 'react';
import classNames from 'classnames';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import { AdvancedOptions as AdvancedOptionsType, Filters } from '../../types';
import Period from './components/Period';
import Region from './components/Region';
import ClearFiltersButton from './components/ClearFiltersButton';
import Collections from './components/Collections';
import Verbatim from './components/Verbatim';
import styles from './styles.module.scss';

type AdvancedOptionsProps = {
  advancedOptions: AdvancedOptionsType,
  filters: Filters,
};

const AdvancedOptions: FC<AdvancedOptionsProps> = ({ advancedOptions, filters }) => {
  const { isMobile } = useMediaQuery();
  const commonProps = { advancedOptions, isMobile };

  return (
    <div className={classNames(styles.advancedOptions, advancedOptions.isOpen && styles.open)}>
      <Period {...commonProps} filters={filters} />

      <Verbatim {...commonProps} filters={filters} />

      <Region {...commonProps} />

      <Collections {...commonProps} filters={filters} />

      <ClearFiltersButton {...commonProps} />
    </div>
  );
};

export default AdvancedOptions;
