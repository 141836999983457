import { useState, useMemo, useEffect } from 'react';

import {
  getReducedMobileCategories,
  getIsActiveMobilePageCategory,
} from 'components/NavigationV2/utils/directory';

function useMobileDirectory({
  disabledProducts,
  isVisible,
}) {
  const categories = useMemo(() => getReducedMobileCategories(disabledProducts), [disabledProducts]);
  const activePageCategory = categories.find(
    category => getIsActiveMobilePageCategory(category.sections)
  );
  const initialActiveId = (activePageCategory && activePageCategory.id) || null;
  const [activeId, setActiveId] = useState(initialActiveId);
  const activeCategory = categories.find(({ id }) => activeId === id);

  useEffect(() => {
    !isVisible && setActiveId(initialActiveId);
  }, [isVisible]);

  return {
    activeId,
    setActiveId,
    categories,
    activeCategory,
  };
}

export default useMobileDirectory;
