import { AdvancedOptions, Collection, Filters } from 'components/UnifiedSearch/types';

import { COLLECTION_OPTIONS, REGION_COLLECTIONS } from './constants';
import { CollectionOptions } from './types';

const filterOptionsByRegion = (advancedOptions: AdvancedOptions, options: CollectionOptions) => {
  const { region } = advancedOptions.values;

  if (region === 'all') return options;

  const collectionsByRegion = REGION_COLLECTIONS[region];

  return Object.entries(options).reduce((filteredOptions: CollectionOptions, [key, label]) => ({
    ...filteredOptions,
    [key]: {
      label,
      isDisabled: !collectionsByRegion?.includes(key as Collection),
    },
  }), {} as CollectionOptions);
};

export const getOptions = (advancedOptions: AdvancedOptions, filters: Filters) => {
  const options = COLLECTION_OPTIONS[filters.value] as CollectionOptions;

  return filterOptionsByRegion(advancedOptions, options);
};
