import React, { FC } from 'react';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import styles from './styles.module.scss';

type ErrorTemplateProps = {
  src: string, alt: string, text: string | JSX.Element, subText: string
};

const ErrorTemplate: FC<ErrorTemplateProps> = ({ src, alt, text, subText }) => {
  const { isMobile } = useMediaQuery();

  return (
    <div className={styles.error}>
      <img
        className={styles.image}
        src={src}
        alt={alt}
        width={isMobile ? 200 : 328}
        height={209}
      />
      <div className={styles.textContainer}>
        <div className={styles.text}>
          {text}
        </div>
        <div className={styles.subText}>
          {subText}
        </div>
      </div>
    </div>
  );
};

export default ErrorTemplate;
