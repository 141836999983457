import React from 'react';
import PropTypes from 'prop-types';

import useTrackItemsModal from '../../utils/useTrackItemsModal';
import SubscriptionCard from '../SubscriptionCard';

function TrackItemsSubscriptions({
  isPresentHearings,
  isPresentStaffers,
  withTitle,
  frequency,
  onChange,
  withFrequency,
  withWeekAheadEmail,
}) {
  const {
    isStaffer,
    isHearing,
    ...restProps
  } = useTrackItemsModal({
    isPresentHearings,
    isPresentStaffers,
    onChange,
  });

  return (
    <SubscriptionCard
      {...frequency}
      {...restProps}
      withTitle={withTitle}
      isStaffer={isStaffer}
      isHearing={isHearing}
      withFrequency={withFrequency}
      withWeekAheadEmail={withWeekAheadEmail}
    />
  );
}

TrackItemsSubscriptions.propTypes = {
  frequency: PropTypes.shape({
    staffer: PropTypes.string,
    hearings: PropTypes.string,
    sendWeekAheadEmail: PropTypes.bool,
  }),
  isPresentHearings: PropTypes.bool,
  isPresentStaffers: PropTypes.bool,
  withTitle: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  withFrequency: PropTypes.bool.isRequired,
  withWeekAheadEmail: PropTypes.bool.isRequired,
};

export default TrackItemsSubscriptions;
