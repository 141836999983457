import { mapboxApiEndpoint } from 'components/core/Map/utils/constants';

export function fetchMapBox(coordinates) {
  return $.ajax({
    url: `${mapboxApiEndpoint}/${coordinates}.json`,
    method: 'GET',
    data: {
      radius: 0,
      access_token: gon.mapboxAccessToken,
    },
  });
}
