import {
  CollectionsPeople,
  CollectionsLegislation,
  CollectionsCommittees,
  CollectionsDocuments,
  Collection,
  Region,
} from 'components/UnifiedSearch/types';

export const COLLECTION_OPTIONS_PEOPLE: { [key in CollectionsPeople]: string } = {
  all: 'All collections',
  federalLegislators: 'Federal legislators',
  congressionalStaffers: 'Congressional staffers',
  federalExecutives: 'Federal executives',
  stateLegislators: 'State legislators',
  stateStaffers: 'State staffers',
  stateExecutives: 'State executives',
  localExecutives: 'Local executives',
  stakeholder: 'Stakeholders',
};

export const COLLECTION_OPTIONS_LEGISLATION: { [key in CollectionsLegislation]: string } = {
  all: 'All collections',
  federalBills: 'Federal bills',
  federalRegulations: 'Federal regulations',
  stateBills: 'State bills',
  stateRegulations: 'State regulations',
};

export const COLLECTION_OPTIONS_COMMITTEES: { [key in CollectionsCommittees]: string } = {
  all: 'All collections',
  federalCommittees: 'Federal committees',
  stateCommittees: 'State committees',
};

export const COLLECTION_OPTIONS_DOCUMENTS: { [key in CollectionsDocuments]: string } = {
  all: 'All collections',
  federalDocuments: 'Federal documents',
  stateDocuments: 'State documents',
  localDocuments: 'Local documents',
};

export const COLLECTION_OPTIONS = {
  all: {},
  people: COLLECTION_OPTIONS_PEOPLE,
  'legislation & regulation': COLLECTION_OPTIONS_LEGISLATION,
  committees: COLLECTION_OPTIONS_COMMITTEES,
  documents: COLLECTION_OPTIONS_DOCUMENTS,
};

export const REGION_COLLECTIONS: { [key in Region]?: Partial<Collection>[] } = {
  federal: [
    'all',
    'federalLegislators',
    'congressionalStaffers',
    'federalExecutives',
    'federalBills',
    'federalRegulations',
    'federalCommittees',
    'federalDocuments',
  ],
  state: [
    'all',
    'stateLegislators',
    'stateStaffers',
    'stateExecutives',
    'localExecutives',
    'stakeholder',
    'stateBills',
    'stateRegulations',
    'stateCommittees',
    'stateDocuments',
    'localDocuments',
  ],
  local: [
    'all',
    'localExecutives',
    'stakeholder',
    'localDocuments',
  ],
};
