import { useState, useEffect, useMemo } from 'react';
import { isEmpty, isEqual } from 'lodash';

import { AdvancedOptions, Filters, Collection } from 'components/UnifiedSearch/types';
import { COLLECTIONS_DEFAULT } from 'components/UnifiedSearch/utils/constants';

import { CollectionOptions } from './types';
import { getOptions } from './helpers';

type UseCollectionsProps = {
  advancedOptions: AdvancedOptions,
  filters: Filters,
  isMobile: boolean,
};

const useCollections = ({ advancedOptions, filters, isMobile }: UseCollectionsProps) => {
  const { region, collections } = advancedOptions.values;

  const [isVisible, setIsVisible] = useState(false);
  const toggleFilter = () => setIsVisible(!isVisible);

  const [prevCollections, setPrevCollections] = useState(collections);
  const [selectedCollections, setSelectedCollections] = useState<Collection[]>(collections);

  if (prevCollections !== collections) {
    setPrevCollections(collections);
    setSelectedCollections(collections);
  }

  const options = useMemo(
    () => getOptions(advancedOptions, filters) as CollectionOptions,
    [region, filters.value],
  );

  const setCollections = (newCollections: Collection[]) => {
    advancedOptions.change({ collections: newCollections });
    setSelectedCollections(newCollections);
  };

  useEffect(() => {
    const disabledOptionKeys = Object.keys(options).filter((key) => {
      const collectionOption = options[key as Collection];

      return typeof collectionOption !== 'string' && collectionOption.isDisabled;
    });
    const newCollections = collections.filter(
      collection => !disabledOptionKeys.includes(collection),
    );

    if (isEqual(collections, newCollections)) return;

    setCollections(isEmpty(newCollections) ? COLLECTIONS_DEFAULT : newCollections);
  }, [JSON.stringify(options)]);

  return {
    isVisible,
    toggleFilter,
    options,
    value: isMobile ? selectedCollections : collections,
    setSelectedCollections,
  };
};

export default useCollections;
