export const DEFAULT_SORT_KEY = 'last_action_date';

export const FEDERAL_BILL_THEAD = [
  { sortKey: 'bill', label: 'bill', style: { width: '30%' } },
  { sortKey: 'introduced_on', label: 'date introduced', style: { width: '13%' } },
  { sortKey: 'last_action', label: 'last action (l.a.)', style: { width: '20%' } },
  { sortKey: DEFAULT_SORT_KEY, label: 'l.a. date', style: { width: '13%' } },
  { sortKey: 'status', label: 'status' },
  { sortKey: 'sponsor', label: 'sponsor' },
  { label: 'tags' },
];

export const STATE_BILL_THEAD = [
  { sortKey: 'state', label: 'state' },
  { sortKey: 'bill', label: 'bill', style: { width: '30%' } },
  { sortKey: 'introduced_on', label: 'date introduced', style: { width: '13%' } },
  { sortKey: 'last_action', label: 'last action (l.a.)', style: { width: '20%' } },
  { sortKey: DEFAULT_SORT_KEY, label: 'l.a. date', style: { width: '13%' } },
  { sortKey: 'status', label: 'status', style: { width: '20%' } },
  { sortKey: 'sponsor', label: 'sponsor' },
  { label: 'tags' },
];

export const FEDERAL_REGULATION_BILL_THEAD = [
  { label: 'title', style: { width: '20%' } },
  { label: 'latest comment', style: { width: '20%' } },
  { label: 'publication date', style: { width: '11%' } },
  { label: 'document type', style: { width: '12%' } },
  { label: 'agency' },
  { label: 'comment close date' },
  { label: 'tags' },
];

export const STATE_REGULATION_BILL_THEAD = [
  { sortKey: 'title', label: 'title', style: { width: '30%' } },
  { sortKey: 'state', label: 'state', style: { width: '5%' } },
  { label: 'agency', style: { width: '20%' } },
  { sortKey: 'last_action_date', label: 'latest publication date', style: { width: '12%' } },
  { sortKey: 'comments_open', label: 'comments open' },
  { sortKey: 'comments_closed', label: 'comments closed' },
  { label: 'tags' },
];

export const BILL_TABLE_MAPPING = {
  federal_bills: FEDERAL_BILL_THEAD,
  state_bills: STATE_BILL_THEAD,
  federal_regulations: FEDERAL_REGULATION_BILL_THEAD,
  state_regulations: STATE_REGULATION_BILL_THEAD,
};
