import React from 'react';
import PropTypes from 'prop-types';

import { PARTIES } from 'utils/constants';
import { TAG_STRATEGY_SLUG } from 'components/Advocates/utils/constants';
import {
  formStatusShape,
  profileShape,
  errorsShape,
} from 'components/Advocates/common/utils/shapes';

import withForm from '../../../utils/withForm';
import styles from '../styles.module.scss';
import BaseField from './Fields/BaseField';
import TagInputField from './Fields/TagInputField';
import ButtonGroupField from './Fields/ButtonGroupField';

function AdditionalDetails({ values, formState, handleBlur, handleChange, setFieldValue }) {
  const commonProps = {
    onChange: handleChange,
    onBlur: handleBlur,
    className: styles.formControl,
    formState,
  };

  function getDefaultValues(listValues) {
    return listValues.map(({ value }) => value);
  }

  function handleChangeLists(lists) {
    return setFieldValue('lists', lists);
  }

  function handleChangeTags(tags) {
    return setFieldValue('tags', tags);
  }

  return (
    <>
      <BaseField
        {...commonProps}
        id="organization"
        title="Organization"
        value={values.organization || ''}
        placeholder="The organization this individual belongs to."
      />

      <BaseField
        {...commonProps}
        id="title"
        title="Title"
        value={values.title || ''}
        placeholder="Title of the stakeholder (e.g., CEO, Professor)"
      />

      <ButtonGroupField
        {...commonProps}
        id="partyCode"
        title="Party"
        value={values.partyCode}
        placeholder="The name of the party individual is part of. (e.g., Republican)."
        data={PARTIES}
        setFieldValue={setFieldValue}
      />

      <TagInputField
        defaultRawValues={getDefaultValues(values.tags)}
        overrideValue={values.tags}
        onChange={handleChangeTags}
        title="Tags"
        placeholder="Apply a tag to this individual (e.g., loves dogs)."
        strategy={TAG_STRATEGY_SLUG}
        name="tags"
        className={styles.formField}
        strategyInputs={{ create: true }}
      />

      <TagInputField
        defaultRawValues={getDefaultValues(values.lists)}
        overrideValue={values.lists}
        onChange={handleChangeLists}
        title="Lists"
        placeholder="Add this individual to a list (e.g., grasstops)."
        strategy="stakeholder_mgmt_list"
        name="lists"
        className={styles.formField}
      />

      <BaseField
        {...commonProps}
        id="facebookProfile"
        title="Facebook profile"
        value={values.facebookProfile || ''}
        placeholder="The url for this individual’s FB profile (e.g., https://www.facebook.com/myfbpageurl)."
      />

      <BaseField
        {...commonProps}
        id="twitterName"
        title="Twitter handle"
        value={values.twitterName || ''}
        placeholder="The name of the twitter account for this individual (e.g., @therealpersonsname)."
      />
    </>
  );
}

AdditionalDetails.propTypes = {
  formState: PropTypes.shape({
    errors: errorsShape,
    touched: formStatusShape,
  }).isRequired,
  values: profileShape.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default withForm(AdditionalDetails);
