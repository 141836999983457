import {
  GOVERNOR_ORDERS_SLUG,
  GOVERNOR_PRESS_RELEASES_SLUG, LOCAL_DOCUMENTS_SLUG, SOCIAL_MEDIA_SLUG,
  STATE_BILLS_SLUG, STATE_REGULATIONS_SLUG,
} from 'components/Dashboard/utils/libProducts';
import {
  CONTINUOUS,
  DAILY,
  NONE,
  NONE_ID,
  WEEKLY,
} from 'components/core/FrequencyOptions/constants';

const { myFeedToken } = gon.currentUser || {};

/** Values [statesToTrack, localDocumentOptions] are defined in transformKeywordForUI */
export const initialValues = {
  keywords: '',
  nickname: '',
  itemsToTrack: [],
  localCities: [],
  isAllSocialMediaSelected: true,
  isAllLocalChecked: true,
  socialMediaToTrack: [],
  notificationFrequency: NONE_ID,
  workspacesToSubscribe: [
    {
      label: 'My feed',
      value: myFeedToken,
      isFixed: true,
    },
  ],
};

export const STATES_TRACK_SLUGS = [
  STATE_BILLS_SLUG,
  GOVERNOR_PRESS_RELEASES_SLUG,
  GOVERNOR_ORDERS_SLUG,
  STATE_REGULATIONS_SLUG,
];
export const LOCAL_DOCUMENTS_TRACK_SLUGS = [LOCAL_DOCUMENTS_SLUG];
export const SOCIAL_LIST_TRACK_SLUGS = [SOCIAL_MEDIA_SLUG];

export const ALL_FREQUENCIES = [NONE, WEEKLY, DAILY, CONTINUOUS];

export const FIRST_STEP = 1;
export const LAST_STEP = 3;
