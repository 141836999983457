import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FrequencyPlaceholder from 'components/Dashboard/common/FrequencyPlaceholder';
import { ALL_FREQUENCIES } from 'components/Dashboard/KeywordSearch/utils/constants';
import FrequencyOptions from 'components/core/FrequencyOptions';

import styles from './styles.module.scss';

function TrackFrequency({
  isMobile,
  isLoading,
  selectedOption,
  className,
  buttonClassName,
  onChange,
}) {
  return (
    <div className={classNames(styles.container, isLoading && styles.loading)}>
      <FrequencyPlaceholder
        notificationFrequency={selectedOption}
        className={styles.frequencyDescription}
      />
      <FrequencyOptions
        options={ALL_FREQUENCIES}
        selectedOption={selectedOption}
        onChange={onChange}
        isMobile={isMobile}
        isDisabled={isLoading}
        buttonClassName={classNames(styles.frequencyButton, buttonClassName)}
        className={className}
      />
    </div>
  );
}

TrackFrequency.propTypes = {
  isMobile: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedOption: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TrackFrequency;
