import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CoreLink from 'components/core/Link';
import { trackDirectoryItemClicked } from 'components/NavigationV2/utils/tracking';

import styles from './styles.module.scss';

function Link({ link, withRelatedAppStyle, disabledProducts }) {
  const isDisabled = disabledProducts.includes(link.slug);

  return (
    <CoreLink
      href={link.href}
      theme="tableLink"
      className={classNames(styles.link, withRelatedAppStyle && styles.relatedApp)}
      disabled={isDisabled}
      onClick={() => trackDirectoryItemClicked(link.title)}
    >
      {isDisabled && <i className="fas fa-lock" />}
      {link.title}
      <span className={styles.description}>{link.description}</span>
    </CoreLink>
  );
}

Link.propTypes = {
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    slug: PropTypes.string,
  }).isRequired,
  withRelatedAppStyle: PropTypes.bool.isRequired,
  disabledProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Link;
