import {
  AdvancedOptions,
  Category,
  Filters,
  Slug,
} from 'components/UnifiedSearch/types';
import * as featureSlugs from 'utils/constants/featureSlugs';
import {
  peopleSlugs,
  legislationAndRegulationSlugs,
  committeesSlugs,
  documentsSlugs,
} from 'components/UnifiedSearch/utils/constants';

import { REGION_SLUGS } from './constants';

type Unit = 'hour' | 'day' | 'month' | 'year';
type CategorySlugs = {
  [key in Category]: Slug[];
};
type FilterSlugsProps = {
  filters?: Filters,
  advancedOptions: AdvancedOptions,
  slugs: Slug[],
};

export const subtractTime = (unit: Unit, time: number, date: Date = new Date()) => {
  const { [unit]: setTime }: { [key in Unit]: () => void } = {
    hour: () => date.setHours(date.getHours() - time),
    day: () => date.setDate(date.getDate() - time),
    month: () => date.setMonth(date.getMonth() - time),
    year: () => date.setFullYear(date.getFullYear() - time),
  };
  setTime();

  return date;
};

const categorySlugs: CategorySlugs = {
  people: peopleSlugs,
  'legislation & regulation': legislationAndRegulationSlugs,
  committees: committeesSlugs,
  documents: documentsSlugs,
  all: [...peopleSlugs, ...legislationAndRegulationSlugs, ...committeesSlugs, ...documentsSlugs],
};

const getDefaultSlugs = (filters: Filters): Slug[] => categorySlugs[filters.value];

const filterSlugsByRegion = ({ advancedOptions, slugs }: FilterSlugsProps) => {
  const { region } = advancedOptions.values;

  if (region === 'all') return slugs;

  return slugs.filter(slug => REGION_SLUGS[region]?.includes(slug));
};

const filterSlugsByCollections = ({ filters, advancedOptions, slugs }: FilterSlugsProps) => {
  const collectionSlugs: Slug[] = [];
  const { collections } = advancedOptions.values;

  if (collections.includes('all')) {
    return slugs;
  }

  const addSlugs = {
    all: () => filters && getDefaultSlugs(filters),
    federalLegislators: () => collectionSlugs.push(featureSlugs.FEDERAL_LEGISLATORS_SLUG),
    stateLegislators: () => collectionSlugs.push(featureSlugs.STATE_LEGISLATORS_SLUG),
    congressionalStaffers: () => collectionSlugs.push(featureSlugs.CONGRESSIONAL_STAFFERS_SLUG),
    stateStaffers: () => collectionSlugs.push(featureSlugs.STATE_STAFFERS_SLUG),
    stateExecutives: () => collectionSlugs.push(featureSlugs.STATE_EXECUTIVE_SLUG),
    federalExecutives: () => collectionSlugs.push(featureSlugs.FEDERAL_EXECUTIVE_SLUG),
    stakeholder: () => collectionSlugs.push(featureSlugs.STAKEHOLDER_SLUG),
    federalBills: () => collectionSlugs.push(featureSlugs.FEDERAL_BILLS_SLUG),
    stateBills: () => collectionSlugs.push(featureSlugs.STATE_BILLS_SLUG),
    federalRegulations: () => collectionSlugs.push(featureSlugs.FEDERAL_REGULATIONS_SLUG),
    stateRegulations: () => collectionSlugs.push(featureSlugs.STATE_REGULATIONS_SLUG),
    federalCommittees: () => collectionSlugs.push(featureSlugs.FEDERAL_COMMITTEE_SLUG),
    stateCommittees: () => collectionSlugs.push(featureSlugs.STATE_COMMITTEE_SLUG),
    federalDocuments: () => collectionSlugs.push(...featureSlugs.FEDERAL_DOCUMENTS as Slug[]),
    stateDocuments: () => collectionSlugs.push(
      featureSlugs.GOVERNOR_ORDERS_SLUG,
      featureSlugs.GOVERNOR_PRESS_RELEASES_SLUG,
    ),
    localExecutives: () => collectionSlugs.push(featureSlugs.LOCAL_EXECUTIVE_SLUG),
    localDocuments: () => collectionSlugs.push(featureSlugs.LD2_REPORTS),
  };

  collections.forEach((collection) => {
    addSlugs[collection]();
  });

  return slugs.filter(slug => collectionSlugs.includes(slug));
};

export const getSearchSlugs = (advancedOptions: AdvancedOptions, filters: Filters): Slug[] => {
  let slugs = getDefaultSlugs(filters);

  slugs = filterSlugsByRegion({ advancedOptions, slugs });
  slugs = filterSlugsByCollections({ filters, advancedOptions, slugs });

  return slugs;
};
