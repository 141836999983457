import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'lodash/isEqual';
import { WithContext as ReactTags } from 'react-tag-input';

export default class TagInput extends React.Component {
  static propTypes = {
    tags: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    inputGroupClassName: PropTypes.string,
    tagClassName: PropTypes.string,
    tagsGroupClassName: PropTypes.string,
    groupClassName: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    tags: [],
    labelClassName: 'control-label',
    inputClassName: 'form-control',
    inputGroupClassName: 'ReactTags__tagInput tag-input__input-group',
    tagClassName: 'ReactTags__tag tag-input__tag',
    tagsGroupClassName: 'ReactTags__tags',
    groupClassName: 'form-group tag-input',
  }

  state = {
    tags: this.props.tags,
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.tags, this.props.tags)) {
      this.setState({ tags: nextProps.tags });
    }
  }

  handleChange = () => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(this.state.tags);
    }
  }

  handleAddition = (tag) => {
    const tags = [...this.state.tags, tag.text];

    this.setState({ tags }, this.handleChange);
  }

  handleDelete = (tagIndex) => {
    const tags = this.state.tags.filter((_tag, index) =>
      index !== tagIndex
    );

    this.setState({ tags }, this.handleChange);
  }

  hiddenInputs = () => {
    const { name } = this.props;

    if (!name) {
      return [];
    }

    const fullName = `${name}[]`;
    const inputs = this.state.tags.map((tag, index) =>
      <input type="hidden" key={index} value={tag} name={fullName} />
    );

    return inputs;
  }

  label = () => {
    const { label, labelClassName } = this.props;

    if (!label) {
      return null;
    }

    return <label className={labelClassName}>{label}</label>;
  }

  tags = () => {
    let id = 0;
    return this.state.tags.map(tag => ({ text: tag, id: `${(id += 1)}` }));
  }


  render() {
    const {
      groupClassName,
      inputClassName,
      inputGroupClassName,
      tagClassName,
      tagsGroupClassName,
      placeholder,
    } = this.props;

    const classNames = {
      tag: tagClassName,
      tagInput: inputGroupClassName,
      tagInputField: inputClassName,
      tags: tagsGroupClassName,
    };

    return (
      <div className={groupClassName}>
        {this.label()}
        <ReactTags
          autofocus={false}
          classNames={classNames}
          tags={this.tags()}
          placeholder={placeholder}
          handleAddition={this.handleAddition}
          handleDelete={this.handleDelete}
        />
        {this.hiddenInputs()}
      </div>
    );
  }
}
