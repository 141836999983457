import React, { FC } from 'react';

import { MenuItemsContext } from 'components/Navigation/MobileView/utils/withMenuItems';
import withNavigation from 'components/Navigation/utils/withNavigation';
import { UseModalType } from 'components/core/Modal/types';
import useLoader from 'utils/useLoader';

import useMobileNavigation from './utils/useMobileNavigation';
import NavigationBar from './components/NavigationBar';
import ApplicationMenu from './components/ApplicationMenu';

type MobileViewProps = {
  isMobilePage: boolean,
  isAppMenuOpen: boolean,
  disabledProducts: string[],
  isFirstPageLoad: boolean,
  toggleAppMenu: () => void,
  unifiedSearchView: UseModalType,
};

const MobileView: FC<MobileViewProps> = ({
  isMobilePage,
  isAppMenuOpen,
  disabledProducts,
  isFirstPageLoad,
  toggleAppMenu,
  unifiedSearchView,
}) => {
  const { isLoading, startLoading } = useLoader();

  const {
    toggleNavigationMenu,
    ...directoryProps
  } = useMobileNavigation({
    disabledProducts,
    toggleAppMenu,
    isAppMenuOpen,
    isMobilePage,
  });

  const context = {
    ...directoryProps,
    disabledProducts,
    isMobilePage,
    toggleNavigationMenu,
    isLoading,
    startLoading,
  };

  const showNavigationBar = !isMobilePage;

  return (
    <MenuItemsContext.Provider value={context}>
      {showNavigationBar && (
        <NavigationBar
          isAppMenuOpen={isAppMenuOpen}
          toggleNavigationMenu={toggleNavigationMenu}
          disabledProducts={disabledProducts}
          unifiedSearchView={unifiedSearchView}
        />
      )}

      <ApplicationMenu
        isAppMenuOpen={isAppMenuOpen}
        disabledProducts={disabledProducts}
        isFirstPageLoad={isFirstPageLoad}
      />
    </MenuItemsContext.Provider>
  );
};

export default withNavigation(MobileView);
