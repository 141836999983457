import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withNavigation from 'components/Navigation/utils/withNavigation';
import Link from 'components/core/Link';
import useModal from 'components/core/Modal/useModal';
import { trackAppClicked } from 'components/NavigationV2/utils/tracking';
import HiddenItemsDropdown from './components/HiddenItemsDropdown';
import DirectoryToggler from './components/DirectoryToggler';

import { Lock as LockIcon } from '../../../icons';
import useMenuBar from './utils/useMenuBar';
import styles from './styles.module.scss';

const HIDDEN_ITEMS_MAX_LENGTH = 4;

function MenuBar({ disabledProducts }) {
  const menuItems = useMenuBar(disabledProducts);
  const linkProps = {
    theme: 'tableLink',
    iconClassName: styles.iconWrapper,
  };

  const {
    isOpenModal: isDirectoryVisible,
    closeModal: closeDirectory,
    toggleModal: toggleDirectory,
  } = useModal();

  const hiddenItems = [...menuItems].reverse().slice(0, HIDDEN_ITEMS_MAX_LENGTH);

  return (
    <section className={styles.menuBar}>
      <DirectoryToggler
        isActive={isDirectoryVisible}
        disabledProducts={disabledProducts}
        closeDirectory={closeDirectory}
        toggleDirectory={toggleDirectory}
      />

      <div className={styles.leftItems}>
        {menuItems.map(({ name, href, icon, isActive, isDisabled }) => (
          <Link
            {...linkProps}
            href={isDisabled ? null : href}
            customIcon={isDisabled ? <LockIcon /> : icon}
            className={classNames(styles.item, isActive && styles.active)}
            onClick={() => trackAppClicked(name)}
            disabled={isDisabled}
            key={name}
          >
            <span className={classNames(styles.itemText, isDisabled && styles.disabled)} data-name={name}>{name}</span>
          </Link>
        ))}
      </div>

      <HiddenItemsDropdown className={styles.hiddenItemsDropdown} items={hiddenItems} />
    </section>
  );
}

MenuBar.propTypes = {
  disabledProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withNavigation(MenuBar);
