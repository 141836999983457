import PropTypes from 'prop-types';

export const strategyInputsShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.oneOfType(
    [
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
    ],
  ),
});
