import { isNil, isEmpty, flatten } from 'lodash';

import { EMPTY_VALUE } from 'utils/constants';

export function currencyFormat(number, fractionDigits = 2) {
  if (!number) {
    return EMPTY_VALUE;
  }

  const parsedFloat = parseFloat(number);
  const numberToFormat = !isNaN(parsedFloat) ? parsedFloat : 0;

  const formated = numberToFormat.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  return formated;
}

export function formatCheckboxValues(fieldName, newFieldValue, prevProps) {
  const { [fieldName]: prevState } = prevProps || {};

  const isChecked = prevState && prevState.find(item => item === newFieldValue);
  const filteredPrevState = (prevState && prevState.filter(item => item !== newFieldValue)) || [];

  return {
    [fieldName]: !Array.isArray(newFieldValue) ? [
      ...filteredPrevState,
      ...(isChecked ? [] : [newFieldValue]),
    ] : newFieldValue,
  };
}

export function formatTypeFilterValues(fieldName, newFieldValue, prevProps) {
  const { [fieldName]: prevState } = prevProps || {};

  const isChecked = prevState && prevState.find(item => item.value === newFieldValue);
  const filteredPrevState = (prevState && prevState.filter(item => item.value !== newFieldValue)) || [];

  return {
    [fieldName]: [
      ...filteredPrevState,
      ...(isChecked ? [] : [{ value: newFieldValue }]),
    ].filter(item => item.value),
  };
}

export function formatTypeFilterItems(fieldName, itemName, newFieldChecked, prevProps) {
  const { [fieldName]: prevState } = prevProps || {};

  return {
    [fieldName]: [
      ...prevState.map(({ value, ...props }) => ({
        value,
        ...props,
        ...(value === itemName && { checked: newFieldChecked }),
      })),
    ],
  };
}

export function getValue(value) {
  if (isNil(value) || isEmpty(value)) return EMPTY_VALUE;

  return value;
}

export function getCurrentUserName() {
  const { first_name, last_name } = gon.currentUser || {};
  return [first_name, last_name].filter(Boolean).join(' ');
}

export function getSelectedClusters(selectedItems) {
  return selectedItems.filter(({ items }) => items);
}

export function getSelectedRecords(selectedItems) {
  return flatten(selectedItems.map(({ items, restItem }) => items || restItem));
}

export function getWithClusterActionBarDescription(selectedItems = []) {
  const clusters = getSelectedClusters(selectedItems).length;
  const records = getSelectedRecords(selectedItems).length;

  return {
    default: `${clusters} cluster(s) with ${records} record(s) selected`,
    mobile: `${clusters} cluster(s) with ${records} record(s) selected`,
    desktop: `${clusters} cluster(s) with ${records} record(s) selected`,
  };
}

export const hasMoneyballV2Subscription = gon.isMoneyballEnabled || false;

export const hasMoneyballClusterbuck = gon && gon.currentUser && gon.currentUser.permitMoneyballClusterbuck || false;

export const hasStakeholderMgmtSubscription = gon.isStakeholderMgmtEnabled || false;
