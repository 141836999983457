import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export default function PartyIcon({ partyShort, partySlug, extraSideMargin, className }) {
  if (!partyShort) {
    return null;
  }

  let iconClassName = 'party-icon';

  iconClassName += ` party-icon--${partySlug || partyShort}`;

  if (extraSideMargin) {
    iconClassName += ' party-icon--extra-side-margin';
  }

  return (
    <span className={classNames(iconClassName, className)}>
      {partyShort}
    </span>
  );
}

PartyIcon.propTypes = {
  extraSideMargin: PropTypes.bool,
  partyShort: PropTypes.string,
  partySlug: PropTypes.string,
  className: PropTypes.string,
};

PartyIcon.defaultProps = {
  extraSideMargin: false,
};
