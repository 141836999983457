import { get } from 'lodash';

import { ApiResult } from 'components/UnifiedSearch/useSearch/types';
import { HighlightedString } from 'components/UnifiedSearch/types';

import { getHighlightedFields } from '../../base';

type Highlights = {
  officeServed?: HighlightedString,
  fullName?: HighlightedString,
  title?: HighlightedString,
};

export const getHighlights = (result: ApiResult): Highlights => {
  const highlightedFields = getHighlightedFields(result);

  const [officeServed] = get(highlightedFields, 'boss_name', []);
  const [fullName] = get(highlightedFields, 'full_name', []);
  const [title] = get(highlightedFields, 'titles', []);

  return { officeServed, fullName, title };
};
