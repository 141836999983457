import { get } from 'lodash';

import { ApiResult } from 'components/UnifiedSearch/useSearch/types';
import { HighlightedString } from 'components/UnifiedSearch/types';

import { getHighlightedFields } from '../../base';

type Highlights = {
  stateName?: HighlightedString,
  description?: HighlightedString,
  title?: HighlightedString,
  districtName?: HighlightedString,
};

export const getHighlights = (result: ApiResult): Highlights => {
  const highlightedFields = getHighlightedFields(result);

  const [stateName] = get(highlightedFields, 'state.name', []);
  const [description] = get(highlightedFields, 'full_biography', []);
  const [title] = get(highlightedFields, 'universal_moniker', []);
  const [nickname] = get(highlightedFields, 'nickname', []);
  const [districtName] = get(highlightedFields, 'display_district_name', []);

  return { stateName, description, title: title || nickname, districtName };
};
