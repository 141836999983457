import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Briefcase({ className }) {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.icon, className)}>
      <path d="M8.125 8.03125C8.125 8.2557 7.9432 8.4375 7.71875 8.4375H5.28125C5.0568 8.4375 4.875 8.2557 4.875 8.03125V6.8125H0V10.4688C0 11.1187 0.56875 11.6875 1.21875 11.6875H11.7812C12.4312 11.6875 13 11.1187 13 10.4688V6.8125H8.125V8.03125ZM11.7812 2.75H9.75V1.53125C9.75 0.88125 9.18125 0.3125 8.53125 0.3125H4.46875C3.81875 0.3125 3.25 0.88125 3.25 1.53125V2.75H1.21875C0.56875 2.75 0 3.31875 0 3.96875V6H13V3.96875C13 3.31875 12.4312 2.75 11.7812 2.75ZM8.125 2.75H4.875V1.9375H8.125V2.75Z" />
    </svg>
  );
}

Briefcase.propTypes = {
  className: PropTypes.string,
};

export default Briefcase;
