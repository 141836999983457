import { escapeRegExp, includes } from 'lodash';
import { isExternal } from 'utils/url';
import {
  FEDERAL_AGENCY_STAFFERS_SLUG,
  LOCAL_EXECUTIVE_SLUG,
  STATE_EXECUTIVE_SLUG,
  FEDERAL_EXECUTIVE_SLUG,
  STATE_STAFFER_EXECUTIVE_SLUG,
} from 'utils/constants/featureSlugs';

import { productSlugs, QUICK_SEARCH_SLUGS } from './constants';

export const transformSuggesterRequest = ({ requiredInputs }) => {
  const { query: terms } = requiredInputs;

  return ({
    search: {
      terms,
      search_slugs: QUICK_SEARCH_SLUGS,
    },
  });
};

export const redirectToSelectedPage = ({ url, strategy }, inputText = '') => {
  if (strategy === FEDERAL_AGENCY_STAFFERS_SLUG) {
    window.location.assign(`/federal/agency_staffers?search=${inputText}`);
    return;
  }

  if (isExternal(url)) {
    window.open(url, '_blank', 'noopener,noreferrer');
    return;
  }

  window.location.assign(url);
};

export const getSearchTermsRegExp = (inputValue) => {
  const escapedInputValue = escapeRegExp(inputValue.trim());
  const searchInputTerms = escapedInputValue.split(' ').join('|');

  return new RegExp(`(${searchInputTerms})`, 'i');
};

export const isItemLocked = ({ strategy }, disabledProducts) => {
  const productSlug = productSlugs[strategy];

  return disabledProducts.includes(productSlug);
};

export const getExecutiveSlug = (customOptions = {}) => {
  const { type } = customOptions;
  const executiveSlugs = [
    LOCAL_EXECUTIVE_SLUG,
    STATE_EXECUTIVE_SLUG,
    FEDERAL_EXECUTIVE_SLUG,
    STATE_STAFFER_EXECUTIVE_SLUG,
  ];

  return includes(executiveSlugs, type) ? type : LOCAL_EXECUTIVE_SLUG;
};
