import isEmpty from 'lodash/isEmpty';

export const DISCLAIMER_TYPES = {
  CONTRIBUTIONS: gon.moneyballDisclaimer,
};

export const hasMoneyballSubscription = gon.isMoneyballEnabled || false;

export const supportEmail = gon.supportEmail;

export function hasRequiredInfo(stakeholder) {
  const { firstName, lastName } = stakeholder || {};

  return (!isEmpty(firstName) && !isEmpty(lastName));
}
