import React, { FC } from 'react';

import { RecentVisit } from 'components/UnifiedSearch/types';

import Icon from './components/Icon';
import Title from './components/Title';
import Subtitle from './components/Subtitle';
import ActionIcons from './components/ActionIcons';
import styles from './styles.module.scss';

type RecentCardProps = {
  recentVisit: RecentVisit,
  isMobile?: boolean,
  onRemoveRecentVisit: (id: number) => void
};

const RecentCard: FC<RecentCardProps> = ({
  recentVisit,
  isMobile,
  onRemoveRecentVisit,
}) => {
  const handleOpenRecent = () => {
    window.location.href = recentVisit.payload.url as string;
  };

  const removeRecentVisit = () => {
    onRemoveRecentVisit(recentVisit.id);
  };

  return (
    <div
      className={styles.recentCard}
      onClick={handleOpenRecent}
      onKeyDown={handleOpenRecent}
    >
      <div className={styles.main}>
        <Icon slug={recentVisit.payload.slug} photoUrl={recentVisit.payload.photoUrl} />
        <div>
          <Title title={recentVisit.payload.title} party={recentVisit.payload.party} isMobile={isMobile} />
          <Subtitle recentVisit={recentVisit.payload} />
        </div>
      </div>
      <div className={styles.icons}>
        <ActionIcons
          onRemove={() => removeRecentVisit()}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
};

export default RecentCard;
