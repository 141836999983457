import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DrillDown from 'components/Navigation/common/DrillDown';
import Loader from 'components/core/Loader';
import { mobileCategoryShape } from 'components/NavigationV2/utils/directory/shapes';

import Links from './components/Links';
import Categories from './components/Categories';
import styles from './styles.module.scss';

function Directory({
  isLoading,
  startLoading,
  categories,
  activeCategory,
  setActiveId,
}) {
  const hasActiveCategory = !!activeCategory;
  const key = hasActiveCategory ? activeCategory.id : 'categories';
  const [isBackDirection, setIsBackDirection] = useState(hasActiveCategory);

  const getList = () => {
    if (hasActiveCategory) {
      return (
        <Links
          activeCategory={activeCategory}
          startLoading={startLoading}
          onReturn={() => setActiveId(null)}
          setIsBackDirection={setIsBackDirection}
        />
      );
    }

    return (
      <Categories
        categories={categories}
        setActiveId={setActiveId}
        setIsBackDirection={setIsBackDirection}
      />
    );
  };

  return (
    <DrillDown
      isFullHeight
      id={key}
      isBackDirection={isBackDirection}
      className={styles.drillDown}
    >
      <>
        {getList()}

        {isLoading && (
          <div className={styles.loadingOverlay}>
            <Loader onlyIcon />
          </div>
        )}
      </>
    </DrillDown>
  );
}

Directory.defaultProps = {
  isLoading: false,
  startLoading: () => {},
};

Directory.propTypes = {
  isLoading: PropTypes.bool,
  startLoading: PropTypes.func,
  categories: PropTypes.arrayOf(mobileCategoryShape).isRequired,
  activeCategory: mobileCategoryShape,
  setActiveId: PropTypes.func.isRequired,
};

export default Directory;
