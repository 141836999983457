import { useState, ChangeEvent, useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';

import useDebounce from 'components/DelayedInput/useDebounce';
import { AdvancedOptions } from 'components/UnifiedSearch/types';
import useMountedRef from 'utils/useMountedRef';

import { CUSTOM_LOCALE_DEFAULT } from '../../constants';
import { Locale, Status } from './types';
import api from './api';

const useLocales = (advancedOptions: AdvancedOptions) => {
  const isMounted = useMountedRef();

  const { region, customRegions } = advancedOptions.values;
  const isSelectedRegion = region === 'local';
  const initialSelectedLocales = isSelectedRegion ? customRegions : [CUSTOM_LOCALE_DEFAULT];
  const [selectedLocales, setSelectedLocales] = useState<Locale[]>(initialSelectedLocales);

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<Status>('idle');
  const [locales, setLocales] = useState<Locale[]>([]);
  const debouncedSearch = useDebounce(search);
  const latestSearch = useRef<Promise<Locale[]>>();

  const reset = (searchStatus: Status = 'idle') => {
    setStatus(searchStatus);
    setLocales([]);
  };

  const searchLocales = async (query: string) => {
    if (isEmpty(query)) {
      reset();
      return;
    }

    reset('loading');

    const currentSearch = api.getLocales(query);
    latestSearch.current = currentSearch;
    const response = await currentSearch;

    // If the user changes the filter/query, a new search is performed.
    // If there is a previous search running we can end up with concurrency issues.
    // This will make sure that we will display only the results for the latest search.
    const isResponseOutdated = currentSearch !== latestSearch.current;

    if (!isMounted || isResponseOutdated) return;

    setLocales(response);
    setStatus('idle');
  };

  useEffect(() => {
    searchLocales(debouncedSearch);
  }, [debouncedSearch]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return {
    search,
    status,
    options: locales,
    handleSearchChange,
    selectedLocales,
    setSelectedLocales,
  };
};

export default useLocales;
