import * as featureSlugs from 'utils/constants/featureSlugs';

export const resultLabels = {
  [featureSlugs.STAKEHOLDER_SLUG]: 'Stakeholder',
  [featureSlugs.CONGRESSIONAL_STAFFERS_SLUG]: 'Congressional staffer',
  [featureSlugs.FEDERAL_AGENCY_STAFFERS_SLUG]: 'Federal agency staffer',
  [featureSlugs.STATE_STAFFERS_SLUG]: 'State staffer',
  [featureSlugs.FEDERAL_LEGISLATORS_SLUG]: 'Federal legislator',
  [featureSlugs.STATE_LEGISLATORS_SLUG]: 'State legislator',
  [featureSlugs.FEDERAL_EXECUTIVE_SLUG]: 'Federal executive',
  [featureSlugs.STATE_EXECUTIVE_SLUG]: 'State executive',
  [featureSlugs.STATE_STAFFER_EXECUTIVE_SLUG]: 'State executive staffer',
  [featureSlugs.LOCAL_EXECUTIVE_SLUG]: 'Local executive',
  [featureSlugs.LOCAL_MUNICIPAL_EXECUTIVE_SLUG]: 'Municipal executive',
  [featureSlugs.LOCAL_COUNTY_EXECUTIVE_SLUG]: 'County executive',
  [featureSlugs.FEDERAL_COMMITTEE_SLUG]: 'Federal committee',
  [featureSlugs.STATE_COMMITTEE_SLUG]: 'State committee',
  [featureSlugs.FEDERAL_BILLS_SLUG]: 'Federal bill',
  [featureSlugs.STATE_BILLS_SLUG]: 'State bill',
  [featureSlugs.FEDERAL_REGULATIONS_SLUG]: 'Federal regulation',
  [featureSlugs.STATE_REGULATIONS_SLUG]: 'State regulation',
  [featureSlugs.AGENCY_PRESS_RELEASES_SLUG]: 'Agency press release',
  [featureSlugs.CBO_COST_ESTIMATES_SLUG]: 'CBO cost estimate',
  [featureSlugs.COMMITTEE_REPORTS_SLUG]: 'Committee report',
  [featureSlugs.CONGRESSIONAL_PRESS_RELEASES_SLUG]: 'Congressional press release',
  [featureSlugs.CONGRESSIONAL_RECORD_ITEMS_SLUG]: 'Congressional record item',
  [featureSlugs.CONTRACTS_SLUG]: 'Contract',
  [featureSlugs.CRS_REPORTS_SLUG]: 'CRS report',
  [featureSlugs.DEAR_COLLEAGUE_LETTERS_SLUG]: 'Dear Colleague letter',
  [featureSlugs.EXECUTIVE_ORDERS_SLUG]: 'Executive order',
  [featureSlugs.GAO_REPORTS_SLUG]: 'GAO report',
  [featureSlugs.GRANTS_SLUG]: 'Grant',
  [featureSlugs.LD2_REPORTS]: 'LD-2 reports',
  [featureSlugs.LIVE_HEARING_TRANSCRIPTS_SLUG]: 'Live hearing transcript',
  [featureSlugs.OFFICIAL_HEARING_TRANSCRIPTS_SLUG]: 'Official hearing transcript',
  [featureSlugs.SAP_REPORTS_SLUG]: 'SAP report',
  [featureSlugs.STATE_OF_UNION_ADDRESSES_SLUG]: 'State of the Union address',
  [featureSlugs.TREATY_DOCUMENTS_SLUG]: 'Treaty document',
  [featureSlugs.THIRD_PARTY_REPORTS_SLUG]: 'Third party reports',
  [featureSlugs.WHITE_HOUSE_PRESS_BRIEFINGS_SLUG]: 'White House press briefing',
  [featureSlugs.GOVERNOR_ORDERS_SLUG]: 'Governor order',
  [featureSlugs.GOVERNOR_PRESS_RELEASES_SLUG]: 'Governor press releases',
  [featureSlugs.TWITTER_SLUG]: 'Twitter',
};
