import factories from './factories';
import { RequestParams, Response } from './types';

const GLOBAL_SEARCH_V2_URL = '/api_web/global_search_v2/search/lookup';

type FetchResults = (params: RequestParams) => Promise<Response>;

const fetchResults: FetchResults = async (params) => {
  const response = await $.ajax({
    method: 'POST',
    url: GLOBAL_SEARCH_V2_URL,
    data: factories.buildARequestData(params),
    contentType: 'application/json',
  });

  return factories.buildResponse(response);
};

export default {
  fetchResults,
};
