import React, { FC } from 'react';
import classNames from 'classnames';

import { FILTER_OPTIONS } from 'components/UnifiedSearch/utils/constants';

import {
  AdvancedOptions as AdvancedOptionsType,
  Filters as FiltersType,
} from '../../types';
import AdvancedOptionsButton from '../AdvancedOptionsButton';
import AdvancedOptions from '../AdvancedOptions';
import styles from './styles.module.scss';

type FiltersProps = {
  filters: FiltersType,
  advancedOptions: AdvancedOptionsType,
};

const Filters: FC<FiltersProps> = ({ filters, advancedOptions }) => (
  <div className={styles.filtersContainer}>
    <div className={styles.filters}>
      {FILTER_OPTIONS.map(item => (
        <button
          type="button"
          key={item.value}
          className={classNames(styles.filterItem, item.value === filters.value && styles.active)}
          onKeyDown={event => (event.key === 'Enter' ? filters.change(item.value) : null)}
          onClick={() => filters.change(item.value)}
          data-name={item.label}
        >
          <div className={styles.filterLabel}>
            {item.label}
          </div>
        </button>
      ))}

      <div className={styles.divider} />

      <AdvancedOptionsButton advancedOptions={advancedOptions} />
    </div>

    <AdvancedOptions advancedOptions={advancedOptions} filters={filters} />
  </div>
);

export default Filters;
