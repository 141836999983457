import useDebounce from 'components/DelayedInput/useDebounce';

import { AdvancedOptions, Filters as FiltersType, RecentVisits, Search } from './types';
import useSearch from './useSearch';
import useFilters from './useFilters';
import useRecentVisits from './useRecentVisits';
import useAdvancedOptions from './useAdvancedOptions';

type UseUnifiedSearchProps = {
  searchQuery: string;
};

type UseUnifiedSearch = {
  filters: FiltersType;
  search: Search;
  recentVisits: RecentVisits;
  debouncedSearchQuery: string;
  advancedOptions: AdvancedOptions;
};

function useUnifiedSearch({
  searchQuery,
}: UseUnifiedSearchProps): UseUnifiedSearch {
  const DEBOUNCE_SEARCH_QUERY_DELAY = 800;
  const debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCE_SEARCH_QUERY_DELAY);
  const advancedOptions = useAdvancedOptions();
  const filters = useFilters(advancedOptions);
  const search = useSearch({ searchQuery: debouncedSearchQuery, filters, advancedOptions });
  const recentVisits = useRecentVisits();

  return {
    filters,
    search,
    recentVisits,
    debouncedSearchQuery,
    advancedOptions,
  };
}

export default useUnifiedSearch;
