import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import negate from 'lodash/negate';
import isEmpty from 'lodash/isEmpty';

import { getSearchTermsRegExp } from 'components/GlobalSearch/utils';

import styles from '../styles.module.scss';

function HighlightedTerm({ label, inputValue }) {
  const highlightMatchedTerm = (regExp) => (term, index) => {
    const termMatchesSearch = term.match(regExp);
    const termClassName = classNames(
      'test__term',
      termMatchesSearch && styles.highlightedTerm,
    );

    return (
      <span key={`${term}${index}`} className={termClassName}>
        {term}
      </span>
    );
  };

  const searchInputRegExp = getSearchTermsRegExp(inputValue);
  const splittedTerms = label.split(searchInputRegExp).filter(negate(isEmpty));

  const formattedLabel = splittedTerms.map(highlightMatchedTerm(searchInputRegExp));

  return (
    <span className={styles.resultTitle}>
      {formattedLabel}
    </span>
  );
}

HighlightedTerm.propTypes = {
  label: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
};

export default HighlightedTerm;
