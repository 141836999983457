const dataCommitteeTypes = [
  { value: 'house', label: 'House' },
  { value: 'senate', label: 'Senate' },
  { value: 'presidential', label: 'Presidential' },
  { value: 'other_campaign_committees', label: 'All other campaign committees' },
  { value: 'party', label: 'Party' },
  { value: 'pac', label: 'PAC' },
  { value: 'super_pac', label: 'Super PAC' },
  { value: 'leadership_pac', label: 'Leadership PAC' },
  { value: 'joint_fundraising_committee', label: 'Joint fundraising committee' },
  { value: '527_org', label: '527 Org' },
  { value: 'other', label: 'Other' },
];

export default dataCommitteeTypes;
