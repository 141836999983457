import { useRef, useEffect } from 'react';

export default function useErrorStepRedirect({ submitCount, errors, isValid, onRedirect, steps }) {
  const submitCountRef = useRef(submitCount);
  const firstErrorKey = Object.keys(errors)[0];

  useEffect(() => {
    if (submitCountRef.current !== submitCount && !isValid && firstErrorKey) {
      submitCountRef.current = submitCount;
      const filteredErrorStep = steps.filter(step => step.errorFields.includes(firstErrorKey))[0] || {};
      const modalStep = filteredErrorStep.index || 0;

      onRedirect(modalStep);
    }
  }, [submitCount, isValid, firstErrorKey]);
}
