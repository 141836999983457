import React, { Dispatch, FC, KeyboardEvent, SetStateAction } from 'react';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';

import { getRecordType, getSlugLabel } from 'components/UnifiedSearch/useSearch/helpers';
import { OnSaveTagsSuccessProps } from 'components/core/ButtonTagManager/types';
import ButtonTagManager from 'components/core/ButtonTagManager';
import ButtonShare from 'components/core/ButtonShare';
import TrackButton from 'components/core/TrackButton';
import { Result } from 'components/UnifiedSearch/types';

import styles from './styles.module.scss';

type ResultTooltipProps = {
  result: Result,
  isActiveTooltip: boolean,
  setIsActiveTooltip: Dispatch<SetStateAction<boolean>>,
  onUpdateTagItems: (item: OnSaveTagsSuccessProps) => void,
  isTrackable: boolean,
};

const ResultTooltip: FC<ResultTooltipProps> = ({
  result,
  isActiveTooltip,
  setIsActiveTooltip,
  onUpdateTagItems,
  isTrackable,
}) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => (
    event.key === 'Enter' ? setIsActiveTooltip(!isActiveTooltip) : null
  );

  const ToolTipContent = () => (
    <div className={styles.tooltip}>
      <ButtonTagManager
        theme="light"
        buttonClassName={styles.tooltipItem}
        recordId={result.id}
        recordType={getRecordType(result.slug)}
        ids={undefined}
        label={undefined}
        onSaveTags={undefined}
        isMultipleItems={undefined}
        isLoading={undefined}
        withAjaxAbort={undefined}
        autoload={undefined}
        updateDataItem={onUpdateTagItems}
        isOpen={undefined}
        CustomButtonComponent={undefined}
        disabled={undefined}
        customIsOpenModal={undefined}
        customToggleModal={undefined}
        itemType={getSlugLabel(result.slug)}
      />
      <ButtonShare
        recordId={result.id}
        recordType={getRecordType(result.slug)}
        theme="light"
        className={styles.tooltipItem}
        itemUrl={result.url}
        itemName={result.title}
        withModal
        isOpen={undefined}
        options={undefined}
        itemType={undefined}
        onSuccess={undefined}
      />
      {isTrackable && (
        <TrackButton
          isTracking={!!result.trackings.length}
          className={styles.tooltipItem}
          recordId={result.id}
          recordType={getRecordType(result.slug)}
          itemType={getSlugLabel(result.slug)}
          itemTitle={result.title}
          withButtonContent
          updateDataItem={item => result.updateTrackings?.(item.trackings)}
          trackings={result.trackings}
          shouldDisplayIcon={false}
        />
      )}
    </div>
  );
  return (
    <Tooltip
      className={styles.tooltip}
      content={ToolTipContent()}
      theme="light"
      placement="bottom-end"
      visible={isActiveTooltip}
      onClickOutside={visible => setIsActiveTooltip(!visible)}
      arrow={false}
      offset={[2, 5]}
    >
      <div
        onClick={() => setIsActiveTooltip(!isActiveTooltip)}
        onKeyDown={handleKeyDown}
        className={classNames(styles.dots, isActiveTooltip && styles.active)}
      >
        <i
          className="far fa-ellipsis-v"
        />
      </div>
    </Tooltip>
  );
};

export default ResultTooltip;
