import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'utils/Link';
import { BASE_URL } from 'components/Dashboard/common/FeedPage/utils/constants';

import styles from '../styles.module.scss';

const linkThemes = {
  light: styles.lightTheme,
};

function WorkspaceLink({ theme, label, url, className }) {
  return (
    <Link
      className={classNames(
        styles.baseLink,
        linkThemes[theme],
        className,
      )}
      theme="default"
      href={url}
    >
      {label}
    </Link>
  );
}

WorkspaceLink.defaultProps = {
  label: 'My feed',
  url: `${BASE_URL}/feed`,
};

WorkspaceLink.propTypes = {
  label: PropTypes.string,
  theme: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
};

export default WorkspaceLink;
