import { get } from 'lodash';

import { ApiResult } from 'components/UnifiedSearch/useSearch/types';
import { HighlightedString } from 'components/UnifiedSearch/types';

import { getHighlightedFields } from '../../base';

type Highlights = {
  title?: HighlightedString,
  description?: HighlightedString,
};

export const getHighlights = (result: ApiResult): Highlights => {
  const highlightedFields = getHighlightedFields(result);

  const [title] = get(highlightedFields, 'title', []);
  const [text] = get(highlightedFields, 'text', []);
  const [textFiles] = get(highlightedFields, 'text_files.text', []);
  const [specificIssue] = get(highlightedFields, 'specific_issue', []);
  const [description] = get(highlightedFields, 'description', []);

  return {
    title,
    description: text || textFiles || specificIssue || description,
  };
};
