import React from 'react';
import PropTypes from 'prop-types';

import TrackFrequencyContent from 'components/Dashboard/common/TrackFrequencyContent';
import { NONE_ID } from 'components/core/FrequencyOptions/constants';
import FrequencyCard from 'components/Dashboard/common/FrequencyCard';

import styles from './styles.module.scss';

function SubscribeOption({
  isMobile,
  isChecked,
  withTitle,
  label,
  value,
  isLoading,
  withWeekAheadEmail,
  sendWeekAheadEmail,
  onChange,
  onCheck,
  onCheckCard,
  withFrequency,
}) {
  function handleCheck({ target: { checked } }) {
    onCheck(checked);
  }

  function handleCheckCard({ target: { checked } }) {
    onCheckCard(checked);
  }

  const hasFrequencyContent = withWeekAheadEmail || withFrequency;

  return (
    <FrequencyCard
      label={label}
      withTitle={withTitle}
      isChecked={isChecked}
      isDisabled={isLoading || false}
      onCheck={handleCheckCard}
      hideChildren={!hasFrequencyContent}
    >
      <TrackFrequencyContent
        isMobile={isMobile}
        isLoading={isLoading}
        frequency={value || NONE_ID}
        withWeekAheadEmail={withWeekAheadEmail}
        sendWeekAheadEmail={sendWeekAheadEmail}
        onChangeSendEmail={handleCheck}
        onChange={onChange}
        withFrequency={withFrequency}
        groupClassName={styles.buttonGroup}
        buttonClassName={styles.frequencyButton}
      />
    </FrequencyCard>
  );
}

SubscribeOption.propTypes = {
  isChecked: PropTypes.bool,
  isMobile: PropTypes.bool,
  withTitle: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  withWeekAheadEmail: PropTypes.bool,
  sendWeekAheadEmail: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onCheck: PropTypes.func,
  onCheckCard: PropTypes.func,
  withFrequency: PropTypes.bool.isRequired,
};

export default SubscribeOption;
