import React from 'react';

import styles from './styles.module.scss';

export default function LoadingWarning() {
  return (
    <p className={styles.warning}>
      Loading...
    </p>
  );
}
