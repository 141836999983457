import GlobalNotifier from 'components/GlobalNotifier';

const addNotification = GlobalNotifier.addNotification;

const success = message => addNotification({ message, level: 'success' });
const error = message => addNotification({ message, level: 'error' });

export default class Notifications {
  static defaultFetchDataFailed = () => error('An error occurred during the data loading. Please try again.');
  static defaultSubmitDataFailed = () => error('Oops, something went wrong. Please try again. If this issue persists please contact your customer success representative.');
  static exportEventSuccess = () => success('Data has been exported successfully.');
  static exportEventFailed = () => error('Data export failed. Please try again.');
}
