import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import StaffersMailer from 'components/StaffersMailer';
import PartyIcon from 'components/shared/party-icon';

function StyledStaffersMailer(props) {
  const mailerClassName = classnames({
    'legislator-link__mailer': true,
    'legislator-link__mailer--extra-side-margin': props.extraSideMargin,
  });

  if (props.legislationLevel !== 'federal') {
    return <span />;
  }

  return (
    <span className={mailerClassName}>
      <StaffersMailer legislatorId={props.legislatorId} />
    </span>
  );
}

StyledStaffersMailer.propTypes = {
  legislatorId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  legislationLevel: PropTypes.string.isRequired,
  extraSideMargin: PropTypes.bool,
};

StyledStaffersMailer.defaultProps = {
  extraSideMargin: false,
};

function legislatorPath(legislatorId, legislationLevel) {
  switch (legislationLevel) {
    case 'federal':
      // uses: federal_legislator_path
      return `/federal/legislators/${legislatorId}`;
    case 'state':
      // uses: state_legislator_path
      return `/state/legislators/${legislatorId}`;
    default:
      throw TypeError(`Legislation type: ${legislationLevel} not found`);
  }
}


// This component should implement same as LegislationHelper link_to_legislator
export default function LinkToLegislator(props) {
  const {
    id,
    party_slug,
    party_short,
    universal_moniker,
  } = props.legislator;

  const legislatorLink = legislatorPath(id, props.legislationLevel);

  const handleClick = (event) => {
    const { onClick } = props;
    onClick();
    event.stopPropagation();
  };

  return (
    <span className={classnames('legislator-link', props.className)}>
      <a href={legislatorLink} onClick={handleClick}>
        <span className="legislator-link__universal_moniker">
          {universal_moniker}
        </span>

        {party_slug && party_short &&
          <PartyIcon
            extraSideMargin={props.extraSideMargin}
            partySlug={party_slug}
            partyShort={party_short}
          />
        }
      </a>

      {props.showEnvelope &&
        <StyledStaffersMailer
          legislatorId={id}
          extraSideMargin={props.extraSideMargin}
          legislationLevel={props.legislationLevel}
        />
      }
    </span>
  );
}

LinkToLegislator.propTypes = {
  onClick: PropTypes.func,
  showEnvelope: PropTypes.bool,
  legislator: PropTypes.object.isRequired,
  legislationLevel: PropTypes.string.isRequired,
  extraSideMargin: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

LinkToLegislator.defaultProps = {
  showEnvelope: true,
  extraSideMargin: false,
  onClick: () => {},
};
