import { get } from 'lodash';

import { ApiResult } from 'components/UnifiedSearch/useSearch/types';
import { HighlightedString } from 'components/UnifiedSearch/types';

import { getHighlightedFields } from '../../base';

type Highlights = {
  state?: HighlightedString,
  title?: HighlightedString,
  agencyTitle?: HighlightedString,
  text?: HighlightedString,
};

export const getHighlights = (result: ApiResult): Highlights => {
  const highlightedFields = getHighlightedFields(result);

  const [state] = get(highlightedFields, 'state.alpha2', []);
  const [title] = get(highlightedFields, 'title', []);
  const [agencyTitle] = get(highlightedFields, 'agencies.title', []);
  const [text] = get(highlightedFields, 'regulation_documents.text', []);

  return { state, title, agencyTitle, text };
};
