export const BASE_URL = '/moneyball_v2';

export const REQUEST_CONTENT_TYPE = 'application/json';

export const DISBURSEMENTS = 'disbursements';
export const CANDIDATES = 'candidates';
export const RECEIPTS = 'receipts';

export const COMMITTEE = 'committee';
export const INDIVIDUAL = 'individual';
export const CORPORATION = 'corporation';
export const VENDOR = 'vendor';
export const BOTH = 'both';
export const ALL = 'all';
export const RECEIVED = 'received';
export const DISBURSED = 'disbursed';

export const REPUBLICAN = 'republican';
export const DEMOCRATIC = 'democrat';

export const TOTAL = 'total';

export const STATE = 'state';
export const LOCAL = 'local';
export const FEC = 'fec';
export const IRS = 'irs';

export const PRIMARY = 'primary';
export const GENERAL = 'general';
export const SPECIAL = 'special';

export const HOUSE = 'house';
export const SENATE = 'senate';
export const STATE_UPPER = 'state_upper';
export const STATE_LOWER = 'state_lower';
export const PRESIDENT = 'president';
export const GOVERNOR = 'governor';
export const OTHER = 'other';
export const CAMPAIGN_COMMITTEE = 'campaign_committee';
export const PARTY_COMMITTEE = 'party_committee';
export const PACS = 'pacs';
export const NOT_PACS = 'not_pacs';
export const CANDIDATE = 'candidate';

export const MONEYBALL_COMBINE_INDIVIDUALS_VIEW_URL = `${BASE_URL}/individuals/combine`;
export const MONEYBALL_COMBINE_CORPORATION_VIEW_URL = `${BASE_URL}/corporations/combine`;
export const MONEYBALL_CLUSTERBUCK_COMBINE_FIRST_QUEUE_URL = `${BASE_URL}/clusterbuck/firstqueue/combine`;
export const MONEYBALL_CLUSTERBUCK_COMBINE_SECOND_QUEUE_URL = `${BASE_URL}/clusterbuck/secondqueue/combine`;

const titlePrefix = 'Intelligence - Donor research';

export const MONEYBALL_TITLES = {
  homepage: titlePrefix,
  vendors: `${titlePrefix} - Vendors`,
  receipts: `${titlePrefix} - Receipts`,
  candidates: `${titlePrefix} - Candidates`,
  committees: `${titlePrefix} - Committees`,
  pacRanking: `${titlePrefix} - PAC ranking`,
  individuals: `${titlePrefix} - Individuals`,
  smartSearch: `${titlePrefix} - Smart Search`,
  corporations: `${titlePrefix} - Corporations`,
  disbursements: `${titlePrefix} - Disbursements`,
  clusterBuck: `${titlePrefix} - Cluster Buck`,
};
