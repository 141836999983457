import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MenuTransition from 'components/Navigation/common/MenuTransition';

import ActiveMenu from './ActiveMenu';
import styles from './styles.module.scss';

function ApplicationMenu({ isAppMenuOpen, disabledProducts, isFirstPageLoad }) {
  const transitionMenuId = isAppMenuOpen ? 'openId' : 'closedId';

  const appMenuSectionClassNames = classNames(
    styles.appMenu,
    isAppMenuOpen && styles.appMenuActive,
  );

  return (
    <MenuTransition id={transitionMenuId} enable={!isFirstPageLoad}>
      <section id="app-menu" className={appMenuSectionClassNames}>
        <ActiveMenu disabledProducts={disabledProducts} />
      </section>
    </MenuTransition>
  );
}

ApplicationMenu.propTypes = {
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ),
  isFirstPageLoad: PropTypes.bool.isRequired,
  isAppMenuOpen: PropTypes.bool.isRequired,
};

export default ApplicationMenu;
