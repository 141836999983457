import { isNumber, isString } from 'lodash';

import { FEDERAL_COMMITTEES_TYPE, STATE_COMMITTEES_TYPE } from 'redux/dashboardV2/constants';
import { EMPTY_VALUE } from 'utils/constants';

import { DOCUMENT_TYPES, RECENT, TRACKED } from './constants';

export function formatString(data) {
  return (isNumber(data) || isString(data)) ? data : EMPTY_VALUE;
}

export function getSubscribableType({ documentType, itemType }) {
  const { [documentType]: committeeItemType } = {
    [DOCUMENT_TYPES.FEDERAL_COMMITTEE]: FEDERAL_COMMITTEES_TYPE,
    [DOCUMENT_TYPES.STATE_COMMITTEE]: STATE_COMMITTEES_TYPE,
  };

  return committeeItemType || itemType;
}

export function getEventType(queryEventType, eventType) {
  if (queryEventType) {
    return queryEventType === RECENT ? RECENT : TRACKED;
  }

  return eventType;
}
