import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isItemLocked, getExecutiveSlug } from '../../utils';
import { customOptionsShape, EXECUTIVE_PERSON_SLUG } from '../../utils/constants';
import withGlobalSearch from '../../utils/withGlobalSearch';
import Label from './components/Label';
import styles from './styles.module.scss';
import Byline from './components/Byline';

function Result({
  label,
  strategy,
  url,
  inputValue,
  isFocused,
  customOptions,
  disabledProducts,
  labelClassName,
  className,
  onDelete,
  onClick,
}) {
  // TODO: remove adjusted strategy when legacy strategy results are removed from users local storages
  const isLegacyStrategy = strategy === EXECUTIVE_PERSON_SLUG;
  const adjustedStrategy = isLegacyStrategy ? getExecutiveSlug(customOptions) : strategy;
  const isLocked = isItemLocked({ strategy: adjustedStrategy }, disabledProducts);

  const handleClick = () => {
    onClick && onClick(isLegacyStrategy);
  };

  const resultClassNames = classNames(
    styles.result,
    isFocused && styles.focusedResult,
    isLocked && styles.locked,
    className,
  );

  return (
    <div
      onKeyDown={handleClick}
      onClick={handleClick}
      className={resultClassNames}
    >
      <Label
        label={label}
        url={url}
        inputValue={inputValue}
        customOptions={customOptions}
        isLocked={isLocked}
        className={labelClassName}
        onDelete={onDelete}
      />
      <Byline
        strategy={adjustedStrategy}
        customOptions={customOptions}
      />
    </div>
  );
}

Result.defaultProps = {
  customOptions: {},
  isFocused: false,
  inputValue: '',
};

Result.propTypes = {
  label: PropTypes.string.isRequired,
  strategy: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  isFocused: PropTypes.bool,
  onClick: PropTypes.func,
  customOptions: customOptionsShape,
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  onDelete: PropTypes.func,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
};

export default withGlobalSearch(Result);
