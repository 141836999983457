import React from 'react';
import PropTypes from 'prop-types';
import partial from 'lodash/partial';

import FrequencyCard from 'components/Dashboard/common/FrequencyCard';
import { WORKSPACE_SUGGESTER_STRATEGY } from 'components/Dashboard/Workspaces/utils/constants';
import NewWorkspaceModal from 'components/Dashboard/Workspaces/components/NewWorkspaceModal';
import { BaseOption } from 'components/core/AdvancedSearch';
import { workspacesShape } from 'components/core/TrackButton/shapes';
import { formatCreateLabel, getNewOptionData } from 'components/core/ButtonTagManager/utils';

import useWorkspacesSelect from './utils/useWorkspacesSelect';
import styles from './styles.module.scss';

function WorkspacesSelect({ workspaces, children, asyncCreatable, onToggleNewWorkspaceModal }) {
  const {
    isOpenModal,
    onToggleModal,
    newWorkspaceName,
    handleChange,
    handleSuccess,
  } = useWorkspacesSelect(workspaces, onToggleNewWorkspaceModal);

  return (
    <>
      <FrequencyCard
        label="Save to workspaces"
        onCheck={workspaces.onCheckWorkspaces}
        isChecked={workspaces.isWorkspacesChecked}
        withTitle={false}
        checkboxClassName="workspaces-select__checkbox"
      >
        {children}
        <BaseOption
          multi
          noGroupClassName
          title="Workspace(s)"
          autoload
          overrideValue={workspaces.values}
          strategy={WORKSPACE_SUGGESTER_STRATEGY}
          onChange={handleChange}
          className={styles.option}
          asyncCreatable={asyncCreatable}
          formatCreateLabel={partial(formatCreateLabel, partial.placeholder, 'workspace')}
          getNewOptionData={getNewOptionData}
          errorMessage={workspaces.errorMessage}
        />
      </FrequencyCard>
      <NewWorkspaceModal
        isOpen={isOpenModal}
        toggleModal={onToggleModal}
        initialName={newWorkspaceName}
        onSuccess={handleSuccess}
      />
    </>
  );
}

WorkspacesSelect.defaultProps = {
  asyncCreatable: false,
};

WorkspacesSelect.propTypes = {
  workspaces: workspacesShape.isRequired,
  children: PropTypes.node,
  asyncCreatable: PropTypes.bool,
  onToggleNewWorkspaceModal: PropTypes.func,
};

export default WorkspacesSelect;
