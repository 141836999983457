import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export default class UploadButton extends React.Component {
  static propTypes = {
    accept: PropTypes.string,
    className: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    disabled: false,
  }

  // transform { 0: file1, 1: file2 } into
  // [file1, file2]
  handleChange = (event) => {
    const { files } = event.target;

    const wrappedFiles = Object.keys(files).map(key => files[key]);

    this.props.handleChange(wrappedFiles);
  }

  // bug-fix, onChange doesnt work when selecting same file
  // for second time
  // eslint-disable-next-line no-param-reassign
  handleClick = (event) => { event.target.value = null; }

  render() {
    const { accept, className, text, disabled, multiple } = this.props;

    const buttonClassName = classNames({
      [className]: true,
      disabled,
    });

    // we use <label> with a btn class instead of a real button
    // to trigger the input-file click when label is clicked
    return (
      <label className={buttonClassName}>
        <i className="far fa-plus" />
        &nbsp;

        { text }

        <input
          type="file"
          className="royal-file-uploader__input"
          accept={accept}
          onChange={this.handleChange}
          onClick={this.handleClick}
          multiple={multiple}
        />
      </label>
    );
  }
}
