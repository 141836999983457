import { US_COUNTRY_CODE, US_COUNTRY } from 'utils/constants/addresses';
import { LOCATION_TYPES } from 'utils/constants/locationTypes';
import { TYPES } from 'components/Stakeholder/Lookup/components/Options/utils/constants';

export default function getDistrictFilters({
  isFederalSenator,
  state,
  stateName,
  districtName,
  districtId,
  chamberCategory,
}) {
  if (!isFederalSenator) {
    return {
      options: {
        districts: [
          {
            value: districtId,
            label: districtName,
          },
        ],
        districtType: TYPES[chamberCategory] || TYPES.congressional,
      },
    };
  }

  return {
    options: {
      locationType: LOCATION_TYPES.states,
      isPrimaryLocation: true,
      locations: [
        {
          label: `${stateName} (${US_COUNTRY})`,
          // TODO: update when fix suggester value (should include country code)
          value: state,
          filters: {
            state_code: state,
            country_code: US_COUNTRY_CODE,
          },
        },
      ],
    },
  };
}
