import { useState } from 'react';

function useModals(currentStep = 0) {
  const [isStakeholderModalOpen, setIsStakeholderModalOpen] = useState(false);
  const [stakeholderModalStep, setStakeholderModalStep] = useState(currentStep);

  function toggleStakeholderModal() {
    setIsStakeholderModalOpen(!isStakeholderModalOpen);
  }

  function handleStakeholderModalOpen() {
    setIsStakeholderModalOpen(true);
  }

  function handleStakeholderModalClose() {
    setIsStakeholderModalOpen(false);
  }

  function handleChangeStakeholderModalStep(step) {
    setStakeholderModalStep(step);
  }

  return {
    stakeholderModalStep,
    isStakeholderModalOpen,
    toggleStakeholderModal,
    handleChangeStakeholderModalStep,
    handleStakeholderModalClose,
    handleStakeholderModalOpen,
  };
}

export default useModals;
