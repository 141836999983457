import { useState, useMemo, useEffect } from 'react';

import { PEOPLE_ID } from 'components/NavigationV2/utils/directory/constants';
import { getReducedCategories, getIsActivePageCategory } from 'components/NavigationV2/utils/directory';

function useDirectory({
  disabledProducts,
  isVisible,
}) {
  const categories = useMemo(() => getReducedCategories(disabledProducts), [disabledProducts]);
  const activePageCategory = categories.find(
    category => getIsActivePageCategory(category.columns)
  );
  const initialActiveId = (activePageCategory && activePageCategory.id) || PEOPLE_ID;
  const [activeId, setActiveId] = useState(initialActiveId);
  const activeCategory = categories.find(({ id }) => activeId === id);

  useEffect(() => {
    !isVisible && setActiveId(initialActiveId);
  }, [isVisible]);

  return {
    activeId,
    setActiveId,
    categories,
    activeCategory,
  };
}

export default useDirectory;
