import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';

import { useHiddenItemsLength } from './useHiddenItemsLength';
import { DropdownContent } from './DropdownContent';
import { HiddenItemsDropdownComponent } from './HiddenItemsDropdown.types';
import styles from './styles.module.scss';

export const HiddenItemsDropdown: HiddenItemsDropdownComponent = ({ items = [], className }) => {
  const computeHiddenItems = (itemsLength: number) => (itemsLength ? items.slice(0, itemsLength) : []);

  const hiddenItemsLength = useHiddenItemsLength();
  const hiddenItems = useMemo(() => computeHiddenItems(hiddenItemsLength), [hiddenItemsLength]);

  return (
    <Tooltip
      content={<DropdownContent items={hiddenItems} />}
      trigger="click"
      placement="bottom-start"
      theme="light"
      arrow={false}
    >
      <button
        type="button"
        className={classNames(styles.dropdownButton, className)}
      >
        <i className="far fa-bars" />
      </button>
    </Tooltip>
  );
};
