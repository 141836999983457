import React, { FC, useState } from 'react';
import truncate from 'lodash/truncate';

import Link from 'components/core/Link';
import ViewTags from 'components/core/ViewTags';
import TrackButton from 'components/core/TrackButton';
import PositionButton from 'components/core/ButtonPosition';
import { OnSaveTagsSuccessProps, ResponseTag } from 'components/core/ButtonTagManager/types';
import { RESULT_TEXT_LIMITS, TRACKABLE_SLUGS } from 'components/UnifiedSearch/utils/constants';
import { Result as ResultType, Tag } from 'components/UnifiedSearch/types';
import { getRecordType, getSlugLabel } from 'components/UnifiedSearch/useSearch/helpers';
import PartyIcon from 'components/shared/party-icon';
import { isExternal } from 'utils/url';

import Tooltip from './components/Tooltip';
import { truncateHighlight } from './utils/helpers';
import { ACCEPTABLE_POSITION_RECORD_TYPES } from './utils/constants';
import styles from './styles.module.scss';

type ResultProps = {
  result: ResultType,
  isMobile?: boolean,
  onAddRecentVisit: (visited: ResultType) => Promise<void>
};

const Result: FC<ResultProps> = ({ result, onAddRecentVisit, isMobile }) => {
  const [isActiveTooltip, setIsActiveTooltip] = useState<boolean>(false);
  const recordType = getRecordType(result.slug);
  const isTrackable = TRACKABLE_SLUGS.includes(result.slug);

  const resultTags = result.tags.map(tag => ({
    value: tag.id, label: tag.name,
  }));

  const handleUpdateResultTags = (item: OnSaveTagsSuccessProps) => (
    result.updateTags && result.updateTags(
      item.tags && item.tags.map((tag: ResponseTag): Tag => ({
        id: tag.value, name: tag.label,
      })),
    )
  );

  const renderTitle = () => {
    const titleLength = isMobile ? RESULT_TEXT_LIMITS.titleMobile : RESULT_TEXT_LIMITS.title;

    if (result.titleHighlight) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: truncateHighlight(result.titleHighlight, titleLength),
          }}
        />
      );
    }

    return truncate(result.title, { length: titleLength });
  };

  const renderSubtitle = () => {
    if (result.subtitleHighlight) {
      return <span dangerouslySetInnerHTML={{ __html: result.subtitleHighlight }} />;
    }

    return result.subTitle;
  };

  const renderDescription = () => {
    if (result.descriptionHighlight) {
      return truncateHighlight(result.descriptionHighlight, RESULT_TEXT_LIMITS.description);
    }

    return truncate(result.description, { length: RESULT_TEXT_LIMITS.description });
  };

  const isExternalLink = isExternal(result.url);

  const handleResultClick = async (event: MouseEvent) => {
    event.preventDefault();
    await onAddRecentVisit(result);
    window.open(result.url, '_self');
  };

  return (
    <div className={styles.result}>
      <div className={styles.title}>
        <Link
          isExternal={isExternalLink}
          onClick={handleResultClick}
          href={result.url}
          theme="default"
          size={undefined}
          customIcon={undefined}
          rightIcon={undefined}
          className={undefined}
          isRouterLink={undefined}
          displayExternalIcon={undefined}
          iconClassName={undefined}
          disabled={undefined}
        >
          {renderTitle()}
        </Link>

        {result.party && (
          <PartyIcon partyShort={result.party} />
        )}
      </div>
      <div className={styles.subTitle}>
        {renderSubtitle()}
      </div>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: renderDescription() }}
      />
      <ViewTags tags={resultTags} />
      <div className={styles.status}>
        {isTrackable && (
          <TrackButton
            isTracking={!!result.trackings.length}
            className={styles.tooltipItem}
            recordId={result.id}
            recordType={recordType}
            itemType={getSlugLabel(result.slug)}
            itemTitle={result.title}
            withButtonContent
            updateDataItem={item => result.updateTrackings && result.updateTrackings(item.trackings)}
            trackings={result.trackings}
          />
        )}
        {ACCEPTABLE_POSITION_RECORD_TYPES.includes(recordType as string) && (
          <PositionButton
            recordId={result.id}
            recordType={recordType}
            containerClassName={styles.position}
            initialPosition={result.position}
            handleSetPosition={result.updatePosition}
          />
        )}
      </div>
      {!isMobile && (
        <Tooltip
          result={result}
          isActiveTooltip={isActiveTooltip}
          setIsActiveTooltip={setIsActiveTooltip}
          onUpdateTagItems={handleUpdateResultTags}
          isTrackable={isTrackable}
        />
      )}
    </div>
  );
};

export default Result;
