// @ts-nocheck
import React, { FC } from 'react';

import { TasksButton } from 'components/Grm2';
import HelpButton from 'components/core/HelpButton';
import Notifications from 'components/core/Notifications';
import HomeButton from 'components/Navigation/GlobalHeader/components/HomeButton';
import UserMenuButton from 'components/Navigation/GlobalHeader/components/UserMenuButton';
import withNavigation from 'components/Navigation/utils/withNavigation';
import { trackTasksClicked } from 'components/NavigationV2/utils/tracking';

import Search from '../Search';
import CreateButton from './components/CreateButton';
import styles from './styles.module.scss';

type SearchBarProps = {
  onSearchQueryChange: (inputText: string) => void;
  onFocus: () => void;
  onCloseUnifiedSearch: () => void;
  isUnifiedSearchOpen: boolean;
  isMobile?: boolean;
};

const SearchBar: FC<SearchBarProps> = ({
  onSearchQueryChange,
  onFocus,
  onCloseUnifiedSearch,
  isUnifiedSearchOpen,
  isMobile = false,
}) => (
  <div className={styles.container}>
    {!isMobile && <HomeButton className={styles.home} />}

    <Search
      isUnifiedSearchOpen={isUnifiedSearchOpen}
      className={styles.globalSearch}
      onCloseUnifiedSearch={onCloseUnifiedSearch}
      onSearchQueryChange={onSearchQueryChange}
      onFocus={onFocus}
      placeholder={
        isMobile ? undefined : 'Search documents, bills, regulations, staffers, legislators, etc.'
      }
    />

    {!isMobile && (
      <div className={styles.rightItems}>
        <CreateButton />
        {gon.isNotificationEnabled ? (
          <Notifications isMobile={false} className={styles.notifications} />
        ) : (
          <HelpButton />
        )}
        <TasksButton onClick={trackTasksClicked} />
        <UserMenuButton />
      </div>
    )}
  </div>
);

export default withNavigation(SearchBar);
