import React, { FC, useState } from 'react';
import classNames from 'classnames';

import { AdvancedOptions } from 'components/UnifiedSearch/types';
import DatePicker from 'components/core/DatePicker';
import Button from 'components/core/Button';

import { ActionsWrapper, ApplyButton, BackButton } from '../../../SlideUpModal';
import styles from './styles.module.scss';

type CustomPeriodProps = {
  advancedOptions: AdvancedOptions,
  toggleFilter: () => void,
  toggleSelectCustomPeriod: () => void,
  isMobile: boolean,
};

const CustomPeriod: FC<CustomPeriodProps> = ({
  advancedOptions,
  toggleFilter,
  toggleSelectCustomPeriod,
  isMobile,
}) => {
  const { customPeriod } = advancedOptions.values;
  const [startDate, setStartDate] = useState<Date | null>(customPeriod?.from || null);
  const [endDate, setEndDate] = useState<Date | null>(customPeriod?.to || null);

  const handleChange = ([start, end]: [Date, Date]) => {
    setStartDate(start);
    setEndDate(end);
  };

  const isCustomPeriodSet = startDate && endDate;

  const handleApply = () => {
    if (!isCustomPeriodSet) return;

    toggleFilter();

    advancedOptions.change({
      period: 'custom',
      customPeriod: { from: startDate, to: endDate },
    });
  };

  const renderActions = () => {
    if (isMobile) {
      return (
        <ActionsWrapper>
          <BackButton onClick={toggleSelectCustomPeriod} />

          <ApplyButton onClick={handleApply} isDisabled={!isCustomPeriodSet} />
        </ActionsWrapper>
      );
    }

    return (
      <Button
        theme="light"
        className={classNames(styles.applyButton, !isCustomPeriodSet && styles.applyDisabled)}
        onClick={handleApply}
        disabled={!isCustomPeriodSet}
      >
        Apply filter
      </Button>
    );
  };

  return (
    <div className={styles.content}>
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        className={classNames(styles.calendar, isMobile && styles.mobileCalendar)}
        selectsRange
        inline
      />

      {renderActions()}
    </div>
  );
};

export default CustomPeriod;
