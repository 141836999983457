import React from 'react';

import withContext from './context/hoc.jsx';
import UploadButton from './upload-button.jsx';

function Container() {
  return (
    <UploadButton />
  );
}

export default withContext(Container);
