import { Locale, ParsedLocale } from './types';

export const buildLocales = (locales: Locale[]) => (
  locales.map((locale) => {
    const parsed: ParsedLocale = JSON.parse(locale.value);

    return {
      label: `${parsed.city} (${parsed.state.code})`,
      value: parsed.city,
    };
  })
);
