import React from 'react';
import { bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';
import store from 'redux/store';
import * as Actions from 'redux/royal-file-uploader';
import Container from './components/container.jsx';

function mapStateToProps(state) {
  return { ...state.royalFileUploader };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const ConnectedContainer = connect(mapStateToProps, mapDispatchToProps)(Container);

export default function RoyalFileUploader(props) {
  return (
    <Provider store={store}>
      <ConnectedContainer {...props} />
    </Provider>
  );
}
