import slugs from 'components/Navigation/menuData/slugs';

import { RELATED_APPS_SECTION, STATE_SUMMARIES } from './common';

const FEDERAL_SECTION = {
  id: 'federal',
  title: 'Federal',
  items: [
    {
      title: 'Federal committees',
      description: 'Federal committee information',
      href: '/federal/committees',
      slug: slugs.federal,
    },
    {
      title: 'Federal hearings calendar',
      description: 'Past and present federal committee hearings',
      href: '/federal/committee_hearings_calendar',
      slug: slugs.federal,
    },
  ],
};

const STATE_SECTION = {
  id: 'state',
  title: 'State',
  items: [
    {
      title: 'State committees',
      description: 'State committee information',
      href: '/state/committees',
      slug: slugs.state,
    },
    {
      title: 'State hearings calendar',
      description: 'Past and present state committee hearings',
      href: '/state/committee_hearings_calendar',
      slug: slugs.state,
    },
  ],
};

const RELATED_SECTION = {
  ...RELATED_APPS_SECTION,
  items: [
    STATE_SUMMARIES,
  ],
};

export const COMMITTEES_COLUMNS = [
  [FEDERAL_SECTION],
  [STATE_SECTION],
  [RELATED_SECTION],
];

export const COMMITTEES_SECTIONS = [
  FEDERAL_SECTION,
  STATE_SECTION,
  RELATED_SECTION,
];
