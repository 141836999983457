import PropTypes from 'prop-types';
import React from 'react';

class FileList extends React.Component {
  static propTypes = {
    files: PropTypes.object,
    fileNamesEditable: PropTypes.bool,
    handleDelete: PropTypes.func.isRequired,
    handleFileNameChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    files: [],
    fileNamesEditable: false,
  }

  handleFileNameChange = (file) => {
    const { handleFileNameChange } = this.props;
    return event => handleFileNameChange(event, file);
  }

  fileName(input) {
    const { fileNamesEditable } = this.props;
    if (fileNamesEditable) {
      return (<input
        type="text"
        className="form-control"
        defaultValue={input.name}
        onChange={this.handleFileNameChange(input)}
      />);
    }

    return (<span className="input-centered royal-file-uploader__file-name-input"> {input.name} </span>);
  }

  handleDelete = (id) => {
    const { handleDelete } = this.props;
    return () => handleDelete(id);
  }

  inputsList() {
    const { files } = this.props;

    return Object.values(files).map((input, index) =>
      (<div className="col-xs-12" key={input.id + index}>
        <div className="row form-group">
          <div className="col-xs-1"><span className="royal-file-uploader__input-index">{index + 1}.</span></div>
          <div className="col-xs-10">
            {this.fileName(input)}
          </div>
          <div>
            <span className="delete-button input-centered">
              <i
                className="far fa-times-circle fa-lg royal-file-uploader__delete-button"
                onClick={this.handleDelete(input.id)}
              />
            </span>
          </div>
        </div>
      </div>)
    );
  }

  render() {
    return (
      <div className="row margin-top-15">
        {this.inputsList()}
      </div>
    );
  }
}

export default FileList;
