import { flatMap, groupBy } from 'lodash';

import { Result, Slug } from '../types';
import { getRecordType, getSlug } from '../useSearch/helpers';
import { ApiResponse, SearchForm, TagsByRecord } from './types';

const buildRequestData = (results: Result[]): SearchForm => {
  const groupedResults = groupBy(results, 'slug');

  return {
    search_form: Object.entries(groupedResults)
      .map(([slug, tagResults]) => ({
        record_type: getRecordType(slug as Slug),
        record_ids: tagResults.map(result => result.id),
      }))
      .filter(({ record_type }) => record_type !== null),
  };
};

const buildResponse = (apiResponse: ApiResponse): TagsByRecord[] => flatMap(apiResponse.data, ((
  {
    record_type,
    record_tags,
  },
) => (
  record_tags.map(({ record_id, tags }) => {
    const tagsByRecord: TagsByRecord = {
      tags,
      resultId: record_id,
      resultSlug: getSlug(record_type),
    };

    return tagsByRecord;
  })
)));

export default {
  buildRequestData,
  buildResponse,
};
