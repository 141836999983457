import { Locale } from './types';
import { buildLocales } from './factory';

const getLocales = async (query: string) => {
  const url = '/api_web/suggester';
  const data = {
    strategy_slug: 'local_executive_city',
    required_inputs: JSON.stringify({ query }),
  };

  const locales: Locale[] = await $.post(url, data).then(response => response.data);

  return buildLocales(locales);
};

export default { getLocales };
