/* This is not a normal React component. We are setting this up as a
singleton object, so we can essentially use it as a global notifications
channel. This component should only be used once on a page. You can invoke
it by calling `app.GlobalNotifier.addNotification(notification)` */

import React, { createRef, Component } from 'react';
import NotificationSystem from 'react-notification-system';
import style from './style';

// TODO: Replace/wrap GlobalNotifier by core/Notification
class GlobalNotifier extends Component {
  notificationSystem = createRef();

  static addNotification(notification) {
    window.GlobalNotifierSingleton.addNotification(notification);
  }

  componentDidMount() {
    window.GlobalNotifierSingleton = this.notificationSystem.current;
  }

  render() {
    return (<NotificationSystem ref={this.notificationSystem} style={style} />);
  }
}

export default GlobalNotifier;
