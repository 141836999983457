import { get, capitalize } from 'lodash';

import { getParenthesisItem, joinCompact } from 'utils/string';

import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/stateRegulations';

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const documentType = get(apiResult, 'options.document_type');
  const introduced = get(apiResult, 'options.first_published_date');
  const state = get(apiResult, 'options.state.alpha2');

  const documentTypeCapitalized = capitalize(documentType);
  const billType = joinCompact([documentTypeCapitalized, introduced], ' - ');

  const subTitle = () => (
    joinCompact([
      baseResult.slugLabel,
      getParenthesisItem(state),
      ' - ',
      billType,
    ], ' ')
  );

  const getSubtitleHighlight = () => (
    joinCompact([
      baseResult.slugLabel,
      getParenthesisItem(highlights.state || state),
      ' - ',
      billType,
    ], ' ')
  );

  const agencyTitle = get(apiResult, 'options.agencies[0].title');
  const closesDate = get(apiResult, 'options.comments_closed');

  const getDescription = () => (
    joinCompact([
      documentTypeCapitalized,
      agencyTitle && `from the ${agencyTitle}.`,
      closesDate && `Comment period closes ${closesDate}.`,
    ], ' ')
  );

  const getDescriptionHighlight = () => {
    if (highlights.text) return highlights.text;

    const agency = highlights.agencyTitle || agencyTitle;

    return joinCompact([
      documentTypeCapitalized,
      agency && `from the ${agency}.`,
      closesDate && `Comment period closes ${closesDate}.`,
    ], ' ');
  };

  return {
    ...baseResult,
    stateCode: get(apiResult, 'options.state.alpha2'),
    description: getDescription(),
    descriptionHighlight: getDescriptionHighlight(),
    subTitle: subTitle(),
    subtitleHighlight: getSubtitleHighlight(),
    title: get(apiResult, 'options.title'),
    titleHighlight: highlights.title,
  };
};

export default resultFactory;
