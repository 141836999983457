import * as featureSlugs from 'utils/constants/featureSlugs';

const PEOPLE = 'people';
const LEGISLATION = 'legislation';
const COMMITTEE = 'committee';
const DOCUMENT = 'document';
const SOCIAL_MEDIA = 'social media';

export const resultCategories = {
  [featureSlugs.STAKEHOLDER_SLUG]: PEOPLE,
  [featureSlugs.CONGRESSIONAL_STAFFERS_SLUG]: PEOPLE,
  [featureSlugs.FEDERAL_AGENCY_STAFFERS_SLUG]: PEOPLE,
  [featureSlugs.STATE_STAFFERS_SLUG]: PEOPLE,
  [featureSlugs.FEDERAL_LEGISLATORS_SLUG]: PEOPLE,
  [featureSlugs.STATE_LEGISLATORS_SLUG]: PEOPLE,
  [featureSlugs.FEDERAL_EXECUTIVE_SLUG]: PEOPLE,
  [featureSlugs.STATE_EXECUTIVE_SLUG]: PEOPLE,
  [featureSlugs.STATE_STAFFER_EXECUTIVE_SLUG]: PEOPLE,
  [featureSlugs.LOCAL_EXECUTIVE_SLUG]: PEOPLE,
  [featureSlugs.FEDERAL_COMMITTEE_SLUG]: COMMITTEE,
  [featureSlugs.STATE_COMMITTEE_SLUG]: COMMITTEE,
  [featureSlugs.FEDERAL_BILLS_SLUG]: LEGISLATION,
  [featureSlugs.STATE_BILLS_SLUG]: LEGISLATION,
  [featureSlugs.FEDERAL_REGULATIONS_SLUG]: LEGISLATION,
  [featureSlugs.STATE_REGULATIONS_SLUG]: LEGISLATION,
  [featureSlugs.AGENCY_PRESS_RELEASES_SLUG]: DOCUMENT,
  [featureSlugs.CBO_COST_ESTIMATES_SLUG]: DOCUMENT,
  [featureSlugs.COMMITTEE_REPORTS_SLUG]: DOCUMENT,
  [featureSlugs.CONGRESSIONAL_PRESS_RELEASES_SLUG]: DOCUMENT,
  [featureSlugs.CONGRESSIONAL_RECORD_ITEMS_SLUG]: DOCUMENT,
  [featureSlugs.CONTRACTS_SLUG]: DOCUMENT,
  [featureSlugs.CRS_REPORTS_SLUG]: DOCUMENT,
  [featureSlugs.DEAR_COLLEAGUE_LETTERS_SLUG]: DOCUMENT,
  [featureSlugs.EXECUTIVE_ORDERS_SLUG]: DOCUMENT,
  [featureSlugs.GAO_REPORTS_SLUG]: DOCUMENT,
  [featureSlugs.GRANTS_SLUG]: DOCUMENT,
  [featureSlugs.LD2_REPORTS]: DOCUMENT,
  [featureSlugs.LIVE_HEARING_TRANSCRIPTS_SLUG]: DOCUMENT,
  [featureSlugs.OFFICIAL_HEARING_TRANSCRIPTS_SLUG]: DOCUMENT,
  [featureSlugs.SAP_REPORTS_SLUG]: DOCUMENT,
  [featureSlugs.STATE_OF_UNION_ADDRESSES_SLUG]: DOCUMENT,
  [featureSlugs.TREATY_DOCUMENTS_SLUG]: DOCUMENT,
  [featureSlugs.THIRD_PARTY_REPORTS_SLUG]: DOCUMENT,
  [featureSlugs.WHITE_HOUSE_PRESS_BRIEFINGS_SLUG]: DOCUMENT,
  [featureSlugs.GOVERNOR_ORDERS_SLUG]: DOCUMENT,
  [featureSlugs.GOVERNOR_PRESS_RELEASES_SLUG]: DOCUMENT,
  [featureSlugs.TWITTER_SLUG]: SOCIAL_MEDIA,
};
