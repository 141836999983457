import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Activity({ className }) {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.icon, className)}>
      <path d="M17.575 7.09831H13.75C13.503 7.09831 13.2783 7.23946 13.1731 7.46079L12.2227 9.45956L10.1168 1.47157C10.0437 1.19346 9.7898 1 9.5 1C9.49495 1 9.48911 1 9.48353 1.00053C9.18816 1.00789 8.93581 1.21607 8.87578 1.50311L6.86128 11.1374L5.86439 7.56173C5.78816 7.28809 5.53634 7.09857 5.24947 7.09857H1.425C1.19019 7.09857 1 7.28678 1 7.51914V7.93972C1 8.17208 1.19019 8.36029 1.425 8.36029H4.76444L6.33588 13.9954C6.41197 14.2689 6.66334 14.4584 6.95 14.4583C6.95664 14.4583 6.96408 14.4583 6.97072 14.4578C7.2653 14.4483 7.51445 14.2404 7.57422 13.9552L9.57145 4.40191L11.4332 11.4633C11.5004 11.7201 11.7238 11.9078 11.991 11.932C12.2699 11.962 12.5122 11.8129 12.6269 11.5727L14.1543 8.36003H17.575C17.8098 8.36003 18 8.17182 18 7.93945V7.51888C18 7.28651 17.8098 7.09831 17.575 7.09831Z" />
    </svg>
  );
}

Activity.propTypes = {
  className: PropTypes.string,
};

export default Activity;
