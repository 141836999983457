import slugs from 'components/Navigation/menuData/slugs';

import * as featureSlugs from 'utils/constants/featureSlugs';

export const productSlugs = {
  [featureSlugs.STAKEHOLDER_SLUG]: slugs.stakeholderManagement,
  [featureSlugs.CONGRESSIONAL_STAFFERS_SLUG]: slugs.federalStaffers,
  [featureSlugs.FEDERAL_AGENCY_STAFFERS_SLUG]: slugs.federalStaffers,
  [featureSlugs.STATE_STAFFERS_SLUG]: slugs.state,
  [featureSlugs.FEDERAL_LEGISLATORS_SLUG]: slugs.federal,
  [featureSlugs.STATE_LEGISLATORS_SLUG]: slugs.state,
  [featureSlugs.FEDERAL_COMMITTEE_SLUG]: slugs.federal,
  [featureSlugs.STATE_COMMITTEE_SLUG]: slugs.state,
  [featureSlugs.FEDERAL_BILLS_SLUG]: slugs.federal,
  [featureSlugs.STATE_BILLS_SLUG]: slugs.state,
  [featureSlugs.FEDERAL_REGULATIONS_SLUG]: slugs.federal,
  [featureSlugs.STATE_REGULATIONS_SLUG]: slugs.stateRegulation,
  [featureSlugs.AGENCY_PRESS_RELEASES_SLUG]: slugs.federal,
  [featureSlugs.CBO_COST_ESTIMATES_SLUG]: slugs.federal,
  [featureSlugs.COMMITTEE_REPORTS_SLUG]: slugs.federal,
  [featureSlugs.CONGRESSIONAL_PRESS_RELEASES_SLUG]: slugs.federal,
  [featureSlugs.CONGRESSIONAL_RECORD_ITEMS_SLUG]: slugs.federal,
  [featureSlugs.CONTRACTS_SLUG]: slugs.federal,
  [featureSlugs.CRS_REPORTS_SLUG]: slugs.federal,
  [featureSlugs.DEAR_COLLEAGUE_LETTERS_SLUG]: slugs.federal,
  [featureSlugs.EXECUTIVE_ORDERS_SLUG]: slugs.federal,
  [featureSlugs.GAO_REPORTS_SLUG]: slugs.federal,
  [featureSlugs.GRANTS_SLUG]: slugs.federal,
  [featureSlugs.LIVE_HEARING_TRANSCRIPTS_SLUG]: slugs.federal,
  [featureSlugs.OFFICIAL_HEARING_TRANSCRIPTS_SLUG]: slugs.federal,
  [featureSlugs.SAP_REPORTS_SLUG]: slugs.federal,
  [featureSlugs.STATE_OF_UNION_ADDRESSES_SLUG]: slugs.federal,
  [featureSlugs.TREATY_DOCUMENTS_SLUG]: slugs.federal,
  [featureSlugs.THIRD_PARTY_REPORTS_SLUG]: slugs.federal,
  [featureSlugs.WHITE_HOUSE_PRESS_BRIEFINGS_SLUG]: slugs.federal,
  [featureSlugs.GOVERNOR_ORDERS_SLUG]: slugs.state,
  [featureSlugs.GOVERNOR_PRESS_RELEASES_SLUG]: slugs.state,
  [featureSlugs.TWITTER_SLUG]: slugs.socialMedia,
  [featureSlugs.STATE_EXECUTIVE_SLUG]: slugs.state,
  [featureSlugs.STATE_STAFFER_EXECUTIVE_SLUG]: slugs.state,
  [featureSlugs.FEDERAL_EXECUTIVE_SLUG]: slugs.federalStaffers,
};
