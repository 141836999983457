import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Directory({ className }) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.icon, className)}>
      <path d="M13.2222 1.55555H10.5L9.72222 0.777771H7.77778C7.5715 0.777771 7.37367 0.859715 7.22781 1.00558C7.08194 1.15144 7 1.34927 7 1.55555V5.44444C7 5.65072 7.08194 5.84855 7.22781 5.99441C7.37367 6.14027 7.5715 6.22222 7.77778 6.22222H13.2222C13.4285 6.22222 13.6263 6.14027 13.7722 5.99441C13.9181 5.84855 14 5.65072 14 5.44444V2.33333C14 2.12705 13.9181 1.92922 13.7722 1.78335C13.6263 1.63749 13.4285 1.55555 13.2222 1.55555ZM13.2222 8.55555H10.5L9.72222 7.77777H7.77778C7.5715 7.77777 7.37367 7.85972 7.22781 8.00558C7.08194 8.15144 7 8.34927 7 8.55555V12.4444C7 12.6507 7.08194 12.8485 7.22781 12.9944C7.37367 13.1403 7.5715 13.2222 7.77778 13.2222H13.2222C13.4285 13.2222 13.6263 13.1403 13.7722 12.9944C13.9181 12.8485 14 12.6507 14 12.4444V9.33333C14 9.12705 13.9181 8.92922 13.7722 8.78335C13.6263 8.63749 13.4285 8.55555 13.2222 8.55555ZM1.55556 1.16666C1.55556 1.06352 1.51458 0.964605 1.44165 0.891674C1.36872 0.818743 1.26981 0.777771 1.16667 0.777771H0.388889C0.285749 0.777771 0.186834 0.818743 0.113903 0.891674C0.0409721 0.964605 0 1.06352 0 1.16666L0 10.8889C0 11.0952 0.0819442 11.293 0.227806 11.4389C0.373667 11.5847 0.571498 11.6667 0.777778 11.6667H6.22222V10.1111H1.55556V4.66666H6.22222V3.1111H1.55556V1.16666Z" />
    </svg>
  );
}

Directory.propTypes = {
  className: PropTypes.string,
};

export default Directory;
