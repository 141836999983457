import { get } from 'lodash';

import { joinCompact } from 'utils/string';

import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/stateCommittees';

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const chamberName = get(apiResult, 'options.chamber_name');
  const stateAlpha = get(apiResult, 'options.state.alpha2');

  const subTitle = () => (
    joinCompact([
      baseResult.slugLabel,
      stateAlpha && `(${stateAlpha})`,
      '-',
      chamberName,
    ], ' ')
  );

  const getSubtitleHighlight = () => {
    const subtitleStateAlpha = highlights.stateAlpha || stateAlpha;
    const subtitleChamber = highlights.chamberName || chamberName;

    return joinCompact([
      baseResult.slugLabel,
      subtitleStateAlpha && `(${subtitleStateAlpha})`,
      '-',
      subtitleChamber,
    ], ' ');
  };

  const stateName = get(apiResult, 'options.state.name');
  const chair = get(apiResult, 'options.chairman.universal_moniker');

  const getDescription = () => (
    joinCompact([
      stateName && `${stateName} state committee`,
      chamberName,
      chair && `Chair: ${chair}`,
    ])
  );

  const getDescriptionHighlight = () => {
    const descriptionState = highlights.stateName || stateName;

    return joinCompact([
      descriptionState && `${descriptionState} state committee`,
      highlights.chamberName || chamberName,
      highlights.chair || chair,
    ]);
  };

  return {
    ...baseResult,
    titleHighlight: highlights.title,
    subTitle: subTitle(),
    subtitleHighlight: getSubtitleHighlight(),
    description: getDescription(),
    descriptionHighlight: getDescriptionHighlight(),
  };
};

export default resultFactory;
