import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'utils/Link';
import { subscriptionShape } from 'components/Dashboard/common/FeedPage/utils/shapes';

import { getTrackedItemProps } from '../utils';
import styles from '../styles.module.scss';

function TrackedItemLink({ feedToken, subscription, className }) {
  const { itemType, url } = getTrackedItemProps(subscription, feedToken);

  return (
    <Link
      className={classNames(styles.baseLink, className)}
      theme="default"
      href={url}
    >
      {itemType}
    </Link>
  );
}

TrackedItemLink.propTypes = {
  feedToken: PropTypes.string.isRequired,
  subscription: subscriptionShape.isRequired,
  className: PropTypes.string,
};

export default TrackedItemLink;
