import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { isExternal } from 'utils/url';
import { trackQuickLinksResults } from 'utils/tracking/globalSearch';
import Link from 'components/core/Link';
import { Link as LinkType } from 'components/UnifiedSearch/types';

import styles from './styles.module.scss';

const QuickLinks = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const quickLinks = gon.quick_links;

  if (isEmpty(quickLinks)) return null;

  const renderQuickLink = ({ label, url }: LinkType) => (
    <Link
      href={url}
      theme="default"
      className={styles.link}
      key={url}
      isExternal={isExternal(url)}
      onClick={() => trackQuickLinksResults(label, url, quickLinks.length)}
      size={undefined}
      customIcon={undefined}
      rightIcon={undefined}
      isRouterLink={undefined}
      displayExternalIcon={undefined}
      iconClassName={undefined}
      disabled={undefined}
    >
      {label}
    </Link>
  );

  return (
    <div className={styles.quickLinks}>
      <div className={styles.title}>QUICK LINKS</div>
      {quickLinks.map(renderQuickLink)}
    </div>
  );
};

export default QuickLinks;
