import { get } from 'lodash';

import { ApiResult } from 'components/UnifiedSearch/useSearch/types';
import { HighlightedString } from 'components/UnifiedSearch/types';

import { getHighlightedFields } from '../../base';

type Highlights = {
  title?: HighlightedString,
  organization?: HighlightedString,
  city?: HighlightedString,
  stateCode?: HighlightedString,
  description?: HighlightedString,
  fullName?: HighlightedString,
};

export const getHighlights = (result: ApiResult): Highlights => {
  const highlightedFields = getHighlightedFields(result);

  const [title] = get(highlightedFields, 'title', []);
  const [organization] = get(highlightedFields, 'organization', []);
  const [city] = get(highlightedFields, 'addresses.city', []);
  const [stateCode] = get(highlightedFields, 'addresses.state_code', []);
  const [description] = get(highlightedFields, 'description', []);
  const [fullName] = get(highlightedFields, 'full_name', []);

  return {
    title,
    organization,
    city,
    stateCode,
    description,
    fullName,
  };
};
