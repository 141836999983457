import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const usPhoneNumberWithCountryCodeMask = [
  '+',
  '1',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const dateMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const dateTimeMask = [
  ...dateMask,
  ' ',
  /0|1/,
  /\d/,
  ':',
  /[0-5]/,
  /\d/,
  ' ',
  /a|p/,
  'm',
];

const integerMask = createNumberMask({
  prefix: '',
});


const maskMapping = {
  us_phone_number_with_country_code: {
    mask: usPhoneNumberWithCountryCodeMask,
    placeholder: '+1 (555) 555-5555',
  },
  integer: {
    mask: integerMask,
    placeholder: 'Number',
  },
  date: {
    mask: dateMask,
    placeholder: 'mm/dd/yyyy',
  },
  date_time: {
    mask: dateTimeMask,
    placeholder: 'mm/dd/yyyy hh:tt',
  },
};
export default maskMapping;
