import React from 'react';
import PropTypes from 'prop-types';
import { Async, AsyncCreatable, components } from 'react-select';
import classNames from 'classnames';
import {
  isEmpty,
  keys as _keys,
  values as _values,
  isArray,
  debounce,
  isEqual,
  unionWith,
  isFunction,
  trim,
  size,
} from 'lodash';

import {
  wrapperClassName,
  getNoOptionsText,
  isArrayWithEmptyValues,
  isEmptyValue,
} from 'components/core/Select/utils';
import Option from 'components/core/Select/components/Option';
import customSelectStyles from 'components/core/Select/theme';
import { DEFAULT_DEBOUNCE_TIME as ASYNC_DELAY } from 'utils/constants/intervals';
import fetcher from 'utils/fetcher';

import wrapMenuList from './components/wrapMenuList';
import { strategyInputsShape } from './utils/shapes';

let paginationPage = 1;
let paginationOptions = [];
let paginationHasMore = true;

function filterOption() {
  return true;
}

class Autosuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.selectedValues(),
      inputValue: '',
    };

    this.cacheKey = this.cacheKey.bind(this);
    this.doFetching = this.doFetching.bind(this);
    this.loadOptionsWithPagination = this.loadOptionsWithPagination.bind(this);
    this.doDebouncedLoadOptionsWithPagination = debounce(this.loadOptionsWithPagination, ASYNC_DELAY);
    this.doDebouncedFetching = debounce(this.doFetching, ASYNC_DELAY);
    this.fetchOptions = this.fetchOptions.bind(this);
    this.reverseFetchDefaultRawValues = this.reverseFetchDefaultRawValues.bind(this);
    this.groupClassName = this.groupClassName.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.value = this.value.bind(this);
    this.getCustomNoOptionsText = this.getCustomNoOptionsText.bind(this);
  }

  componentDidMount() {
    this.reverseFetchDefaultRawValues();
  }

  componentWillReceiveProps(nextProps) {
    const { defaultRawValues } = this.props;

    if (!isEqual(nextProps.defaultRawValues, defaultRawValues)) {
      this.props = nextProps;

      this.reverseFetchDefaultRawValues(true);
    }
  }

  onInputChange(inputValue, actionMeta) {
    const startTrimedValue = inputValue.trimStart();

    const { onInputChange } = this.props;
    isFunction(onInputChange) && onInputChange(startTrimedValue, actionMeta);

    this.setState({ inputValue: startTrimedValue });
  }

  getCustomNoOptionsText() {
    const { minimumCharactersToSearch } = this.props;

    if (minimumCharactersToSearch) {
      return `Type ${minimumCharactersToSearch} or more characters to search`;
    }

    return getNoOptionsText();
  }

  onChange(value) {
    // this hack is needed cause fetcher may run with delays
    // and may finish when element is unmounted
    if (!this) return;
    const { onChange } = this.props;

    if (onChange !== undefined) {
      onChange(value);
    }

    this.setState({ value });
  }

  getName = () => {
    const { name, multiple } = this.props;
    const { value } = this.state;

    if ((multiple && isArrayWithEmptyValues(value)) || isEmptyValue(value)) {
      return '';
    }

    // If input allow to specify multiple values
    // than we automatically append `[]` to input name
    if (multiple) {
      return `${name}[]`;
    }

    return name;
  };

  selectedValues() {
    const { multiple, defaultSelections } = this.props;

    if (multiple) {
      return defaultSelections.filter(option => !!option.value);
    }

    return defaultSelections[0];
  }

  // clear array from [null, undefined, NaN]
  defaultRawValues = () => {
    const { defaultRawValues } = this.props;

    if (defaultRawValues !== null && isArray(defaultRawValues)) {
      return defaultRawValues.filter(value => value);
    }

    return null;
  };

  loadOptionsWithPagination(inputText, callback) {
    const {
      uri,
      // eslint-disable-next-line no-shadow
      strategy,
      optionsParser,
      strategyInputs,
      defaultSelections,
      reqContentType,
      reqTransform,
      resTransform,
      reqMethod,
      withAjaxAbort,
      minimumCharactersToSearch,
      pagination,
    } = this.props;

    const inputTextSize = size(trim(inputText));
    const noMinimumCharacters = inputTextSize < minimumCharactersToSearch;

    if (noMinimumCharacters) {
      return;
    }

    const { pageSize } = pagination;
    const handlePaginationOptions = (newOptions) => {
      if (size(newOptions) < pageSize) paginationHasMore = false;
      paginationOptions = unionWith(paginationOptions, newOptions, isEqual);

      return paginationOptions;
    };

    const onSuccess = (newOptions) => {
      const options = handlePaginationOptions(newOptions);

      callback(options, { options });
    };

    const ajax = fetcher({
      uri,
      inputText,
      optionsParser,
      strategyInputs,
      defaultSelections,
      strategySlug: strategy,
      defaultRawValues: this.defaultRawValues(),
      page: paginationPage,
      reqContentType,
      reqTransform,
      resTransform,
      reqMethod,
      withAjaxAbort,
    });

    Promise.resolve(ajax).then(onSuccess);
  }

  doFetching(reverseSearch, onSuccess, inputText) {
    const {
      uri,
      strategy,
      optionsParser,
      strategyInputs,
      defaultSelections,
      reqContentType,
      reqTransform,
      resTransform,
      reqMethod,
      customFetcher,
      multipleStrategySlugs,
      withAjaxAbort,
    } = this.props;

    fetcher({
      uri,
      inputText,
      onSuccess,
      reverseSearch,
      optionsParser,
      strategyInputs,
      defaultSelections,
      strategySlug: strategy,
      defaultRawValues: this.defaultRawValues(),
      page: paginationPage,
      reqContentType,
      reqTransform,
      resTransform,
      reqMethod,
      customFetcher,
      multipleStrategySlugs,
      withAjaxAbort,
    });
  }

  fetchOptions(inputText, callback) {
    const {
      autoload,
      defaultSelections,
      minimumCharactersToSearch,
    } = this.props;

    const isInputEmpty = isEmpty(inputText) && !autoload;
    const noMinimumCharacters = inputText.trim().length < minimumCharactersToSearch;

    if (isInputEmpty || noMinimumCharacters) {
      callback(null, {
        options: defaultSelections,
      });

      return;
    }

    this.doDebouncedFetching(false, callback, inputText);
  }

  reverseFetchDefaultRawValues(fromNewProps = false) {
    const { multiple } = this.props;

    if (!isEmpty(this.defaultRawValues())) {
      this.doFetching(true, (err, data) => {
        if (multiple) {
          this.onChange(data.options);
        } else {
          this.onChange(data.options[0]);
        }
      });
    } else if (fromNewProps) {
      this.onChange([]);
    }
  }

  value() {
    const { shouldOverrideValue, overrideValue } = this.props;

    if (shouldOverrideValue) {
      return overrideValue || null;
    }

    // fix Clearable x Icon at the right, because of
    // state.value === '' when nothing is selected.
    const { value } = this.state;
    return value || undefined;
  }

  groupClassName() {
    const { groupClassName, noGroupClassName } = this.props;
    if (groupClassName) {
      return groupClassName;
    }

    if (noGroupClassName) {
      return null;
    }

    return 'form-group';
  }

  cacheKey() {
    const { strategyInputs } = this.props;
    const keys = _keys(strategyInputs).join('');
    const values = _values(strategyInputs).join('');

    return `${keys}${values}`;
  }

  labelComponent() {
    const { label, labelClassName } = this.props;

    if (!label) {
      return null;
    }

    return (
      <label className={labelClassName}>
        {label}
      </label>
    );
  }

  renderInput = () => {
    const {
      theme,
      pagination,
      asyncCreatable,
      clearIndicator,
      placeholder,
      autofocus,
      autoload,
      disabled,
      multiple,
      onBlur,
      onFocus,
      formatCreateLabel,
      allowCreateWhileLoading,
      createOptionPosition,
      customFilterOption,
      customMultiValueLabel,
      customOption,
      cacheOptions,
      isSearchable,
      className,
      strategy,
      withRichOption,
      getNewOptionData,
      isClearable,
      overriddenComponents,
      backspaceRemovesValue,
      onMenuClose,
      menuIsOpen,
      tabSelectsValue,
      noOptionsMessage,
      loadingMessage,
      getOptionValue,
    } = this.props;

    const { inputValue } = this.state;

    const AutosuggestComponent = asyncCreatable ? AsyncCreatable : Async;
    const customSelectComponents = {
      ...overriddenComponents,
      ...(clearIndicator && { DropdownIndicator: null }),
      Option: customOption || Option,
    };

    let asyncProps = {
      filterOption: customFilterOption || filterOption,
      isClearable,
      isSearchable,
      isMulti: multiple,
      isDisabled: disabled,
      styles: theme || customSelectStyles(),
      className: classNames(wrapperClassName, className),
      key: this.cacheKey(),
      name: this.getName(),
      value: this.value(),
      onChange: this.onChange,
      inputValue,
      loadOptions: this.fetchOptions,
      components: customSelectComponents,
      noOptionsMessage: noOptionsMessage || this.getCustomNoOptionsText,
      defaultOptions: autoload,
      autoFocus: autofocus,
      allowCreateWhileLoading,
      createOptionPosition,
      formatCreateLabel,
      placeholder,
      onBlur,
      onFocus,
      cacheOptions,
      strategy,
      withRichOption,
      getNewOptionData,
      backspaceRemovesValue,
      onMenuClose,
      menuIsOpen,
      tabSelectsValue,
      onInputChange: this.onInputChange,
      loadingMessage,
      getOptionValue,
    };

    if (pagination) {
      const { MenuList: DefaultMenuList } = components;
      const MenuList = wrapMenuList(DefaultMenuList, ASYNC_DELAY);

      const handlePaginationMore = (handleInputChange, currentInputValue) => {
        if (paginationHasMore) {
          paginationPage += 1;
          handleInputChange(currentInputValue);
        }
      };

      const handlePaginationReset = () => {
        paginationPage = 1;
        paginationOptions = [];
        paginationHasMore = true;
      };

      const handleMenuClose = () => {
        handlePaginationReset();
        onMenuClose();
      };

      asyncProps = {
        ...asyncProps,
        components: { ...asyncProps.components, MenuList },
        handleScrolledToBottom: handlePaginationMore,
        onMenuClose: handleMenuClose,
        onMenuOpen: handlePaginationReset,
        loadOptions: this.doDebouncedLoadOptionsWithPagination,
      };
    }

    if (customMultiValueLabel) {
      asyncProps = {
        ...asyncProps,
        components: {
          ...asyncProps.components,
          MultiValueLabel: customMultiValueLabel,
        },
      };
    }

    return <AutosuggestComponent {...asyncProps} />;
  };

  render() {
    const { inputClassName } = this.props;
    return (
      <div className={this.groupClassName()}>
        {this.labelComponent()}

        <div className={inputClassName}>
          {this.renderInput()}
        </div>
      </div>
    );
  }
}

const optionShape = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
});

Autosuggest.propTypes = {
  loadingMessage: PropTypes.func,
  asyncCreatable: PropTypes.bool,
  autofocus: PropTypes.bool,
  clearIndicator: PropTypes.bool,
  autoload: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        optionShape,
      ]),
    ),
  ]),
  defaultRawValues: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      optionShape,
    ]),
  ),
  defaultSelections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      optionShape,
    ]),
  ),
  disabled: PropTypes.bool,
  groupClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  noGroupClassName: PropTypes.bool,
  formatCreateLabel: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  overrideValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.oneOf([null, undefined]),
  ]),
  placeholder: PropTypes.string,
  shouldOverrideValue: PropTypes.bool,
  uri: PropTypes.string,
  strategy: PropTypes.string,
  strategyInputs: strategyInputsShape,
  theme: PropTypes.shape({
    control: PropTypes.func,
    option: PropTypes.func,
    dropdownIndicator: PropTypes.func,
    menu: PropTypes.func,
    clearIndicator: PropTypes.func,
    indicatorSeparator: PropTypes.func,
    multiValue: PropTypes.func,
    multiValueLabel: PropTypes.func,
    multiValueRemove: PropTypes.func,
    placeholder: PropTypes.func,
  }),
  optionsParser: PropTypes.func,
  allowCreateWhileLoading: PropTypes.bool,
  createOptionPosition: PropTypes.oneOf(['first', 'last']),
  reqMethod: PropTypes.oneOf(['GET', 'POST']),
  reqContentType: PropTypes.string,
  reqTransform: PropTypes.func,
  resTransform: PropTypes.func,
  customFilterOption: PropTypes.func,
  customMultiValueLabel: PropTypes.func,
  customOption: PropTypes.func,
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  cacheOptions: PropTypes.bool,
  isSearchable: PropTypes.bool,
  className: PropTypes.string,
  customFetcher: PropTypes.func,
  multipleStrategySlugs: PropTypes.arrayOf(PropTypes.string),
  withRichOption: PropTypes.bool,
  withAjaxAbort: PropTypes.bool,
  isClearable: PropTypes.bool,
  getNewOptionData: PropTypes.func,
  overriddenComponents: PropTypes.shape({
    Input: PropTypes.elementType,
    Control: PropTypes.elementType,
    Menu: PropTypes.elementType,
  }),
  backspaceRemovesValue: PropTypes.bool,
  onMenuClose: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  minimumCharactersToSearch: PropTypes.number,
  tabSelectsValue: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  onInputChange: PropTypes.func,
  getOptionValue: PropTypes.func,
};

Autosuggest.defaultProps = {
  asyncCreatable: false,
  autofocus: false,
  autoload: false,
  defaultRawValues: [],
  defaultSelections: [],
  disabled: false,
  multiple: false,
  shouldOverrideValue: false,
  strategyInputs: {},
  clearIndicator: false,
  allowCreateWhileLoading: false,
  createOptionPosition: 'first',
  formatCreateLabel: labelItem => labelItem,
  optionsParser: options => options,
  withRichOption: false,
  withAjaxAbort: true,
  isClearable: true,
  placeholder: '', // get rid of default 'Select...' placeholder
  backspaceRemovesValue: true,
  onMenuClose: () => {},
  minimumCharactersToSearch: 0,
  tabSelectsValue: true,
  onInputChange: () => {},
};

export default Autosuggest;
