import PropTypes from 'prop-types';
import React from 'react';

export default class LoadingMessage extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    uploading: PropTypes.bool.isRequired,
  }

  loadingMessage = message => (
    <span>
      { message }, please wait ... &nbsp;
      <i className="far fa-circle-notch fa-spin" />
    </span>
  );

  render() {
    const { loading, uploading } = this.props;

    return (
      <span>
        { loading && this.loadingMessage('Loading') }

        { uploading && this.loadingMessage('Uploading') }
      </span>
    );
  }
}
