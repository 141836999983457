import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import useDevice from 'utils/breakpoints/useDevice';
import { Kbd } from 'components/core/TipMessage';

import styles from './styles.module.scss';

type SearchProps = {
  className: string,
  isUnifiedSearchOpen: boolean,
  onCloseUnifiedSearch: () => void,
  onSearchQueryChange: (inputText: string) => void,
  onFocus: () => void,
  placeholder?: string
};

const Search: FC<SearchProps> = ({
  className,
  isUnifiedSearchOpen,
  onCloseUnifiedSearch,
  onSearchQueryChange,
  onFocus,
  placeholder,
}) => {
  const [inputText, setInputText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { isDesktop } = useMediaQuery();
  const device = useDevice();

  const handleCloseUnifiedSearch = () => {
    inputRef.current && inputRef.current.blur();
    setInputText('');
    onCloseUnifiedSearch();
  };

  useEffect(() => {
    onSearchQueryChange(inputText);
  }, [inputText]);

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCloseUnifiedSearch();
      }
    };

    document.addEventListener('keydown', handleKeydown, true);

    return () => {
      document.removeEventListener('keydown', handleKeydown, true);
    };
  }, []);

  return (
    <div className={className}>
      <div className={styles.search}>
        <i className={classNames('fa fa-search', styles.searchIcon)} />
        <input
          type="text"
          placeholder={placeholder}
          className={styles.input}
          onFocus={onFocus}
          ref={inputRef}
          value={inputText}
          onChange={event => setInputText(event.target.value)}
          autoFocus={isUnifiedSearchOpen}
        />
        {isUnifiedSearchOpen && (
          <div className={styles.closeButtonWrapper}>
            {device.isMobile || isDesktop && (
              <span className={styles.hintText}>
                To cancel hit <Kbd className={styles.key}>ESC</Kbd> or press
              </span>
            )}
            <i
              className={classNames('fas fa-times-circle', styles.close)}
              onClick={handleCloseUnifiedSearch}
              onKeyDown={handleCloseUnifiedSearch}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
