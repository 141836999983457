import { get, join } from 'lodash';

import { ApiResult } from '../../types';
import { getSlugLabel } from '../../helpers';
import { ResultFactory } from '../types';

export const getHighlightedFields = (result: ApiResult) => (
  result?.options?.highlighted_fields
);

const getBaseResult: ResultFactory = (apiResult: ApiResult) => {
  const title = apiResult.label;

  const subTitle = getSlugLabel(apiResult.slug);

  const description = get(apiResult, 'options.snippet');

  const onClick = (event: MouseEvent) => {
    event.preventDefault();
    // TODO add tracking event

    return true;
  };
  const slugLabel = getSlugLabel(apiResult.slug);

  return {
    id: apiResult.id,
    slug: apiResult.slug,
    key: join([apiResult.id, apiResult.slug], '-'),
    title,
    subTitle,
    onClick,
    description,
    slugLabel,
    url: apiResult.url,
  };
};

export default getBaseResult;
