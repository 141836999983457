import { useEffect, useState } from 'react';

const HIDE_FIRST_ELEMENT_OFFSET = 1200;
const HIDE_SECOND_ELEMENT_OFFSET = 1065;
const HIDE_THIRD_ELEMENT_OFFSET = 980;
const HIDE_FOURTH_ELEMENT_OFFSET = 820;

export const useHiddenItemsLength = () => {
  const [hiddenItemsLength, setHiddenItemsLength] = useState<0 | 1 | 2 | 3 | 4>(0);

  useEffect(() => {
    const handleResize = () => {
      const currentScreenSize = document.body.clientWidth;

      if (currentScreenSize > HIDE_FIRST_ELEMENT_OFFSET) {
        setHiddenItemsLength(0);
        return;
      }

      const showOneElement = currentScreenSize <= HIDE_FIRST_ELEMENT_OFFSET
        && currentScreenSize >= HIDE_SECOND_ELEMENT_OFFSET;

      if (showOneElement) {
        setHiddenItemsLength(1);
        return;
      }

      const showTwoElements = currentScreenSize <= HIDE_SECOND_ELEMENT_OFFSET
        && currentScreenSize >= HIDE_THIRD_ELEMENT_OFFSET;

      if (showTwoElements) {
        setHiddenItemsLength(2);
        return;
      }

      const showThreeElements = currentScreenSize <= HIDE_THIRD_ELEMENT_OFFSET
        && currentScreenSize >= HIDE_FOURTH_ELEMENT_OFFSET;

      if (showThreeElements) {
        setHiddenItemsLength(3);
        return;
      }

      setHiddenItemsLength(4);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return hiddenItemsLength;
};
