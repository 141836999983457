import { get, capitalize } from 'lodash';

import { joinCompact } from 'utils/string';

import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/federalCommittees';

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const chamberName = get(apiResult, 'options.type');
  const capitalizedChamberName = capitalize(chamberName);

  const subTitle = () => (
    joinCompact([baseResult.slugLabel, capitalizedChamberName], ' - ')
  );

  const getSubtitleHighlight = () => (
    joinCompact([
      baseResult.slugLabel,
      highlights.chamberName || capitalizedChamberName,
    ], ' - ')
  );

  const optionsChair = get(apiResult, 'options.current_chairman.universal_moniker');

  const getDescription = () => (
    joinCompact([
      baseResult.slugLabel,
      capitalizedChamberName,
      optionsChair && `Chair: ${optionsChair}`,
    ])
  );

  const getDescriptionHighlight = () => {
    const chair = highlights.chair || optionsChair;

    return joinCompact([
      baseResult.slugLabel,
      highlights.chamberName || capitalizedChamberName,
      chair && `Chair: ${chair}`,
    ]);
  };

  return {
    ...baseResult,
    title: baseResult.title,
    titleHighlight: highlights.title,
    subTitle: subTitle(),
    subtitleHighlight: getSubtitleHighlight(),
    description: getDescription(),
    descriptionHighlight: getDescriptionHighlight(),
  };
};

export default resultFactory;
