import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { typeOptions } from 'utils/constants';
import AddressAutocomplete from 'components/core/AddressAutocomplete';
import inputStyles from 'components/core/Input/styles.module.scss';

import {
  addressPlaceholder,
  defaultNewAddressValue,
} from 'components/Stakeholder/common/Modals/StakeholderForm/utils';

import MultipleField from './MultipleField';
import BaseField from './BaseField';
import styles from './styles.module.scss';

const { defaultTypeOptions } = typeOptions;

function AddressField({
  className,
  values,
  formState,
  setFieldValue,
  setFieldTouched,
  ...restProps
}) {
  const name = 'addresses';
  const complementaryFieldName = 'Apt/Unit #(Optional)';
  const complementaryFieldPlaceholder = 'An optional address field (e.g., “Apt. 102”)';
  const options = values.addresses;

  function clearComplementaryField(index) {
    setFieldValue(`${name}.${index}.complement`, '');
  }

  function handleAddressChange(inputProps) {
    const { index } = inputProps;

    return ({ address, placeId }) => {
      setFieldValue(`${name}.${index}.place_id`, placeId);
      setFieldValue(`${name}.${index}.address`, address);
      setFieldTouched(`${name}.${index}.address`, true);
    };
  }

  function handleComplementaryFieldChange(inputProps) {
    const { index } = inputProps;

    return ({ target }) => {
      const { value } = target;

      setFieldValue(`${name}.${index}.complement`, value);
      setFieldTouched(`${name}.${index}.complement`, true);
    };
  }

  const addressClassNames = classNames(
    inputStyles.input,
    inputStyles.default,
    className,
    styles.input,
  );

  function renderAddress(inputProps) {
    const {
      index,
      id,
      showComplementaryField,
      complementaryFieldState,
      ...restInputProps
    } = inputProps;
    const address = options[index] || {};
    const { place_id: placeId, address: autocompleteValue, complement } = address;
    const complementaryAddressValue = complement || '';
    const { isVisible, initialLoad } = complementaryFieldState;

    if (initialLoad && !isVisible && !isEmpty(complementaryAddressValue)) {
      showComplementaryField();
    }

    return (
      <Fragment>
        <AddressAutocomplete
          {...restInputProps}
          id={id}
          index={index}
          value={autocompleteValue}
          placeId={placeId}
          onChange={handleAddressChange(inputProps)}
          onBlur={handleAddressChange(inputProps)}
          inputClassName={addressClassNames}
          allowInternationalSearch
        />
        {isVisible && (
          <BaseField
            {...restInputProps}
            id={`${id}-complement`}
            index={index}
            name={`${name}.${index}.complement`}
            value={complementaryAddressValue}
            formState={formState}
            placeholder={complementaryFieldPlaceholder}
            onChange={handleComplementaryFieldChange(inputProps)}
            onBlur={handleComplementaryFieldChange(inputProps)}
            className={styles.complementaryFieldInput}
          />
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <MultipleField
        {...restProps}
        id="addresses"
        name={name}
        fieldName="address"
        typeName="category"
        options={options}
        isLoading={false}
        typeOptions={defaultTypeOptions}
        title="Address"
        placeholder={addressPlaceholder}
        defaultValue={defaultNewAddressValue}
        setFieldValue={setFieldValue}
        renderCustomInputNode={renderAddress}
        formState={formState}
        setFieldTouched={setFieldTouched}
        complementaryFieldName={complementaryFieldName}
        clearComplementaryField={clearComplementaryField}
      />
      {options.length > 1 && <hr />}
    </Fragment>
  );
}

AddressField.propTypes = {
  values: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  className: PropTypes.string,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

export default AddressField;
