const options = [
  {
    label: 'Action center',
    value: '/public_facing_tools/action_center',
  },
  {
    label: 'Watchlists',
    value: '/public_facing_tools/state_legislation_watchlists',
  },
  {
    label: 'Legislators',
    value: '/public_facing_tools/legislator_lookup',
  },
  {
    label: 'Bill Scorecards',
    value: '/public_facing_tools/bill_scorecards',
  },
];

export default options;
