const style = {
  NotificationItem: {
    DefaultStyle: {
      backgroundColor: 'white',
      borderTop: '0',
      fontSize: '14px',
      padding: '24px',
      borderLeft: '8px solid #02c7c8',
      borderRadius: '4px',
      boxShadow: '0 1px 4px 0 rgba(#9ca6d9, 0.51)',
      fontWeight: '500',
    },
    error: {
      borderLeft: '8px solid #ed5565',
    },
  },
  Title: {
    DefaultStyle: {
      color: '#080C38',
      margin: '0',
    },
  },
  Dismiss: {
    DefaultStyle: {
      backgroundColor: 'white',
      color: '#080C38',
      marginTop: '20px',
      fontWeight: '200',
      fontSize: '18px',
      marginRight: '4px',
      fontFamily: 'inherit',
    },
  },
};

export default style;
