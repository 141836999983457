import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

function AddRemoveButtons({
  name,
  onRemove,
  onAdd,
  isLoading,
  isAddMoreDisabled,
  showRemove,
  complementaryFieldProps,
}) {
  const commonButtonProps = {
    type: 'button',
    size: 'none',
    theme: 'link',
  };

  function renderComplementaryFieldButton() {
    const { name: complementaryName, onToggle, isVisible } = complementaryFieldProps;
    const buttonTitle = isVisible ? `Remove ${complementaryName}` : `Add ${complementaryName}`;

    if (isEmpty(complementaryName)) {
      return null;
    }

    return (
      <>
        <Button
          {...commonButtonProps}
          onClick={onToggle}
          disabled={isLoading}
          className={styles.addButton}
        >
          {buttonTitle}
        </Button>
        <span className={styles.separator}>|</span>
      </>
    );
  }

  function renderRemoveButton() {
    if (!showRemove) {
      return null;
    }

    return (
      <>
        <span className={styles.separator}>|</span>
        <Button
          {...commonButtonProps}
          onClick={onRemove}
          className={styles.removeButton}
          disabled={isLoading}
        >
          Remove this {name}
        </Button>
      </>
    );
  }

  return (
    <div className={styles.formGroup}>
      {renderComplementaryFieldButton()}
      <Button
        {...commonButtonProps}
        onClick={onAdd}
        disabled={isLoading || isAddMoreDisabled}
        className={styles.addButton}
      >
        Add another {name}
      </Button>
      {renderRemoveButton()}
    </div>
  );
}

AddRemoveButtons.propTypes = {
  name: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isAddMoreDisabled: PropTypes.bool,
  showRemove: PropTypes.bool.isRequired,
  complementaryFieldProps: PropTypes.shape({
    name: PropTypes.string,
    onToggle: PropTypes.func,
    isVisible: PropTypes.bool,
  }),
};

export default AddRemoveButtons;
