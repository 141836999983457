import React, { FC } from 'react';

import { AdvancedOptions, Collection } from 'components/UnifiedSearch/types';

import OptionsWrapper from '../../../OptionsWrapper';
import Option from '../../../Option';
import { ActionsWrapper, ApplyButton } from '../../../SlideUpModal';
import { CollectionOption, Collections } from '../../types';
import { handleOptionClick } from './helpers';
import styles from './styles.module.scss';

type OptionsProps = {
  advancedOptions: AdvancedOptions,
  isMobile: boolean,
  collections: Collections,
};

const Options: FC<OptionsProps> = ({
  advancedOptions,
  isMobile,
  collections,
}) => {
  const setCollections = (newCollections: Collection[]) => {
    if (isMobile) {
      collections.setSelectedCollections(newCollections);
      return;
    }

    advancedOptions.change({ collections: newCollections });
  };

  const renderOption = (key: Collection, value: CollectionOption) => {
    const isSelected = collections.value.some(collection => collection === key);
    const isDisabled = typeof value !== 'string' && value.isDisabled;

    const handleClick = () => {
      handleOptionClick({ key, collections, setCollections });
    };

    return (
      <Option
        onClick={handleClick}
        id={key}
        isSelected={isSelected}
        key={key}
        className={styles.option}
        isDisabled={isDisabled}
        isMobile={isMobile}
      >
        {typeof value === 'string' ? value : value.label}
      </Option>
    );
  };

  const handleApply = () => {
    advancedOptions.change({ collections: collections.value });
    collections.toggleFilter();
  };

  return (
    <>
      <OptionsWrapper>
        {Object.entries(collections.options).map(([key, value]) => (
          renderOption(key as Collection, value)
        ))}
      </OptionsWrapper>
      {isMobile && (
        <ActionsWrapper>
          <ApplyButton onClick={handleApply} />
        </ActionsWrapper>
      )}
    </>
  );
};

export default Options;
