import React, { FC, useState } from 'react';

import { AdvancedOptions } from 'components/UnifiedSearch/types';

import Dropdown from '../Dropdown';
import Trigger from '../Trigger';
import SlideUpModal from '../SlideUpModal';
import { MODAL_TITLES, REGION_OPTIONS } from './constants';
import Options from './components/Options';
import States from './components/States';
import Locales from './components/Locales';
import CustomRegionsWrapper from './components/CustomRegionsWrapper';
import { CustomRegion } from './types';

type RegionProps = {
  advancedOptions: AdvancedOptions,
  isMobile: boolean,
};

const Region: FC<RegionProps> = ({ advancedOptions, isMobile }) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleFilter = () => setIsVisible(!isVisible);
  const [customRegion, setCustomRegion] = useState<CustomRegion>(null);
  const resetCustomRegion = () => setCustomRegion(null);

  const renderButtonContent = () => {
    const { region, customRegions } = advancedOptions.values;

    if (region === 'state') {
      const stateValues = customRegions.map((state) => {
        if (state.value === 'all') return state.label;

        return state.value;
      });

      return (
        <CustomRegionsWrapper>
          {`State: ${stateValues.join(', ')}`}
        </CustomRegionsWrapper>
      );
    }

    if (region === 'local') {
      const localLabels = customRegions.map(local => local.label);

      return (
        <CustomRegionsWrapper>
          {`Local: ${localLabels.join(', ')}`}
        </CustomRegionsWrapper>
      );
    }

    return REGION_OPTIONS[region];
  };

  const renderFilterContent = () => {
    const commonProps = {
      advancedOptions,
      isMobile,
      toggleFilter,
      onResetCustomRegion: resetCustomRegion,
    };

    if (customRegion === 'state') {
      return <States {...commonProps} />;
    }

    if (customRegion === 'local') {
      return <Locales {...commonProps} />;
    }

    return (
      <Options
        {...commonProps}
        toggleFilter={toggleFilter}
        onSelectCustomRegion={setCustomRegion}
      />
    );
  };

  const handleCloseFilter = () => {
    toggleFilter();
    setCustomRegion(null);
  };

  if (isMobile) {
    const getModalTitle = () => {
      if (customRegion) {
        return MODAL_TITLES[customRegion];
      }

      return 'Level';
    };

    return (
      <>
        <Trigger onClick={toggleFilter}>
          {renderButtonContent()}
        </Trigger>
        <SlideUpModal isOpen={isVisible} toggleOpen={handleCloseFilter} title={getModalTitle()}>
          {renderFilterContent()}
        </SlideUpModal>
      </>
    );
  }

  return (
    <Dropdown
      isVisible={isVisible}
      content={renderFilterContent()}
      onClickOutside={handleCloseFilter}
      onClick={toggleFilter}
    >
      {renderButtonContent()}
    </Dropdown>
  );
};

export default Region;
