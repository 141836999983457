import React, { FC } from 'react';
import classNames from 'classnames';

import useAvatar from 'components/core/Avatar/useAvatar';
import { Slug } from 'components/UnifiedSearch/types';

import { getIconClass } from './helpers';
import styles from './styles.module.scss';

type IconComponent = FC<{ slug: Slug, photoUrl?: string }>;

const Icon: IconComponent = ({ slug, photoUrl }) => {
  const avatar = useAvatar(photoUrl);

  return (
    <div className={styles.avatarContainer}>
      {avatar.isValid ? (
        <img src={photoUrl} className={styles.avatarImage} onError={avatar.handleError} alt="User default avatar" />
      ) : (
        <i className={classNames(getIconClass(slug))} />
      )}
    </div>
  );
};

export default Icon;
