import slugs from 'components/Navigation/menuData/slugs';

const SOCIAL_SECTION = {
  id: 'social',
  items: [
    {
      title: 'Social media',
      description: 'Keep track of what politicians and influencers are saying on social media',
      href: '/social_media',
      slug: slugs.socialMedia,
    },
  ],
};

const NEWS_SECTION = {
  id: 'news',
  items: [
    {
      title: 'News',
      description: 'Monitor news related to your keywords, bills, people, etc.',
      href: '/news',
      slug: slugs.news,
    },
  ],
};

export const NEWS_AND_SOCIAL_COLUMNS = [
  [SOCIAL_SECTION],
  [NEWS_SECTION],
];

export const NEWS_AND_SOCIAL_SECTIONS = [
  {
    id: 'social-news',
    items: [...SOCIAL_SECTION.items, ...NEWS_SECTION.items],
  },
];
