import useButtonState from './useButtonState';

function useTrackItemsModal({ isPresentHearings, isPresentStaffers, onChange }) {
  const {
    type,
    isStaffer,
    isHearing,
    setStaffers,
    setHearings,
  } = useButtonState({ isPresentHearings, isPresentStaffers });

  function setStaffersOption(checked) {
    setStaffers(checked);
    onChange({ isStaffer: checked });
  }

  function setHearingsOption(checked) {
    setHearings(checked);
    onChange({ isHearing: checked });
  }

  function setStafferFrequency(currentValue) {
    onChange({ isStaffer: true, staffer: currentValue });
  }

  function setHearingsFrequency(currentValue) {
    onChange({ isHearing: true, hearings: currentValue });
  }

  function setWeekAhead(currentValue) {
    onChange({ isHearing: true, sendWeekAheadEmail: currentValue });
  }

  return {
    type,
    isStaffer,
    isHearing,
    setStaffers: setStaffersOption,
    setHearings: setHearingsOption,
    setStafferFrequency,
    setHearingsFrequency,
    setWeekAhead,
  };
}

export default useTrackItemsModal;
