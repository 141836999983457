import { sortBy, size, toLower, filter } from 'lodash';

import {
  STATE_BILL_STATUSES,
  FEDERAL_BILL_STATUSES,
  ALL_ACTIVE,
  ALL_INACTIVE,
} from 'utils/constants/billStatus';

export const AGENCY_PRESS_RELEASES_SLUG = 'doctrove_agency_press_release';
export const CBO_COST_ESTIMATES_SLUG = 'doctrove_cbo';
export const COMMITTEE_REPORTS_SLUG = 'doctrove_committee_report';
export const CONGRESSIONAL_PRESS_RELEASE_SLUG = 'doctrove_press_release';
export const CONGRESSIONAL_RECORD_ITEMS_SLUG = 'congressional_record';
export const CRS_REPORTS_SLUG = 'doctrove_crs';
export const DEAR_COLLEAGUE_LETTERS_SLUG = 'doctrove_dear_colleague_letter';
export const EXECUTIVE_ORDERS_SLUG = 'doctrove_executive_order';
export const FEDERAL_BILLS_SLUG = 'bill_keyword';
export const FEDERAL_CONTRACTS_SLUG = 'doctrove_fbo';
export const FEDERAL_GRANTS_SLUG = 'doctrove_grant';
export const FEDERAL_PROPOSED_REGULATIONS_SLUG = 'federal_register_regulation';
export const GAO_REPORTS_SLUG = 'doctrove_gao';
export const GOVERNOR_ORDERS_SLUG = 'doctrove_governor_order';
export const GOVERNOR_PRESS_RELEASES_SLUG = 'doctrove_governor_press_release';
export const LIVE_HEARING_TRANSCRIPTS_SLUG = 'doctrove_live_hearing_transcript';
export const LOCAL_DOCUMENTS_SLUG = 'town_intel_document';
export const OFFICIAL_HEARING_TRANSCRIPTS_SLUG = 'doctrove_hearing_transcript';
export const SAP_REPORTS_SLUG = 'doctrove_sap';
export const SOCIAL_MEDIA_SLUG = 'social_media_post';
export const STATE_BILLS_SLUG = 'state_bill';
export const STATE_UNION_ADDRESSES_SLUG = 'doctrove_union_statement';
export const STATE_REGULATIONS_SLUG = 'state_regulation_document';
export const THIRD_PARTY_REPORTS_SLUG = 'doctrove_third_party_report';
export const TREATIES_SLUG = 'doctrove_treaty';
export const WHITE_HOUSE_PRESS_BRIEFINGS_SLUG = 'doctrove_press_briefing';
export const NEWS_STORY_SLUG = 'news_story';

const {
  isFederalProductEnabled,
  isStateProductEnabled,
  isStateRegulationsEnabled,
  localProductEnabledLocaleTypes,
  isGrmEnabled,
} = gon || {};

function getDisabledMessage(productName) {
  return `You do not currently have access to ${productName}.
    Please contact your Intelligence customer success representative if you would like to learn more.`;
}

export const DISABLED_STATE_MESSAGE = getDisabledMessage('State Intelligence');
export const DISABLED_STATE_REGULATION_MESSAGE = getDisabledMessage('State Regulations');
export const DISABLED_FEDERAL_MESSAGE = getDisabledMessage('Federal Intelligence');
export const DISABLED_LOCAL_MESSAGE = getDisabledMessage('Local Intelligence');
export const DISABLED_SOCIAL_MEDIA_MESSAGE = getDisabledMessage('Social Media');
const DISABLED_GRM_MESSAGE = getDisabledMessage('Activity');

export function getFederalProducts() {
  const props = {
    product: 'Federal',
    disabled: !isFederalProductEnabled,
    disabledMessage: DISABLED_FEDERAL_MESSAGE,
  };

  return [
    { ...props, id: AGENCY_PRESS_RELEASES_SLUG, label: 'Agency press releases' },
    { ...props, id: CBO_COST_ESTIMATES_SLUG, label: 'CBO cost estimates' },
    { ...props, id: COMMITTEE_REPORTS_SLUG, label: 'Committee reports' },
    { ...props, id: CONGRESSIONAL_PRESS_RELEASE_SLUG, label: 'Congressional press releases' },
    { ...props, id: CONGRESSIONAL_RECORD_ITEMS_SLUG, label: 'Congressional record items' },
    { ...props, id: EXECUTIVE_ORDERS_SLUG, label: 'Executive orders' },
    { ...props, id: FEDERAL_BILLS_SLUG, label: 'Federal bills', product: '' },
    { ...props, id: FEDERAL_CONTRACTS_SLUG, label: 'Federal contracts', product: '' },
    { ...props, id: FEDERAL_GRANTS_SLUG, label: 'Federal grants', product: '' },
    { ...props, id: FEDERAL_PROPOSED_REGULATIONS_SLUG, label: 'Federal proposed regulations', product: '' },
    { ...props, id: GAO_REPORTS_SLUG, label: 'GAO reports' },
    { ...props, id: LIVE_HEARING_TRANSCRIPTS_SLUG, label: 'Live hearing transcripts' },
    { ...props, id: OFFICIAL_HEARING_TRANSCRIPTS_SLUG, label: 'Official hearing transcripts' },
    { ...props, id: SAP_REPORTS_SLUG, label: 'SAP reports' },
    { ...props, id: STATE_UNION_ADDRESSES_SLUG, label: 'State of the Union addresses' },
    { ...props, id: WHITE_HOUSE_PRESS_BRIEFINGS_SLUG, label: 'White House press briefings' },
    { ...props, id: TREATIES_SLUG, label: 'Treaties' },
    { ...props, id: CRS_REPORTS_SLUG, label: 'CRS reports' },
    { ...props, id: THIRD_PARTY_REPORTS_SLUG, label: 'Third party reports' },
  ];
}

export function getStateProducts() {
  const props = {
    product: 'State',
    disabled: !isStateProductEnabled,
    disabledMessage: DISABLED_STATE_MESSAGE,
  };

  return [
    { ...props, id: STATE_BILLS_SLUG, label: 'State bills', product: '' },
    {
      id: STATE_REGULATIONS_SLUG,
      label: 'State regulations',
      disabled: !isStateRegulationsEnabled,
      disabledMessage: DISABLED_STATE_REGULATION_MESSAGE,
    },
    { ...props, id: GOVERNOR_ORDERS_SLUG, label: 'Governor orders' },
    { ...props, id: GOVERNOR_PRESS_RELEASES_SLUG, label: 'Governor press releases' },
  ];
}

export function getLocalProducts() {
  return [
    {
      id: LOCAL_DOCUMENTS_SLUG,
      label: 'Local documents',
      disabled: !size(localProductEnabledLocaleTypes),
      disabledMessage: DISABLED_LOCAL_MESSAGE,
    },
  ];
}

export function getAllProducts() {
  return [
    ...getFederalProducts(),
    ...getStateProducts(),
    ...getLocalProducts(),
  ];
}

export const TRACKED_STATE_REGULATION = 'state_regulation';
export const TRACKED_FEDERAL_BILL = 'federal_bill';
export const TRACKED_FEDERAL_COMMITTEE_HEARING = 'federal_committee_hearing';
export const TRACKED_FEDERAL_COMMITTEE_STAFFER = 'federal_committee_staffer';
export const TRACKED_FEDERAL_LEGISLATIVE_STAFFER = 'federal_legislator_staffer';
export const TRACKED_STATE_LEGISLATIVE_STAFFER = 'state_legislator_staffer';
export const TRACKED_STATE_COMMITTEE_HEARING = 'state_committee_hearing';
export const TRACKED_STATE_COMMITTEE_STAFFER = 'state_committee_staffer';

const filterActiveAndInactiveOptions = statuses => (
  filter(statuses, status => !([ALL_ACTIVE, ALL_INACTIVE].includes(status.value)))
);

export function getTrackedProducts() {
  function getFederal() {
    const props = {
      product: 'Federal',
      disabled: !isFederalProductEnabled,
      disabledMessage: DISABLED_FEDERAL_MESSAGE,
    };

    return [
      {
        ...props,
        id: TRACKED_FEDERAL_BILL,
        label: 'Federal bills',
        subItems: {
          id: 'federalBillStatus',
          title: 'Status',
          options: filterActiveAndInactiveOptions(FEDERAL_BILL_STATUSES),
          withSelectAll: true,
        },
      },
      { ...props, id: FEDERAL_PROPOSED_REGULATIONS_SLUG, label: 'Federal regulations' },
      { ...props, id: TRACKED_FEDERAL_LEGISLATIVE_STAFFER, label: 'Federal legislators' },
      { ...props, id: TRACKED_FEDERAL_COMMITTEE_STAFFER, label: 'Federal committees' },
    ];
  }

  function getState() {
    const props = {
      product: 'State',
      disabled: !isStateProductEnabled,
      disabledMessage: DISABLED_STATE_MESSAGE,
    };

    return [
      { ...props, id: TRACKED_STATE_COMMITTEE_STAFFER, label: 'State committees' },
      {
        ...props,
        id: STATE_BILLS_SLUG,
        label: 'State bills',
        subItems: {
          id: 'stateBillStatus',
          title: 'Status',
          options: filterActiveAndInactiveOptions(STATE_BILL_STATUSES),
          withSelectAll: true,
        },
      },
      { ...props, id: TRACKED_STATE_LEGISLATIVE_STAFFER, label: 'State legislators' },
      {
        id: TRACKED_STATE_REGULATION,
        label: 'State regulations',
        disabled: !isStateRegulationsEnabled,
        disabledMessage: DISABLED_STATE_REGULATION_MESSAGE,
      },
    ];
  }

  return sortBy([
    ...getFederal(),
    ...getState(),
  ], ({ label }) => toLower(label));
}

export const FEDERAL_COMMITTEE = 'federal_committee';
export const FEDERAL_LEGISLATOR = 'federal_legislator';
export const STATE_COMMITTEE = 'state_committee';
export const GRM_ACTIVITY_TYPE = 'grm_v2_card';
export const STATE_LEGISLATOR = 'state_legislator';

export function getAllFilterProducts() {
  function getFederal() {
    const props = {
      product: 'Federal',
      disabled: !isFederalProductEnabled,
      disabledMessage: DISABLED_FEDERAL_MESSAGE,
    };

    return [
      { ...props, id: TRACKED_FEDERAL_BILL, label: 'Federal bills', type: 'Federal bill' },
      { ...props, id: FEDERAL_COMMITTEE, label: 'Federal committees', type: 'Federal committee' },
      { ...props, id: FEDERAL_LEGISLATOR, label: 'Federal legislators', type: 'Federal legislator' },
      { ...props, id: FEDERAL_GRANTS_SLUG, label: 'Federal grants', type: 'Federal grant', product: '' },
      { ...props, id: FEDERAL_PROPOSED_REGULATIONS_SLUG, label: 'Federal proposed regulations', type: 'Federal regulation', product: '' },
      { ...props, id: DEAR_COLLEAGUE_LETTERS_SLUG, label: 'Dear colleague letters', type: 'Dear colleague letter' },
      { ...props, id: CONGRESSIONAL_RECORD_ITEMS_SLUG, label: 'Congressional record items', type: 'Congressional record item' },
      { ...props, id: FEDERAL_CONTRACTS_SLUG, label: 'Federal contracts', type: 'Federal contract', product: '' },
      { ...props, id: CBO_COST_ESTIMATES_SLUG, label: 'CBO cost estimates', type: 'CBO cost estimate' },
      { ...props, id: CRS_REPORTS_SLUG, label: 'CRS reports', type: 'CRS report' },
      { ...props, id: GAO_REPORTS_SLUG, label: 'GAO reports', type: 'GAO report' },
      { ...props, id: SAP_REPORTS_SLUG, label: 'SAP reports', type: 'SAP report' },
      { ...props, id: COMMITTEE_REPORTS_SLUG, label: 'Committee reports', type: 'Committee report' },
      { ...props, id: OFFICIAL_HEARING_TRANSCRIPTS_SLUG, label: 'Official hearing transcripts', type: 'Official hearing transcript' },
      { ...props, id: WHITE_HOUSE_PRESS_BRIEFINGS_SLUG, label: 'White House press briefings', type: 'White House press briefing' },
      { ...props, id: THIRD_PARTY_REPORTS_SLUG, label: 'Third party reports', type: 'Third party report' },
      { ...props, id: TREATIES_SLUG, label: 'Treaties', type: 'Treaty' },
      { ...props, id: STATE_UNION_ADDRESSES_SLUG, label: 'State of the Union addresses', type: 'State of the Union address' },
      { ...props, id: CONGRESSIONAL_PRESS_RELEASE_SLUG, label: 'Congressional press releases', type: 'Congressional press release' },
      { ...props, id: AGENCY_PRESS_RELEASES_SLUG, label: 'Agency press releases', type: 'Agency press release' },
      { ...props, id: LIVE_HEARING_TRANSCRIPTS_SLUG, label: 'Live hearing transcripts', type: 'Live hearing transcript' },
      { ...props, id: EXECUTIVE_ORDERS_SLUG, label: 'Executive orders', type: 'Executive order' },
    ];
  }

  function getState() {
    const props = {
      product: 'State',
      disabled: !isStateProductEnabled,
      disabledMessage: DISABLED_STATE_MESSAGE,
    };

    return [
      { ...props, id: STATE_COMMITTEE, label: 'State committees', type: 'State committee' },
      { ...props, id: STATE_BILLS_SLUG, label: 'State bills', type: 'State bill' },
      { ...props, id: GOVERNOR_PRESS_RELEASES_SLUG, label: 'Governor press releases', type: 'Governor press release' },
      { ...props, id: GOVERNOR_ORDERS_SLUG, label: 'Governor orders', type: 'Governor order' },
      { ...props, id: STATE_LEGISLATOR, label: 'State legislators', type: 'State legislators' },
      {
        id: TRACKED_STATE_REGULATION,
        label: 'State regulations',
        type: 'State regulation',
        disabled: !isStateRegulationsEnabled,
        disabledMessage: DISABLED_STATE_REGULATION_MESSAGE,
      },
    ];
  }

  function getGrm() {
    return [
      {
        product: 'Grm',
        disabled: !isGrmEnabled,
        disabledMessage: DISABLED_GRM_MESSAGE,
        id: GRM_ACTIVITY_TYPE,
        label: 'Activity note',
        type: 'Activity note',
      },
    ];
  }

  return sortBy([
    ...getFederal(),
    ...getState(),
    ...getGrm(),
    {
      id: LOCAL_DOCUMENTS_SLUG,
      label: 'Local documents',
      disabled: !size(localProductEnabledLocaleTypes),
      disabledMessage: DISABLED_LOCAL_MESSAGE,
    },
  ], ({ label }) => toLower(label));
}
