import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/core/Link';
import { SlideUpModal } from 'components/core/Modal';
import { getApps } from 'components/NavigationV2/utils/apps';
import PanelHeader from 'components/NavigationV2/components/MobileView/components/common/PanelHeader';
import { trackAppClicked } from 'components/NavigationV2/utils/tracking';

import styles from './styles.module.scss';

function Panel({ disabledProducts, isOpen, toggleOpen }) {
  const apps = getApps(disabledProducts);

  const renderApp = ({ icon, name, href }) => (
    <Link
      href={href}
      key={name}
      className={styles.link}
      theme="light"
      onClick={() => trackAppClicked(name)}
    >
      {cloneElement(icon, { className: styles.appIcon })}
      {name}
    </Link>
  );

  return (
    <SlideUpModal
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      className={styles.modal}
      bodyClassName={styles.body}
    >
      <PanelHeader title="Applications" onClose={toggleOpen} />

      <div className={styles.background}>
        <div className={styles.apps}>
          {apps.map(renderApp)}
        </div>
      </div>
    </SlideUpModal>
  );
}

Panel.propTypes = {
  disabledProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default Panel;
