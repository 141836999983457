import React, { FC } from 'react';
import truncate from 'lodash/truncate';

import PartyIcon from 'components/shared/party-icon';
import { RESULT_TEXT_LIMITS } from 'components/UnifiedSearch/utils/constants';

import styles from './styles.module.scss';

type TitleProps = {
  title: string;
  party?: string;
  isMobile?: boolean;
};

const Title: FC<TitleProps> = ({ title, party, isMobile }) => {
  const titleContent = truncate(title, {
    length: isMobile ? RESULT_TEXT_LIMITS.titleMobile : RESULT_TEXT_LIMITS.title,
  });

  return (
    <span className={styles.title}>
      {titleContent}
      {party && (
        <PartyIcon partyShort={party} className={styles.party} />
      )}
    </span>
  );
};

export default Title;
