import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { RELATED_APPS_SECTION } from 'components/NavigationV2/utils/directory/constants/common';
import { categoryShape } from 'components/NavigationV2/utils/directory/shapes';

import Link from './components/Link';
import styles from './styles.module.scss';

function Links({ disabledProducts, activeCategory }) {
  const renderLink = (link, withRelatedAppStyle) => (
    <Link
      link={link}
      withRelatedAppStyle={withRelatedAppStyle}
      disabledProducts={disabledProducts}
      key={link.title}
    />
  );

  const renderSection = ({ id, title, items }) => {
    const withRelatedAppStyle = id === RELATED_APPS_SECTION.id;

    return (
      <div className={styles.categorySection} key={id}>
        {title && <h5 className={styles.title}>{title}</h5>}
        {items.map(link => renderLink(link, withRelatedAppStyle))}
      </div>
    );
  };

  const renderColumn = (sections) => {
    if (isEmpty(sections)) {
      return null;
    }

    const columnKey = sections.map(section => section.id).join('-');

    return (
      <div className={styles.column} key={columnKey}>
        {sections.map(renderSection)}
      </div>
    );
  };

  return (
    <section className={styles.links}>
      {activeCategory.columns.map(renderColumn)}
    </section>
  );
}

Links.propTypes = {
  disabledProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeCategory: categoryShape.isRequired,
};

export default Links;
