import React from 'react';
import { Provider } from 'react-redux';

import store from 'redux/store';
import * as commonActions from 'redux/stakeholder/common/actions';
import * as customFieldsActions from 'redux/customFields/actions';
import reduxConnect from 'utils/redux-connect';

import Container from './components/Container';

const ConnectedContainer = reduxConnect('stakeholder.standalone', {
  ...customFieldsActions,
  ...commonActions,
})(Container);

function NewStakeholderButton(props) {
  return (
    <Provider store={store}>
      <ConnectedContainer {...props} />
    </Provider>
  );
}

export default NewStakeholderButton;
