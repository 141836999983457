import PropTypes from 'prop-types';

function legislatorStaffersFetcher({ legislatorId, onSuccess }) {
  const addNotification = window.app.GlobalNotifier.addNotification;
  const url = `/api_web/legislators/${legislatorId}/staffers`;
  const method = 'GET';

  $.ajax({
    method,
    url,
    success: onSuccess,
    error: () => {
      addNotification({
        message: 'Failed to load staffers.',
        level: 'error',
      });
    },
  });
}

legislatorStaffersFetcher.propTypes = {
  legislatorId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default legislatorStaffersFetcher;
