import { get } from 'lodash';

import { getParenthesisItem, joinCompact } from 'utils/string';

import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/federalRegulations';

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const documentType = get(apiResult, 'options.display_type');

  const typeHighlight = highlights.documentType || documentType;

  const introduced = get(apiResult, 'options.publication_date');

  const subTitle = () => (
    joinCompact([
      baseResult.slugLabel,
      documentType,
      introduced,
    ], ' - ')
  );

  const getSubtitleHighlight = () => (
    joinCompact([
      baseResult.slugLabel,
      typeHighlight,
      introduced,
    ], ' - ')
  );

  const agencyName = get(apiResult, 'options.agencies[0].name');
  const docketNumber = get(apiResult, 'options.docket.id');
  const closesDate = get(apiResult, 'options.comments_close_at');
  const commentClosePeriod = closesDate && `Comment period closes ${closesDate}.`;
  const docketNumberFormatted = getParenthesisItem(docketNumber);

  const getDescription = () => {
    const agencyInfo = joinCompact([
      documentType,
      agencyName && `from the ${agencyName}`,
      docketNumberFormatted,
    ], ' ');

    return joinCompact([agencyInfo, commentClosePeriod], '. ');
  };

  const getDescriptionHighlight = () => {
    const agencyInfo = joinCompact([
      typeHighlight,
      agencyName && `from the ${agencyName}`,
      docketNumberFormatted,
    ], ' ');

    return joinCompact([agencyInfo, commentClosePeriod], '. ');
  };

  return {
    ...baseResult,
    description: getDescription(),
    descriptionHighlight: getDescriptionHighlight(),
    subTitle: subTitle(),
    subtitleHighlight: getSubtitleHighlight(),
    title: get(apiResult, 'options.title'),
    titleHighlight: highlights.title,
  };
};

export default resultFactory;
