import { isEmpty } from 'lodash';
import { Result } from '../types';
import { ApiResponse, TrackingsByRecord } from './types';
import factories from './factories';

const fetchTrackings = async (results: Result[]): Promise<TrackingsByRecord[]> => {
  if (isEmpty(results)) return [];

  const requestData = factories.buildRequestData(results);

  if (!requestData) return [];

  const apiResponse: ApiResponse = await $.ajax({
    method: 'POST',
    url: '/api_web/discovery/feeds/subscriptions/trackings/lookup',
    data: JSON.stringify(requestData),
    contentType: 'application/json',
  });

  return factories.buildResponse(apiResponse);
};

export default {
  fetchTrackings,
};
