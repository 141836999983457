import React from 'react';
import { Provider } from 'react-redux';
import store from 'redux/store';
import Container from './components/container.jsx';

export default function LinkShareIcons(props) {
  return (
    <Provider store={store}>
      <Container {...props} />
    </Provider>
  );
}
