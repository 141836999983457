import truncate from 'lodash/truncate';

import { HighlightedString } from 'components/UnifiedSearch/types';

const OMISSION_LENGTH = 3;

export const truncateHighlight = (highlight: HighlightedString, limit: number) => {
  if (highlight.length <= limit) return highlight;

  const openingEmphasisRegex = /<em>/g;
  const closingEmphasisRegex = /<\/em>/g;

  const openingTag = '<em>';
  const closingTag = '</em>';

  let adjustedLimit = limit;

  while (openingEmphasisRegex.test(highlight) && closingEmphasisRegex.test(highlight)) {
    const openingEmphasisStartIndex = openingEmphasisRegex.lastIndex - openingTag.length;
    const closingEmphasisStartIndex = closingEmphasisRegex.lastIndex - closingTag.length;

    if (openingEmphasisStartIndex < adjustedLimit) {
      adjustedLimit += openingTag.length;
    } else {
      break;
    }

    if ((adjustedLimit - openingEmphasisRegex.lastIndex) <= OMISSION_LENGTH) {
      return truncate(highlight, { length: adjustedLimit - openingTag.length });
    }

    if (closingEmphasisStartIndex <= adjustedLimit) {
      adjustedLimit += closingTag.length;
    } else {
      return truncate(highlight, { length: adjustedLimit }).concat(closingTag);
    }

    if ((adjustedLimit - closingEmphasisRegex.lastIndex) < OMISSION_LENGTH) {
      const prefix = highlight.slice(0, closingEmphasisStartIndex);
      const sufix = highlight.slice(closingEmphasisRegex.lastIndex);

      adjustedLimit -= closingTag.length;

      return truncate(prefix.concat(sufix), { length: adjustedLimit }).concat(closingTag);
    }
  }

  return truncate(highlight, { length: adjustedLimit });
};
