import React, { createContext } from 'react';

export const GlobalSearchContext = createContext();

function withGlobalSearch(Component) {
  return (props) => (
    <GlobalSearchContext.Consumer>
      {(contexts) => <Component {...props} {...contexts} />}
    </GlobalSearchContext.Consumer>
  );
}

export default withGlobalSearch;
