import React, { FC } from 'react';

import { AdvancedOptions } from 'components/UnifiedSearch/types';

import { ActionsWrapper, ApplyButton, BackButton } from '../../../SlideUpModal';
import OptionsWrapper from '../../../OptionsWrapper';
import Option from '../../../Option';
import { handleOptionClick } from '../../helpers';
import { CUSTOM_STATE_DEFAULT } from '../../constants';
import SearchWrapper from '../SearchWrapper';
import { State } from './types';
import useStates from './useStates';
import styles from './styles.module.scss';

type StatesProps = {
  advancedOptions: AdvancedOptions,
  isMobile: boolean,
  onResetCustomRegion: () => void,
  toggleFilter: () => void,
};

const States: FC<StatesProps> = ({
  advancedOptions,
  isMobile,
  onResetCustomRegion,
  toggleFilter,
}) => {
  const states = useStates(advancedOptions);

  const { customRegions } = advancedOptions.values;
  const selectedOptions = isMobile ? states.selectedStates : customRegions;
  const setCustomRegions = (newCustomRegions: State[]) => (
    advancedOptions.change({ customRegions: newCustomRegions })
  );
  const setOptions = isMobile ? states.setSelectedStates : setCustomRegions;

  const renderOption = (state: State) => {
    const isSelected = selectedOptions.some(region => region.value === state.value);
    const content = state.value === 'all' ? state.label : `${state.label} (${state.value})`;

    const handleClick = () => handleOptionClick({
      option: state,
      selectedOptions,
      setOptions,
      defaultOption: CUSTOM_STATE_DEFAULT,
    });

    return (
      <Option
        onClick={handleClick}
        isSelected={isSelected}
        id={state.value}
        className={styles.option}
        key={state.value}
        isMobile={isMobile}
      >
        {content}
      </Option>
    );
  };

  const placeholder = isMobile ? 'Search a State...' : 'State name';

  const handleApply = () => {
    advancedOptions.change({ region: 'state', customRegions: states.selectedStates });
    toggleFilter();
    onResetCustomRegion();
  };

  return (
    <div className={styles.wrapper}>
      <SearchWrapper
        value={states.search}
        onChange={states.handleSearchChange}
        placeholder={placeholder}
      />

      <OptionsWrapper className={styles.options}>
        {states.options.map((state: State) => renderOption(state))}
      </OptionsWrapper>

      {isMobile && (
        <ActionsWrapper>
          <BackButton onClick={onResetCustomRegion} />

          <ApplyButton onClick={handleApply} />
        </ActionsWrapper>
      )}
    </div>
  );
};

export default States;
