import { filter, get } from 'lodash';

import { getParenthesisItem, joinCompact } from 'utils/string';

import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/stateBill';

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const status = get(apiResult, 'options.status_type');
  const session = get(apiResult, 'options.session.name');
  const stateAlpha = get(apiResult, 'options.state.alpha2');

  const subTitle = () => {
    const billSlug = filter([
      baseResult.slugLabel,
      stateAlpha && `${(stateAlpha)}`,
    ]).join(' ');

    return filter([billSlug, status, session]).join(' - ');
  };

  const getSubtitleHighlight = () => {
    const subtitleStateAlpha = highlights.stateAlpha || stateAlpha;
    const subtitleSession = highlights.sessionName || session;

    const billSlug = joinCompact([
      baseResult.slugLabel,
      getParenthesisItem(subtitleStateAlpha),
    ], ' ');

    return joinCompact([billSlug, status, subtitleSession], ' - ');
  };

  const stateName = get(apiResult, 'options.state.name');
  const title = get(apiResult, 'options.title');
  const billNumber = get(apiResult, 'options.bill_number');
  const formattedBillNumber = `${billNumber}:`;

  const getTitle = () => (
    joinCompact([stateName, formattedBillNumber, title], ' ')
  );

  const getTitleHighlight = () => {
    const titleStateName = highlights.stateName || stateName;
    const titleBillNumber = highlights.billNumber
      ? `${highlights.billNumber}:`
      : formattedBillNumber;

    return joinCompact([titleStateName, titleBillNumber, highlights.title || title], ' ');
  };

  return {
    ...baseResult,
    description: get(apiResult, 'options.description'),
    descriptionHighlight: highlights.description,
    stateCode: get(apiResult, 'options.state.alpha2'),
    subTitle: subTitle(),
    subtitleHighlight: getSubtitleHighlight(),
    title: getTitle(),
    titleHighlight: getTitleHighlight(),
  };
};

export default resultFactory;
