import { PEOPLE_COLUMNS, PEOPLE_SECTIONS } from './people';
import {
  LEGISLATION_AND_REGULATION_COLUMNS,
  LEGISLATION_AND_REGULATION_SECTIONS,
} from './legislationAndRegulation';
import { COMMITTEES_COLUMNS, COMMITTEES_SECTIONS } from './committees';
import { FEDERAL_DOCUMENTS_COLUMNS, FEDERAL_DOCUMENTS_SECTIONS } from './federalDocuments';
import {
  STATE_AND_LOCAL_DOCUMENTS_COLUMNS,
  STATE_AND_LOCAL_DOCUMENTS_SECTIONS,
} from './stateAndLocalDocuments';
import { NEWS_AND_SOCIAL_COLUMNS, NEWS_AND_SOCIAL_SECTIONS } from './newsAndSocial';

export const PEOPLE_ID = 'people';
export const LEGISLATION_AND_REGULATION_ID = 'legislationAndRegulation';
export const COMMITTEES_ID = 'committees';
export const FEDERAL_DOCUMENTS_ID = 'federalDocuments';
export const STATE_AND_LOCAL_DOCUMENTS_ID = 'stateAndLocalDocuments';
export const NEWS_AND_SOCIAL_ID = 'newsAndSocial';

const CATEGORIES_BASE = [
  {
    id: PEOPLE_ID,
    title: 'People',
  },
  {
    id: LEGISLATION_AND_REGULATION_ID,
    title: 'Legislation & regulation',
  },
  {
    id: COMMITTEES_ID,
    title: 'Legislative committees',
  },
  {
    id: FEDERAL_DOCUMENTS_ID,
    title: 'Federal documents',
  },
  {
    id: STATE_AND_LOCAL_DOCUMENTS_ID,
    title: 'State & local documents',
  },
  {
    id: NEWS_AND_SOCIAL_ID,
    title: 'News & social',
  },
];

const COLUMNS = {
  [PEOPLE_ID]: PEOPLE_COLUMNS,
  [LEGISLATION_AND_REGULATION_ID]: LEGISLATION_AND_REGULATION_COLUMNS,
  [COMMITTEES_ID]: COMMITTEES_COLUMNS,
  [FEDERAL_DOCUMENTS_ID]: FEDERAL_DOCUMENTS_COLUMNS,
  [STATE_AND_LOCAL_DOCUMENTS_ID]: STATE_AND_LOCAL_DOCUMENTS_COLUMNS,
  [NEWS_AND_SOCIAL_ID]: NEWS_AND_SOCIAL_COLUMNS,
};

export const CATEGORIES = CATEGORIES_BASE.map(
  category => ({ ...category, columns: COLUMNS[category.id] })
);

const SECTIONS = {
  [PEOPLE_ID]: PEOPLE_SECTIONS,
  [LEGISLATION_AND_REGULATION_ID]: LEGISLATION_AND_REGULATION_SECTIONS,
  [COMMITTEES_ID]: COMMITTEES_SECTIONS,
  [FEDERAL_DOCUMENTS_ID]: FEDERAL_DOCUMENTS_SECTIONS,
  [STATE_AND_LOCAL_DOCUMENTS_ID]: STATE_AND_LOCAL_DOCUMENTS_SECTIONS,
  [NEWS_AND_SOCIAL_ID]: NEWS_AND_SOCIAL_SECTIONS,
};

export const MOBILE_CATEGORIES = CATEGORIES_BASE.map(
  category => ({ ...category, sections: SECTIONS[category.id] })
);
