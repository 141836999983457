import { dataURItoBlob } from './helpers';

export const handleUpload = async (props) => {
  const {
    croppedImage,
    options: {
      url, method, inputName, strategySlug, requiredInputs = {},
    },
  } = props;

  const data = new FormData();

  if (method) {
    data.append('_method', method);
  }

  data.append(inputName, dataURItoBlob(croppedImage), 'image.jpg');
  data.append('required_inputs', JSON.stringify(requiredInputs));

  if (strategySlug) {
    data.append('strategy_slug', strategySlug);
  }

  const ajax = await $.ajax({
    url,
    data,
    method: 'POST',
    cache: false,
    contentType: false,
    processData: false,
  });

  return ajax;
};

export const fetchFiles = (fileUploadIds) => {
  const cleanFileIds = fileUploadIds.filter(fileUploadId => !!fileUploadId);

  const requests = cleanFileIds.map((fileUploadId) => {
    const url = `/api_web/file_uploads/${fileUploadId}`;

    const ajax = $.ajax({
      method: 'GET',
      url,
    });

    return Promise.resolve(ajax);
  });

  return Promise.all(requests);
};
