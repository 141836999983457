import React, { createContext } from 'react';

export const StakeholderFormContext = createContext();

function withForm(Component) {
  return props => (
    <StakeholderFormContext.Consumer>
      {context => <Component {...props} {...context} />}
    </StakeholderFormContext.Consumer>
  );
}

export default withForm;
