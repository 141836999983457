import React, { ReactNode, FC } from 'react';
import classNames from 'classnames';

import { SlideUpModal as CoreModal } from 'components/core/Modal';

import Header from './Header';
import styles from './styles.module.scss';

type ModalProps = {
  isOpen: boolean,
  toggleOpen: () => void,
  children: ReactNode,
  className?: string,
  title: string,
  bodyClassName?: string,
};

const SlideUpModal: FC<ModalProps> = ({
  isOpen,
  toggleOpen,
  children,
  className,
  title,
  bodyClassName,
}) => (
  <CoreModal
    isOpen={isOpen}
    toggleOpen={toggleOpen}
    className={className}
    slideDurationMillis={0}
    bodyClassName={classNames(styles.body, bodyClassName)}
  >
    <Header toggleOpen={toggleOpen} title={title} />
    {children}
  </CoreModal>
);

export default SlideUpModal;
