import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import ReactTextMask from 'react-text-mask';
import maskMapping from './mask-mapping';

const HAS_ERROR = 'has-error';

export default class MaskedInput extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    groupClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    value: PropTypes.string,
    errors: PropTypes.array,
    placeholder: PropTypes.string,
    maskSlug: PropTypes.string.isRequired,
    guide: PropTypes.bool,
    required: PropTypes.bool,
    placeholderChar: PropTypes.string,
    keepCharPositions: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }

  static defaultProps = {
    labelClassName: 'col-md-4 control-label',
    inputClassName: 'col-md-8',
    groupClassName: 'form-group',
    errors: [],
    guide: true,
    placeholderChar: '_',
    keepCharPositions: false,
    required: false,
  }

  hasError = () => this.props.errors.length > 0;

  maskMapper = () => {
    const mask = maskMapping[this.props.maskSlug];

    // @neal that is for devs, if they forget to provide
    // maskSlug, is it better to do a console.log here?
    // IMHO alert seems to be much more noticeable
    if (!mask) {
      console.error('Mask Mapping is not defined!');
    }

    return mask;
  }

  label = () => {
    const { label, labelClassName, id } = this.props;

    if (!label) {
      return null;
    }

    return (
      <label className={labelClassName} htmlFor={id}>
        { this.required() }
        { label }
      </label>
    );
  }

  required = () => {
    const { required } = this.props;

    if (!required) {
      return null;
    }

    return (
      <span>
        <abbr title="required">*</abbr>
        &nbsp;
      </span>
    );
  }

  groupClassName = () => classNames({
    [this.props.groupClassName]: true,
    [HAS_ERROR]: this.hasError(),
  });

  error = () => {
    if (!this.hasError()) {
      return null;
    }

    return (
      <span className="help-block">
        {this.props.errors[0]}
      </span>
    );
  }

  placeholder = () => this.props.placeholder || this.maskMapper().placeholder;

  render() {
    const {
      id,
      name,
      value,
      inputClassName,
      guide,
      placeholderChar,
      keepCharPositions,
      onChange,
      onBlur,
    } = this.props;

    return (
      <div className={this.groupClassName()} onBlur={onBlur}>
        {this.label()}

        <div className={inputClassName}>
          <ReactTextMask
            id={id}
            name={name}
            value={value}
            placeholder={this.placeholder()}
            className="form-control"
            mask={this.maskMapper().mask}
            guide={guide}
            placeholderChar={placeholderChar}
            keepCharPositions={keepCharPositions}
            onChange={onChange}
          />
          {this.error()}
        </div>
      </div>
    );
  }
}
