import React, { FC } from 'react';

import {
  OptionsWrapper,
  Option,
} from 'components/UnifiedSearch/components/AdvancedOptions';
import { AdvancedOptions } from 'components/UnifiedSearch/types';

import { CUSTOM_LOCALE_DEFAULT } from '../../../../constants';
import { handleOptionClick } from '../../../../helpers';
import { Locale, Locales } from '../../types';
import styles from './styles.module.scss';

type OptionsProps = {
  advancedOptions: AdvancedOptions,
  locales: Locales,
  isMobile: boolean,
};

const Options: FC<OptionsProps> = ({
  advancedOptions,
  locales,
  isMobile,
}) => {
  const { customRegions } = advancedOptions.values;
  const selectedOptions = isMobile ? locales.selectedLocales : customRegions;

  const setCustomRegions = (newCustomRegions: Locale[]) => (
    advancedOptions.change({ customRegions: newCustomRegions })
  );
  const setOptions = isMobile ? locales.setSelectedLocales : setCustomRegions;

  const renderOption = (locale: Locale) => {
    const isSelected = selectedOptions.some(region => region.value === locale.value);

    const handleClick = () => handleOptionClick({
      option: locale,
      selectedOptions,
      setOptions,
      defaultOption: CUSTOM_LOCALE_DEFAULT,
    });

    return (
      <Option
        onClick={handleClick}
        isSelected={isSelected}
        id={locale.label}
        className={styles.option}
        key={locale.value}
        isMobile={isMobile}
      >
        {locale.label}
      </Option>
    );
  };

  const defaultOption = renderOption(CUSTOM_LOCALE_DEFAULT);

  const hasPlaceholder = !(isMobile || locales.options.length);

  return (
    <OptionsWrapper className={styles.options}>
      {hasPlaceholder && (
        <div className={styles.placeholder}>Type to search</div>
      )}

      {defaultOption}
      {locales.options.map(renderOption)}
    </OptionsWrapper>
  );
};

export default Options;
