export const FEDERAL = 'federal';
export const STATE = 'state';
export const CUSTOM = 'custom';

export const HOUSE = 'house';
export const SENATE = 'senate';

export const TWITTER = 'twitter';
export const FACEBOOK = 'facebook';
export const MAX_TWEET_LENGTH = 200;

export const CAMPAIGN_LEGISLATOR_TYPE = 'legislator';
export const CAMPAIGN_REGULATION_TYPE = 'regulation';
export const CAMPAIGN_TYPES = [
  CAMPAIGN_LEGISLATOR_TYPE,
  CAMPAIGN_REGULATION_TYPE,
];

export const MESSAGES_CUSTOMIZATION_OPTION_APPEND = 'append';
export const MESSAGES_CUSTOMIZATION_OPTION_EDIT = 'edit';
export const MESSAGES_CUSTOMIZATION_OPTION_NONE = 'none';

export const REGULATION_SLUG = 'regulation';

export const DEFAULT_CUSTOM_COLORS = {
  primary_color: '#215fe6',
  secondary_color: '#ffffff',
};

export const MAX_REGULATION_COMMENT_LENGTH = 5000;
export const MAX_REGULATION_COMMENT_COMPOSE_LENGTH = 4000;

export const DISTRICT_SETTINGS = {
  ALL: 'all',
  REPRESENTATIVES: 'representatives',
};
