import PropTypes from 'prop-types';

function suggesterFillerFetcher({ strategySlug, requiredInputs, onSuccess }) {
  const addNotification = window.app.GlobalNotifier.addNotification;
  const url = '/api_web/suggester_filler';
  const method = 'GET';
  const data = {
    strategy_slug: strategySlug,
    required_inputs: JSON.stringify(requiredInputs),
  };

  $.ajax({
    method,
    url,
    data,
    success: onSuccess,
    error: () => {
      addNotification({
        message: 'Failed to add items to select box.',
        level: 'error',
      });
    },
  });
}

suggesterFillerFetcher.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  strategySlug: PropTypes.string.isRequired,
  requiredInputs: PropTypes.object.isRequired,
};

export default suggesterFillerFetcher;
