import { useEffect, useState } from 'react';
import { map } from 'lodash';

import useMountedRef from 'utils/useMountedRef';

import { Position, Result } from '../types';
import { PositionByRecord } from './types';
import api from './api';

type UsePositions = (results: Result[]) => {
  getPosition: (result: Result) => Position | null;
  updatePosition: (result: Result, position: Position | null) => void;
};

const usePositions: UsePositions = (results) => {
  const isMounted = useMountedRef();
  const [positions, setPositions] = useState<PositionByRecord[]>([]);

  const getPosition = (result: Result) => {
    const resultPosition = positions.find(
      position => position.resultId === result.id && position.resultSlug === result.slug,
    );

    if (resultPosition) {
      return resultPosition.position;
    }

    return null;
  };

  const fetchPositions = async () => {
    const userPositions = await api.fetchPositions(results);

    if (!isMounted) return;

    setPositions(userPositions);
  };

  const updatePosition = (result: Result, newPosition: Position | null) => {
    const updatedPositions = positions.map((position) => {
      if (position.resultId === result.id && position.resultSlug === result.slug) {
        return {
          ...position,
          position: newPosition,
        };
      }

      return position;
    });

    setPositions(updatedPositions);
  };

  useEffect(() => {
    fetchPositions();
  }, [JSON.stringify(map(results, 'key'))]);

  return {
    getPosition,
    updatePosition,
  };
};

export default usePositions;
