import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import { linkShape } from 'components/core/Link/shapes';
import Link from 'components/core/Link';
import { isExternal } from 'utils/url';
import { trackQuickLinksResults } from 'utils/tracking/globalSearch';

import styles from './styles.module.scss';

export default function QuickLinks({ quickLinks = gon.quick_links }) {
  if (isEmpty(quickLinks)) return null;

  const renderQuickLink = ({ label, url }) => {
    const isExternalUrl = isExternal(url);

    return (
      <Link
        href={url}
        theme="default"
        className={styles.link}
        key={url}
        isExternal={isExternalUrl}
        onClick={() => trackQuickLinksResults(label, url, quickLinks.length)}
      >
        {label}
      </Link>
    );
  };

  return (
    <div className={styles.container}>
      <h2>QUICK LINKS</h2>
      <nav className={styles.links}>
        {quickLinks.map(renderQuickLink)}
      </nav>
    </div>
  );
}

QuickLinks.propTypes = {
  quickLinks: PropTypes.arrayOf(linkShape),
};
