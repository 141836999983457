import { COLLECTIONS_DEFAULT } from '../utils/constants';
import { AdvancedOptionsValues } from '../types';

export const INITIAL_STATE: AdvancedOptionsValues = {
  period: 'any',
  customPeriod: null,
  region: 'all',
  customRegions: [],
  collections: COLLECTIONS_DEFAULT,
  verbatim: 'all',
};
