import { filter, get } from 'lodash';

import { joinCompact } from 'utils/string';

import { ResultFactory } from '../../types';
import getBaseResult from '../base';
import { getHighlights } from './helpers/federalBill';

const resultFactory: ResultFactory = (apiResult) => {
  const baseResult = getBaseResult(apiResult);
  const highlights = getHighlights(apiResult);

  const getSubTitle = () => {
    const status = get(apiResult, 'options.status_full');

    return filter([
      baseResult.slugLabel,
      status,
    ]).join(' - ');
  };

  const displayId = get(apiResult, 'options.display_id');
  const displayTitle = get(apiResult, 'options.display_title');

  const getTitle = () => joinCompact([displayId, displayTitle], ': ');

  const getTitleHighlight = () => (
    joinCompact([
      highlights.displayId || displayId,
      highlights.officialTitle || displayTitle,
    ], ': ')
  );

  return {
    ...baseResult,
    description: displayTitle,
    descriptionHighlight: highlights.officialTitle || highlights.text || displayTitle,
    subTitle: getSubTitle(),
    title: getTitle(),
    titleHighlight: getTitleHighlight(),
  };
};

export default resultFactory;
