import React from 'react';

import TabsNavigation from 'components/core/TabsNavigation';

import options from './utils/options';

function CosponsorshipNavBar() {
  return (
    <div className="navBarDropdownPadding">
      <TabsNavigation options={options} />
    </div>
  );
}

export default CosponsorshipNavBar;
