import React, { FC } from 'react';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

type BackButtonProps = {
  onClick: () => void,
};

const BackButton: FC<BackButtonProps> = ({ onClick }) => (
  <Button theme="light" onClick={onClick} className={styles.back}>
    <i className="fas fa-arrow-left" /> Back
  </Button>
);

export default BackButton;
