import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { updateTrackShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { CommitteeTrack } from 'components/Dashboard/common/MultipleTrack';
import { multipleSubscriptionShape } from 'components/Dashboard/utils/shapes';
import { TRACK_SUBSCRIPTION_FREQUENCIES } from 'redux/dashboardV2/constants';
import Button from 'components/core/Button';
import Modal from 'components/core/Modal';
import { frequencyType } from 'components/core/FrequencyOptions/shapes';
import { workspacesShape } from 'components/core/TrackButton/shapes';
import TrackFrequencyContent from 'components/Dashboard/common/TrackFrequencyContent';
import FrequencyCard from 'components/Dashboard/common/FrequencyCard';
import { trackingShape } from 'redux/subscriptions/utils/shapes';

import MyFeedFrequency from './components/MyFeedFrequency';
import WorkspacesSelect from './components/WorkspacesSelect';
import SlideUpTrackModal from './components/SlideUpTrackModal';
import styles from './styles.module.scss';

function TrackModal({
  feedToken,
  isCommittee,
  isMobile,
  isLoading,
  isOpenModal,
  documentType,
  recordType,
  itemType,
  title,
  frequencyTitle,
  frequency,
  withWeekAheadEmail,
  sendWeekAheadEmail,
  withSlideUp,
  withHeaderNode,
  defaultSubscription,
  listSubscriptions,
  updateParams,
  onUpdateTrackCommittee,
  onChangeSendEmailMobile,
  onChangeSendEmail,
  onSaveMobile,
  onSave,
  onChange,
  toggleModal,
  workspaces,
  onCancel,
  trackings,
  withSelectedWorkspace,
  withSaveToWorkspaces,
  isCheckboxDisabled,
}) {
  const modalProps = {
    documentType,
    itemType,
    title,
    frequency,
    toggleModal,
    isLoading,
    isOpenModal,
    onChange,
    onSaveMobile,
    onSave,
    sendWeekAheadEmail,
    onChangeSendEmailMobile,
    withWeekAheadEmail,
  };
  const showWorkspacesSelect = withSaveToWorkspaces && gon.isWorkspacesEnabled;

  if (isCommittee) {
    return (
      <CommitteeTrack
        {...modalProps}
        feedToken={feedToken}
        defaultSubscription={defaultSubscription}
        listSubscriptions={listSubscriptions}
        updateParams={updateParams}
        onUpdateTrackCommittee={onUpdateTrackCommittee}
        workspaces={workspaces}
        trackings={trackings}
        withWorkspacesSelect={showWorkspacesSelect}
        withSelectedWorkspace={withSelectedWorkspace}
      />
    );
  }

  if (withSlideUp && isMobile && isOpenModal) {
    return <SlideUpTrackModal {...modalProps} />;
  }

  const buttonsNode = (
    <>
      <Button
        theme="light"
        size="small"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        size="small"
        onClick={onSave}
        disabled={isLoading || isEmpty(frequency)}
        isLoading={isLoading}
      >
        Save
      </Button>
    </>
  );

  const frequencyContentNode = (
    <TrackFrequencyContent
      isMobile={isMobile}
      isLoading={isLoading}
      frequency={frequency}
      withWeekAheadEmail={withWeekAheadEmail}
      sendWeekAheadEmail={sendWeekAheadEmail}
      onChangeSendEmail={onChangeSendEmail}
      onChange={onChange}
      withFrequency
    />
  );

  const renderContent = () => {
    if (withSelectedWorkspace || !showWorkspacesSelect) {
      return (
        <FrequencyCard
          label={TRACK_SUBSCRIPTION_FREQUENCIES[recordType]}
          title={frequencyTitle}
          withHeaderNode={withHeaderNode}
          isDisabled
        >
          {frequencyContentNode}
        </FrequencyCard>
      );
    }

    return (
      <>
        <MyFeedFrequency
          isChecked={workspaces.isMyFeedChecked}
          onCheck={workspaces.onCheckMyFeed}
          isCheckboxDisabled={isCheckboxDisabled}
        >
          {frequencyContentNode}
        </MyFeedFrequency>
        {showWorkspacesSelect && <WorkspacesSelect workspaces={workspaces} />}
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpenModal}
      theme="scrollable"
      title={title}
      buttons={buttonsNode}
      toggleOpen={onCancel}
      className={styles.modal}
      bodyClassName={styles.body}
    >
      {renderContent()}
    </Modal>
  );
}

TrackModal.defaultProps = {
  title: 'Subscription settings',
  withSlideUp: false,
  isLoading: false,
  withSelectedWorkspace: false,
  withSaveToWorkspaces: true,
  isCheckboxDisabled: false,
};

TrackModal.propTypes = {
  ...multipleSubscriptionShape,
  feedToken: PropTypes.string,
  itemType: PropTypes.string,
  subscribableType: PropTypes.string,
  isMobile: PropTypes.bool,
  withSlideUp: PropTypes.bool,
  withHeaderNode: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpenModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  documentType: PropTypes.string,
  title: PropTypes.string,
  frequencyTitle: PropTypes.string,
  frequency: frequencyType,
  onSave: PropTypes.func.isRequired,
  onSaveMobile: PropTypes.func.isRequired,
  withWeekAheadEmail: PropTypes.bool,
  sendWeekAheadEmail: PropTypes.bool,
  onChangeSendEmail: PropTypes.func,
  onChangeSendEmailMobile: PropTypes.func,
  updateParams: updateTrackShape,
  defaultSubscription: PropTypes.string,
  onUpdateTrackCommittee: PropTypes.func,
  workspaces: workspacesShape,
  onCancel: PropTypes.func.isRequired,
  trackings: PropTypes.arrayOf(trackingShape),
  withSelectedWorkspace: PropTypes.bool,
  withSaveToWorkspaces: PropTypes.bool,
  isCheckboxDisabled: PropTypes.bool,
};

export default memo(TrackModal);
export { SlideUpTrackModal };
