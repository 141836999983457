import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SomethingWentWrong from 'components/Errors/SomethingWentWrong';

import styles from './styles.module.scss';

export default function Placeholder({ className, isRecentVisitsError }) {
  if (isRecentVisitsError) {
    return <SomethingWentWrong />;
  }

  return (
    <p className={classNames(styles.warning, className)}>
      Search for nearly anything found in the app!<br />
      Enter a term then click on a result to go directly to that item's page.
    </p>
  );
}

Placeholder.propTypes = {
  className: PropTypes.string,
  isRecentVisitsError: PropTypes.bool,
};
