import React from 'react';

import styles from './styles.module.scss';

const Loading = () => (
  <div className={styles.loadingWrapper}>
    <img src="/images/loading_search.gif" className={styles.loadingImage} alt="loading..." />
  </div>
);

export default Loading;
