import React, { FC, KeyboardEvent, MouseEvent } from 'react';
import classNames from 'classnames';

import styles from '../../styles.module.scss';

type ActionIconsProps = {
  onRemove: () => void;
  isMobile?: boolean;
};

const ActionIcons: FC<ActionIconsProps> = ({ onRemove, isMobile }) => {
  const handleRemove = (event: MouseEvent | KeyboardEvent) => {
    event.stopPropagation();
    onRemove();
  };

  return (
    <>
      <i
        className={classNames(styles.delete, 'fas fa-times')}
        onClick={handleRemove}
        onKeyDown={handleRemove}
      />
      {!isMobile && <i className={classNames(styles.enter, 'far fa-level-down-alt')} />}
    </>
  );
};

export default ActionIcons;
