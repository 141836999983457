import React from 'react';
import { Provider } from 'react-redux';
import store from 'redux/store';
import Container from './components/container.jsx';

export default function Autocomplete(props) {
  return (
    <Provider store={store}>
      <Container {...props} />
    </Provider>
  );
}

export function sortAutocompleteOptions(options) {
  return options.sort((prevOption, nextOption) => {
    const prevLabel = prevOption.label.toLowerCase();
    const nextLabel = nextOption.label.toLowerCase();

    if (prevLabel < nextLabel) {
      return -1;
    }

    if (prevLabel > nextLabel) {
      return 1;
    }

    return 0;
  });
}
