import React, { FC, ReactNode } from 'react';
import Tooltip from '@tippyjs/react';

import Trigger from '../Trigger';
import styles from './styles.module.scss';

type DropdownProps = {
  isVisible: boolean,
  content: ReactNode,
  onClickOutside: () => void,
  onClick: () => void,
  children: ReactNode,
  isDisabled?: boolean,
};

const Dropdown: FC<DropdownProps> = ({
  isVisible,
  content,
  onClickOutside,
  onClick,
  children,
  isDisabled,
}) => (
  <Tooltip
    visible={isVisible}
    content={content}
    theme="light"
    arrow={false}
    className={styles.options}
    onClickOutside={onClickOutside}
    placement="bottom-start"
    maxWidth="none"
    interactive
  >
    <span>
      <Trigger
        onClick={onClick}
        isDisabled={isDisabled}
      >
        {children}
      </Trigger>
    </span>
  </Tooltip>
);

export default Dropdown;
