import React from 'react';

import styles from './styles.module.scss';

function PageNotFound() {
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <a href="/">
          <img
            src="/images/intelligence-logo.svg"
            alt="Intelligence Logo"
            height="25"
          />
        </a>
        <a className={styles.link} href="/">
          Return Home
        </a>
      </div>
      <div className={styles.content}>
        <h1>
          404 - Page Not Found
        </h1>
        <h3>
          Oh no, it looks like the page you were looking for can’t be found.
          Please check the URL you entered or
          <a onClick={goBack} className={styles.link} onKeyDown={goBack}>
            go back to the previous page.
          </a>
        </h3>
      </div>
      <div className={styles.imgContainer}>
        <img src="/images/404-img.svg" alt="Intelligence" />
      </div>
    </div>
  );
}

export default PageNotFound;
