import { isMyFeed } from 'components/Dashboard/utils';
import { BASE_URL } from 'components/Dashboard/utils/constants';

const baseWorkspacesUrl = `${BASE_URL}/workspaces`;

export const getViewUrl = feedToken => (
  isMyFeed(feedToken)
    ? `${BASE_URL}/feed`
    : `${baseWorkspacesUrl}/${feedToken}`
);

export const getEditUrl = feedToken => (
  isMyFeed(feedToken)
    ? `${BASE_URL}/my_subscriptions`
    : `${baseWorkspacesUrl}/${feedToken}/settings`
);
