import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import {
  allCustomFieldsShape,
  profileShape,
  customFieldsAnswersShape,
} from 'components/Advocates/common/utils/shapes';

import useForm from '../../utils/useForm';
import withForm from '../../utils/withForm';
import Form from '../Form';

function Container({
  isOpen,
  toggleOpen,
  customField,
  allCustomFields,
  fetchCustomFields,
  createStakeholder,
  updateStakeholder,
  currentStep,
  handleChangeCurrentStep,
  stakeholder,
  isProfilePageUpdate,
  avoidDispatchFetch,
  onSuccess,
  ...restProps
}) {
  const {
    initialValues,
    schema,
    onSubmit,
    ...formProps
  } = useForm({
    isOpen,
    stakeholder,
    toggleOpen,
    customField,
    allCustomFields,
    createStakeholder,
    updateStakeholder,
    fetchCustomFields,
    currentStep,
    handleChangeCurrentStep,
    isProfilePageUpdate,
    avoidDispatchFetch,
    onSuccess,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {formikProps => (
        <Form
          {...formikProps}
          {...restProps}
          {...formProps}
        />
      )}
    </Formik>
  );
}

Container.defaultProps = {
  customField: {},
  avoidDispatchFetch: false,
};

Container.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  fetchCustomFields: PropTypes.func.isRequired,
  createStakeholder: PropTypes.func,
  updateStakeholder: PropTypes.func,
  handleChangeCurrentStep: PropTypes.func,
  allCustomFields: allCustomFieldsShape,
  customField: customFieldsAnswersShape,
  currentStep: PropTypes.number,
  stakeholder: profileShape,
  isProfilePageUpdate: PropTypes.bool,
  avoidDispatchFetch: PropTypes.bool,
  onSuccess: PropTypes.func,
};

export default withForm(Container);
