import React from 'react';
import PropTypes from 'prop-types';

import KEY_CODES from 'utils/keyCodes';
import CoreInput from 'components/core/Input';
import withGlobalSearch from 'components/GlobalSearch/utils/withGlobalSearch';

import styles from './styles.module.scss';

export const GLOBAL_SEARCH_INPUT_ID = 'global-search__overriden-input';

function Input({ onChange, onBlur, innerRef, onFocus, value, placeholder }) {
  const onKeyDown = ({ keyCode, target }) => {
    const wasEscPressed = keyCode === KEY_CODES.ESCAPE;

    if (wasEscPressed) {
      target.blur();
      onBlur();
    }
  };

  return (
    <div className={styles.inputWrapper}>
      <CoreInput
        id={GLOBAL_SEARCH_INPUT_ID}
        className={styles.input}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        ref={innerRef}
        onFocus={onFocus}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
}

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  innerRef: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default withGlobalSearch(Input);
