import sortDirections from 'utils/constants/sortDirections';

export const BASE_URL = '/dashboard_v2';

export const RECENT = 'recent';
export const TRACKED = 'tracked';
export const EXPORT_LIMIT = 100;
export const TITLE_MAX_LENGTH = 155;
export const DESCRIPTION_MAX_LENGTH = 280;

export const FILTER_READ_HIDE_READ = 'hide_read';
export const FILTER_READ_HIDE_UNREAD = 'hide_unread';

export const responseEventTypes = {
  SHARED_DOCUMENT: 'shared_document',
  FEDERAL_BILL_ACTION: 'federal_bill_action', // is common
  FEDERAL_BILL_COSPONSOR: 'federal_bill_cosponsor',
  FEDERAL_BILL_TEXT: 'federal_bill_text',
  FEDERAL_BILL_HEARING: 'federal_bill_hearing',
  FEDERAL_COMMITTEE_HEARING: 'federal_committee_hearing', // is common
  FEDERAL_REGULATION_PUBLIC_SUBMISSION: 'federal_regulation_public_submission',
  FEDERAL_STAFFER_JOIN: 'federal_staffer_join',
  FEDERAL_STAFFER_LEAVE: 'federal_staffer_leave',
  FEDERAL_STAFFER_TITLE_CHANGE: 'federal_staffer_title_change',
  STATE_BILL_ACTION: 'state_bill_action', // is common
  STATE_BILL_SPONSOR: 'state_bill_sponsor',
  STATE_REGULATION_PUBLICATION: 'state_regulation_publication',
  STATE_REGULATION_STATE_AGENCY_NOTICE: 'state_regulation_state_agency_notice',
  STATE_REGULATION_STATE_NOTICE: 'state_regulation_state_notice',
  STATE_STAFFER_JOIN: 'state_staffer_join',
  STATE_STAFFER_LEAVE: 'state_staffer_leave',
  STATE_STAFFER_TITLE_CHANGE: 'state_staffer_title_change',
  KEYWORD_FEDERAL_BILL: 'keyword_federal_bill',
  KEYWORD_CONGRESSIONAL_RECORD: 'keyword_congressional_record',
  KEYWORD_DOCTROVE_AGENCY_PRESS_RELEASE: 'keyword_doctrove_agency_press_release',
  KEYWORD_DOCTROVE_CBO: 'keyword_doctrove_cbo',
  KEYWORD_DOCTROVE_COMMITTEE_REPORT: 'keyword_doctrove_committee_report',
  KEYWORD_DOCTROVE_CRS: 'keyword_doctrove_crs',
  KEYWORD_DOCTROVE_DEAR_COLLEAGUE_LETTER: 'keyword_doctrove_dear_colleague_letter',
  KEYWORD_DOCTROVE_EXECUTIVE_ORDER: 'keyword_doctrove_executive_order',
  KEYWORD_DOCTROVE_FBO: 'keyword_doctrove_fbo',
  KEYWORD_DOCTROVE_GAO: 'keyword_doctrove_gao',
  KEYWORD_DOCTROVE_GOVERNOR_ORDER: 'keyword_doctrove_governor_order',
  KEYWORD_DOCTROVE_GOVERNOR_PRESS_RELEASE: 'keyword_doctrove_governor_press_release',
  KEYWORD_DOCTROVE_GRANT: 'keyword_doctrove_grant',
  KEYWORD_DOCTROVE_HEARING_TRANSCRIPT: 'keyword_doctrove_hearing_transcript',
  KEYWORD_DOCTROVE_LIVE_HEARING_TRANSCRIPT: 'keyword_doctrove_live_hearing_transcript',
  KEYWORD_DOCTROVE_PRESS_BRIEFING: 'keyword_doctrove_press_briefing',
  KEYWORD_DOCTROVE_PRESS_RELEASE: 'keyword_doctrove_press_release',
  KEYWORD_DOCTROVE_SAP: 'keyword_doctrove_sap',
  KEYWORD_DOCTROVE_THIRD_PARTY_REPORT: 'keyword_doctrove_third_party_report',
  KEYWORD_DOCTROVE_TREATY: 'keyword_doctrove_treaty',
  KEYWORD_DOCTROVE_UNION_STATEMENT: 'keyword_doctrove_union_statement',
  KEYWORD_FEDERAL_REGULATION: 'keyword_federal_regulation',
  KEYWORD_SOCIAL_MEDIA_POST: 'keyword_social_media_post',
  KEYWORD_STATE_BILL: 'keyword_state_bill',
  KEYWORD_STATE_REGULATION: 'keyword_state_regulation',
  KEYWORD_TOWN_INTEL_DOCUMENT: 'keyword_town_intel_document',
  FEDERAL_BILL_USER_POSITION: 'federal_bill_user_position',
  FEDERAL_REGULATION_USER_POSITION: 'federal_regulation_user_position',
  STATE_BILL_USER_POSITION: 'state_bill_user_position',
  STATE_REGULATION_USER_POSITION: 'state_regulation_user_position',
  STATE_COMMITTEE_HEARING: 'state_committee_hearing',
  NEWS_STORY: 'news_story',
};

export const responseTrackingTypes = {
  FEDERAL_BILL: 'federal_bill',
  FEDERAL_REGULATION: 'federal_register_regulation',
  FEDERAL_LEGISLATOR: 'federal_legislator',
  FEDERAL_COMMITTEE_HEARING: 'federal_committee_hearing',
  FEDERAL_COMMITTEE_STAFFER: 'federal_committee_staffer',
  FEDERAL_LEGISLATOR_STAFFER: 'federal_legislator_staffer',
  STATE_BILL: 'state_bill',
  STATE_REGULATION: 'state_regulation',
  STATE_COMMITTEE_HEARING: 'state_committee_hearing',
  STATE_COMMITTEE_STAFFER: 'state_committee_staffer',
  STATE_LEGISLATOR_STAFFER: 'state_legislator_staffer',
  STATE_LEGISLATOR: 'state_legislator',
};

export const SUBSCRIBABLE_TYPES = {
  KEYWORD: 'keyword',
  FEDERAL_BILL: 'bill',
  COMMITTEE: 'committee',
  FEDERAL_REGISTER_REGULATION: 'federal_register_regulation',
  FEDERAL_STAFFER_OFFICE: 'staffer_office',
  STATE_BILL: 'state_bill',
  STATE_COMMITTEE: 'state_committee',
  STATE_REGULATION: 'state_regulation',
  STATE_STAFFER_OFFICE: 'state_staffer_office',
  STATE_COMMITTEE_STAFFER: 'state_committee_staffer',
  NEWSFEED: 'news',
  WORKSPACE: 'workspace',
};

export const DOCUMENT_TYPES = {
  FEDERAL_BILL: 'federal_bill',
  STATE_BILL: 'state_bill',
  STATE_LEGISLATOR: 'state_legislator',
  STATE_LEGISLATOR_STAFFER: 'state_legislator_staffer',
  STATE_EXECUTIVE: 'state_executive',
  STATE_AGENCY_STAFFER: 'state_agency_staffer',
  STATE_STAFFER: 'state_staffer',
  FEDERAL_REGISTER_REGULATION: 'federal_register_regulation',
  STATE_REGULATION: 'state_regulation',
  STATE_REGULATION_DOCUMENT: 'state_regulation_document',
  FEDERAL_COMMITTEE: 'federal_committee',
  FEDERAL_LEGISLATOR: 'federal_legislator',
  FEDERAL_LEGISLATOR_STAFFER: 'federal_legislator_staffer',
  FEDERAL_AGENCY_STAFFER: 'federal_executive',
  CONGRESSIONAL_STAFFER: 'federal_staffer',
  STATE_COMMITTEE: 'state_committee',
  STATE_COMMITTEE_STAFFER: 'state_committee_staffer',
  TOWN_INTEL_DOCUMENT: 'town_intel_document',
  HEARING_TRANSCRIPT: 'doctrove_hearing_transcript',
  COMMITTEE_REPORT: 'doctrove_committee_report',
  CONGRESSIONAL_RECORD: 'congressional_record',
  SOCIAL_MEDIA_POST: 'social_media_post',
  DOCTROVE_PRESS_RELEASE: 'doctrove_press_release',
  DOCTROVE_GOVERNOR_ORDER: 'doctrove_governor_order',
  DOCTROVE_GOVERNOR_PRESS_RELEASE: 'doctrove_governor_press_release',
  DOCTROVE_AGENCY_PRESS_RELEASE: 'doctrove_agency_press_release',
  DOCTROVE_TREATY: 'doctrove_treaty',
  DOCTROVE_GAO: 'doctrove_gao',
  NEWS_SEARCH: 'news_search',
  NEWS_STORY: 'news_story',
  GRM_V2_CARD: 'grm_v2_card',
  LOCAL_EXECUTIVE: 'local_executive',
};

export const POSITION_BUTTON_RECORD_TYPES = {
  [DOCUMENT_TYPES.FEDERAL_BILL]: 'federal_bill',
  [DOCUMENT_TYPES.STATE_BILL]: 'state_bill',
  [DOCUMENT_TYPES.FEDERAL_REGISTER_REGULATION]: 'federal_regulation',
  [responseTrackingTypes.FEDERAL_REGULATION]: 'federal_regulation',
  [DOCUMENT_TYPES.STATE_REGULATION]: 'state_regulation',
};

export const FEDERAL_REGULATION_TYPES = {
  notice: 'Notice',
  proposed_rule: 'Proposed rule',
  rule: 'Rule',
  other: 'Other',
};

export const SAVE_ACTION = 'SAVE_ACTION';
export const PRIORITY_ACTION = 'PRIORITY_ACTION';
export const SUBSCRIPTION_ACTION = 'SUBSCRIPTION_ACTION';
export const READ_ACTION = 'READ_ACTION';

export const TRACKED_DEFAULT_SORT = {
  label: 'Last action date (New→Old)',
  value: 'last_action_date',
  direction: sortDirections.DESC,
};

export const TRACKED_SORT_OPTIONS = [
  TRACKED_DEFAULT_SORT,
  {
    label: 'Last action date (Old→New)',
    value: 'last_action_date',
    direction: sortDirections.ASC,
  },
  {
    label: 'State (A→Z)',
    value: 'state_name',
    direction: sortDirections.ASC,
  },
  {
    label: 'State (Z→A)',
    value: 'state_name',
    direction: sortDirections.DESC,
  },
  {
    label: 'Title (A→Z)',
    value: 'title',
    direction: sortDirections.ASC,
  },
  {
    label: 'Title (Z→A)',
    value: 'title',
    direction: sortDirections.DESC,
  },
  {
    label: 'Item type (A→Z)',
    value: 'item_type',
    direction: sortDirections.ASC,
  },
  {
    label: 'Item type (Z→A)',
    value: 'item_type',
    direction: sortDirections.DESC,
  },
  {
    label: 'Priority (High→Low)',
    value: 'priority',
    direction: sortDirections.DESC,
  },
  {
    label: 'Priority (Low→High)',
    value: 'priority',
    direction: sortDirections.ASC,
  },
];

export const DASHBOARD_BASE_URL = '/dashboard_v2/feed';

export const BACK_TO_WORKSPACES = 'workspaces';
