import { useEffect, useState } from 'react';
import { map } from 'lodash';

import useMountedRef from 'utils/useMountedRef';

import { Result, Tag } from '../types';
import { TagsByRecord } from './types';
import api from './api';

type UseTags = (results: Result[]) => {
  getTags: (result: Result) => Tag[];
  updateTags: (result: Result, tags: Tag[]) => void;
};

const useTags: UseTags = (results) => {
  const isMounted = useMountedRef();
  const [tags, setTags] = useState<TagsByRecord[]>([]);

  const getTags = (result: Result) => {
    const resultTags = tags.find(
      tag => tag.resultId === result.id && tag.resultSlug === result.slug,
    );

    if (resultTags) {
      return resultTags.tags;
    }

    return [];
  };

  const fetchTags = async () => {
    const newTags = await api.fetchTags(results);

    if (!isMounted) return;

    setTags(newTags);
  };

  const updateTags = (result: Result, newTags: Tag[]) => {
    const updatedTags = tags.map((tag) => {
      if (tag.resultId === result.id && tag.resultSlug === result.slug) {
        return {
          ...tag,
          tags: newTags,
        };
      }

      return tag;
    });

    setTags(updatedTags);
  };

  useEffect(() => {
    fetchTags();
  }, [JSON.stringify(map(results, 'key'))]);

  return {
    getTags,
    updateTags,
  };
};

export default useTags;
