import { useEffect, useState } from 'react';

function useButtonState(props) {
  const { isPresentHearings, isPresentStaffers } = props || {};
  const [isHearing, setHearings] = useState(isPresentHearings || false);
  const [isStaffer, setStaffers] = useState(isPresentStaffers || false);

  function resetButtonState() {
    if (isPresentStaffers) setStaffers(true);
    if (isPresentHearings) setHearings(true);
  }

  useEffect(() => {
    resetButtonState();
  }, [isPresentHearings, isPresentStaffers]);

  return {
    isHearing,
    isStaffer,
    setStaffers,
    setHearings,
    resetButtonState,
  };
}

export default useButtonState;
