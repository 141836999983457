import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filter, isEmpty } from 'lodash';

import MultiStepModal from 'components/core/Modal/components/MultiStep';
import {
  allCustomFieldsShape,
  profileShape,
  formStatusShape,
  errorsShape,
} from 'components/Advocates/common/utils/shapes';

import useErrorStepRedirect from '../../utils/useErrorStepRedirect';
import withForm from '../../utils/withForm';
import CustomFields from './components/CustomFields';
import AdditionalDetails from './components/AdditionalDetails';
import BasicInfo from './components/BasicInfo';
import styles from './styles.module.scss';

function Form({
  isOpen,
  toggleOpen,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  submitCount,
  isValid,
  allCustomFields,
  resetForm,
  currentStep,
  isLoading,
  onRedirect,
  setFieldTouched,
  onChangeCurrentStep,
  submitForm,
  isSubmitting,
  isLoadingCustomFields,
}) {
  const [isCustomFieldsRedirect, setCustomFieldsRedirect] = useState(false);

  function handleFormChange(props) {
    handleChange(props);
    onRedirect(null);
    setCustomFieldsRedirect(false);
  }

  const commonProps = {
    formState: { errors, touched },
    values,
    handleChange: handleFormChange,
    handleBlur,
    setFieldValue,
    submitForm,
    isSubmitting,
  };
  const requiredCustomFields = filter(allCustomFields, 'required');

  const steps = [
    {
      index: 0,
      title: 'Basic information',
      errorFields: ['firstName', 'middleName', 'lastName', 'addresses', 'phones', 'emails'],
      component: <BasicInfo {...commonProps} setFieldTouched={setFieldTouched} />,
    },
    {
      index: 1,
      title: 'Additional details',
      errorFields: ['partyCode', 'organization', 'title', 'lists', 'tags', 'facebookProfile', 'twitterName'],
      component: <AdditionalDetails {...commonProps} />,
    },
    {
      index: 2,
      title: 'Custom fields',
      errorFields: requiredCustomFields.map(customField => customField.token),
      component: (
        <CustomFields
          {...commonProps}
          allCustomFields={allCustomFields}
          isCustomFieldsRedirect={isCustomFieldsRedirect}
          onCustomFieldsRedirect={setCustomFieldsRedirect}
          isLoadingCustomFields={isLoadingCustomFields}
        />
      ),
    },
  ];

  useErrorStepRedirect({
    submitCount,
    errors,
    isValid,
    onRedirect,
    steps,
  });

  const closeMessage = <p>You're about to exit the stakeholder edit window. Are you sure you want to exit?</p>;

  const onSubmit = () => {
    handleSubmit();
    if (!isEmpty(errors)) {
      requiredCustomFields.forEach(customField => setFieldTouched(customField.token));
    }
  };

  return (
    <div className={styles.formControls}>
      <form onSubmit={onSubmit}>
        <MultiStepModal
          isOpen={isOpen}
          toggleOpen={toggleOpen}
          steps={steps}
          handleAction={onSubmit}
          customCloseMessage={closeMessage}
          withCloseConfirmation
          onReset={resetForm}
          currentStep={currentStep}
          resetStep={submitCount}
          isLoading={isLoading}
          isSaving={isSubmitting}
          onChangeCurrentStep={onChangeCurrentStep}
          showLoadingPlaceholder={false}
        />
      </form>
    </div>
  );
}

Form.propTypes = {
  values: profileShape.isRequired,
  errors: errorsShape.isRequired,
  touched: formStatusShape.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  allCustomFields: allCustomFieldsShape.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  currentStep: PropTypes.number,
  isLoading: PropTypes.bool,
  onRedirect: PropTypes.func,
  onChangeCurrentStep: PropTypes.func,
  submitForm: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isLoadingCustomFields: PropTypes.bool.isRequired,
};

export default withForm(Form);
