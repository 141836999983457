import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Dome({ className }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.icon, className)}>
      <path d="M1.59375 8.5H15.4062C15.6984 8.5 15.9375 8.26094 15.9375 7.96875V6.90625C15.9375 6.61406 15.6984 6.375 15.4062 6.375H14.7787C14.3305 3.70879 12.2287 1.60703 9.5625 1.15879V0.53125C9.5625 0.239063 9.32344 0 9.03125 0H7.96875C7.67656 0 7.4375 0.239063 7.4375 0.53125V1.15879C4.77129 1.60703 2.66953 3.70879 2.22129 6.375H1.59375C1.30156 6.375 1.0625 6.61406 1.0625 6.90625V7.96875C1.0625 8.26094 1.30156 8.5 1.59375 8.5Z" />
      <path d="M16.4688 14.5714H15.9375V13.9643C15.9375 13.6304 15.6984 13.3571 15.4062 13.3571H14.875V8.5H12.75V13.3571H9.5625V8.5H7.4375V13.3571H4.25V8.5H2.125V13.3571H1.59375C1.30156 13.3571 1.0625 13.6304 1.0625 13.9643V14.5714H0.53125C0.239063 14.5714 0 14.8446 0 15.1786V16.3929C0 16.7268 0.239063 17 0.53125 17H16.4688C16.7609 17 17 16.7268 17 16.3929V15.1786C17 14.8446 16.7609 14.5714 16.4688 14.5714Z" />
    </svg>
  );
}

Dome.propTypes = {
  className: PropTypes.string,
};

export default Dome;
