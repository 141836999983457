import React, { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

type CustomRegionsWrapperProps = {
  children: ReactNode,
};

const CustomRegionsWrapper: FC<CustomRegionsWrapperProps> = ({ children }) => (
  <span className={styles.customRegions}>{children}</span>
);

export default CustomRegionsWrapper;
