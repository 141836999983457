import React from 'react';

import { RecentVisits as RecentVisitsType } from '../../types';
import RecentVisits from './components/RecentVisits';
import QuickLinks from './components/QuickLinks';
import styles from './styles.module.scss';

type LobbyProps = {
  recentVisits: RecentVisitsType;
};

const Lobby = ({ recentVisits }: LobbyProps) => (
  <div className={styles.lobby}>
    <div className={styles.recentVisits}>
      <RecentVisits recentVisits={recentVisits} />
    </div>
    <div className={styles.quickLinks}>
      <QuickLinks />
    </div>
  </div>
);

export default Lobby;
