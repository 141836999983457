import { isNil, get, omitBy, isEmpty } from 'lodash';

import { STATE_COMMITTEE_ITEM_TYPE } from 'utils/constants/itemTypes';

import useCommitteeItem from './useCommitteeItem';

function useCommitteeTrack({
  feedToken,
  itemType,
  documentType,
  defaultSubscription,
  toggleModal,
  updateParams,
  onUpdateTrackCommittee,
  trackings,
  workspaces,
  listSubscriptions,
}) {
  const {
    buttonState,
    hearingSubscription,
    stafferSubscription,
    resetButtonState,
    resetData,
    isPresentHearings,
    isPresentStaffers,
    setStafferFrequency,
    setHearingsFrequency,
    setWeekAhead,
  } = useCommitteeItem({
    listSubscriptions,
    frequency: defaultSubscription,
  });

  const workspacesCommitteeItem = useCommitteeItem({ frequency: defaultSubscription });
  const showHearings = itemType === STATE_COMMITTEE_ITEM_TYPE;

  function onClose() {
    toggleModal();
    resetButtonState();
    resetData();
  }

  function getActionData({ sendWeekAheadEmail, type, ...restProps }) {
    return omitBy({
      ...(updateParams || {}),
      ...restProps,
      withWeekAheadEmail: !!sendWeekAheadEmail,
      sendWeekAheadEmail,
      type,
    }, isNil);
  }

  function onSave() {
    const { isStaffer, isHearing } = buttonState || {};
    const hearingData = getActionData(hearingSubscription);
    const stafferData = getActionData(stafferSubscription);
    const workspacesData = {
      ...workspacesCommitteeItem.buttonState,
      isPresentHearings: workspacesCommitteeItem.isPresentHearings,
      isPresentStaffers: workspacesCommitteeItem.isPresentStaffers,
      hearingData: getActionData(workspacesCommitteeItem.hearingSubscription),
      stafferData: getActionData(workspacesCommitteeItem.stafferSubscription),
    };

    return onUpdateTrackCommittee({
      feedToken,
      documentType,
      isStaffer,
      isHearing,
      isPresentStaffers,
      isPresentHearings,
      hearingData,
      stafferData,
      trackings,
      workspaces,
      itemType,
      ...(!isEmpty(workspaces) && { workspacesData }),
    }).finally(toggleModal);
  }

  const getFrequency = subscription => get(subscription, 'frequency');
  const getSendWeekAheadEmail = subscription => get(subscription, 'sendWeekAheadEmail', false);

  const getIsDisabled = () => {
    const { isStaffer, isHearing } = buttonState;

    if (isEmpty(workspaces)) {
      return !(isStaffer || isHearing);
    }

    const isMyFeedValid = workspaces.isMyFeedChecked && (isStaffer || isHearing);
    const {
      isStaffer: isWorkspaceStaffer,
      isHearing: isWorkspaceHearing,
    } = workspacesCommitteeItem.buttonState;
    const isWorkspacesValid = workspaces.isWorkspacesChecked
      && !isEmpty(workspaces.values)
      && (isWorkspaceStaffer || isWorkspaceHearing);

    return !(isMyFeedValid || isWorkspacesValid);
  };

  return {
    ...buttonState,
    isPresentHearings,
    isPresentStaffers,
    staffer: getFrequency(stafferSubscription),
    hearings: getFrequency(hearingSubscription),
    sendWeekAheadEmail: getSendWeekAheadEmail(hearingSubscription),
    setStafferFrequency,
    setHearingsFrequency,
    setWeekAhead,
    onClose,
    onSave,
    workspacesCommitteeItem: {
      ...workspacesCommitteeItem,
      ...workspacesCommitteeItem.buttonState,
      staffer: getFrequency(workspacesCommitteeItem.stafferSubscription),
      hearings: getFrequency(workspacesCommitteeItem.hearingSubscription),
      sendWeekAheadEmail: getSendWeekAheadEmail(workspacesCommitteeItem.hearingSubscription),
    },
    isDisabled: getIsDisabled(),
    showHearings,
  };
}

export default useCommitteeTrack;
