import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/core/Link';

import styles from './styles.module.scss';

function DisabledCreateMessage({ isMobile }) {
  const image = isMobile ? 'not-allowed-illustration' : 'not-allowed-icon';

  return (
    <div className={styles.messageContainer}>
      <img
        src={`/images/${image}.png`}
        alt="alert symbol"
        className={styles.image}
      />

      <p>
        Contact our Customer Success team to enable collaboration with your team across the app.
      </p>

      <Link
        href="mailto:success@phone2action.com"
        theme="light"
        className={styles.link}
      >
        Get in touch with Customer Success
      </Link>
    </div>
  );
}

DisabledCreateMessage.defaultProps = {
  isMobile: false,
};

DisabledCreateMessage.propTypes = {
  isMobile: PropTypes.bool,
};

export default DisabledCreateMessage;
