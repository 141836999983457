import { LOCATION_TYPES } from 'utils/constants/locationTypes';

export const STRATEGIES = {
  none: 'none',
  smartLists: 'smart_lists',
  lists: 'stakeholder_mgmt_list',
  congressionalDistricts: 'mappy_congressional_district',
  stateUpperDistricts: 'state_legislation_upper_district',
  stateLowerDistricts: 'state_legislation_lower_district',
  [LOCATION_TYPES.countries]: 'address_country',
  [LOCATION_TYPES.states]: 'address_state',
  [LOCATION_TYPES.cities]: 'address_city',
  [LOCATION_TYPES.zipCodes]: 'address_zip_code',
};

export const TYPES = {
  none: 'none',
  congressional: 'congressionalDistricts',
  upper: 'stateUpperDistricts',
  lower: 'stateLowerDistricts',
};

export const getDistrictFilters = isMobile => [
  {
    label: 'All',
    strategy: STRATEGIES.none,
    type: TYPES.none,
  },
  {
    label: isMobile ? 'Cong.' : 'Congressional',
    strategy: STRATEGIES.congressionalDistricts,
    type: TYPES.congressional,
  },
  {
    label: isMobile ? 'Upper' : 'State Upper',
    strategy: STRATEGIES.stateUpperDistricts,
    type: TYPES.upper,
  },
  {
    label: isMobile ? 'Lower' : 'State Lower',
    strategy: STRATEGIES.stateLowerDistricts,
    type: TYPES.lower,
  },
];

export const getLocationFilters = () => [
  {
    label: 'All',
    strategy: STRATEGIES.none,
    type: LOCATION_TYPES.none,
  },
  {
    label: 'Country',
    strategy: STRATEGIES.countries,
    type: LOCATION_TYPES.countries,
  },
  {
    label: 'State',
    strategy: STRATEGIES.states,
    type: LOCATION_TYPES.states,
  },
  {
    label: 'City',
    strategy: STRATEGIES.cities,
    type: LOCATION_TYPES.cities,
  },
  {
    label: 'ZIP',
    strategy: STRATEGIES.zipCodes,
    type: LOCATION_TYPES.zipCodes,
  },
];
