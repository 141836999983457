import PropTypes from 'prop-types';
import React from 'react';

export default class ErrorMessage extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    filesPresent: PropTypes.bool.isRequired,
    fileErrors: PropTypes.array.isRequired,
    inputErrors: PropTypes.array.isRequired,
  }

  errorMessage = (messages) => {
    if (messages.length === 0) {
      return null;
    }

    return (
      <span className="text-danger">
        { messages[0] }
      </span>
    );
  }

  showErrors = () => {
    const { filesPresent, loading } = this.props;

    return !filesPresent && !loading;
  }

  fileErrors = () => {
    const { fileErrors } = this.props;

    if (this.showErrors()) {
      return this.errorMessage(fileErrors);
    }

    return null;
  }

  inputErrors = () => {
    const { inputErrors, fileErrors } = this.props;

    if (this.showErrors() && fileErrors.length === 0) {
      return this.errorMessage(inputErrors);
    }

    return null;
  }

  render() {
    return (
      <span>
        { this.fileErrors() }
        { this.inputErrors() }
      </span>
    );
  }
}
