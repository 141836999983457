import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Reports({ className }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.icon, className)}>
      <path d="M5.5 8V6.5C5.5 4.01469 7.51562 2 10 2C12.4844 2 14.5 4.01469 14.5 6.5V8H15C16.1031 8 17 8.89688 17 10V16C17 17.1031 16.1031 18 15 18H5C3.89531 18 3 17.1031 3 16V10C3 8.89688 3.89531 8 5 8H5.5ZM7.5 8H12.5V6.5C12.5 5.11937 11.3813 4 10 4C8.61875 4 7.5 5.11937 7.5 6.5V8Z" fill="#E9EAEF" />
    </svg>
  );
}

Reports.propTypes = {
  className: PropTypes.string,
};

export default Reports;
