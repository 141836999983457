import PropTypes from 'prop-types';
import React from 'react';
import Form from './Form.jsx';
import legislatorStaffersFetcher from './legislatorStaffersFetcher';

class StaffersMailer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staffers: [],
    };
    this.toggleFormVisibility = this.toggleFormVisibility.bind(this);
    this.loadStaffers = this.loadStaffers.bind(this);
  }

  loadStaffers() {
    legislatorStaffersFetcher({
      legislatorId: this.props.legislatorId,
      onSuccess: (response) => {
        const staffers = response.data;
        this.setState({ staffers });
      },
    });
  }

  toggleFormVisibility(formVisible) {
    if (formVisible) {
      this.loadStaffers();
    }
    this.setState({ formVisible });
  }

  render() {
    return (
      <span>
        <i
          className="far fa-envelope pointer"
          onClick={this.toggleFormVisibility.bind(this, true)}
        />
        <Form
          staffers={this.state.staffers}
          visible={this.state.formVisible}
          onToggleVisibility={this.toggleFormVisibility}
        />
      </span>
    );
  }
}

StaffersMailer.propTypes = {
  legislatorId: PropTypes.string.isRequired,
};

export default StaffersMailer;
